import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { generateRandomColor } from '../../../../utils/helper-functions';

interface Props {
    accountNames: string[]
    runtimeValues: number[]
}

const RuntimePie: React.FC<Props> = observer(({ accountNames, runtimeValues }) => {
    const [chartData, setChartData] = useState<{series: number[]; options: ApexOptions}>();
    
    const colors = React.useMemo(() => {
        return Array.from({ length: accountNames.length }, () => generateRandomColor())
    }, [accountNames.length]) 

    useEffect(() => {
        setChartData({
            series: runtimeValues,
            options: {
                title: {
                    text: 'Training Runtime by User',
                    margin: 30,
                    style: {
                        fontWeight: 'bold',
                        fontFamily: 'inherit',
                    },
                },
                chart: {
                    type: 'pie',
                    background: '#fff',
                    toolbar: {
                        show: false,
                    }
                },
                tooltip: {
                    y:{
                        formatter(val, opts) {
                            return `${val} minutes`
                        },
                    }
                },
                legend: {
                    position: 'top',
                },
                labels: accountNames,
                colors,
                noData: {
                    text: 'No data found for selected month',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      fontSize: '14px',
                      fontFamily: 'inherit'
                    }
                  }
            },
        });
    }, [accountNames, runtimeValues]);

    return (
        <>
            {chartData && (
                <div className="p-2 w-100">
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="pie"
                        width="100%"
                        height="400"
                    />
                </div>
            )}
        </>
    );
});

export default RuntimePie;
