import React, { useEffect, useState } from 'react';
import { Project, ProjectGroupUpdate, ProjectUpdate } from '../../../../models/api';
import { useFormik } from 'formik';
import { Button, Form, Input, Label, ModalProps } from 'reactstrap';
import DropdownList from 'react-widgets/DropdownList';
import { useStores } from '../../../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ProjectGroupModal } from '../../../../components/Layout/AppSidebar/ProjectGroupModal';
import { observer } from 'mobx-react-lite';
import { sortByItemName } from '../../../../utils/sortByName';

interface Props extends ModalProps {
    project: Project | null;
    onConfirm: (id: string, params: ProjectUpdate) => void;
}

export const EditProjectForm: React.FC<Props> = observer((props) => {
    const { formId, project, onConfirm } = props;
    const [projectGroupCreateConfirmation, setProjectGroupCreateConfirmation] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState<{id: string | null, name: string}[]>([])
    const { projectGroupsStore } = useStores();
    const formik = useFormik({
        initialValues: {
            id: project?.id ?? '',
            projectName: project?.name ?? '',
            groupId: project?.groupId,
        },
        onSubmit: (values) => {
            onConfirm(values.id, { name: values.projectName, groupId: values.groupId });
        },
    });

    

    const handleGroupChange = (dataItem: { id: string | null, name: string }) => {
        formik.setFieldValue('groupId', dataItem.id);
    };

    const handleProjectGroupCreateOpen = () => {
        setProjectGroupCreateConfirmation(true);
    };
    const handleProjectGroupCreate = (params: ProjectGroupUpdate) => {
        projectGroupsStore.createItem(params);
        setProjectGroupCreateConfirmation(false);
    };
    const handleProjectGroupCreateClose = () => {
        setProjectGroupCreateConfirmation(false);
    };

    useEffect(() => {
        if (projectGroupsStore.isLoading) {
            return;
        }

        const emptyOption = { id: null, name: '-' }
        const groupOptions = projectGroupsStore.list.map((projectGroup) => ({
            id: projectGroup.id,
            name: projectGroup.name
        }))
        setDropdownOptions([emptyOption, ...groupOptions])
    }, [projectGroupsStore.isLoading]);

    return (
        <>
            <Form
                onSubmit={formik.handleSubmit}
                id={formId}
                className="d-flex flex-column align-items-start py-4"
            >
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <Label for="projectName" className="w-40 mb-0">
                        Project Name:
                    </Label>
                    <Input
                        id="projectName"
                        name="projectName"
                        type="text"
                        className="w-60"
                        onChange={formik.handleChange}
                        value={formik.values.projectName}
                    />
                </div>
                <div className='d-flex justify-content-start align-items-center w-100 mt-2'>
                    <Label for="id" className="w-40 mb-0">
                        Group:
                    </Label>
                    <div className='d-flex w-60'>
                        <DropdownList
                            data={sortByItemName(dropdownOptions)}
                            className='w-80 mr-3'
                            dataKey='id'
                            textField='name'
                            filter={false}
                            value={formik.values.groupId}
                            onChange={handleGroupChange}
                        />
                        <Button color='light' className='pt-2 pb-2' onClick={handleProjectGroupCreateOpen}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </div>
                </div>
            </Form>
            <ProjectGroupModal
                modalTitle='Create New Group'
                isOpen={projectGroupCreateConfirmation}
                onClosed={handleProjectGroupCreateClose}
                onConfirm={handleProjectGroupCreate}
            />
        </>
    );
});
