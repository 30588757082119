import { RootStore } from './root';
import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import { FileUploadElement, User } from '../../models/api';
import container from '../../container/container';
import { toast } from 'react-toastify';
import { createProgressToast } from '../../components/Toasts/ProgressToast';

interface ListProperty {
    items: User[];
    isLoading: boolean;
    isFetched: boolean;
}

interface ItemProperty {
    item?: User;
    isLoading: boolean;
    isFetched: boolean;
}

const api = container.apiClient;

export class UsersStore {
    list: ListProperty = {
        items: [],
        isLoading: false,
        isFetched: false
    };

    item: ItemProperty = {
        isLoading: false,
        isFetched: false
    };

    rootStore: RootStore;

    private readonly disposers: IReactionDisposer[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.disposers.push(
            reaction(() => ({
                userCount: this.list.items.length,
            }), ({ userCount }) => {
                this.rootStore.statisticsStore.setAdminStatistic({
                    ...this.rootStore.statisticsStore.admin,
                    userCount
                })
            })
        )
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    get isLoading() {
        return this.list.isLoading || this.item.isLoading;
    }

    async fetchList() {
        if (this.isLoading) {
            return;
        }

        this.list.isLoading = true;

        try {
            const { data } = await api.userList();

            data.map(user => {
                if(user.events !== null && user.events?.length > 0){
                    return user.lastActivity = user.events[0].createdAt;
                } else {
                    return user.lastActivity = user.updatedAt;
                }
            })

            runInAction(() => {
                this.list.items = data;
                this.list.isFetched = true;
            });
        } finally {
            runInAction(() => {
                this.list.isLoading = false;
            });
        }
    }

    async fetchItem(id: User['id']) {
        this.item.isLoading = true;
        this.item.isFetched = false;
        this.item.item = undefined;

        try {
            const { data } = await api.userGet(id);

            runInAction(() => {
                this.item.item = data;
                this.item.isFetched = true;
            });
        } finally {
            runInAction(() => {
                this.item.isLoading = false;
            });
        }
    }

    async updateItem(user: User) {
        this.item.isLoading = true;

        try {
            const { data } = await api.userUpdate(user);
            data.lastActivity = data.updatedAt;
            
            runInAction(() => {
                this.item.item = data;
                if (this.list.isFetched) {
                    this.list.items = this.list.items.map(item => item.id === data.id ? data : item);
                }
            });

            toast('User information updated successfully', { type: toast.TYPE.SUCCESS });
        } catch (e) {
            toast('Update user information failed. Try again later', { type: toast.TYPE.ERROR });
        } finally {
            runInAction(() => {
                this.item.isLoading = false;
            });
        }
    }

    async deleteItem(id: User['id']) {
        this.item.isLoading = true;
        const toastId = createProgressToast();
        try {
            await api.userDelete(id);

            runInAction(() => {
                this.list.items = this.list.items.filter(item => item.id !== id);
            });
        } finally {
            runInAction(() => {

                this.item.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async addFileUpload(cognitoId:string, values:FileUploadElement){
        if (!cognitoId) {
            return;
        }

        const toastId = createProgressToast();
        this.item.isLoading = true;

        try {
            const { data } = await api.userAddFileUpload(cognitoId, { ...values });
             runInAction(() => {
                if(this.item.item?.fileUploads) {
                    this.item.item.fileUploads.push(data)
                }
                this.item.isLoading = false;
            });
        } finally {
             runInAction(() => {
                 this.item.isLoading = false;
                 toast.dismiss(toastId);
            });
        }
    }

    async updateFileUpload(fileUploadId:string, values:FileUploadElement){
        if (!fileUploadId) {
            return;
        }

        const toastId = createProgressToast();
        this.item.isLoading = true;

        try {
            await api.userUpdateFileUpload(fileUploadId, {...values});
        } finally {
            runInAction(() => {
                const index = <number> this.item.item?.fileUploads?.findIndex(item => item.id === fileUploadId);
                if (this.item.item?.fileUploads && index >= 0) {
                    this.item.item.fileUploads[index].date = values.date;
                    this.item.item.fileUploads[index].type = values.type
                    this.item.item.fileUploads[index].title = values.title
                    this.item.item.fileUploads[index].link = values.link
                }
                this.item.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }


    async deleteFileUpload(fileUploadId:string) {
        if (!fileUploadId) {
            return;
        }

        const toastId = createProgressToast();
        this.item.isLoading = true;

        try {
            await api.userDeleteFileUpload(fileUploadId);
        } finally {
            runInAction(() => {
                if (this.item.item?.fileUploads) {
                    this.item.item.fileUploads = this.item.item?.fileUploads?.filter(item => item.id !== fileUploadId);
                }

                this.item.isLoading = false;
                toast.dismiss(toastId);

            });
        }
    }
}
