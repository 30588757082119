import React, { FunctionComponent} from 'react';
import { Route, RouteProps } from 'react-router';
import { useAuthCheck } from '../hooks/useAuthCheck';
import DnaLoader from '../../components/DnaLoader';
import { useStores } from '../../store';
import { Auth } from '@aws-amplify/auth';


export const NotAvailableRoute: FunctionComponent<RouteProps> = (
    props: RouteProps
) => {
    const {authenticated, checkingAuth} = useAuthCheck()
    const {userStore} = useStores();

    const logout = async () => {
        try{
            await Auth.signOut();
            userStore.clearUser();
        }catch (e) {
            console.error(e);
        }
    }

    React.useEffect(() => {
        if(authenticated) {
            logout();
        }
    }, [authenticated])

    if (checkingAuth) {
        return (
            <div className="layout-loading-overlay">
                <div>
                    <DnaLoader />
                    <span className="layout-loading-overlay-text">Initializing your workspace...</span>
                </div>
            </div>
        );
    }

    const { component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(props) =>
                (
                    //@ts-ignore
                    <Component {...props} />
                ) 
            }
        />
    );
};

export default NotAvailableRoute;
