import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';
import { Annotation, DEFAULT_LABEL_COLOR } from '../../../../models/api';
import { TooltipState } from '../../AnnotateLayout';

interface Props {
    annotation: Annotation;
    onChange: (annotation: Annotation) => void;
    boxId: string;
    setTooltipState: React.Dispatch<React.SetStateAction<TooltipState>>;
    className?: string;
    onDelete?: (annotation: Annotation) => void;
    disableDragging?: boolean;
    disableTooltip?: boolean;
}

const Box: React.FC<Props> = ({
    annotation,
    onChange,
    boxId,
    setTooltipState,
    onDelete,
    className,
    disableDragging,
    disableTooltip
}) => {
    const { x, y, width, height, label } = annotation;
    const color = label?.color || DEFAULT_LABEL_COLOR

    const handleDragStop: RndDragCallback = (e, d) => {
        onChange({
            ...annotation,
            x: d.x,
            y: d.y
        });
    };

    const handleResizeStop: RndResizeCallback = (e, direction, ref, delta, position) => {
        onChange({
            ...annotation,
            x: position.x,
            y: position.y,
            width: parseInt(ref.style.width, 10),
            height: parseInt(ref.style.height, 10)
        });
    };

    const handleDelete = (marker: Annotation) => () => {
        onDelete && onDelete(marker);
    };

    const handleTooltipEnter = () => {
        if (!disableTooltip) {
            setTooltipState({ currentTarget: boxId})
        }
    };
    const handleTooltipLeave = () => {
        if (!disableTooltip) {
            setTooltipState({ currentTarget: ''})
        }
    };

    return (
        <Rnd
            size={{ width, height }}
            position={{ x, y }}
            disableDragging={disableDragging}
            onDragStop={handleDragStop}
            onResizeStop={handleResizeStop}
            bounds='parent'
            className={className}
            >
            <div 
                id={boxId} 
                className='app-components-annotate-tool__box-selector' 
                onMouseEnter={handleTooltipEnter} 
                onMouseLeave={handleTooltipLeave}
            >
                <div className='app-components-annotate-tool__box-selector-header'>
                    <Button
                        hidden={!onDelete}
                        style={{ color }}
                        className={`app-components-annotate-tool__box-selector-remove-btn ml-2`}
                        onClick={handleDelete(annotation)}
                        >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>

                <div style={{ backgroundColor: color }} className={`app-components-annotate-tool__box-selector-bg`} />
                <div style={{ borderColor: color }} className={`app-components-annotate-tool__box-selector-border`} />
            </div>
        </Rnd>
    );
};

export default Box;
