import { ImageType, TextType, VideoType } from '../models/mimeTypes';
// @ts-ignore
import { getInfo } from 'react-mediainfo';
import * as path from 'path';
import qs from 'qs';

export const imageHashFormat = (str: string, fromStart: number, fromEnd: number) => {
    const [string, extension] = str.split('.');
    return `${string.slice(0, fromStart)}...${string.slice(fromEnd * -1)}.${extension}`;
};

export const isObjectEmpty = (obj: Object) => {
    return Object.keys(obj).length === 0;
};

export const isImageFile = (file: File) => {
    return Object.values(ImageType).includes(file.type as ImageType);
};

export const isVideoFile = (file: File) => {
    return Object.values(VideoType).includes(file.type as VideoType);
};

export const isTextFile = (file: File) => {
    return Object.values(TextType).includes(file.type as TextType);
};

export const getRandomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
};

export const getFileMediaInfo = async (file: File) => {
    const allInfo = await getInfo(file);
    return allInfo.media.track[0];
};

export const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const concatWithoutDuplicates = <T>(arr1: T[], arr2: T[]) => {
    return Array.from(new Set([...arr1, ...arr2]));
};

export const concatWithoutDuplicatesByOption = <T extends Record<string, any>>(
    arr1: T[],
    arr2: T[],
    option: string
) => {
    const obj: Record<string, T> = {};
    arr1.forEach((item) => {
        obj[item[option]] = item;
    });
    arr2.forEach((item) => {
        obj[item[option]] = item;
    });
    return Object.values(obj);
};

export const bytesToMb = (bytes: number) => {
    return bytes / (1024 * 1024);
};

export const truncate = (string: string, maxLength: number) => {
    return string.length > maxLength ? string.slice(0, maxLength - 3) + '...' : string;
};

export const compareByField = (
    obj1: Record<string, any>,
    obj2: Record<string, any>,
    option: string
) => {
    return obj1[option].localeCompare(obj2[option]);
};

export const removeSpecialCharactersFromString = (string: string) => {
    return string
        .replace(/\s\s+|\t|\r|\n/g, ' ')
        .replace(/[`~!@#$%^&*()|+=?;:'",.<>{}\[\]\\\/]/g, '')
        .replace(/_/g, '-')
        .trim();
};

export const concatFilesNameWithMaxLength = (files: File[], maxLength = 30) => {
    let str = '';

    files.forEach((file) => {
        str += file.name + ' ';
    });

    if (str.length > maxLength + 3) {
        return str.slice(0, maxLength - 3) + '...';
    } else {
        return str.slice(0, maxLength);
    }
};

export const changeFilenameExtension = (filename: string, newExtension: string) => {
    return path.basename(filename, path.extname(filename)) + '.' + newExtension;
};

export const getGroupIdFromURL = () => {
    return qs.parse(window.location.search, { ignoreQueryPrefix: true }).id?.toString();
}

export const generateRandomColor = () => {
    return '#' + (Math.random().toString(16) + '00000').slice(2,8)
}
