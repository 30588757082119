import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';

export const SearchBox: React.FC = observer(() => {
    const { projectsStore, datasetImageStore } = useStores();
    const projectId = projectsStore.current!.id;
    const isDisabled = datasetImageStore.hasUnprocessedItems;

    const [query, setQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] =  useState(false);
    const searchRef = useRef<HTMLInputElement>(null);

    const resetValue = () => {
        searchRef.current && (searchRef.current.value = '');
        setQuery('');
    }

    const handleSearch = async () => {
        if (datasetImageStore.pagination.sortOrder.searchQuery === query) {
            return;
        }
        setIsLoading(true);
        await datasetImageStore.fetchImages({ query, isClearQuery: !query });
        setIsLoading(false);
    };

    const handleClear = async () => {
        resetValue();
        if (datasetImageStore.pagination.sortOrder.searchQuery === '') {
            return;
        }
        setIsLoading(true);
        await datasetImageStore.fetchImages({ isClearQuery: true });
        setIsLoading(false);
    };

    useEffect(() => {
        resetValue();
        setIsOpen(false);
    }, [projectId, isDisabled]);

    return <>
        <div className={cn('search-wrapper', { active: isOpen })}>
            <div className='input-holder'>
                <input
                    ref={searchRef}
                    type='text'
                    className='search-input'
                    placeholder='Label name'
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                />
                {query && (
                    <FontAwesomeIcon icon={faTimes} onClick={handleClear} />
                )}
                <button
                    disabled={isDisabled}
                    className={cn('search-icon', { 'loading': isLoading })}
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    {isLoading ? <Spinner color='primary' size='sm' /> : <span />}
                </button>
            </div>
        </div>
    </>
});
