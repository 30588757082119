import { observer } from "mobx-react-lite";
import React from "react";
import * as Yup from 'yup';
import { Formik } from "formik";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PageTitle from "../../../../components/PageTitle";
import { useStores } from "../../../../store";
import { SetDeviceLicense } from "../../../../models/api";
import dayjs from "dayjs";
import { dateConfig } from "../../../../config/date";

const DeviceLicenseButton:React.FC = observer(() => {
    const { deviceLicensesStore } = useStores();

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const toggleModal = () => {
        setIsOpen((prev => !prev));
    }

    const onSubmit = (values: SetDeviceLicense) => {
        let licenseExpiry = new Date(values.expire);
        licenseExpiry.setHours(0, 0, 0, 0);
        deviceLicensesStore.addItem(
                {
                    clientInfo: values.clientInfo,
                    machine_id: values.machine_id,
                    expire: `${dayjs(licenseExpiry).format(dateConfig.formats.dateLicenseFormat)}`,
                    deviceName: values.deviceName
                }
        );
        toggleModal();
        deviceLicensesStore.fetchLicenses();
    }

    const validationSchema = Yup.object().shape({
        clientInfo: Yup.string().required('Client Info is a required field'),
        deviceName: Yup.string().required('Device Name is a required field'),
        machine_id: Yup.string().required('Machine ID is a required field'),
        expire: Yup.date().min(
            dayjs(new Date().setDate(new Date().getDate() + 1)).format(dateConfig.formats.dateOnlyDayTypeDate),
            ({min}) => `Date needs to be after or equal ${min}`)
        .required('License Expiry is a required field')
    })

    return (
        <Formik 
            initialValues={{
                clientInfo: '',
                machine_id: '',
                deviceName: '',
                expire: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmit(values)
            }}
        >
            {({values, handleChange, handleBlur, touched, errors, isValid}) => (
                <>
                    <div>
                        <Button
                            className="btn mr-1 mr-lg-2 text-nowrap"
                            color="primary"
                            onClick={toggleModal}
                            style={{ height: '35px', marginBottom: '25px' }}
                        >
                            New License
                        </Button>
                    </div>
                    <Modal isOpen={isOpen} toggle={toggleModal} className='w-100' centered-size="xl">
                        <ModalHeader toggle={toggleModal}>
                            <PageTitle 
                                heading="Create New License"
                                className="app-page-title--device-license"
                            />
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <div>
                                        <Label for="clientInfo">
                                            Client
                                        </Label>
                                        <Input 
                                            name="clientInfo"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.clientInfo}
                                            maxLength={255}
                                        />    
                                        <p style={{color: 'red'}}>{touched.clientInfo && errors.clientInfo}</p>
                                    </div>
                                    <div>
                                        <Label for="deviceName">
                                            Device Name
                                        </Label>
                                        <Input 
                                            name="deviceName"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.deviceName}
                                            maxLength={255}
                                        />  
                                        <p style={{color: 'red'}}>{touched.deviceName && errors.deviceName}</p>
                                    </div>
                                    <div>
                                        <Label for="machine_id">
                                            Machine ID
                                        </Label>
                                        <Input 
                                            name="machine_id"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.machine_id}
                                            maxLength={255}
                                        /> 
                                        <p style={{color: 'red'}}>{touched.machine_id && errors.machine_id}</p>
                                    </div>
                                    <div>
                                        <Label for="expiryDate">
                                            Expiry Date
                                        </Label>
                                        <Input 
                                            name="expire"
                                            type="date"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.expire}
                                            maxLength={255}
                                        />
                                        <p style={{color: 'red'}}>{touched.expire && errors.expire}</p>
                                    </div>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={() => onSubmit(values)} disabled={!isValid}>
                                Create
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
            )}
        </Formik>
    );
});

export default DeviceLicenseButton;