import React from 'react';
import { Badge } from 'reactstrap';
import CircularProgress from '../../../components/CircularProgress';
import { UploadingData, UploadingStatus } from '../../../store/stores/datasetImagesStore';

type Props = Partial<UploadingData>;

const ImageUploadIndicator: React.FC<Props> = ({ status, current, total, chunk }) => {
    return (
        <Badge pill color="warning mr-3" hidden={!status}>
            <div className="d-flex justify-content-center align-items-center">
                <CircularProgress color="dark" />
                <span className="ml-2">
                    {status === UploadingStatus.Compressing ? (
                        <>
                            Compressing:{' '}
                            {chunk === total
                                ? <>{current! + 1}-{current! + chunk!}</>
                                : <>{current! + 1}-{current! + chunk!} / {total}</>}
                        </>
                    ) : status === UploadingStatus.Uploading ? (
                        <>Uploading: {current!} / {total!}</>
                    ) : status === UploadingStatus.Annotating ? (
                        <>Annotating: {current!} / {total!}</>
                    ) : null}
                </span>
            </div>
        </Badge>
    );
};

export default ImageUploadIndicator;
