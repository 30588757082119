import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
} from 'reactstrap';
import { ProjectGroup, ProjectGroupUpdate } from '../../../../models/api';

interface Props extends ModalProps {
    onConfirm: (params: ProjectGroupUpdate) => void;
    projectGroup?: ProjectGroup | null;
}

export const DeleteProjectGroupModal: React.FC<Props> = (props) => {
    const { isOpen, projectGroup, onClosed } = props;
    return (
        <Modal isOpen={isOpen} toggle={onClosed}>
            <ModalHeader toggle={onClosed}>Confirming Deletion</ModalHeader>
            <ModalBody>
                Are you sure you want to delete the “{projectGroup?.name}” group? <br />
                All projects in this group will still be available under "All Projects".
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => props.onConfirm({ id: projectGroup?.id })}>
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    );
};
