import React, { FunctionComponent } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Field } from 'formik';
import { FormGroup, Label, FormFeedback, Col, Row } from 'reactstrap';
import { FeedbackStore } from '../../../../../store/stores/feedbackStore';

interface Props {
    credentials: {
        name: string;
        lastName: string;
        email: string;
    };
    formName: string;
    toggle: () => void;
    feedback: FeedbackStore;
}
interface LeaveFeedbackFormData {
    name: string;
    email: string;
    message: string;
}

export const LeaveFeedbackForm: FunctionComponent<Props> = (props) => {
    const { credentials, formName, toggle, feedback } = props;
    const initValues = {
        name: credentials['name'],
        lastName: credentials['lastName'],
        email: credentials['email'],
        message: '',
    };
    const validationSchema = Yup.object().shape({
        message: Yup.string().required('Please fill in this field'),
    });

    return (
        <Formik<LeaveFeedbackFormData>
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={async (values, formikBag) => {
                const reqBody = {
                    name: `${credentials['name']} ${credentials['lastName']}`,
                    email: credentials['email'],
                    message: values['message'],
                };
                feedback.sendEmail(reqBody);
                toggle();
            }}
        >
            {({ errors, touched }) => (
                <Form id={formName}>
                    <FormGroup>
                        <Row form className="align-items-center">
                            <Col xs={1.5} style={{minWidth: "50px"}}>
                                <Label className="font-weight-bold mb-0">
                                    {'Name'}
                                </Label>
                            </Col>
                            <Col>
                                {credentials.name} {credentials.lastName}
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row form className="align-items-center">
                            <Col xs={1.5} style={{minWidth: "50px"}}>
                                <Label className="font-weight-bold mb-0">
                                    {'Email'}
                                </Label>
                            </Col>
                            <Col>
                                {credentials.email}
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup className="mt-5">
                        <Label htmlFor={formName} className="font-weight-bold">
                            {'Message'}
                        </Label>
                        <Field
                            className={`form-control ${
                                touched.message &&
                                errors.message &&
                                'is-invalid'
                            }`}
                            id={formName}
                            name={'message'}
                            component={'textarea'}
                            placeholder={'Leave your feedback here'}
                            rows={4}
                        ></Field>
                        <FormFeedback>
                            {touched.message && errors.message}
                        </FormFeedback>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    );
};
