import cx from 'classnames';
import React from 'react';
import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, ModalHeader, ModalProps } from 'reactstrap';
import { toast } from 'react-toastify';

import container from '../../../container/container';
import LicenseCard from './LicenseCard';
import { FreeLicenseTypes, ProfessionalLicenseTypes } from './licenses';
import { useStores } from '../../../store';
import { createProgressToast } from '../../Toasts/ProgressToast';

interface Props extends ModalProps {
    isOpen: boolean;
    onClosed: () => void;
    onAccept?: () => void;
}

const api = container.apiClient;
const UpgradeLicense: React.FC<Props> = ({
    isOpen,
    body,
    onClosed,
    onAccept,
    ...rest
}) => {
    const { userStore } = useStores();
    const handleContactUs = async () => {
        if (userStore.user) {
            const toastId = createProgressToast();
            try {
                await api.sendUpgradeLicenseRequest({
                    email: userStore.user.email,
                });
                toast.success('Thank you! We will contact you via email soon.');
            } catch (err) {
                toast.error(err?.response?.data?.errors?.message);
            } finally {
                toast.dismiss(toastId);
            }
            onAccept && onAccept();
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={onClosed} {...rest} size="lg">
            <ModalHeader toggle={onClosed}>Upgrade to PRO</ModalHeader>
            <ModalBody className="app-component-modal-body__md">
                <div className="app-components-license__modal">
                    <LicenseCard
                        header={<span>FREE</span>}
                        content={FreeLicenseTypes.map((item, index) => {
                            return (
                                <>
                                    <p
                                        className={cx({
                                            'text-disabled': !item.default,
                                        })}
                                    >
                                        {!item.default && (
                                            <FontAwesomeIcon
                                                icon={faLock}
                                                className="mr-1"
                                            />
                                        )}
                                        {item.name}
                                    </p>
                                    {(index === 0 || index === 4 || index === 6) && (
                                        <div className="divider"></div>
                                    )}
                                </>
                            );
                        })}
                        footer={
                            <div className="license-button license-button-success">
                                <FontAwesomeIcon icon={faCheck} />
                                <span className="ml-1">Active</span>
                            </div>
                        }
                    />
                    <LicenseCard
                        header={
                            <span className="text-primary">PROFESSIONAL</span>
                        }
                        content={ProfessionalLicenseTypes.map((item, index) => {
                            return (
                                <>
                                    <p
                                        className={cx({
                                            'text-success': item.available,
                                        })}
                                    >
                                        {item.name}
                                    </p>
                                    {(index === 0 || index === 4 || index === 6) && (
                                        <div className="divider"></div>
                                    )}
                                </>
                            );
                        })}
                        footer={
                            <Button
                                className="license-button"
                                color="primary"
                                onClick={handleContactUs}
                            >
                                <span>Contact us</span>
                            </Button>
                        }
                        classNames="ml-4"
                    />
                </div>
            </ModalBody>
        </Modal>
    );
};

export default UpgradeLicense;
