import React, { MutableRefObject } from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
    Nav,
    NavItem,
    NavLink,
    Row, Spinner,
    UncontrolledDropdown
} from 'reactstrap';
import { Annotation, DatasetImage, Label, PolygonAnnotation, User } from '../../../models/api';
import cx from 'classnames';
import { AnnotateListProgress } from '../ImageAnnotateModal';
import { Storage } from 'aws-amplify';
import { s3Util } from '../../../utils/s3';
import { AccessLevel } from '@aws-amplify/ui-components';
import { v4 as uuidv4 } from 'uuid'
import Canvas from './Canvas';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faCropAlt, faHandPaper, faTrash } from '@fortawesome/free-solid-svg-icons';
import { compareByField } from '../../../utils/helper-functions';
import DropdownList from 'react-widgets/DropdownList';
import AnnotateStatusBadge from '../../../components/AnnotateStatusBadge';
import DatasetImageNavigation from '../Components/DatasetImageNavigation';
import { useHotkeys } from 'react-hotkeys-hook';
import { hotkeyAnnotateHandler } from '../utils';
import { Stage } from 'konva/lib/Stage';
import Konva from "konva";
import { ActiveAnnotatingImage } from '../../../store/stores/datasetImagesStore';

Konva.dragButtons = [0,2];

export const MAXIMUM_WIDTH = 750;
export const MAXIMUM_HEIGHT = 550;

export interface StageProps extends Stage { }

interface Props extends ModalProps {
    labelList: Label[];
    annotatedDatasetImage: ActiveAnnotatingImage;
    annotateListProgress?: AnnotateListProgress;
    onSave: (newAnnotationList: DatasetImage['polygonAnnotations']) => void;
    onNavNext: () => Promise<void>;
    onNavPrev: () => Promise<void>;
    onDelete?: () => Promise<void>;
    isLoading: boolean;
    error?: string;
    navPrevDisabled: boolean;
    navNextDisabled: boolean;
    impersonationUser?: User;
}

export type Points = {
    x: number,
    y: number
}

interface loadedImage {
    width: number;
    height: number;
    naturalWidth: number;
    naturalHeight: number;
    scale: number;
}

enum ToolBar {
    NewAnnotation = 'newAnnotation',
    RndLayout = 'rndLayout',
    MoveLayout = 'moveLayout'
}

const LABELS_N_THRESHOLD_TO_SHOW_DROPDOWN = 5;

const ImagePolygonAnnotateModal: React.FC<Props> = ({
    annotatedDatasetImage,
    labelList,
    annotateListProgress,
    onSave,
    onClosed,
    onNavNext,
    onNavPrev,
    onDelete,
    isLoading,
    error,
    navPrevDisabled = false,
    navNextDisabled = false,
    impersonationUser,
    ...rest
}) => {
    const [imageUrl, setImageUrl] = React.useState<string>('');
    const [loadedImage, setLoadedImage] = React.useState<loadedImage>();

    const [selectedLabel, setSelectedLabel] = React.useState<Label>(labelList[0]);
    const [isLoadingNext, setIsLoadingNext] = React.useState<boolean>(false);
    const [isLoadingPrev, setIsLoadingPrev] = React.useState<boolean>(false);

    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [isDraggable, setIsDraggable] = React.useState<boolean>(true);
    const [isStartMarkup, setIsStartMarkup] = React.useState<boolean>(false);

    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const stageRef = React.useRef<StageProps>(null);

    const [scale, setScale] = React.useState<number>();
    const [image, setImage] = React.useState<HTMLImageElement>();
    const [isPolygonComplete, setIsPolygonComplete] = React.useState<boolean>(false);

    const [points, setPoints] = React.useState<Points[]>([{x: 0, y: 0}]);
    const [flattenedPoints, setFlattenedPoints] = React.useState<Points[]>([{x: 0, y: 0}]);
    const [position, setPosition] = React.useState<Points>({x: 0, y: 0});
    const [isMouseOverPoint, setIsMouseOverPoint] = React.useState<boolean>(false);

    const [layouts, setLayouts] = React.useState<DatasetImage['polygonAnnotations']>();
    const [toolbar, setToolbar] = React.useState<ToolBar>();

    const [isIncreasePointRadius, setIsIncreasePointRadius] = React.useState<boolean>(false);
    const [stageHeight, setStageHeight] = React.useState<number>(0);
    const [stageWidth, setStageWidth] = React.useState<number>(0);
    const [stageOffset, setStageOffset] = React.useState<Points>({x: 0, y: 0});

    const sortedLabelList = labelList.slice().sort((a, b) => compareByField(a, b, 'name'));

    const imageSource = React.useMemo(() => {
        const element = new window.Image();
        element.src = imageUrl;
        return element;
    }, [imageUrl]);

    React.useEffect(() => {
        setLoadedImage(undefined);
        (async () => {
            setImageUrl(await annotatedDatasetImage.blobUrl);
        })();
    }, [annotatedDatasetImage]);

    React.useEffect(() => {
        const { polygonAnnotations } = annotatedDatasetImage;

        if (!polygonAnnotations) {
            return;
        }

        setLayouts(polygonAnnotations.map((item) => {
            const convertedCoordinates = item.coordinates!.reduce((resultArray: any[], item, index) => {
                const chunkIndex = Math.floor(index / 2)

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            }, []).map((p: number[]) => ({ x: Number(p[0]), y: Number(p[1]) }));

            return {
                ...item,
                points: convertedCoordinates,
                flattenedPoints: convertedCoordinates,
            }
        }))
    }, [annotatedDatasetImage, loadedImage]);

    const resetCanvasPosition = () => {
        if (stageRef.current) {
            stageRef.current.attrs.x = 0;
            stageRef.current.attrs.y = 0;
            stageRef.current.attrs.scale = { x: 1, y: 1 };
        }
    }

    const handleSaveNewLayout = () => {
        const newAnnotation = {
            id: uuidv4(),
            points: points,
            flattenedPoints: flattenedPoints,
            isPolygonComplete: true,
            color: selectedLabel.color,
            labelId: selectedLabel.id,
            datasetImageId: annotatedDatasetImage.id,
            label: selectedLabel
        };

        let newLayout: DatasetImage['polygonAnnotations'];

        if (layouts && layouts?.length > 0) {
            newLayout = [...layouts];
            newLayout.push(newAnnotation);
        } else {
            newLayout = [newAnnotation];
        }

        setLayouts(newLayout)
        onSave && onSave(newLayout);
        setPoints([]);
        setFlattenedPoints([]);
        setIsMouseOverPoint(false);
        setIsPolygonComplete(false);
    }

    const handleEditLabel = () => {
        setIsStartMarkup(false);
        setIsEdit(true);
        setPoints([]);
        setFlattenedPoints([]);
        setIsPolygonComplete(false);
        setToolbar(ToolBar.RndLayout)
    }

    const handleNewLabel = () => {
        setIsStartMarkup(true);
        setIsEdit(false);
        setPoints([]);
        setFlattenedPoints([]);
        setIsPolygonComplete(false);
        setToolbar(ToolBar.NewAnnotation)
    }

    const handleResetAnnotation = () => {
        setPoints([]);
        setFlattenedPoints([]);
        setIsPolygonComplete(false);
    }

    const handleDeleteAllLabel = () => {
        setLayouts([]);
        onSave && onSave([]);
    }

    const handleDeleteAnnotation = (id: string) => {
        if (layouts) {
            const newLayoutState = layouts.filter((item: PolygonAnnotation) => item.id !== id) || [];
            setLayouts(newLayoutState);
            onSave && onSave(newLayoutState);
        }
    }   

    const handleNavNext = async () => {
        if (onNavNext && !navNextDisabled) {
            setIsLoadingNext(true);
            setImage(undefined);
            resetCanvasPosition();
            await onNavNext();
            setIsLoadingNext(false);
        }
    }

    const handleNavPrev = async () => {
        if (onNavPrev && !navPrevDisabled) {
            setIsLoadingPrev(true);
            setImage(undefined);
            resetCanvasPosition();
            await onNavPrev();
            setIsLoadingPrev(false);
        }
    };

    useHotkeys('a,d,e,q,esc,n', hotkeyAnnotateHandler(
        sortedLabelList,
        handleNavPrev,
        handleNavNext,
        setSelectedLabel,
        selectedLabel,
        isLoadingPrev || isLoadingNext,
        handleResetAnnotation,
        handleNewLabel
    ), {}, [
        navNextDisabled,
        navPrevDisabled,
        selectedLabel,
        isLoadingPrev,
        isLoadingNext
    ]);

    return (
        <Modal
            toggle={onClosed}
            className={cx(
                'app-pages-dataset-page__annotation-modal',
                'app-component-modal',
                'app-component-modal_full-height'
            )}
            centered
            keyboard={false}
            onContextMenu={(e) => e.preventDefault()}
            {...rest}
        >
            <ModalHeader toggle={onClosed}>
                Annotate
            </ModalHeader>

            <div className='p-3'>
                <Row>
                    <Col sm={9}>
                        <Nav pills className='m-0 pr-3'>
                            {sortedLabelList.length <= LABELS_N_THRESHOLD_TO_SHOW_DROPDOWN ? (
                                sortedLabelList.map((item) => {
                                    const isActive = selectedLabel?.id === item.id;
                                    return (
                                        <NavItem key={item.id}>
                                            <NavLink
                                                active={isActive}
                                                style={{color: isActive ? '#fff' : item.color}}
                                                onClick={() => {
                                                    setSelectedLabel(item);
                                                    handleNewLabel();
                                                }}
                                            >
                                                {item.name}
                                            </NavLink>
                                        </NavItem>
                                    );
                                })
                            ) : (
                                <DropdownList
                                    data={sortedLabelList}
                                    value={selectedLabel}
                                    dataKey='id'
                                    textField='name'
                                    onChange={(label) => {
                                        setSelectedLabel(label);
                                        handleNewLabel();
                                    }}
                                />
                            )}
                        </Nav>
                    </Col>

                    <Col sm={3}>
                        <div className='d-flex justify-content-end align-items-center he-100'>
                            <ButtonWithTooltip
                                id='pages-dataset-image-annotate-modal-crop-btn'
                                size='sm'
                                tooltipProps={{
                                    tooltipText: 'Add new annotation'
                                }}
                                color={toolbar === ToolBar.NewAnnotation ? 'primary' : 'link'}
                                className='rounded-pill px-2'
                                onClick={handleNewLabel}
                            >
                                <FontAwesomeIcon icon={faCropAlt} />
                            </ButtonWithTooltip>

                            <ButtonWithTooltip
                                id='pages-dataset-image-annotate-modal-rnd-btn'
                                size='sm'
                                tooltipProps={{
                                    tooltipText: 'Edit annotations'
                                }}
                                color={toolbar === ToolBar.RndLayout ? 'primary' : 'link'}
                                className='rounded-pill px-2'
                                onClick={handleEditLabel}
                            >
                                <FontAwesomeIcon icon={faHandPaper} />
                            </ButtonWithTooltip>

                            <UncontrolledDropdown>
                                <DropdownToggle caret={true} className={'ml-2'} color={'danger'}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </DropdownToggle>
                                <DropdownMenu className='btn-exp-dr-body mt-1'>
                                    <DropdownItem className='pl-3' onClick={() => onDelete && onDelete()}>
                                        Delete image
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </Col>
                </Row>
            </div>

            <ModalBody className='d-flex align-items-center position-relative'>
                <div ref={wrapperRef} className='d-flex justify-content-center align-items-center'>
                    <div className='app-pages-dataset-page__annotation-modal__canvas'>
                        <Canvas
                            isEdit={isEdit}
                            isDraggable={isDraggable}
                            setIsDraggable={setIsDraggable}
                            isStartMarkup={isStartMarkup}
                            isPolygonComplete={isPolygonComplete}
                            setIsMouseOverPoint={setIsMouseOverPoint}
                            setScale={setScale}
                            setImage={setImage}
                            setPoints={setPoints}
                            setFlattenedPoints={setFlattenedPoints}
                            flattenedPoints={flattenedPoints}
                            image={image}
                            points={points}
                            isMouseOverPoint={isMouseOverPoint}
                            setIsPolygonComplete={setIsPolygonComplete}
                            position={position}
                            setPosition={setPosition}
                            layouts={layouts}
                            setLayouts={setLayouts}
                            scale={scale}
                            imageSource={imageSource}
                            handleDeleteAnnotation={handleDeleteAnnotation}
                            handleSaveNewLayout={handleSaveNewLayout}
                            onSave={onSave}
                            stageRef={stageRef}
                            isIncreasePointRadius={isIncreasePointRadius}
                            setStageHeight={setStageHeight}
                            stageHeight={stageHeight}
                            setStageWidth={setStageWidth}
                            stageWidth={stageWidth}
                            setStageOffset={setStageOffset}
                            stageOffset={stageOffset}
                        />
                    </div>
                </div>
                {!imageSource.width && <Spinner className='app-pages-dataset-page__annotation-modal__spinner' />}
            </ModalBody>

            <div className='p-3'>
                <div className='d-flex flex-column'>
                    <Col className='font-weight-bold text-center'>
                        Press "N" to switch to markup mode
                    </Col>
                </div>
                <Row>
                    <Col xs={12}>
                        <DatasetImageNavigation
                            imageName={annotatedDatasetImage.hashedName}
                            onNavNext={handleNavNext}
                            onNavPrev={handleNavPrev}
                            navNextDisabled={navNextDisabled}
                            navPrevDisabled={navPrevDisabled}
                            isLoadingNext={isLoadingNext}
                            isLoadingPrev={isLoadingPrev}
                        />
                    </Col>
                </Row>
            </div>

            <ModalFooter>
                <div className='w-100'>
                    <Row>
                        <Col md={3}>
                            <AnnotateStatusBadge isLoading={isLoading} error={error} />
                        </Col>

                        <Col md={6}>
                            <div
                                className='d-flex justify-content-center align-items-center w-100 font-weight-bold'>
                                Annotations: {layouts?.length || 0}

                                {annotateListProgress && (
                                    <div className='ml-4'>
                                        Images: {annotateListProgress.current} / {annotateListProgress.total}
                                    </div>
                                )}
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className='d-flex justify-content-end align-items-center w-100'>
                                <Button color='primary' onClick={onClosed}>
                                    Close
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ImagePolygonAnnotateModal;
