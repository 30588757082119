import React, { Fragment, useState, useEffect } from 'react';
import cx from 'classnames';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import { TransitionGroup } from 'react-transition-group';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Formik } from 'formik';

import { UserBox } from './Components/UserBox';
import { NotificationsBox } from './Components/NotificationsBox';
import HeaderLogo from '../AppLogo';
import CurrentProjectSelector from './Components/CurrentProjectSelector';
import { LeaveFeedbackForm } from './Components/Feedback-form';
import { LeaveFeedbackModal } from './Components/Feedback-form';
import { useStores } from '../../../store';
import UpgradeLicenseModal from '../../Modals/UpgradeLicense';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from 'reactstrap';
import { AIAssistantModal } from '../../Modals/AIAssistantModal';
import { NewProjectModal } from '../../../pages/dashboard/NewProjectModal';
import NewProjectForm from '../../../pages/dashboard/NewProjectForm';
import { ProjectType, ProjectTypeUI } from '../../../models/api';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../router/routes';

const aiTypeMap = {
    [ProjectTypeUI.modeling3d]: ProjectType.Modeling3d,
    [ProjectTypeUI.object]: ProjectType.ObjectDetection,
    [ProjectTypeUI.objectClassification]: ProjectType.ObjectClassification,
    [ProjectTypeUI.objectSegmentation]: ProjectType.ObjectSegmentation,
    [ProjectTypeUI.poseDetection]: ProjectType.PoseDetection,
    [ProjectTypeUI.predictiveMaintenance]: ProjectType.PredictiveMaintenance,
}

const Header = (props) => {
    const { feedbackStore, userStore, projectsStore } = useStores();
    const [showUpgradeLicenseModal, setShowUpgradeLicenseModal] = useState(false);
    const [showAIAssistantModal, setShowAIAssistantModal] = useState(false)
    const [showNewProjectModal, setShowNewProjectModal] = useState(false)
    const [state, setState] = useState({
        credentials: {
            name: '',
            lastName: '',
            email: '',
        },
        newProjectModalData: {
            projectTitle: '',
            aiType: ''
        },
        modal: false,
    });
    const history = useHistory();
    const {headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow} = props;
    const leaveFeedbackFormName = 'leaveFeedback-form';
    const newProjectFormNameFormName = 'pages-dashboard-new-project-form';

    useEffect(() => {
        const userInfo = async () => await Auth.currentUserInfo();
        userInfo().then((data) => {
            setState({
                ...state,
                credentials: {
                    name: data.attributes.name,
                    lastName: data.attributes.family_name,
                    email: data.attributes.email,
                },
            });
        });
    }, []);
    useEffect(() => {
        if (feedbackStore.isSent) {
            toast.success(
                'Thank you for your feedback! We will contact you via email soon.'
            );
            runInAction(() => {
                feedbackStore.isSent = false;
            });
        }

        if (feedbackStore.isError) {
            toast.error('Something went wrong');
            runInAction(() => {
                feedbackStore.isError = false;
            });
        }
    }, [feedbackStore.isSent, feedbackStore.isError]);

    const toggle = () => {
        setState((prev) => ({ ...prev, modal: !prev.modal }));
    };

    const toggleUpgradeLicense = () => {
        setShowUpgradeLicenseModal(prev => !prev);
    };

    const handleImpersonationUserLogout = () => {
        userStore.userImpersonation();
    };

    const handleSubmit = async (values) => {
        const newValues = {
            ...values,
            name: values.name.trim(),
        };
        const data = await projectsStore.createItem(newValues);
        projectsStore.setCurrentProject(data.id);

        if (data.type === ProjectType.PoseDetection) {
            history.push(Routes.ModelSimulation);
        } else if (data.type === ProjectType.Modeling3d) {
            history.push(Routes.ModelGenerator);
        } else {
            history.push(Routes.Labels);
        }
        setShowNewProjectModal(false);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .test(
                'is-project-exists',
                'A project with the same name already exists',
                (value) => !projectsStore.list.some((project) => project.name === value)
            )
            .required('Please fill in this field')
            .trim(),
    });

    const toggleAIAssistantModal = () => {
        setShowAIAssistantModal(prev => !prev)
    }

    const toggleNewProjectModal = (projectTitle, aiType) => {
        setState((prevState) => ({...prevState, newProjectModalData: {
            projectTitle: projectTitle || '',
            aiType: aiType || ''
        }}))
        setShowNewProjectModal(prev => !prev)
    }

    return (
        <Fragment>
            <TransitionGroup
                component="div"
                className={cx('app-header', headerBackgroundColor, {
                    'header-shadow': enableHeaderShadow,
                })}
                transitionName="HeaderAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}
            >
                <HeaderLogo adminLogo={userStore.user?.isAdmin} />
                <div
                    className={cx('app-header__content', {
                        'header-mobile-open': enableMobileMenuSmall,
                    })}
                >
                    <div className="app-header-left">
                        {userStore.impersonationUser &&
                            <div className='mr-4 d-flex align-items-center'>
                                <span className='font-weight-bold text-primary flex-shrink-0 mr-2'>User:</span>
                                {userStore.impersonationUser.email}
                                <Button color={null} className='ml-2 p-0'>
                                    <FontAwesomeIcon
                                        onClick={handleImpersonationUserLogout}
                                        icon={faSignOutAlt}
                                        className='text-primary'
                                        size='lg'
                                    />
                                </Button>
                            </div>
                        }

                        <div className='app-header__project-selector'>
                            <CurrentProjectSelector />
                        </div>
                    </div>
                    <div className="app-header-right">
                        <Button className="ml-3 btn-info btn-lg" onClick={toggleAIAssistantModal}>
                            <h6 className='m-0' style={{display: 'inline'}}>
                                <Badge color='warning' className='ml-0 mr-2 pro-color'>
                                    New
                                </Badge>
                            </h6>
                            Try our AI Assistant
                        </Button>
                        <AIAssistantModal isOpen={showAIAssistantModal} toggle={toggleAIAssistantModal} toggleNewProjectModal={toggleNewProjectModal} />
                        <UserBox toggle={toggle} toggleUpgradeLicense={toggleUpgradeLicense}/>
                        <NotificationsBox />
                    </div>
                    <LeaveFeedbackModal
                        isOpen={state.modal}
                        body={
                            <LeaveFeedbackForm
                                credentials={state.credentials}
                                formName={leaveFeedbackFormName}
                                toggle={toggle}
                                feedback={feedbackStore}
                            />
                        }
                        onClosed={toggle}
                        form={leaveFeedbackFormName}
                        centered={true}
                    />
                    <UpgradeLicenseModal
                        isOpen={showUpgradeLicenseModal}
                        onClosed={toggleUpgradeLicense}
                        onAccept={toggleUpgradeLicense}
                    />
                    <Formik
                        initialValues={{
                            name: state.newProjectModalData.projectTitle || '',
                            type: aiTypeMap[state.newProjectModalData.aiType] || ProjectType.ObjectDetection
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ resetForm }) => {
                            return (
                                <>
                                    <NewProjectModal toggle={toggleNewProjectModal} isOpen={showNewProjectModal} onClosed={resetForm}>
                                            <NewProjectForm formName={newProjectFormNameFormName} />
                                    </NewProjectModal>
                                </>
                            )
                        }}
                    </Formik>
                </div>
            </TransitionGroup>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(observer(Header));
