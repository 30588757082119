import React, { useMemo } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
    DetectionModel,
    DetectionModelBaseModelEnumUI,
    DetectionModelClassTypeEnumUI,
    DetectionModelFrameworkType,
    DetectionModelFrameworkTypeUI, DetectionModelInputSizeEnum,
    DetectionModelInputSizeEnumUI,
    DetectionModelValidationRatioEnumUI,
    ModelTrainingAugmentationSettings,
    ModelTrainingAugmentationSettingsUI
} from '../../../models/api';
import { BooleanUI } from '../../../models/utils';

export type ModelInfo = DetectionModel & { maxDetections?: number };
interface Props {
    modelInfo?: ModelInfo;
    isOpen: boolean;
    toggle: () => void;
}

const ModelInfoModal: React.FC<Props> = ({ isOpen, toggle, modelInfo }) => {

    const modelInputSize = useMemo(() => {
        return (
            modelInfo?.frameworkType === DetectionModelFrameworkType.TensorFlow &&
            modelInfo.modelInputSize === DetectionModelInputSizeEnum.VeryFast
            ? DetectionModelInputSizeEnum.Fast
            : modelInfo?.modelInputSize
        );
    }, [modelInfo]);

    const augmentationUIHandlersMap: Record<keyof ModelTrainingAugmentationSettings, (val: unknown) => string> = {
        'adjustBrightness': (val) => BooleanUI[`${val}`],
        'adjustHue': (val) => BooleanUI[`${val}`],
        'adjustSaturation': (val) => BooleanUI[`${val}`],
        'horizontalFlip': (val) => BooleanUI[`${val}`],
        'verticalFlip': (val) => BooleanUI[`${val}`],
        'randomScale': (val) => BooleanUI[`${val}`],
        'blur': (val) => typeof val === 'number' ? `${val * 100}%` : 'NaN',
        'saltPepper': (val) => typeof val === 'number' ? `${val * 100}%` : 'NaN',
        'randomLine': (val) => typeof val === 'number' ? `${val * 100}%` : 'NaN',
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="app-component-modal">
            <ModalHeader toggle={toggle}>Model Info</ModalHeader>
            <ModalBody>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>Model Name:</td>
                            <td>{modelInfo?.modelWithModelIndex}</td>
                        </tr>
                        {modelInfo?.frameworkType && (
                        <tr>
                            <td>Framework:</td>
                            <td>{DetectionModelFrameworkTypeUI[modelInfo.frameworkType]}</td>
                        </tr>
                        )}
                        {modelInfo?.isMultiLabel !== undefined && (
                        <tr>
                            <td>Model Type:</td>
                            <td>
                                {modelInfo?.isMultiLabel
                                    ? DetectionModelClassTypeEnumUI.Multi
                                    : DetectionModelClassTypeEnumUI.Single}
                            </td>
                        </tr>
                        )}
                        {modelInfo?.baseModel && (
                        <tr>
                            <td>Base Model:</td>
                            <td>{DetectionModelBaseModelEnumUI[modelInfo.baseModel]}</td>
                        </tr>
                        )}
                        {modelInputSize && (
                        <tr>
                            <td>Model Input Size:</td>
                            <td>{DetectionModelInputSizeEnumUI[modelInputSize]}</td>
                        </tr>
                        )}
                        {modelInfo?.trainingRatio && (
                        <tr>
                            <td>Training / Validation Ratio:</td>
                            <td>{DetectionModelValidationRatioEnumUI[modelInfo.trainingRatio]}</td>
                        </tr>
                        )}
                        {modelInfo?.accuracy && (
                        <tr>
                            <td>Model Accuracy:</td>
                            <td>{modelInfo.accuracy}%</td>
                        </tr>
                        )}
                        {modelInfo?.maxDetections && (
                        <tr>
                            <td>Max Detections:</td>
                            <td>{modelInfo.maxDetections}</td>
                        </tr>
                        )}
                        {modelInfo?.augmentations && Object.entries(modelInfo.augmentations)
                            .map(([key, value]) =>
                                <tr key={key}>
                                    <td>{ModelTrainingAugmentationSettingsUI[key as keyof ModelTrainingAugmentationSettings]}</td>
                                    <td>{augmentationUIHandlersMap[key as keyof ModelTrainingAugmentationSettings](value)}</td>
                                </tr>
                        )}
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModelInfoModal;
