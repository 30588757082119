import {
    DatasetImage,
    DetectionModel,
    DetectionModelDownload,
    DetectionModelGenerator,
    DetectionModelSimulation,
    Label,
    Project,
    ProjectGroup,
    User
} from '../api';

export enum CustomEventType {
    Login = 'Login',
    Logout = 'Logout',
    CreateProject = 'Create project',
    DuplicateProject = 'Duplicate project',
    DeleteProject = 'Delete project',
    OpenProject = 'Open project',
    CreateProjectGroup = 'Create project group',
    DeleteProjectGroup = 'Delete project group',
    ImageUpload = 'Image Upload',
    DeleteImage = 'Delete Image',
    AddLabel = 'Add Label',
    DeleteLabel = 'Delete Label',
    UpdateLabel = 'Update Label',
    Annotate = 'Annotate',
    StartTraining = 'Start Training',
    CancelTraining = 'Cancel Training',
    EarlyStopTraining = 'Early Stop Training',
    DeleteTraining = 'Delete Training',
    StartSimulation = 'Start Simulation',
    CreateDownload = 'Create Download',
    Start3dModeling = 'Start 3D Modeling',
    Cancel3dModeling = 'Cancel 3D Modeling',
    Delete3dModeling = 'Delete 3D Modeling',
    StartPredictiveMaintenanceTraining = 'Start Predictive Maintenance Training',
    DeletePredictiveMaintenanceTraining = 'Delete Predictive Maintenance Training'
}

interface LabelEventTarget {
    id: Label['id'];
    name: Label['name'];
    projectId: Project['id'];
    projectName: Project['name'];
}

interface DatasetImageEventTarget {
    id: DatasetImage['id'];
    hashedName: DatasetImage['hashedName'];
    projectId: Project['id'];
    projectName: Project['name'];
}

export interface AnnotationEventTarget {
    datasetImageId: DatasetImage['id'];
    datasetImageHash: DatasetImage['hashedName'];
    labelId: Label['name'];
    labelName: Label['name'];
    projectId: Project['id'];
    projectName: Project['name'];
}

interface ProjectEventTarget {
    id: Project['id'];
    name: Project['name'];
    type: Project['type'];
    templateId?: Project['id'];
}

interface ProjectGroupEventTarget {
    id: ProjectGroup['id'];
    name: ProjectGroup['name'];
}

interface DetectionModelEventTarget {
    id: DetectionModel['id'];
    index: DetectionModel['modelIndex'];
    frameworkType: DetectionModel['frameworkType'];
    projectId: Project['id'];
    projectName: Project['name'];
}

interface DetectionModelSimulationEventTarget {
    detectionModelId: DetectionModel['id'];
    detectionModelIndex: DetectionModel['modelIndex'];
    type: DetectionModelSimulation['type'];
    projectId: Project['id'];
    projectName: Project['name'];
}

interface DetectionModelDownloadEventTarget {
    detectionModelId: DetectionModel['id'];
    detectionModelIndex: DetectionModel['modelIndex'];
    type: DetectionModelDownload['type'];
    projectId: Project['id'];
    projectName: Project['name'];
}

interface DetectionModel3dModelingEventTarget {
    id: DetectionModelGenerator['id'];
    filename: DetectionModelGenerator['filename'];
    projectId: Project['id'];
    projectName: Project['name'];
}

export type CustomEventTarget =
    | LabelEventTarget
    | DatasetImageEventTarget
    | AnnotationEventTarget
    | ProjectEventTarget
    | ProjectGroupEventTarget
    | DetectionModelEventTarget
    | DetectionModelSimulationEventTarget
    | DetectionModelDownloadEventTarget
    | DetectionModel3dModelingEventTarget;

export interface DbEvent {
    type: CustomEventType;
    userId: User['id'];
    target: CustomEventTarget;
    createdAt: string;
    updatedAt: string;
}

export interface CustomEvent {
    type: CustomEventType;
    targetsCount: number;
    targets: CustomEventTarget[];
    createdAt: string;
}
