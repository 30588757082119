import * as Yup from 'yup';
import React, { FunctionComponent } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button } from 'reactstrap';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

import history from '../../../router/history';
import { PwdFormField } from '../../../components/Form';
import { Routes } from '../../../router/routes';
import { ChangeTemporaryPwdFormData } from '../../../models/login/change-temporary-pwd';
import { useStores } from '../../../store';

interface Props {}
type FormProps = Props & FormikProps<ChangeTemporaryPwdFormData>;

const InternalChangeTemporaryPwdForm: FunctionComponent<FormProps> = (props) => {
    const { userStore } = useStores();
    const formName = 'change-temporary-pwd-form';
    const isLoading = !props.isValidating && props.isSubmitting;

    return <Form>
        <PwdFormField formName={formName} field={'pwd'} placeholder={'New password'}/>

        <div className={'pt-4'}>
            <Button
                color={'primary'}
                type={'submit'}
                block
                disabled={isLoading}
            >
                Change password
            </Button>
        </div>
    </Form>
}

export const ChangeTemporaryPwdForm: FunctionComponent<Props> = (props) => {
    const { userStore } = useStores();

    const initialValues: ChangeTemporaryPwdFormData = {
        pwd: '',
    };

    const handleSubmit = async (values:ChangeTemporaryPwdFormData, formikHelpers: FormikHelpers<ChangeTemporaryPwdFormData>) => {
        try {
            await Auth.completeNewPassword(userStore.cognitoUserForNewPassword, values.pwd);
            toast('Password changed', {type:toast.TYPE.SUCCESS})
            history.push(Routes.SignIn);
            return
        } catch(error){
            toast(error.message, {type:toast.TYPE.ERROR});
        }
    
        formikHelpers.setSubmitting(false);
    }
    
    const validationSchema = Yup.object().shape({
        pwd: Yup.string().required('required')
    });

    return (
        <Formik<ChangeTemporaryPwdFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {(props) => <InternalChangeTemporaryPwdForm {...props} />}
        </Formik>
    );
}
