import React from 'react';
import { OptionComponentProp } from 'react-widgets/List';
import ListOption from 'react-widgets/ListOption';
import { UncontrolledTooltip } from 'reactstrap';
import DetectionModelFrameworkTypeIcon from '../../DetectionModelFrameworkTypeIcon';

const ModelOptionWithIcon: OptionComponentProp = ({ dataItem, selected, ...rest }) => {
    const { text, frameworkType } = dataItem;

    return (
        <ListOption
            selected={selected}
            dataItem={dataItem}
            {...rest}
        >
            {
                <DetectionModelFrameworkTypeIcon
                    frameworkType={frameworkType}
                    className='mr-2 img-w-sm'
                />
            }
            {
                rest.disabled ? (
                    <>
                        <span id="TextId">{text}</span>
                        <UncontrolledTooltip target="TextId">
                            You can't simulate TensorFlow (v1.15.2) models
                        </UncontrolledTooltip>
                    </>
                ) : <span>{text}</span>
                
            }
        </ListOption>
    );
};

export default ModelOptionWithIcon;
