import React from 'react';
import { ToastContent, toast } from 'react-toastify';
import CircularProgress from '../../CircularProgress';

const ProgressToast: React.FC<{text: string}> = ({ text }) => {
    return (
        <div className='d-flex'>
          <CircularProgress />
          <span className="ml-2">{text}...</span>
        </div>
    );
};

export default ProgressToast;

export const createProgressToast = (text = 'Processing') => {
  return toast(<ProgressToast text={text}/>, {
      position: 'bottom-center',
      autoClose: false,
      closeButton: false
  });
}

export const createProgressBarToast = (content: ToastContent) => {
  return toast(content, {
    autoClose: false,
    hideProgressBar: false,
    type: toast.TYPE.INFO
});
}
