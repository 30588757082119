import React from 'react';
import { FileUploadElement, Project } from '../../../models/api';
import { EditFileUploadForm } from './EditFileUploadForm';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
} from 'reactstrap';

interface Props extends ModalProps {
    project: FileUploadElement | null;
    onConfirm: (id:string, values: FileUploadElement) => void;
}

export const EditFileUploadModal: React.FC<Props> = (props) => {
    const { isOpen, onClosed, project, onConfirm } = props;
    const formId = 'edit-project-form';
    return (
        <Modal isOpen={isOpen} toggle={onClosed}>
            <ModalHeader toggle={onClosed}>Edit File Upload</ModalHeader>
            <ModalBody>
                <EditFileUploadForm
                    project={project}
                    formId={formId}
                    onConfirm={onConfirm}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="success" form={formId}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};
