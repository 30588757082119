import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import { VideoType, ZipType } from '../../../models/mimeTypes';
import { Extensions } from '../../../models/extensions';
import * as path from 'path';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { ModelGeneratorSettingsModal } from '../ModelGeneratorSettingsModal';
import { toast } from 'react-toastify';
import { truncate } from '../../../utils/helper-functions';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import { videoUtil } from '../../../utils/video';
import { MODELING_3D_MAX_FILE_DURATION_ERROR, MODELING_3D_WRONG_FILE_TYPE_ERROR } from '../../../utils/errors';

const POSSIBLE_MODEL_GENERATOR_VIDEO_TYPES = [VideoType.Mp4];
const POSSIBLE_MODEL_GENERATOR_VIDEO_EXTENSIONS = [Extensions.Mp4];

const POSSIBLE_MODEL_GENERATOR_IMAGE_TYPES = [ZipType.Zip];
const POSSIBLE_MODEL_GENERATOR_IMAGE_EXTENSIONS = [Extensions.Zip];

const MAX_VIDEO_DURATION_IN_SECONDS = 120;
const DEFAULT_VIDEO_FRAMES_NUMBER = 50;

interface Props {
    isStartingProcess: boolean;
    onProcess: (file: File, frames: number) => void;
}

export const ModelGeneratorVideoUploadPanel: React.FC<Props> = observer((props) => {
    const { isStartingProcess, onProcess } = props;

    const { projectsStore } = useStores();

    const [file, setFile] = useState<File | null>(null);
    const [frames, setFrames] = useState(DEFAULT_VIDEO_FRAMES_NUMBER);
    const [isVideoSettingsModalOpen, setIsVideoSettingsModalOpen] = useState(false);
    const [disabledVideoSettings, setDisabledVideoSettings] = useState<boolean>(true);

    useEffect(() => {
        setFrames(DEFAULT_VIDEO_FRAMES_NUMBER);
    }, [projectsStore.current?.id]);

    useEffect(() => {
        if (!isStartingProcess) {
            setFile(null);
        }
    }, [isStartingProcess]);

    const handleDropFiles = async (droppedFile: File) => {
        if (!isCorrectFileType(droppedFile)) {
            toast(MODELING_3D_WRONG_FILE_TYPE_ERROR('mp4 or zip'), { type: toast.TYPE.ERROR });
            return;
        }
        if (droppedFile.type !== ZipType.Zip && !(await isCorrectFileDuration(droppedFile))) {
            toast(MODELING_3D_MAX_FILE_DURATION_ERROR, { type: toast.TYPE.ERROR });
            return;
        }
        setFile(droppedFile);
    };

    const isCorrectFileType = (droppedFile: File) => {
        const mimeType = droppedFile.type;
        const extension = path.extname(droppedFile.name).slice(1);
        if(droppedFile.type === ZipType.Zip) {
            setDisabledVideoSettings(true);
            return (
                !!POSSIBLE_MODEL_GENERATOR_IMAGE_TYPES.find((item) => item === mimeType) &&
                !!POSSIBLE_MODEL_GENERATOR_IMAGE_EXTENSIONS.find((item) => item === extension)
            )
        }
        setDisabledVideoSettings(false);
        return (
            !!POSSIBLE_MODEL_GENERATOR_VIDEO_TYPES.find((item) => item === mimeType) &&
            !!POSSIBLE_MODEL_GENERATOR_VIDEO_EXTENSIONS.find((item) => item === extension)
        );
    };

    const isCorrectFileDuration = async (droppedFile: File) => {
        const duration = await videoUtil.getVideoDuration(droppedFile);
        return duration < MAX_VIDEO_DURATION_IN_SECONDS;
    };

    const toggleVideoSettingsModalOpen = () => {
        setIsVideoSettingsModalOpen((prev) => !prev);
    };

    const handleProcess = () => {
        if (file) {
            onProcess(file, frames);
        }
    };

    return (
        <>
            <div className='d-flex align-items-center w-100'>
                <div className='font-weight-bold text-primary flex-shrink-0'>
                    Upload video (mp4) or images (zip)
                </div>
                <div className='position-relative dropzone-wrapper h-auto w-auto ml-4'>
                    <Dropzone onDrop={(files) => files?.[0] && handleDropFiles(files[0])}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className='dropzone-content content-width-md p-2'>
                                    <span>
                                        {file
                                            ? truncate(file.name, 22)
                                            : 'Drop files here or click to upload'}
                                    </span>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
                <Button
                    color='light'
                    className='ml-4'
                    disabled={(disabledVideoSettings || isStartingProcess)}
                    onClick={toggleVideoSettingsModalOpen}
                >
                    <FontAwesomeIcon icon={faCog} />
                </Button>
                <Button
                    type='button'
                    color='primary'
                    className='ml-3 flex-shrink-0'
                    disabled={!file || isStartingProcess}
                    onClick={handleProcess}
                >
                    Process
                </Button>
            </div>
            <ModelGeneratorSettingsModal
                isOpen={isVideoSettingsModalOpen}
                handleToggle={toggleVideoSettingsModalOpen}
                frames={frames}
                setFrames={setFrames}
            />
        </>
    );
});
