import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import LabelsTable from './LabelsTable';
import NewLabelPanel from './NewLabelPanel';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store';
import Paper from '../../components/Paper';
import DnaLoader from '../../components/DnaLoader';
import { LabelUpdate } from '../../models/api';
import { compareByField } from '../../utils/helper-functions';

export const LabelsPage: React.FC = observer(() => {
    const { labelsStore } = useStores();

    const sortedLabelList = [...labelsStore.list].sort((a, b) => compareByField(a, b, 'name'));

    const handleCreate = (params: LabelUpdate[]) => {
        labelsStore.createItem(params);
    };

    const handleDelete = (id: string) => {
        labelsStore.deleteItem(id);
    };

    useEffect(() => {
        labelsStore.fetchList();
    }, [labelsStore]);

    useEffect(() => {
        if (!labelsStore.listIsFetched) {
            labelsStore.fetchList();
        }
    }, [labelsStore, labelsStore.listIsFetched]);

    return (
        <Layout>
            <>
                <PageTitle
                    heading="Object Labels"
                    icon="fas fa-tags icon-gradient bg-warm-flame"
                    subheading="Create your Machine Learning Projects"
                    showCloseButton={true}
                />

                <Row>
                    <Col sm={12}>
                        <NewLabelPanel onCreate={handleCreate} isLoading={labelsStore.isLoading} />
                    </Col>
                </Row>

                {!labelsStore.listIsFetched && <DnaLoader />}

                <Row>
                    <Col sm={12}>
                        <Paper rounded className="mt-1" hidden={!labelsStore.listIsFetched}>
                            <LabelsTable
                                items={sortedLabelList}
                                onDeleteLabel={handleDelete}
                                isLoading={labelsStore.isLoading}
                            />
                        </Paper>
                    </Col>
                </Row>
            </>
        </Layout>
    );
});
