import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import {
    faArrowRight,
    faClone,
    faPen,
    faTimes,
    faUndoAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';
import DeleteConfirmation from '../../../components/Modals/DeleteConfirmation';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import { useStores } from '../../../store';
import {
    Project,
    ProjectField,
    ProjectTag,
    ProjectTagCSSClasses,
    ProjectTagUI,
    ProjectType,
    ProjectTypeUI,
    ProjectUpdate
} from '../../../models/api';
import { dateConfig } from '../../../config/date';
import { Routes } from '../../../router/routes';
import { noDataText } from '../../../components/BootstrapTable';
import { CustomS3ImageWithSpinner } from '../../../components/AmplifyUi';
import { AccessLevel } from '@aws-amplify/ui-components';
import { s3Util } from '../../../utils/s3';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    UncontrolledTooltip
} from 'reactstrap';
import { EditProjectModal } from '../EditProjectModal';
import { CloneProjectModal } from '../CloneProjectModal';
import { compareByField, getGroupIdFromURL } from '../../../utils/helper-functions';
import imagePoseDetection from '../../../assets/app/images/image-pose-detection.png';
import { TagSort } from '../../../config/tags';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    tableType: 'projects' | 'projectsTrash' | 'oldProjects';
    urlSearchParam?: string;
}

const ProjectsTable: React.FC<Props> = observer(({ tableType, urlSearchParam }) => {
    const { projectsStore, projectsTrashStore, userStore } = useStores();
    const history = useHistory();

    const [projectEditConfirmation, setProjectEditConfirmation] = useState<Project | null>(null);
    const [projectCloneConfirmation, setProjectCloneConfirmation] = useState<Project | null>(null);
    const [projectDeleteConfirmation, setProjectDeleteConfirmation] = useState<Project | null>(
        null
    );

    const dashboardStoresList = {
        projects: projectsStore.list,
        projectsTrash: projectsTrashStore.list,
        oldProjects: projectsStore.listOld
    };

    const handleEditClose = () => {
        setProjectEditConfirmation(null);
    };
    const handleCloneClose = () => {
        setProjectCloneConfirmation(null);
    };
    const handleDeleteClosed = () => {
        setProjectDeleteConfirmation(null);
    };

    const handleEditConfirm = (id: string, params: ProjectUpdate) => {
        if (!projectEditConfirmation) {
            return;
        }
        projectsStore.updateItem(id, params);
        setProjectEditConfirmation(null);
    };
    const handleCloneConfirm = (id: string, fieldsToClone: ProjectField[]) => {
        if (!projectCloneConfirmation) {
            return;
        }
        const projectGroupId = getGroupIdFromURL();
        projectsStore.cloneItem(id, fieldsToClone, projectGroupId);
        setProjectCloneConfirmation(null);
    };
    const handleDeleteConfirm = () => {
        if (!projectDeleteConfirmation) {
            return;
        }
        projectsStore.deleteItem(projectDeleteConfirmation.id);
        setProjectDeleteConfirmation(null);
    };
    const handleRestore = (id: string) => {
        projectsTrashStore.restoreItem(id);
    };
    const handleProjectChange = (row: Project) => () => {
        projectsStore.setCurrentProject(row.id);
        if (row.type === ProjectType.PoseDetection) {
            history.push(Routes.ModelSimulation);
        } else if (row.type === ProjectType.Modeling3d) {
            history.push(Routes.ModelGenerator);
        } else {
            history.push(Routes.Labels);
        }
    };

    const handleProjectTagChange = (project: Project, tag: ProjectTag | null) => async () => {
        await projectsStore.updateItem(project.id, { tag });

        if (project.tag === 'old' && tag !== 'old') {
            projectsStore.setProjectCount(1);

            const projectGroupId = getGroupIdFromURL();
            projectsStore.fetchList(userStore.impersonationUser?.id, projectGroupId);
        } else if (tag === 'old') {
            projectsStore.setProjectCount(-1);

            const projectGroupId = getGroupIdFromURL();
            projectsStore.fetchList(userStore.impersonationUser?.id, projectGroupId);
        }
    };

    useEffect(() => {
        const groupId = getGroupIdFromURL();
        if (!projectsStore.isLoading && tableType === 'projects') {
            projectsStore.fetchList(userStore.impersonationUser?.id, groupId);
        }
    }, [projectsStore, projectsStore.isFetched, urlSearchParam, userStore.impersonationUser?.id]);

    useEffect(() => {
        if (
            !projectsTrashStore.isFetched &&
            !projectsTrashStore.isLoading &&
            tableType === 'projectsTrash'
        ) {
            projectsTrashStore.fetchList(userStore.impersonationUser?.id);
        }
    }, [projectsTrashStore, projectsTrashStore.isFetched]);

    const columns: ColumnDescription[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => {
                return rowIndex + 1;
            }
        },
        {
            dataField: 'name',
            text: 'Project Name',
            sort: true,
            formatter: (_cel, row: Project) => {
                const tooltipId = `pages-projects-projects-table_template-project-${row.id}`;
                return (
                    <div style={{ cursor: 'pointer' }} onClick={handleProjectChange(row)}>
                        {row.isTemplate && userStore.user?.isAdmin && (
                            <>
                                <span id={tooltipId} className='text-primary mr-1'>
                                    <FontAwesomeIcon icon={faClone} />
                                </span>
                                <UncontrolledTooltip target={tooltipId}>
                                    Template project
                                </UncontrolledTooltip>
                            </>
                        )}
                        {row.name}
                    </div>
                );
            }
        },
        {
            dataField: 'tag',
            text: 'Tag',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a: keyof typeof TagSort, b: keyof typeof TagSort, order) => {
                const aWeight = a in TagSort ? TagSort[a] : TagSort['other'];
                const bWeight = b in TagSort ? TagSort[b] : TagSort['other'];
                return order === 'asc' ? aWeight - bWeight : bWeight - aWeight;
            },
            formatter: (_cel, row: Project) => {
                const currentProjectTag = row.tag;
                return (
                    <UncontrolledDropdown>
                        <DropdownToggle
                            caret={true}
                            className={cx(
                                'project-tag-dropdown',
                                currentProjectTag
                                    ? ProjectTagCSSClasses[currentProjectTag]
                                    : 'project-tag-dropdown_transparent'
                            )}
                        >
                            {currentProjectTag ? ProjectTagUI[currentProjectTag] : 'Select'}
                        </DropdownToggle>
                        <DropdownMenu className='project-tag-dropdown__menu btn-exp-dr-body mt-1'>
                            {[null, ...Object.values(ProjectTag)].map((tag, i) => (
                                <DropdownItem
                                    key={`tag-option_${i}`}
                                    onClick={handleProjectTagChange(row, tag)}
                                    className='pl-3'
                                >
                                    {tag ? ProjectTagUI[tag] : 'None'}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            }
        },
        {
            dataField: 'datasetImages',
            text: 'Dataset Preview',
            headerClasses: 'app-components-boostrap-table__th-xs',
            classes: 'app-components-boostrap-table__td-thumbnail',
            formatter: (_cel, row: Project) => {
                const isPoseDetection = row.type === ProjectType.PoseDetection;
                const isModeling3d = row.type === ProjectType.Modeling3d;
                return isPoseDetection ? (
                    <div className='d-flex justify-content-center'>
                        <img src={imagePoseDetection} alt='Pose Estimation' />
                    </div>
                ) : isModeling3d ? (
                    row.detectionModelGenerators && (
                        <div className='d-flex justify-content-center'>
                            {row.detectionModelGenerators.map((generator) => (
                                <div className='mr-1'>
                                    <CustomS3ImageWithSpinner
                                        level={AccessLevel.Private}
                                        imgKey={s3Util.getModelGeneratorThumbnailKey(
                                            row.id,
                                            generator.id
                                        )}
                                        identityId={userStore.getIdentityIdSync()}
                                    />
                                </div>
                            ))}
                        </div>
                    )
                ) : (
                    row.datasetImages && (
                        <div className='d-flex justify-content-center'>
                            {row.datasetImages.map((image) => (
                                <div className='mr-1'>
                                    <CustomS3ImageWithSpinner
                                        level={AccessLevel.Private}
                                        imgKey={s3Util.getDatasetImageThumbnailKey(image)}
                                        identityId={userStore.impersonationUser?.storagePath}
                                    />
                                </div>
                            ))}
                        </div>
                    )
                );
            }
        },
        {
            dataField: 'open',
            text: 'Open Project',
            align: 'center',
            headerAlign: 'center',
            hidden: tableType === 'projectsTrash',
            formatter: (_cel, row: Project) => {
                const tooltipButtonId = `pages-projects-projects-table_open-button-${row.id}`;
                return (
                    <ActionButton
                        color='success'
                        onClick={handleProjectChange(row)}
                        id={tooltipButtonId}
                        tooltipText='Open item'
                        className='app-pages-projects-page__open-project-button'
                    >
                        <FontAwesomeIcon icon={faArrowRight} />
                    </ActionButton>
                );
            }
        },
        {
            dataField: 'type',
            text: 'Project Type',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row: Project) => ProjectTypeUI[row.type]
        },
        {
            dataField: 'createdAt',
            text: 'Date Created',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row: Project) => dayjs(row.createdAt).format(dateConfig.formats.date)
        },
        {
            dataField: 'updatedAt',
            text: 'Last Edited',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row: Project) => dayjs(row.updatedAt).format(dateConfig.formats.date),
            hidden: true
        },
        {
            dataField: 'detectionModels[0].accuracy',
            text: 'Accuracy',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row: Project) => {
                const isPoseDetection = row.type === ProjectType.PoseDetection;
                const isModeling3d = row.type === ProjectType.Modeling3d;
                const isAccuracyExists =
                    row.detectionModels &&
                    row.detectionModels.length &&
                    row.detectionModels[0].accuracy;

                if (isPoseDetection || isModeling3d) {
                    return 'N/A';
                } else {
                    return isAccuracyExists ? `${row.detectionModels[0].accuracy}%` : '';
                }
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            align: 'center',
            headerAlign: 'center',
            hidden: tableType === 'projectsTrash',
            formatter: (_cel, row: Project) => {
                const tooltipEditButtonId = `pages-projects-projects-edit-button-${row.id}`;
                const tooltipCloneButtonId = `pages-projects-projects-clone-button-${row.id}`;
                const tooltipDeleteButtonId = `pages-projects-projects-table_delete-button-${row.id}`;
                return (
                    <div className='d-flex justify-content-center'>
                        <ActionButton
                            color='warning'
                            onClick={() => setProjectEditConfirmation(row)}
                            id={tooltipEditButtonId}
                            tooltipText='Edit project'
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </ActionButton>
                        <ActionButton
                            className='ml-2'
                            color='primary'
                            onClick={() => setProjectCloneConfirmation(row)}
                            id={tooltipCloneButtonId}
                            tooltipText='Clone project'
                        >
                            <FontAwesomeIcon icon={faClone} />
                        </ActionButton>
                        <ActionButton
                            className='ml-2'
                            color='danger'
                            onClick={() => setProjectDeleteConfirmation(row)}
                            id={tooltipDeleteButtonId}
                            tooltipText='Delete project'
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </ActionButton>
                    </div>
                );
            }
        },
        {
            dataField: 'restore',
            text: 'Restore',
            align: 'center',
            headerAlign: 'center',
            hidden: tableType !== 'projectsTrash',
            formatter: (_cel, row: Project) => {
                const tooltipButtonId = `pages-projects-projects-table_delete-button-${row.id}`;
                return (
                    <ActionButton
                        color='primary'
                        onClick={() => handleRestore(row.id)}
                        id={tooltipButtonId}
                        tooltipText='Restore item'
                    >
                        <FontAwesomeIcon icon={faUndoAlt} />
                    </ActionButton>
                );
            }
        }
    ];

    const projectList = [...dashboardStoresList[tableType]].sort((a, b) =>
        compareByField(a, b, 'name')
    );

    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={projectList}
                columns={columns}
                striped
                bordered={false}
                // wrapperClasses='table-responsive'
                classes='m-0'
                noDataIndication={noDataText}
                defaultSorted={[{ dataField: 'tag', order: 'asc' }]}
            />

            <EditProjectModal
                isOpen={!!projectEditConfirmation}
                onClosed={handleEditClose}
                project={projectEditConfirmation}
                onConfirm={handleEditConfirm}
            />
            <CloneProjectModal
                isOpen={!!projectCloneConfirmation}
                onClosed={handleCloneClose}
                project={projectCloneConfirmation}
                onConfirm={handleCloneConfirm}
            />
            <DeleteConfirmation
                body={`Are you sure you want to delete the "${projectDeleteConfirmation?.name}" project?`}
                isOpen={!!projectDeleteConfirmation}
                onConfirm={handleDeleteConfirm}
                onClosed={handleDeleteClosed}
            />
        </>
    );
});

export default ProjectsTable;
