import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import cx from 'classnames';

export interface TabItem {
    id: string,
    title: string,
    content: React.ReactNode;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    items: TabItem[];
    activeTabId?: TabItem['id'];
    onTabChange?: (item: TabItem) => void;
    canChange?: boolean;
    setActiveTabId?: (id: string) => void;
}

const Tabs: React.FC<Props> = (props) => {
    const {items, className, onTabChange, canChange = true} = props;
    const [activeTabId, setActiveTabId] = useState(items[0].id);

    const handleNavClick = (item: TabItem) => () => {
        canChange && setActiveTabId(item.id);
        onTabChange && onTabChange(item);
    };
    
    useEffect(() => {
        if (props.activeTabId) {
            setActiveTabId(props.activeTabId)
        }
    }, [props.activeTabId]);

    return (
        <div className={cx('app-components-tabs', className)}>
            <Nav justified className='mb-3'>
                {items.map((item)  => (
                    <NavItem>
                        <NavLink
                            active={activeTabId === item.id}
                            onClick={handleNavClick(item)}
                        >
                            {item.title}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTabId}>
                {items.map(({ content, id })  => (
                    <TabPane tabId={id}>
                        {content}
                    </TabPane>
                ))}
            </TabContent>
        </div>
    );
}

export default Tabs;