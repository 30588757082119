import React, { Fragment, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import { BoxSelector } from '../';
import { Annotation } from '../../../models/api';
import { useStores } from '../../../store';

interface Props {
    annotationList: Annotation[];
    isLoadingImage: boolean;
    onChange?: (annotation: Annotation) => void;
    onDelete?: (annotation: Annotation) => void;
    boxClassName?: string;
    disableDragging?: boolean;
}

export interface TooltipState {
    currentTarget: string;
}

const AnnotateLayout: React.FC<Props> = ({
    annotationList,
    onChange,
    onDelete,
    boxClassName,
    isLoadingImage,
    disableDragging
}) => {
    const [tooltipState, setTooltipState] = React.useState<TooltipState>({ currentTarget: '' });
    const { annotationsEditorStore } = useStores();

    const handleMarkerChange = (changedAnnotation: Annotation) => {
        onChange && onChange(changedAnnotation);
    };

    const handleMarkerDelete = (changedAnnotation: Annotation) => {
        annotationsEditorStore.filterAnnotation(changedAnnotation.labelId, changedAnnotation.id);
        onDelete && onDelete(changedAnnotation);
        setTooltipState({ currentTarget: '' });
    };

    useEffect(() => {
        if (isLoadingImage) {
            setTooltipState({ currentTarget: '' });
        }
    }, [isLoadingImage]);

    return (
        <>
            {annotationList.map((item, idx) => (
                <Fragment key={`boxId${item.id}`}>
                    {tooltipState.currentTarget && (
                        <Tooltip
                            isOpen={tooltipState.currentTarget === `boxId${idx}`}
                            target={tooltipState.currentTarget}
                            placement={'top'}
                        >
                            {item.label?.name}
                        </Tooltip>
                    )}
                    <BoxSelector
                        boxId={`boxId${idx}`}
                        setTooltipState={setTooltipState}
                        annotation={item}
                        onChange={handleMarkerChange}
                        onDelete={onDelete && handleMarkerDelete}
                        className={boxClassName}
                        disableDragging={disableDragging}
                        disableTooltip={isLoadingImage}
                    />
                </Fragment>
            ))}
        </>
    );
};

export default AnnotateLayout;
