import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import React from 'react';
import UnblockedText from './UnblockedText';

interface Props {
    isMultiLabel: boolean;
    isOpen: boolean;
    onCancel: () => void;
    onAccept: () => void;
    isLoading?: boolean;
    isBlocked?: boolean;
}

export const MultiLabelConfirmationModal: React.FC<Props> = (props) => {
    const {
        isMultiLabel,
        isOpen,
        onCancel,
        onAccept,
        isLoading,
        isBlocked
    } = props;

    const blockedText = 'Cannot switch to single label classification because some of images have multiple labels.';

    return (
        <Modal
            isOpen={isOpen}
            toggle={onCancel}
            centered={true}
        >
            <ModalHeader toggle={onCancel}>
                Classification Model Type
            </ModalHeader>
            <ModalBody>
                <div className='py-4'>
                    {isBlocked ? blockedText : <UnblockedText isMultiLabel={isMultiLabel} />}
                </div>
            </ModalBody>
            <ModalFooter>
                {isBlocked || (
                    <Button
                        color='primary'
                        style={{ width: '5rem' }}
                        disabled={isLoading}
                        onClick={onAccept}
                    >
                        {isLoading ? <Spinner size='sm' /> : 'Yes'}
                    </Button>
                )}
                <Button
                    color='secondary'
                    style={{ width: '5rem' }}
                    onClick={onCancel}
                >
                    {isBlocked ? 'Close' : 'No'}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
