import {ReactComponent as TypingAnimation} from '../../assets/app/images/typing-animation.svg';
import './style.css'

const TypingIndicator = () => {
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <TypingAnimation />
        </div>
    );
}

export default TypingIndicator;
