import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import cx from 'classnames'
import { getGroupIdFromURL } from "../../../utils/helper-functions";

export interface ContextMenuItem {
    onClick: (itemId: string) => void;
    name: string;
    className?: string;
}

interface Props {
    onNavClick: () => void;
    contextMenuItems: ContextMenuItem[];
    dropdownToggleDisabled?: boolean;
}

const ContextMenuNavLink: (props: Props) => React.FC = (props) => (navLinkProps: any) => {
  const isLinkActive = () => navLinkProps.id === getGroupIdFromURL();

  return (
    <div className="metismenu-item_with-context-btn">
        <NavLink onClick={props.onNavClick} isActive={isLinkActive} {...navLinkProps} />
        <UncontrolledDropdown direction="right">
            <DropdownToggle
                color='transparent'
                className={cx('metismenu-item__context-btn', navLinkProps.className, { active: navLinkProps.id === getGroupIdFromURL()})}
                disabled={props.dropdownToggleDisabled}
            >
                <FontAwesomeIcon icon={faEllipsisH} />
            </DropdownToggle>
            <DropdownMenu
                positionFixed
                className='metismenu-item_context-menu mt-1'
            >
                {props.contextMenuItems.map((menuItem, i) => (
                    <DropdownItem key={`context-menu-option_${i}`} onClick={() => menuItem.onClick(navLinkProps.id)} className={cx('pl-3', menuItem.className)}>
                        {menuItem.name}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    </div>
)}

export default ContextMenuNavLink;