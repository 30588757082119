import React from 'react';
import cx from 'classnames'
import { MessageProps } from ".."
import ProfileImage from '../../../pages/user-profile/ProfileImage';


const OutgoingMessage = React.forwardRef<HTMLDivElement | null, MessageProps>((
    { 
        message,
        avatarUrl,
        wrapperClassName,
        messageClassName,
        avatarClassName
    },
    ref
) => {
    return (
        <div ref={ref} className={cx('app-components-chat-message app-components-chat-message_outgoing', wrapperClassName)}>
            <ProfileImage avatarUrl={avatarUrl} className={avatarClassName} />
            <p className={cx('app-components-chat-message__text', messageClassName)}>
                {message}
            </p>
        </div>
    );
})

export default OutgoingMessage