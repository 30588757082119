import React, { createRef, useState } from 'react';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import { Button, FormGroup, Label } from 'reactstrap';
import { FormField } from '../../../components/Form';
import { ProfileInfoFormData } from '../../../models/user-profile/profile-info';
import * as Yup from 'yup';
import ProfileImage from '../ProfileImage';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { User } from '../../../models/api';

interface Props {
    user: User;
    onSave: (user: User) => void;
    isLoading?: boolean;
    avatarUrl?: string;
    onUploadAvatar?: (file: File) => Promise<void>;
}
interface InnerProps{
    handleUploadAvatar?: (file:File) => Promise<void>;
    isLoading: Props['isLoading']
    avatarUrl: Props['avatarUrl']
}

type FormProps = InnerProps & FormikProps<ProfileInfoFormData>;

const _ProfileInfoForm: React.FC<FormProps> = (props) => {
    const formName = 'profile-info-form';
    const isLoading = !props.isValidating && (props.isSubmitting || props.isLoading);
    const [isUploadingAvatar, setIsUploadingAvatar] = useState<boolean>(false);

    const dropZoneRef = createRef<DropzoneRef>();

    const handleImageUpload = () => {
        if(dropZoneRef.current)
            dropZoneRef.current.open();
    }
    const handleAcceptedFile = async (files:File[]) => {
        setIsUploadingAvatar(true);

        props.handleUploadAvatar && await props.handleUploadAvatar(files[0]);

        setIsUploadingAvatar(false);
    }

    return <Form>
        {props.handleUploadAvatar &&
        <FormGroup>
            <Label>
                Avatar
            </Label>

            <div className='d-flex align-items-center'>
                <ProfileImage avatarUrl={props.avatarUrl} />
                <>
                    <Dropzone
                        noClick
                        noKeyboard
                        ref={dropZoneRef}
                        accept={'.png, .gif, .jpg , .jpeg'}
                        maxFiles={1}
                        onDropAccepted={handleAcceptedFile}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <Button type={'button'} color={'primary'} onClick={handleImageUpload}
                                        disabled={isUploadingAvatar}>
                                    Upload image
                                </Button>
                            </div>
                        )}
                    </Dropzone>

                    <div className='ml-4'>
                        {'JPG, GIF or PNG, < 5 MB.'}
                    </div>
                </>
            </div>
        </FormGroup>
        }

        <FormField
            label='First Name'
            formName={formName}
            field='name'
            isFirst
        />

        <FormField
            label='Last Name'
            formName={formName}
            field='surname'
        />

{/*         <FormField
            label='Role'
            formName={formName}
            field='role'
        /> */}

        <FormField
            label='Email'
            type='email'
            formName={formName}
            field='email'
            readOnly={true}
        />

        <Button
            color='primary'
            type='submit'
            disabled={isLoading}
        >
            Save
        </Button>
    </Form>
}

export const ProfileInfoForm:React.FC<Props> = (
    {
        user,
        onSave,
        avatarUrl,
        onUploadAvatar,
        isLoading
    }
) => {
    const initialValues:ProfileInfoFormData = {
        name: user.name,
        surname: user.surname,
        role: '',
        email: user.email
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('required'),
        surname: Yup.string().required('required'),
        email: Yup.string().required('required').email('invalid email format'),
    })

    const handleSubmit = async (
        values:ProfileInfoFormData,
        formikHelpers: FormikHelpers<ProfileInfoFormData>
    ) => {
        onSave({
            ...user,
            name: values.name,
            surname: values.surname,
            email: values.email
        });

        formikHelpers.setSubmitting(false);
    }

    return <Formik<ProfileInfoFormData>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {(props) => <_ProfileInfoForm
            {...props}
            avatarUrl={avatarUrl}
            isLoading={isLoading}
            handleUploadAvatar={onUploadAvatar}
        />}
    </Formik>
};
