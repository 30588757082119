import React from 'react';
import spinnerImg from '../../../assets/app/images/gold-orange-spinner.gif';

interface Props {
    spinner?: boolean
}

const MenuLabel: React.FC<Props> = ({ children, spinner }) => {
    return(
        <div className='d-flex align-items-center'>
            {children}

            {spinner && <img className='ml-3 img-w-xs' src={spinnerImg} alt='spinner' />}
        </div>
    );
}

export default MenuLabel;
