import React, { HTMLAttributes, MouseEventHandler, useRef, useState } from 'react';
import Line from './Line';
import { Coordinate } from '../../models/geometry';

interface Props extends HTMLAttributes<HTMLDivElement> {
    show: boolean;
}

const CrossHair: React.FC<Props> = ({ children, show, ...rest }) => {
    const [position, setPosition] = useState<Coordinate>();
    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter: MouseEventHandler<HTMLDivElement> = (e) => {
        setPosition(undefined);
    };

    const handleMouseLeave: MouseEventHandler<HTMLDivElement> = (e) => {
        setPosition(undefined);
    };

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
        if (!wrapperRef.current){
            return;
        }

        setPosition({
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY
        });
    };

    return (
        <div
            {...rest}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            ref={wrapperRef}
        >
            {children}

            {(show && position) && <>
                <Line axis='x' position={position?.x} />
                <Line axis='y' position={position?.y} />
            </>
            }
        </div>
    );
};

export default CrossHair;
