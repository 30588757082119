import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import container from '../../container/container';
import {
    DetectionModel,
    DetectionModelTrainingAccuracyStatistic,
    DetectionModelTrainingReport,
    DetectionModelTrainingStartRequest,
    ModelTrainingSettings,
    TrainingReportRequest
} from '../../models/api';
import { s3Util } from '../../utils/s3';
import { RootStore } from './root';
import { toast } from 'react-toastify';
import keyBy from 'lodash.keyby';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import { CustomEventType } from '../../models/events';
import { createHardRefreshToast } from '../../components/Toasts/HardRefreshToast';
import { NETWORK_ERROR_MESSAGE } from '../../utils/errors';

const api = container.apiClient;

interface ItemTrainingAccuracyStatisticProperty {
    isLoading: boolean;
    data?: DetectionModelTrainingAccuracyStatistic;
}

interface RunningListProperty {
    ListById: { [id: string]: DetectionModel };
    isLoading: boolean;
    isFetched: boolean;
}

interface TrainingReportProperty {
    ListByUserId: DetectionModelTrainingReport[];
    isLoading: boolean;
    isFetched: boolean;
}

export class DetectionModelsStore {
    listById: { [id: string]: DetectionModel } = {};
    runningTrainingsList: RunningListProperty = {
        ListById: {},
        isLoading: false,
        isFetched: false
    };

    terminatedTrainingsList: RunningListProperty = {
        ListById: {},
        isLoading: false,
        isFetched: false
    };

    isLoading: boolean = false;
    isStartingTraining: boolean = false;
    listIsFetched: boolean = false;
    itemTrainingAccuracyStatistic: ItemTrainingAccuracyStatisticProperty = {
        isLoading: false
    };

    trainingReport: TrainingReportProperty = {
        ListByUserId: [],
        isFetched: false,
        isLoading: false
    }

    rootStore: RootStore;

    private readonly disposers: IReactionDisposer[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.disposers.push(
            reaction(
                () => ({
                    runningModelTrainingCount: this.listOfRunning.length,
                    modelTrainingCount: Object.values(this.listById).length
                }),
                ({ runningModelTrainingCount, modelTrainingCount }) => {
                    this.rootStore.statisticsStore.setDashboardStatistic({
                        ...this.rootStore.statisticsStore.project,
                        runningModelTrainingCount,
                        modelTrainingCount
                    });
                }
            )
        );

        this.disposers.push(
            reaction(
                () => ({
                    runningTrainingCount: this.listOfAllRunning.length
                }),
                ({ runningTrainingCount }) => {
                    this.rootStore.statisticsStore.setAdminStatistic({
                        ...this.rootStore.statisticsStore.admin,
                        runningTrainingCount
                    });
                }
            )
        );
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    resetList() {
        runInAction(() => {
            this.listById = {};
            this.isLoading = false;
            this.listIsFetched = false;
        });
    }

    public get listOfCompleted() {
        if (!this.listById) {
            return [];
        }

        return Object.values(this.listById).filter(
            (item) => item.completedAt && !item.canceledAt && item.accuracy && item.accuracy > 0
        );
    }

    public get listOfRunning() {
        if (!this.listById) {
            return [];
        }

        return Object.values(this.listById).filter(
            (item) => !item.completedAt && !item.canceledAt && !item.earlyStoppedAt
        );
    }

    public get listOfAllRunning() {
        if (!this.runningTrainingsList.ListById) {
            return [];
        }

        return Object.values(this.runningTrainingsList.ListById);
    }

    async fetchList() {
        // TODO: find solution for check and waiting
        if (!this.rootStore.projectsStore.current || this.isLoading) {
            return;
        }

        this.isLoading = true;

        try {
            const { data } = await api.detectionModelList(this.rootStore.projectsStore.current.id);

            runInAction(() => {
                this.listById = keyBy(data, 'id');
                this.listIsFetched = true;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async fetchRunningTrainings() {
        if (this.runningTrainingsList.isLoading) {
            return;
        }
        this.runningTrainingsList.isLoading = true;

        try {
            const { data } = await api.detectionModelRunningList();
            runInAction(() => {
                this.runningTrainingsList.ListById = keyBy(data, 'id');
                this.runningTrainingsList.isFetched = true;
            });
        } finally {
            runInAction(() => {
                this.runningTrainingsList.isLoading = false;
            });
        }
    }

    async fetchTerminatedTrainings() {
        if (this.terminatedTrainingsList.isLoading) {
            return;
        }
        this.terminatedTrainingsList.isLoading = true;

        try {
            const { data } = await api.detectionModelRunningList({
                filter: {
                    completedAt: true,
                    earlyStoppedAt: true,
                    canceledAt: true
                },
                limit: 10,
                sortField: 'createdAt',
                sortOrder: 'DESC'
            });
            runInAction(() => {
                this.terminatedTrainingsList.ListById = keyBy(data, 'id');
                this.terminatedTrainingsList.isFetched = true;
            });
        } finally {
            runInAction(() => {
                this.terminatedTrainingsList.isLoading = false;
            });
        }
    }

    async fetchTrainingReport(params: TrainingReportRequest) {
        if (this.trainingReport.isLoading) {
            return;
        }
        this.trainingReport.isLoading = true;

        try {
            const { data } = await api.detectionModelTrainingReport(params);
            runInAction(() => {
                this.trainingReport.ListByUserId = data.filter((report) => report.totalRuntime !== '0');
                this.trainingReport.isFetched = true;
            });
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            runInAction(() => {
                this.trainingReport.isLoading = false;
            });
        }
    }

    async fetchItemTrainingAccuracyStatistic(id: DetectionModel['id']) {
        this.itemTrainingAccuracyStatistic.isLoading = true;
        this.itemTrainingAccuracyStatistic.data = undefined;

        try {
            const { data } = await api.detectionModelTrainingAccuracyStatistic(id);

            runInAction(() => {
                this.itemTrainingAccuracyStatistic.data = data;
            });
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            runInAction(() => {
                this.itemTrainingAccuracyStatistic.isLoading = false;
            });
        }
    }

    async deleteItem(id: string) {
        this.isLoading = true;
        const toastId = createProgressToast();
        try {
            //TODO find solution for check and waiting
            if (!this.rootStore.projectsStore.current) {
                return;
            }

            const { data } = await api.detectionModelDelete(id);
            runInAction(() => {
                delete this.listById[id];
                this.rootStore.eventsStore.addItem(CustomEventType.DeleteTraining, {
                    id: data.id,
                    index: data.modelIndex,
                    frameworkType: data.frameworkType,
                    projectId: this.rootStore.projectsStore.current!.id,
                    projectName: this.rootStore.projectsStore.current!.name
                });
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    // TODO: find solution for check and waiting
    async trainingStart(trainConfig: Partial<ModelTrainingSettings>) {
        if (!this.rootStore.projectsStore.current) {
            return;
        }

        const identityId = await this.rootStore.userStore.getIdentityIdByImpersonationUser();

        const toastId = createProgressToast();
        const params: DetectionModelTrainingStartRequest = {
            sourceS3BucketName: s3Util.getS3BucketName(),
            sourceS3ProjectFolder: await s3Util.getS3ProjectFolder(
                identityId,
                this.rootStore.projectsStore.current.id
            ),
            trainConfig
        };

        this.isStartingTraining = true;
        try {
            const { data } = await api.detectionModelTrainingStart(
                this.rootStore.projectsStore.current.id,
                params
            );
            toast('Training started! Sit back an enjoy your coffee.', { type: toast.TYPE.SUCCESS });
            runInAction(() => {
                this.listById[data.id] = data;
                this.incrementRunningTrainingsStatisticsCount();
                this.rootStore.eventsStore.addItem(CustomEventType.StartTraining, {
                    id: data.id,
                    index: data.modelIndex,
                    frameworkType: data.frameworkType,
                    projectId: this.rootStore.projectsStore.current!.id,
                    projectName: this.rootStore.projectsStore.current!.name
                });
            });
        } catch (e) {
            const errorMessage = e?.response?.data?.errors?.message ?? e.message;
            if (errorMessage !== NETWORK_ERROR_MESSAGE) {
                toast(errorMessage, { type: toast.TYPE.ERROR });
            } else {
                createHardRefreshToast(() => (this.listIsFetched = false));
            }
        } finally {
            toast.dismiss(toastId);
            runInAction(() => {
                this.isStartingTraining = false;
            });
        }
    }

    async trainingEarlyStop(id: string) {
        this.isLoading = true;

        const toastId = createProgressToast();

        try {
            const { data } = await api.detectionModelTrainingEarlyStop(id);
            runInAction(() => {
                this.listById[data.id] = data;
                this.decrementRunningTrainingsStatisticsCount();
                this.rootStore.eventsStore.addItem(CustomEventType.EarlyStopTraining, {
                    id: data.id,
                    index: data.modelIndex,
                    frameworkType: data.frameworkType,
                    projectId: this.rootStore.projectsStore.current!.id,
                    projectName: this.rootStore.projectsStore.current!.name
                });
            });

            toast("Roger that! We're stopping the training early.", { type: toast.TYPE.SUCCESS });
        } catch (e) {
            // TODO common error handler
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            toast.dismiss(toastId);
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async trainingCancel(id: string) {
        this.isLoading = true;

        const toastId = createProgressToast();

        try {
            const { data } = await api.detectionModelTrainingCancel(id);
            runInAction(() => {
                this.listById[data.id] = data;
                this.decrementRunningTrainingsStatisticsCount();
                this.rootStore.eventsStore.addItem(CustomEventType.CancelTraining, {
                    id: data.id,
                    index: data.modelIndex,
                    frameworkType: data.frameworkType,
                    projectId: this.rootStore.projectsStore.current!.id,
                    projectName: this.rootStore.projectsStore.current!.name
                });
            });

            toast("Scrap that! We'll cancel that training.", { type: toast.TYPE.SUCCESS });
        } catch (e) {
            // TODO common error handler
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            toast.dismiss(toastId);
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    incrementRunningTrainingsStatisticsCount() {
        if (this.rootStore.statisticsStore.admin?.runningTrainingCount !== undefined) {
            this.rootStore.statisticsStore.admin.runningTrainingCount += 1;
        }
    }

    decrementRunningTrainingsStatisticsCount() {
        if (this.rootStore.statisticsStore.admin?.runningTrainingCount) {
            this.rootStore.statisticsStore.admin.runningTrainingCount -= 1;
        }
    }
}
