import { ProjectsStore } from './projectsStore';
import { ProjectsTrashStore } from './projectsTrashStore';
import { DatasetImagesStore } from './datasetImagesStore';
import { LabelsStore } from './labelsStore';
import { AnnotationsStore } from './annotationsStore';
import { DetectionModelsStore } from './detectionModelsStore';
import { DetectionModelDownloadsStore } from './detectionModelDownloadsStore';
import { UserStore } from './userStore';
import { DetectionModelSimulationsStore } from './detectionModelSimulationsStore';
import { FeedbackStore } from './feedbackStore';
import { HelpVideoStore } from './helpVideoStore';
import { ImageToZipStore } from './imageToZipStore';
import { UsersStore } from './usersStore';
import { StatisticsStore } from './statisticsStore';
import { ClassificationsStore } from './classificationsStore';
import { EventsStore } from './eventsStore';
import { API } from 'aws-amplify';
import { DetectionModelGeneratorsStore } from './detectionModelGeneratorsStore';
import { DeviceLicensesStore } from './deviceLicensesStore';
import { PredictiveMaintenanceModelTrainingStore } from './predictiveMaintenanceModelTrainingStore';
import { PolygonAnnotationStore } from './polygonAnnotationsStore';
import { AnnotationsEditorStore } from './annotationsEditorStore';
import { ProjectGroupsStore } from './projectGroupsStore';

// TODO add error handler
export class RootStore {
    projectsStore: ProjectsStore;
    projectsTrashStore: ProjectsTrashStore;
    datasetImageStore: DatasetImagesStore;
    labelsStore: LabelsStore;
    annotationsStore: AnnotationsStore;
    detectionModelsStore: DetectionModelsStore;
    detectionModelDownloadsStore: DetectionModelDownloadsStore;
    userStore: UserStore;
    detectionModelSimulationsStore: DetectionModelSimulationsStore;
    detectionModelGeneratorsStore: DetectionModelGeneratorsStore;
    feedbackStore: FeedbackStore;
    helpVideoStore: HelpVideoStore;
    imageToZipStore: ImageToZipStore;
    usersStore: UsersStore;
    statisticsStore: StatisticsStore;
    classificationsStore: ClassificationsStore;
    eventsStore: EventsStore;
    deviceLicensesStore: DeviceLicensesStore;
    predictiveMaintenanceModelTrainingStore: PredictiveMaintenanceModelTrainingStore;
    polygonAnnotationStore: PolygonAnnotationStore;
    projectGroupsStore: ProjectGroupsStore;
    annotationsEditorStore: AnnotationsEditorStore;
    apiUrl: string | undefined;

    constructor() {
        this.projectsStore = new ProjectsStore(this);
        this.projectsTrashStore = new ProjectsTrashStore(this);
        this.datasetImageStore = new DatasetImagesStore(this);
        this.labelsStore = new LabelsStore(this);
        this.annotationsStore = new AnnotationsStore(this);
        this.detectionModelsStore = new DetectionModelsStore(this);
        this.detectionModelDownloadsStore = new DetectionModelDownloadsStore(this);
        this.userStore = new UserStore(this);
        this.detectionModelSimulationsStore = new DetectionModelSimulationsStore(this);
        this.detectionModelGeneratorsStore = new DetectionModelGeneratorsStore(this);
        this.feedbackStore = new FeedbackStore(this);
        this.helpVideoStore = new HelpVideoStore(this);
        this.imageToZipStore = new ImageToZipStore(this);
        this.usersStore = new UsersStore(this);
        this.classificationsStore = new ClassificationsStore(this);
        this.statisticsStore = new StatisticsStore(this);
        this.eventsStore = new EventsStore(this);
        this.deviceLicensesStore = new DeviceLicensesStore(this);
        this.predictiveMaintenanceModelTrainingStore = new PredictiveMaintenanceModelTrainingStore(this);
        this.polygonAnnotationStore = new PolygonAnnotationStore(this);
        this.projectGroupsStore = new ProjectGroupsStore(this);
        this.annotationsEditorStore = new AnnotationsEditorStore(this);
        this.getApiUrl();
    }

    async getApiUrl() {
        const amplifyUrl = await API.endpoint(process.env.REACT_APP_PROVIDERS_AMPLIFY_APP_NAME);
        const localUrl = process.env.REACT_APP_PROVIDERS_REMOTE_SERVER_BASE_URL;
        this.apiUrl =
            process.env.REACT_APP_CONTAINER_API_CLIENT !== 'remoteServer' ? amplifyUrl : localUrl;
    }
}
