import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import DownloadListTable from './DownloadListTable';
import GeneratingPanel from './GeneratingPanel';
import Paper from '../../components/Paper';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store';
import DnaLoader from '../../components/DnaLoader';
import {
    DetectionModelDownload,
    DetectionModelDownloadType,
    DetectionModelDownloadUpdate,
    LicenseType,
    ProjectType,
} from '../../models/api';
import { longOperationsConfig } from '../../config/long-operations';

const DownloadFilenames = {
    [DetectionModelDownloadType.ApkProDetection]: 'object-detection.apk',
    [DetectionModelDownloadType.ApkProClassification]: 'object-classification.apk',
    [DetectionModelDownloadType.PoseEstimationFree]: 'pose-estimation-free.apk',
    [DetectionModelDownloadType.PoseEstimationPro]: 'pose-estimation-pro.apk',
    [DetectionModelDownloadType.TFlite16PoseEstimation]: 'pose-estimation-tflite16.tflite',
    [DetectionModelDownloadType.ApkSourceCodeDetection]: 'apk_template_detection.zip',
    [DetectionModelDownloadType.ApkSourceCodeClassification]: 'apk_template_classification.zip',
    [DetectionModelDownloadType.TFLite]: 'model_int8.tflite',
    [DetectionModelDownloadType.TFLiteFloat16]: 'model_float16.tflite',
    [DetectionModelDownloadType.TFLiteFloat32]: 'model_float32.tflite',
    [DetectionModelDownloadType.FrozenGraphPBDetection]: 'pb_model.zip',
    [DetectionModelDownloadType.FrozenGraphPBClassification]: 'best_checkpoint.pb',
    [DetectionModelDownloadType.ONNXDetection]: 'model_final.onnx',
    [DetectionModelDownloadType.ONNXClassification]: 'model_final.onnx',
    [DetectionModelDownloadType.LabelMap]: 'labelMap.txt',
    [DetectionModelDownloadType.PytorchModel]: 'best.pt',
    [DetectionModelDownloadType.PytorchModel2]: 'model.zip',
    [DetectionModelDownloadType.None]: 'none',
};

export const ModelDownloadPage: React.FC = observer(() => {
    const { detectionModelDownloadsStore, detectionModelsStore, userStore, projectsStore } = useStores();

    const isObjectClassification = projectsStore.current?.type === ProjectType.ObjectClassification;
    const isObjectSegmentation = projectsStore.current?.type === ProjectType.ObjectSegmentation;
    const isObjectDetection = projectsStore.current?.type === ProjectType.ObjectDetection;
    const isPoseDetection = projectsStore.current?.type === ProjectType.PoseDetection;

    const licenceType = userStore.impersonationUser
        ? userStore.impersonationUser.licenseType
        : userStore.user?.licenseType;

    const handleCreate = ({detectionModel, ...rest}: DetectionModelDownloadUpdate) => {
        if (!detectionModel) {
            return;
        }
        detectionModelDownloadsStore.createItem(detectionModel.id, rest);
    };

    const handleDelete = (id: string) => {
        detectionModelDownloadsStore.deleteItem(id);
    };

    const handleDownload = async (params: DetectionModelDownload) => {
        const currentProjectName = projectsStore.current?.name;
        const s3Bucket = params.detectionModel.s3Bucket;
        const fileName = 
            params.type === DetectionModelDownloadType.ApkProDetection ||
            params.type === DetectionModelDownloadType.ApkProClassification
                ? `${currentProjectName?.replaceAll(' ', '_')}_${params.apkConfig.appTemplate}_${DownloadFilenames[params.type]}`
                : `${currentProjectName?.replaceAll(' ', '_')}_${DownloadFilenames[params.type]}`;
        await detectionModelDownloadsStore.downloadItem({ url: params.url, fileName, s3Bucket });
    };

    useEffect(() => {
        if (detectionModelDownloadsStore.list.isFetched) {
            detectionModelDownloadsStore.fetchList()
        }
        const handle = setInterval(async () => {
            await detectionModelDownloadsStore.fetchList();
        }, longOperationsConfig.refreshInterval)
        return () => {
            clearInterval(handle)
        }
    }, [detectionModelDownloadsStore]);

    useEffect(() => {
        if (!detectionModelDownloadsStore.list.isFetched) {
            detectionModelDownloadsStore.fetchList();
        }
        if (!detectionModelsStore.listIsFetched){
            detectionModelsStore.fetchList();
        }
    }, [
        detectionModelDownloadsStore,
        detectionModelsStore,
        detectionModelDownloadsStore.list.isFetched,
        detectionModelsStore.listIsFetched
    ]);

    const shouldGeneratingPanelBeDisabled = (detectionModelsStore.isLoading && !detectionModelsStore.listIsFetched) ||
        !detectionModelDownloadsStore.list.isFetched || detectionModelDownloadsStore.item.isLoading;

    return (
        <Layout>
            <PageTitle
                heading='Model Download'
                icon='fas fa-download icon-gradient bg-warm-flame'
                subheading='Download your machine learning model as an APK or TFlite model.'
                showCloseButton={true}
            />

            <Row>
                <Col sm={12}>
                    <GeneratingPanel
                        detectionModelList={detectionModelsStore.listOfCompleted}
                        disabled={shouldGeneratingPanelBeDisabled}
                        onCreate={handleCreate}
                        isLicenseTypePro={licenceType === LicenseType.Pro}
                        isObjectClassification={isObjectClassification}
                        isObjectSegmentation={isObjectSegmentation}
                        isObjectDetection={isObjectDetection}
                        isPoseDetection={isPoseDetection}
                    />
                </Col>
            </Row>

            {!detectionModelDownloadsStore.list.isFetched && (
                <DnaLoader />
            )}

            <Row>
                <Col sm={12}>
                    <Paper rounded className='mt-4' hidden={!detectionModelDownloadsStore.list.isFetched}>
                        <DownloadListTable
                            items={detectionModelDownloadsStore.list.items}
                            onDelete={handleDelete}
                            onDownload={handleDownload}
                            isLoading={detectionModelDownloadsStore.isLoading}
                        />
                    </Paper>
                </Col>
            </Row>
        </Layout>
    );
});
