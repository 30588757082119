import { ComponentType } from 'react';
import { DropdownProps } from 'react-widgets/DropdownList';
import { DetectionModel } from "../../../models/api";

interface DownloadDropdownProps extends DropdownProps<any> {
    detectionModelList: DetectionModel[]
}

const withDownloadDropdown = <T extends DownloadDropdownProps>(Component: ComponentType<T>) => (props: T) => {
    const modelOptions = props.detectionModelList.map(item => {
        return {
            value: item.id,
            model: item,
            text: `${item.modelWithModelIndex} (accuracy: ${item.accuracy ?? ''}%)`,
            frameworkType: item.frameworkType
        };
    });

    return <Component {...props} detectionModelList={modelOptions} />
}

export default withDownloadDropdown