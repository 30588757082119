import { ProjectField } from '../models/api';

export const getConnectedProjectFields = (
    field: ProjectField,
    isReversedDependency: boolean
): ProjectField[] => {
    switch (`${field}${isReversedDependency}`) {
        case `${ProjectField.Annotations}${true}`:
            return [
                ProjectField.Annotations,
                ProjectField.Labels,
                ProjectField.Dataset,
            ];
        case `${ProjectField.TrainedModels}${true}`:
            return [
                ProjectField.TrainedModels,
                ProjectField.Annotations,
                ProjectField.Labels,
                ProjectField.Dataset,
            ];
        case `${ProjectField.Simulations}${true}`:
            return [
                ProjectField.Simulations,
                ProjectField.TrainedModels,
                ProjectField.Annotations,
                ProjectField.Labels,
                ProjectField.Dataset,
            ];
        case `${ProjectField.Downloads}${true}`:
            return [
                ProjectField.Downloads,
                ProjectField.TrainedModels,
                ProjectField.Annotations,
                ProjectField.Labels,
                ProjectField.Dataset,
            ];
        case `${ProjectField.Labels}${false}`:
            return [
                ProjectField.Labels,
                ProjectField.Annotations,
                ProjectField.TrainedModels,
                ProjectField.Simulations,
                ProjectField.Downloads,
            ];
        case `${ProjectField.Dataset}${false}`:
            return [
                ProjectField.Dataset,
                ProjectField.Annotations,
                ProjectField.TrainedModels,
                ProjectField.Simulations,
                ProjectField.Downloads,
            ];
        case `${ProjectField.Annotations}${false}`:
            return [
                ProjectField.Annotations,
                ProjectField.TrainedModels,
                ProjectField.Simulations,
                ProjectField.Downloads,
            ];
        case `${ProjectField.TrainedModels}${false}`:
            return [
                ProjectField.TrainedModels,
                ProjectField.Simulations,
                ProjectField.Downloads,
            ];
        default:
            return [field];
    }
};
