export const getSuggestionMessage = (aiType: string) => {
    return `
        I recommend creating an ${aiType} AI project, with 100 - 150 images.
        You will need to annotate the images. But I'll help you deploy your trained AI model
        to an EDGE device to run real-time inspections. Ready to start?
    `
}

export const BOT_ERROR_MESSAGE = 'Something went wrong fetching AI response.';

export const BOT_WELCOME_MESSAGE = `
    Need help starting a new AI project?
    Tell me about it and we can create it together.
`