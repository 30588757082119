import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { videoUtil } from '../../../utils/video';
import { VideoData } from '../../../models/video-data';
import CircularProgress from '../../../components/CircularProgress';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import { FEWER_FRAMES_PROCESSED_WARNING } from '../../../utils/errors';

interface Props {
    video: File | null;
    onClose: () => void;
    onProcess: (files: File[]) => void;
}

const IMAGES_TO_LOAD_DEFAULT = 100;

export const VideoFramingModal: React.FC<Props> = (props) => {
    const { video, onClose, onProcess } = props;
    const previewVideoElement = useRef<HTMLVideoElement>(null);

    const [videoData, setVideoData] = useState<VideoData | null>(null);
    const [imagesToLoad, setImagesToLoad] = useState<number | ''>(0);
    const [loaderProgress, setLoaderProgress] = useState(0);
    const [isRandomSelection, setIsRandomSelection] = useState(false);
    const [isInvalidInput, setIsInvalidInput] = useState(false);

    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [processingProgress, setProcessingProgress] = useState(0);

    useEffect(() => {
        (async () => {
            if (!video) {
                return;
            }
            setVideoData(null);
            setIsRandomSelection(false);
            setIsInvalidInput(false);
            setError('');
            try {
                const data = await videoUtil.getVideoData(video);
                const newImagesToLoad = Math.min(IMAGES_TO_LOAD_DEFAULT, data.framesTotal);
                const newLoaderProgress = Math.ceil((newImagesToLoad / data.framesTotal) * 100);
                setVideoData(data);
                setImagesToLoad(newImagesToLoad);
                setLoaderProgress(newLoaderProgress);
            } catch (e) {
                setError(e.message);
            }
        })();
    }, [video]);

    const handleImagesNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!videoData) {
            return;
        }
        let newValue = parseInt(e.target.value);
        let newImagesToLoad: number | '';
        let newLoaderProgress: number;
        if (isNaN(newValue)) {
            newImagesToLoad = '';
            newLoaderProgress = 0;
        } else {
            newImagesToLoad = Math.min(newValue, videoData.framesTotal) || 1;
            newLoaderProgress = Math.ceil((newImagesToLoad / videoData.framesTotal) * 100);
            setIsInvalidInput(false);
        }
        setImagesToLoad(newImagesToLoad);
        setLoaderProgress(newLoaderProgress);
    };

    const handleImagesNumberRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!videoData) {
            return;
        }
        const newLoaderProgress = parseInt(e.target.value) || 1;
        const newImagesToLoad = Math.floor((newLoaderProgress / 100) * videoData.framesTotal);
        setImagesToLoad(newImagesToLoad);
        setLoaderProgress(newLoaderProgress);
    };

    const handleVideoProcess = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!videoData || !previewVideoElement.current) {
            return;
        }
        if (!imagesToLoad) {
            setIsInvalidInput(true);
            return;
        }
        previewVideoElement.current.pause();
        setError('');
        setIsProcessing(true);
        setProcessingProgress(0);
        try {
            const frames = await videoUtil.getVideoFrames(
                videoData,
                imagesToLoad,
                isRandomSelection,
                setProcessingProgress
            );
            if (frames.length < imagesToLoad) {
                toast(FEWER_FRAMES_PROCESSED_WARNING(frames.length, imagesToLoad), {
                    type: toast.TYPE.WARNING,
                    autoClose: 7000
                });
            }
            onProcess(frames);
            onClose();
        } catch (e) {
            setError(e.message);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Modal centered={true} isOpen={!!video}>
            <Form onSubmit={handleVideoProcess}>
                <ModalHeader toggle={onClose}>Adding video to the dataset</ModalHeader>
                <ModalBody>
                    {error ? (
                        <div className='text-danger my-4'>{error}</div>
                    ) : !videoData ? (
                        <div className='d-flex justify-content-center my-4'>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <FormGroup>
                                <p>
                                    <b>Video duration:</b> {videoData.duration.toFixed(1)} seconds
                                </p>
                                <p>
                                    <b>Video frames per second:</b> {videoData.fps} FPS
                                </p>
                                <p>
                                    <b>Total frames:</b> {videoData.framesTotal}
                                </p>
                            </FormGroup>
                            <FormGroup>
                                <video
                                    ref={previewVideoElement}
                                    src={videoData.urlObject}
                                    controls={!isProcessing}
                                    playsInline={true}
                                    className='w-100 rounded'
                                    style={{ maxHeight: '30vh' }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    <b>Number of images to add to your dataset:</b>
                                </Label>
                                <Input
                                    type='text'
                                    inputMode='numeric'
                                    value={imagesToLoad}
                                    onChange={handleImagesNumberInputChange}
                                    invalid={isInvalidInput}
                                />
                                {isInvalidInput && (
                                    <FormFeedback>
                                        Please, specify the number of images
                                    </FormFeedback>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type='range'
                                    value={loaderProgress}
                                    onChange={handleImagesNumberRangeChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <legend>
                                    <b>Image selection:</b>
                                </legend>
                                <FormGroup check={true}>
                                    <Input
                                        type='radio'
                                        name='imageSelection'
                                        id='imageSelectionUniform'
                                        defaultChecked={true}
                                        onChange={() => {
                                            setIsRandomSelection(false);
                                        }}
                                    />
                                    <Label
                                        check={true}
                                        htmlFor='imageSelectionUniform'
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Uniform <small>(equal spacing between frames)</small>
                                    </Label>
                                </FormGroup>
                                <FormGroup check={true}>
                                    <Input
                                        type='radio'
                                        name='imageSelection'
                                        id='imageSelectionRandom'
                                        onChange={() => {
                                            setIsRandomSelection(true);
                                        }}
                                    />
                                    <Label
                                        check={true}
                                        htmlFor='imageSelectionRandom'
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Random <small>(random frames from video)</small>
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                        </>
                    )}
                </ModalBody>
                <ModalFooter className='flex-row-reverse justify-content-between'>
                    {error ? (
                        <Button color='secondary' className='m-0' onClick={onClose}>
                            Close
                        </Button>
                    ) : (
                        <>
                            <Button
                                type='submit'
                                color='primary'
                                className='m-0'
                                disabled={isProcessing}
                            >
                                Add to dataset
                            </Button>
                            {isProcessing && (
                                <div className='d-flex align-items-center'>
                                    <CircularProgress />
                                    <div className='ml-2'>Processing: {processingProgress}%</div>
                                </div>
                            )}
                        </>
                    )}
                </ModalFooter>
            </Form>
        </Modal>
    );
};
