interface Props {
    isMultiLabel: boolean
}

const UnblockedText: React.FC<Props> = ({ isMultiLabel }) => {
    return (
        <>
            Currently:{' '}
            <span className="font-weight-bold">{isMultiLabel ? 'Multi' : 'Single'} Label Classification</span>
            <br />
            <br />
            Do you want to switch to {!isMultiLabel ? 'Multi' : 'Single'} Label Classification?
        </>
    );
};

export default UnblockedText;