import React, { useEffect, useMemo, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
// @ts-ignore
import MetisMenu from 'react-metismenu';
import { observer } from 'mobx-react-lite';

import CustomNavLink from './CustomNavLink';
import { navigation } from '../../../config/sidebar';
import { useStores } from '../../../store';
import MenuLabel from './MenuLabel';
import { ProjectGroup, ProjectGroupUpdate, ProjectType, ProjectTypeUI } from '../../../models/api';
import DnaLoader from '../../DnaLoader';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ProjectGroupModal } from './ProjectGroupModal';
import ContextMenuNavLink, { ContextMenuItem } from './ContextMenuNavLink';
import { DeleteProjectGroupModal } from './DeleteProjectGroupModal';
import { sortByMenuItem } from '../../../utils/sortByName';

interface Props {
    toggleMobileSidebar: () => void;
}

const Menu: React.FC<Props> = observer(({ toggleMobileSidebar }) => {
    const { projectsStore, userStore, statisticsStore, projectGroupsStore } = useStores();
    const [navWithStatistic, setNavWithStatistic] = useState(navigation);

    const [projectGroupCreateConfirmation, setProjectGroupCreateConfirmation] = useState(false);
    const [projectGroupEditConfirmation, setProjectGroupEditConfirmation] = useState<ProjectGroup | null>(null);
    const [projectGroupDeleteConfirmation, setProjectGroupDeleteConfirmation] = useState<ProjectGroup | null>(null);

    const activeNavigation = () => {
        switch (projectsStore.current?.type) {
            case ProjectType.PoseDetection:
                return ProjectType.PoseDetection;
            case ProjectType.Modeling3d:
                return ProjectType.Modeling3d;
            case ProjectType.PredictiveMaintenance:
                return ProjectType.PredictiveMaintenance;
            default:
                return ProjectType.ObjectDetection;
        }
    };

    const loadProjectGroups = async (id: string | undefined) => {
        await projectGroupsStore.fetchList(id);
    }

    const handleProjectGroupCreateOpen = () => {
        setProjectGroupCreateConfirmation(true);
    };
    const handleProjectGroupCreate = (params: ProjectGroupUpdate) => {
        projectGroupsStore.createItem(params);
        setProjectGroupCreateConfirmation(false);
    };
    const handleProjectGroupCreateClose = () => {
        setProjectGroupCreateConfirmation(false);
    };

    const handleProjectGroupEditOpen = (groupId: string) => {
        const projectGroup = projectGroupsStore.list.find((projectGroup) => projectGroup.id === groupId);
        if (projectGroup) {
            setProjectGroupEditConfirmation(projectGroup);
        }
    };
    const handleProjectGroupEdit = (params: ProjectGroupUpdate) => {
        if (params.id) {
            projectGroupsStore.updateItem(params.id, params);
            setProjectGroupEditConfirmation(null);
        }
    };
    const handleProjectGroupEditClose = () => {
        setProjectGroupEditConfirmation(null);
    };

    const handleProjectGroupDeleteOpen = (groupId: string) => {
        const projectGroup = projectGroupsStore.list.find((projectGroup) => projectGroup.id === groupId);
        if (projectGroup) {
            setProjectGroupDeleteConfirmation(projectGroup);
        }
    };
    const handleProjectGroupDelete = (params: ProjectGroupUpdate) => {
        if (params.id) {
            projectGroupsStore.deleteItem(params.id);
            setProjectGroupDeleteConfirmation(null);
        }
    };
    const handleProjectGroupDeleteClose = () => {
        setProjectGroupDeleteConfirmation(null);
    };

    const projectGroupsContextMenuItems: ContextMenuItem[] = useMemo(() => ([
        {name: 'Edit Group', onClick: (itemId: string) => handleProjectGroupEditOpen(itemId)},
        {name: 'Delete Group', onClick: (itemId: string) => handleProjectGroupDeleteOpen(itemId)}
    ]), [projectGroupsStore.list])

    useEffect(() => {
        const newNav = cloneDeep(navigation);

        if (statisticsStore.adminIsFetched) {
            if (statisticsStore.admin) {
                newNav.admin.users.label = `${newNav.admin.users.label} (${statisticsStore.admin.userCount})`;
                newNav.admin.runningTrainings.label = `${newNav.admin.runningTrainings.label} (${statisticsStore.admin.runningTrainingCount})`;
                newNav.admin.runningSimulations.label = `${newNav.admin.runningSimulations.label} (${statisticsStore.admin.runningAllSimulationsCount})`;
            }
            if (statisticsStore.project) {
                newNav.dashboard.projects.label = `${newNav.dashboard.projects.label} (${statisticsStore.project.projectCount})`;
            }
        } else {
            statisticsStore.fetchStatistic();
        }

        if (!statisticsStore.projectIsFetched) {
            statisticsStore.fetchProjectStatistic();
            setNavWithStatistic(newNav);
            return;
        }

        if (!statisticsStore.project) {
            return;
        }

        const objectDetectionNav = newNav[ProjectType.ObjectDetection];
        const modeling3dNav = newNav[ProjectType.Modeling3d];
        const poseDetectionNav = newNav[ProjectType.PoseDetection];
        const predictiveMaintenanceNav = newNav[ProjectType.PredictiveMaintenance];

        if (statisticsStore.project.labelCount) {
            objectDetectionNav.labels.label = `${objectDetectionNav.labels.label} (${statisticsStore.project.labelCount})`;
        }

        if (statisticsStore.project.datasetImageCount) {
            objectDetectionNav.dataset.label = `${objectDetectionNav.dataset.label} (${statisticsStore.project.datasetImageCount})`;
        }

        objectDetectionNav.modelTraining.label = statisticsStore.project
            .runningModelTrainingCount ? (
            <MenuLabel spinner={!!statisticsStore.project.runningModelTrainingCount}>
                {objectDetectionNav.modelTraining.label}
            </MenuLabel>
        ) : statisticsStore.project.modelTrainingCount ? (
            `${objectDetectionNav.modelTraining.label} (${statisticsStore.project.modelTrainingCount})`
        ) : (
            objectDetectionNav.modelTraining.label
        );

        objectDetectionNav.modelSimulation.label = statisticsStore.project
            .runningModelSimulationCount ? (
            <MenuLabel spinner={!!statisticsStore.project.runningModelSimulationCount}>
                {objectDetectionNav.modelSimulation.label}
            </MenuLabel>
        ) : statisticsStore.project.modelSimulationCount ? (
            `${objectDetectionNav.modelSimulation.label} (${statisticsStore.project.modelSimulationCount})`
        ) : (
            objectDetectionNav.modelSimulation.label
        );

        objectDetectionNav.download.label = statisticsStore.project.runningModelDownloadCount ? (
            <MenuLabel spinner={!!statisticsStore.project.runningModelDownloadCount}>
                {objectDetectionNav.download.label}
            </MenuLabel>
        ) : statisticsStore.project.modelDownloadCount ? (
            `${objectDetectionNav.download.label} (${statisticsStore.project.modelDownloadCount})`
        ) : (
            objectDetectionNav.download.label
        );
        
        modeling3dNav.modelSimulation.label = statisticsStore.project
            .running3DModelSimulationCount ? (
                <MenuLabel spinner={!!statisticsStore.project.running3DModelSimulationCount}>
                    {modeling3dNav.modelSimulation.label}
                </MenuLabel>
            ) : statisticsStore.project.model3DSimulationCount ? (
                `${modeling3dNav.modelSimulation.label} (${statisticsStore.project.model3DSimulationCount})`
            ) : (
                modeling3dNav.modelSimulation.label
        );
        
        poseDetectionNav.modelSimulation.label = statisticsStore.project
        .runningModelSimulationCount ? (
            <MenuLabel spinner={!!statisticsStore.project.runningModelSimulationCount}>
                {poseDetectionNav.modelSimulation.label}
            </MenuLabel>
        ) : statisticsStore.project.modelSimulationCount ? (
            `${poseDetectionNav.modelSimulation.label} (${statisticsStore.project.modelSimulationCount})`
        ) : (
            poseDetectionNav.modelSimulation.label
        );
        
        predictiveMaintenanceNav.modelSimulation.label = statisticsStore.project
        .runningPredictiveMaintenanceCount ? (
            <MenuLabel spinner={!!statisticsStore.project.runningPredictiveMaintenanceCount}>
                {predictiveMaintenanceNav.modelSimulation.label}
            </MenuLabel>
        ) : statisticsStore.project.predictiveMaintenanceModelCount ? (
            `${predictiveMaintenanceNav.modelSimulation.label} (${statisticsStore.project.predictiveMaintenanceModelCount})`
        ) : (
            predictiveMaintenanceNav.modelSimulation.label
        );

        setNavWithStatistic({
            ...newNav,
        });
    }, [statisticsStore.admin, statisticsStore.project, statisticsStore.admin?.runningAllSimulationsCount, userStore.impersonationUser?.id]);

    useEffect(() => {
        loadProjectGroups(userStore.impersonationUser?.id ?? userStore.user?.id);
    }, [userStore.impersonationUser?.id, userStore.user?.id]);

    return (
        <div className="app-sidebar__inner">
            <>
                {userStore.user?.isAdmin && (
                    <>
                        <h5 className="app-sidebar__heading">Admin</h5>
                        <MetisMenu
                            LinkComponent={CustomNavLink(toggleMobileSidebar)}
                            content={Object.values(navWithStatistic.admin)}
                            onSelected={toggleMobileSidebar}
                            iconNamePrefix=""
                            className="vertical-nav-menu"
                        />
                    </>
                )}

                <span className="app-sidebar__heading-block">
                    <h5 className="app-sidebar__heading">Dashboard</h5>
                    <Button color='primary' onClick={handleProjectGroupCreateOpen}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </span>
                <MetisMenu
                    LinkComponent={CustomNavLink(toggleMobileSidebar)}
                    content={Object.values(navWithStatistic.dashboard)}
                    onSelected={toggleMobileSidebar}
                    iconNamePrefix=""
                    className="vertical-nav-menu"
                />
                {projectGroupsStore.isLoading ? (<DnaLoader />) : (
                    <MetisMenu
                        LinkComponent={ContextMenuNavLink({
                            onNavClick: toggleMobileSidebar,
                            contextMenuItems: projectGroupsContextMenuItems
                        })}
                        content={sortByMenuItem(projectGroupsStore.menuItems)}
                        onSelected={toggleMobileSidebar}
                        iconNamePrefix=""
                        className="vertical-nav-menu"
                        
                    />
                )}

                {projectsStore.current && (
                    <>
                        <h5 className="app-sidebar__heading">
                            {ProjectTypeUI[projectsStore.current.type]}
                        </h5>
                        <MetisMenu
                            LinkComponent={CustomNavLink(toggleMobileSidebar)}
                            content={Object.values(navWithStatistic[activeNavigation()])}
                            iconNamePrefix=""
                            className="vertical-nav-menu"
                        />
                    </>
                )}
                <ProjectGroupModal
                    modalTitle='Create New Group'
                    isOpen={projectGroupCreateConfirmation}
                    onClosed={handleProjectGroupCreateClose}
                    onConfirm={handleProjectGroupCreate}
                />
                <ProjectGroupModal
                    modalTitle='Edit Group'
                    isOpen={!!projectGroupEditConfirmation}
                    projectGroup={projectGroupEditConfirmation}
                    onClosed={handleProjectGroupEditClose}
                    onConfirm={handleProjectGroupEdit}
                />
                <DeleteProjectGroupModal
                    isOpen={!!projectGroupDeleteConfirmation}
                    projectGroup={projectGroupDeleteConfirmation}
                    onClosed={handleProjectGroupDeleteClose}
                    onConfirm={handleProjectGroupDelete}
                />
            </>
        </div>
    );
});

export default Menu;
