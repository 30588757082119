import { makeAutoObservable } from 'mobx';
import { SortOrder } from './sort-order';

export class Pagination {
    public page: number = 0;
    public sizePerPage: number = 10;
    public totalSize: number = 0;

    public sortOrder: SortOrder = new SortOrder();
    public isFetched: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public get firstIndexOnPage() {
        return this.page * this.sizePerPage;
    }
    public get lastPage() {
        const lastPage = Math.floor(this.totalSize / this.sizePerPage);
        return this.totalSize % this.sizePerPage ? lastPage : lastPage - 1;
    }
    public get isLastPage() {
        return this.lastPage === this.page;
    }
    public get isFirstPage() {
        return this.page === 0;
    }
    public get queryObject(){
        return {offset: this.page * this.sizePerPage, limit: this.sizePerPage};
    }
    public get uiProps(){
        return {page: this.page + 1, sizePerPage: this.sizePerPage, totalSize: this.totalSize};
    }

    public prevPage = () => {
        if (this.isFirstPage) {
            return;
        }
        this.page--;
    }

    public prevSeveralPages = (pages: number) => {
        this.page = Math.max(0, this.page - pages);
        this.isFetched = false;
    }

    public nextPage = () => {
        if (this.isLastPage) {
            return;
        }
        this.page++;
    }

    public update = (page: number, sizePerPage: number, field?: string, order?: string) => {
        this.page = page - 1;
        this.sizePerPage = sizePerPage;
        field && (this.sortOrder.sortField = field);
        order && (this.sortOrder.sortOrder = order);
        this.isFetched = false;
    }
}

export interface Paged<T>{
    count:number;
    rows: T[]
}
