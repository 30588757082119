import React, { useState, useEffect } from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../../../components/BootstrapTable/ActionButton';
import { noDataText } from '../../../../components/BootstrapTable';
import EditVideoForm from '../EditVideoForm';
import { useStores } from '../../../../store';
import { videoElement } from '../../../../models/api'
import { Videos } from '../index'


interface PageVideo {
	page: string;
	data: videoElement[];
}
interface preparedRow {
	page: string,
	titles: any,
	urls: any
}
interface Props {
	items: Videos;
	projectName: string;
	onDelete?: (id: string) => void;
}


const HelpVideosListTable: React.FC<Props> = ({items, projectName, onDelete}) => {
	const {helpVideoStore} = useStores();
	const [showModal, setShowModal] = useState(false);
	const [chosenPage, setChosenPage] = useState<PageVideo>();
	const [tableData, setTableData] = useState<preparedRow[]>([]);
	const [formData, setFormData] = useState<videoElement[]>([]);


	useEffect(() => {
		chosenPage?.page && toFormData(chosenPage)
	}, [chosenPage])
	useEffect(() => {
		Object.values(items).length && toTableFormat(items)
	}, [items])

	const handleSubmit = (data:Videos) => {
		const isDataNew = !chosenPage?.data[0].title
		const formData = {
			projectName: projectName,
			pageName: chosenPage?.page ? chosenPage.page : '',
			videoList: data.pageData,
		}
		handleToggle();

		if(isDataNew && data.pageData.length)
			helpVideoStore.addVideo(formData)
		else if(!isDataNew && data.pageData.length)
			helpVideoStore.updateVideo(formData)
		else
			helpVideoStore.deleteVideo(formData)
	};
	const handleToggle = () => {
		setShowModal(data => !data)
	}
	const handleEditButtonClick = (row: preparedRow) => () => {
		const chosenData = {
			page: row.page,
			data: items[row.page]
		}
		setChosenPage(chosenData)
		setShowModal(true)
	};
	const toTableFormat = (project: Videos) => {
		const preparedArray = Object.keys(project).map((page: string) => {
			return {
				page: page,
				titles: project[page].map((video) => {
					return video.title
				}),
				urls: project[page].map((video) => {
					return video.url
				})
			}
		})

		setTableData(preparedArray)
	}
	const toFormData = (pageVideo: PageVideo) => {
		const formattedData = pageVideo.data.map((el) => {
			return {
				title: el.title,
				url: el.url
			}
		})

		setFormData(formattedData)
	}

	const columns: ColumnDescription<preparedRow>[] = [
		{
			dataField: 'page',
			text: 'Page',
			headerClasses: 'pl-3',
			classes: 'pl-3',
			formatter: (_cel, row) => {
				return row.page.replace('-', ' ').replace(/(^|\s)\S/g, (l: string) => l.toUpperCase())
			}
		},
		{
			dataField: 'titles',
			text: 'Titles',
			headerClasses: 'text-nowrap',
			classes: 'text-nowrap',
			formatter: (_cel, row) => {
				return row.titles.map((title: string) => {
					return (
						<div className='pb-1'>
							{title}
						</div>
					)
				})
			}
		},
		{
			dataField: 'url',
			text: 'Url',
			headerClasses: 'text-uppercase',
			formatter: (_cel, row) => {
				return row.urls.map((url: string) => {
					return (

						<div className='pb-1'>
							<a href={url} target="_blank" rel="noopener noreferrer">
								{url}
							</a>
						</div>
					)
				})
			}
		},
		{
			dataField: 'actions',
			text: 'Actions',
			headerClasses: 'text-nowrap pr-3',
			classes: 'text-nowrap pr-3',
			headerAlign: 'right',
			align: 'right',
			formatter: (_cel, row) => {
				const tooltipEditId = `pages-admin-user-list-table_edit-button-${row.page}`;

				return (
					<div className='d-flex justify-content-end'>
						<ActionButton
							color='warning'
							id={tooltipEditId}
							tooltipText='Edit item'
							className='ml-2'
							onClick={handleEditButtonClick(row)}
						>
							<FontAwesomeIcon icon={faPen}/>
						</ActionButton>
					</div>
				);
			}
		}
	];


	return (
		<>
			<BootstrapTable
				bootstrap4
				keyField='id'
				data={tableData}
				columns={columns}
				striped
				bordered={false}
				wrapperClasses='table-responsive'
				defaultSorted={[{dataField: 'titles', order: 'asc'}]}
				classes='m-0'
				noDataIndication={noDataText}
			/>

			<EditVideoForm
				onSubmit={handleSubmit}
				handleToggle={handleToggle}
				showModal={showModal}
				formData={formData}
			/>
		</>
	);
};

export default HelpVideosListTable;