import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Formik } from 'formik';
import DropdownList from 'react-widgets/DropdownList';
import { LicenseType, LicenseTypeUI, User } from '../../../models/api';
import dayjs from 'dayjs';
import { getFormattedLicenseExpiry } from '../../../utils/dateTime';

interface Props {
    user: User;
    onSave: (user: User) => void;
    isLoading?: boolean;
    disabled?: boolean;
}

interface Values {
    licenseType: LicenseType;
    licenseExpiry: string;
}

const licenseTypeOptions = [
    {
        value: LicenseType.Free,
        text: LicenseTypeUI.free,
    },
    {
        value: LicenseType.Pro,
        text: LicenseTypeUI.pro,
    },
];

const LicenseTypeForm: React.FC<Props> = ({ user, onSave, isLoading, disabled }) => {
    const handleSubmit = (values: Values) => {
        onSave({ ...user, ...values });
    };

    const isUserAdmin = !disabled;

    const wasUserPro =
        user.licenseType === LicenseType.Free &&  // Is license type - free now
        !!user.licenseExpiry;                     // Was license expiry set before

    return (
        <Formik<Values>
            initialValues={{
                licenseType: user.licenseType,
                licenseExpiry: user.licenseExpiry
                    ? user.licenseExpiry.substr(0, 10)
                    : dayjs().format('YYYY-MM-DD')
            }}
            onSubmit={handleSubmit}
        >
            {({ values, handleSubmit, setFieldValue }) => {
                let [licenseExpiry, daysToExpiry, minutesToExpiry] = getFormattedLicenseExpiry(
                    values.licenseExpiry
                );
                daysToExpiry = minutesToExpiry < 0 ? '' : ` - ${daysToExpiry} day(s)`;
                return (
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label>License type:</Label>
                            <DropdownList
                                data={licenseTypeOptions}
                                dataKey="value"
                                textField="text"
                                filter={false}
                                value={values.licenseType}
                                onChange={(option) => setFieldValue('licenseType', option.value)}
                                disabled={disabled}
                            />
                        </FormGroup>
                        {(values.licenseType === LicenseType.Pro || (wasUserPro && !isUserAdmin)) && (
                            <FormGroup>
                                <Label>
                                    {isUserAdmin ? (
                                        'License valid until (23:59 on date selected):'
                                    ) : wasUserPro ? (
                                        'Professional license expired:'
                                    ) : (
                                        'License valid until:'
                                    )}
                                </Label>
                                {!isUserAdmin ? (
                                    <>
                                        {/* If not an admin */}
                                        <Input
                                            type="text"
                                            value={`${licenseExpiry}${daysToExpiry}`}
                                            disabled={true}
                                        />
                                    </>
                                ) : values.licenseType === LicenseType.Pro ? (
                                    <>
                                        {/* If an admin & chose pro license type for user */}
                                        <Input
                                            type="date"
                                            value={values.licenseExpiry}
                                            onChange={(e) => setFieldValue('licenseExpiry', e.target.value)}
                                        />
                                    </>
                                ) : null}
                            </FormGroup>
                        )}
                        {(wasUserPro && !isUserAdmin) && (
                            <FormGroup>
                                <p>For renewal, contact: sales@firststep.ai</p>
                            </FormGroup>
                        )}
                        {isUserAdmin && (
                            <Button color="primary" type="submit" disabled={isLoading}>
                                Save
                            </Button>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default LicenseTypeForm;
