import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { WelcomeModal } from './welcomeModal'
import { useStores } from '../../../store';
import YouTubeVideo from '../../../components/Layout/AppHelpVideo/youTubeVideo';
import { youTubeGetID } from '../../../components/Layout/AppHelpVideo';
import { videoElement } from '../../../models/api'


const WelcomeVideo:React.FC = observer(() => {
	const {projectsStore, helpVideoStore, userStore} = useStores();
	const [isOpen, setIsOpen] = useState(false);
	const [welcomeVideo, setWelcomeVideo] = useState<videoElement>({title:'', url:''});
	const sessionKey = `welcome-${userStore?.user?.cognitoId}`

	useEffect(()=>{
		const video = helpVideoStore.videos?.dashboard?.dashboard[0];
		if(!projectsStore.projectCount && video && !getSessionStorageItem(sessionKey)){
			setWelcomeVideo(video)
			setIsOpen(true)
			setSessionStorageItem(sessionKey, true)
		}
	},[helpVideoStore.videos, projectsStore.projectCount]);

	const toggle = ()=>{
		setIsOpen( isOpen => !isOpen )
	}
	const setSessionStorageItem = (key:string, value:any)=>{
		sessionStorage.setItem(key, value);
	}
	const getSessionStorageItem = (key:string)=>{
		return sessionStorage.getItem(key)
	}


	return (
		<>
			{
				welcomeVideo.url ?
					<WelcomeModal
						isOpen={isOpen}
						onClosed={toggle}
						onSubmit={toggle}
						centered={true}
					>
						<YouTubeVideo
							width={'100%'}
							height={400}
							paused={!isOpen}
							id={youTubeGetID(welcomeVideo.url)}
						/>
					</WelcomeModal> : ''
			}
		</>

	);
});

export default WelcomeVideo