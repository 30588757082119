import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import { CustomS3Image, CustomS3ImageProps } from '../index';

//TODO implement other props
type Props = CustomS3ImageProps;

const CustomS3ImageWithSpinner: React.FC<Props> = ({ ...rest }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleLoaded = () => {
        setIsLoading(false);
    };

    return (
        <div className='d-flex justify-content-center align-items-center' >
            <CustomS3Image
                {...rest}
                onLoad={handleLoaded}
                style={{ visibility: isLoading ? 'hidden' : 'visible' }}
            />
            {isLoading && <Spinner color='secondary' className='position-absolute'/>}
        </div>
    );
};

export default CustomS3ImageWithSpinner;