import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import { RootStore } from './root';
import { DetectionModelGenerator, DetectionModelGeneratorCreateRequest, DetectionModelGeneratorProgressStatus } from '../../models/api';
import { s3StorageConfig, s3Util } from '../../utils/s3';
import container from '../../container/container';
import { videoUtil } from '../../utils/video';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import { toast } from 'react-toastify';
import { imageUtil } from '../../utils/image';
import { COMPRESSED_IMAGE_EXT, THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from '../../config/image';
import { AccessLevel } from '@aws-amplify/ui-components';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { START_MODELING_3D_IDENTITY_ERROR, START_MODELING_3D_NOT_VALID_ARCHIVE_ERROR } from '../../utils/errors';
import { CustomEventType } from '../../models/events';
import { ZipType } from '../../models/mimeTypes';

const api = container.apiClient;

interface TerminatedAndRunningListProperty {
    items: DetectionModelGenerator[];
    isLoading: boolean;
    isFetched: boolean;
}

export class DetectionModelGeneratorsStore {
    private rootStore: RootStore;
    private readonly disposers: IReactionDisposer[] = [];

    list: DetectionModelGenerator[] = [];
    terminatedList: TerminatedAndRunningListProperty = {
        items: [],
        isLoading: false,
        isFetched: false
    };  
    runningList: TerminatedAndRunningListProperty = {
        items: [],
        isLoading: false,
        isFetched: false
    };
    isLoading: boolean = false;
    isFetched: boolean = false;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.disposers.push(
            reaction(
                () => ({
                    running3DModelSimulationCount: this.listOfAllRunning.length,
                    model3DSimulationCount: Object.values(this.list).length
                }),
                ({ running3DModelSimulationCount, model3DSimulationCount }) => {
                    this.rootStore.statisticsStore.setDashboardStatistic({
                        ...this.rootStore.statisticsStore.project,
                        running3DModelSimulationCount,
                        model3DSimulationCount
                    });
                }
            )
        );

        this.disposers.push(
            reaction(
                () => ({
                    runningAllSimulationsCount: this.listOfRunning.length
                }),
                ({ runningAllSimulationsCount }) => {
                    this.rootStore.statisticsStore.setAdminStatistic({
                        ...this.rootStore.statisticsStore.admin,
                        runningAllSimulationsCount
                    });
                }
            )
        );
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    public get listOfRunning() {
        if (!this.runningList.items) {
            return [];
        }

        return Object.values(this.runningList.items).filter(
            (item) => !item.completedAt
        );
    }

    public get listOfAllRunning() {
        if (!this.list) {
            return [];
        }

        return Object.values(this.list).filter(
            (item) => !item.completedAt
        );
    }

    async fetchList() {
        if (!this.rootStore.projectsStore.current || this.isLoading) {
            return;
        }
        this.isLoading = true;
        try {
            const { data } = await api.detectionModelGeneratorList({
                projectId: this.rootStore.projectsStore.current.id
            });
            runInAction(() => {
                this.list = data;
                this.isFetched = true;
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async fetchTerminatedGenerators() {
        if(this.terminatedList.isLoading) {
            return;
        }

        this.terminatedList.isLoading = true;
        
        const params = {
            limit: 10,
            sortField: 'createdAt',
            sortOrder: 'DESC',
        };
        try {
            const { data } = await api.detectionModelGeneratorRunningList(params);
            runInAction(() => {
                this.terminatedList.items = data;
                this.terminatedList.isFetched = true;
            })
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.terminatedList.isLoading = false;
            })
        }
    }

    async fetchRunningGenerators() {
        if (this.runningList.isLoading) {
            return;
        }
        this.runningList.isLoading = true;

        const params = {
            filter: {
                completedAt: null
            },
            limit: 10,
            sortField: 'createdAt',
            sortOrder: 'DESC'
        }
        try {
            const { data } = await api.detectionModelGeneratorRunningList(params);
            runInAction(() => {
                this.runningList.items = data;
                this.runningList.isFetched = true;
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.runningList.isLoading = false;
            });
        }
    }

    async createItem(file: File, frames: number) {
        this.isLoading = true;
        const toastId = createProgressToast();
        let imageFile: File | null = null;
        let inputFilePath: string = '';
        let thumbnail: File | null = null;

        // Getting identityId for current user and also current project
        const identityId = this.rootStore.userStore.getIdentityIdSync();
        const project = this.rootStore.projectsStore.current;

        if (!identityId || !project) {
            toast(START_MODELING_3D_IDENTITY_ERROR, { type: toast.TYPE.ERROR });
            this.isLoading = false;
            toast.dismiss(toastId);
            return;
        }

        // Getting id for new generator and hash for video
        const id = uuidv4();
        const md5Hash = await imageUtil.getMd5Hash(file);

        // Paths to files on S3
        const s3Bucket = s3Util.getS3BucketName();
        const s3PrivateFolder = s3Util.getS3PrivateFolder(identityId);
        const thumbnailPath = s3Util.getModelGeneratorThumbnailKey(project.id, id);
        const outputFolder = s3Util.getModelGeneratorOutputFolder(project.id, id);

        if(file.type === ZipType.Zip) {
            // Getting first image from images zip folder and making thumbnail from it
            imageFile = await imageUtil.getThumbnailFromZippedImages(file);
            inputFilePath = s3Util.getModelGeneratorZipImagesKey(project.id, id);
            frames = 0;

            if (!imageFile) {
                toast(START_MODELING_3D_NOT_VALID_ARCHIVE_ERROR, { type: toast.TYPE.ERROR });
                this.isLoading = false;
                toast.dismiss(toastId);
                return;
            } 
        } else {
            // Getting middle frame from video and making thumbnail from it
            imageFile = await videoUtil.getMiddleFrameFromVideo(file);
            inputFilePath = s3Util.getModelGeneratorVideoKey(project.id, id);
        }

        if (imageFile) {
            thumbnail = await imageUtil.resizeFile(
                imageFile,
                COMPRESSED_IMAGE_EXT,
                THUMBNAIL_WIDTH,
                THUMBNAIL_HEIGHT
            );
        }

        // Creating initial model for generator
        const model = {
            id,
            filename: file.name,
            hash: md5Hash,
            hasThumbnail: !!thumbnail,
            frames,
            progressStatus: DetectionModelGeneratorProgressStatus.Initializing,
            startedAt: new Date().toISOString(),
            projectId: project.id
        }

        // Uploading video and thumbnail to S3 for current generator
        const storageConfig: s3StorageConfig = { level: AccessLevel.Private, identityId };
        await Storage.put(inputFilePath, file, storageConfig);
        if (thumbnail) {
            await Storage.put(thumbnailPath, thumbnail, storageConfig);
        }

        try {
            const { data } = await api.detectionModelGeneratorCreate({
                model,
                s3Bucket,
                inputFilePath: `${s3PrivateFolder}/${inputFilePath}`,
                outputFolder: `${s3PrivateFolder}/${outputFolder}`,
                frames
            });
            runInAction(() => {
                this.list = [...this.list, data];
                this.incrementRunningSimulationsStatisticsCount();
                this.rootStore.eventsStore.addItem(CustomEventType.Start3dModeling, {
                    id: data.id,
                    filename: data.filename,
                    projectId: project.id,
                    projectName: project.name
                });
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async cancelItem(id: string) {
        if (!this.rootStore.projectsStore.current) {
            return;
        }
        const project = this.rootStore.projectsStore.current;

        this.isLoading = true;
        const toastId = createProgressToast();
        try {
            const { data } = await api.detectionModelGeneratorCancelGenerating(id);
            runInAction(() => {
                this.list = this.list.map((item) => (item.id === id ? data : item));
                this.decrementRunningSimulationsStatisticsCount();
                this.rootStore.eventsStore.addItem(CustomEventType.Cancel3dModeling, {
                    id: data.id,
                    filename: data.filename,
                    projectId: project.id,
                    projectName: project.name
                });
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async deleteItem(id: string) {
        if (!this.rootStore.projectsStore.current) {
            return;
        }
        const project = this.rootStore.projectsStore.current;

        this.isLoading = true;
        const toastId = createProgressToast();
        try {
            await api.detectionModelGeneratorDelete(id);
            runInAction(() => {
                const data = this.list.find((item) => item.id === id);
                if (data) {
                    this.list = this.list.filter((item) => item.id !== id);
                    this.rootStore.eventsStore.addItem(CustomEventType.Delete3dModeling, {
                        id: data.id,
                        filename: data.filename,
                        projectId: project.id,
                        projectName: project.name
                    });
                }
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    incrementRunningSimulationsStatisticsCount() {
        if (this.rootStore.statisticsStore.admin?.runningAllSimulationsCount !== undefined) {
            this.rootStore.statisticsStore.admin.runningAllSimulationsCount += 1;
        }
    }

    decrementRunningSimulationsStatisticsCount() {
        if (this.rootStore.statisticsStore.admin?.runningAllSimulationsCount) {
            this.rootStore.statisticsStore.admin.runningAllSimulationsCount -= 1;
        }
    }
}
