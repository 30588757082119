import React from 'react';
import { HeaderWithTooltip } from '../../../components/BootstrapTable';
import CustomSlider from '../../../components/CustomSlider';

interface Props {
    onChange: (value: number) => void;
    maxDetections: number;
}
const marks = {
    10: '10',
    100: '100',
    200: '200',
};

const AdvancedSettings: React.FC<Props> = ({ onChange, maxDetections }) => {
    const handleChange = (value: number) => {
        onChange(value);
    };

    return (
        <div className="px-3">
            <div className="d-flex">
                <span className="mb-3 font-weight-bold">
                    MAX DETECTIONS: {maxDetections}
                </span>
                <div className="ml-2">
                    <HeaderWithTooltip
                        tooltipContent={'Watch the help video'}
                        tooltipId={`model-download-advanced-settings_info-button`}
                    />
                </div>
            </div>
            <CustomSlider
                min={1}
                max={100}
                defaultValue={maxDetections}
                marks={marks}
                onChange={handleChange}
                className="mb-4"
            />
        </div>
    );
};

export default AdvancedSettings;
