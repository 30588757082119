import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import container from '../../container/container';
import { ProjectGroup, ProjectGroupUpdate, User } from '../../models/api';
import { RootStore } from './root';
import { NETWORK_ERROR_MESSAGE } from '../../utils/errors';
import { createHardRefreshToast } from '../../components/Toasts/HardRefreshToast';
import { compareByField } from '../../utils/helper-functions';
import { CustomEventType } from '../../models/events';
import { MenuItem } from '../../config/sidebar';

const api = container.apiClient;

export class ProjectGroupsStore {
    list: ProjectGroup[] = [];
    menuItems: MenuItem[] = [];

    isLoading: boolean = false;
    isFetched: boolean = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async fetchList(userId?: User['id']) {
        this.list = [];
        this.isLoading = true;

        try {
            const { data } = await api.projectGroupList({
                filter: {
                    userId: userId ? userId : this.rootStore.userStore.user?.id
                }
            });

            runInAction(() => {
                this.list = data;
                this.isFetched = true;
                this.createProjectGroupsMenuItems();
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async createItem(params: ProjectGroupUpdate) {
        if (!this.rootStore.userStore.user) {
            return;
        }

        this.isLoading = true;

        const toastId = createProgressToast();
        const userId = this.rootStore.userStore.impersonationUser
            ? this.rootStore.userStore.impersonationUser.id
            : this.rootStore.userStore.user.id;

        try {
            const { data } = await api.projectGroupCreate({ ...params, userId });

            runInAction(() => {
                this.list = [...this.list, data].sort((a, b) => compareByField(a, b, 'name'));

                this.rootStore.eventsStore.addItem(CustomEventType.CreateProjectGroup, {
                    id: data.id,
                    name: data.name
                });
                this.createProjectGroupsMenuItems();
            });
            return data;
        } catch (e) {
            const errorMessage = e?.response?.data?.errors?.message ?? e.message;
            if (errorMessage !== NETWORK_ERROR_MESSAGE) {
                toast(errorMessage, { type: toast.TYPE.ERROR });
            } else {
                createHardRefreshToast(() => (this.isFetched = false));
            }
        } finally {
            runInAction(() => {
                this.isLoading = false;
                this.isFetched = false;
                toast.dismiss(toastId);
            });
        }
    }

    async updateItem(id: ProjectGroup['id'], params: ProjectGroupUpdate) {
        if (!this.rootStore.userStore.user) {
            return;
        }

        this.isLoading = true;
        const toastId = createProgressToast();

        try {
            await api.projectGroupUpdate(id, params);

            runInAction(() => {
                this.list = this.list.map((project) => {
                    return project.id === id ? { ...project, ...params } : project;
                });
                this.createProjectGroupsMenuItems();
            });
        } catch (e) {
            toast(e?.response?.data?.errors?.message ?? e.message, {
                type: toast.TYPE.ERROR
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async deleteItem(id: ProjectGroup['id']) {
        if (!this.rootStore.userStore.user) {
            return;
        }

        this.isLoading = true;
        const toastId = createProgressToast();

        try {
            await api.projectGroupDelete(id);

            runInAction(() => {
                const groupToRemove = this.list.find((item) => item.id === id);

                if (groupToRemove) {
                    this.rootStore.eventsStore.addItem(CustomEventType.DeleteProjectGroup, {
                        id: groupToRemove.id,
                        name: groupToRemove.name
                    });
                }
                this.list = this.list.filter((item) => item.id !== id);

                const relatedProject = this.rootStore.projectsStore.list.find(
                    (project) => project.groupId === id
                );

                if (relatedProject) {
                    relatedProject.groupId = null;
                }
                this.createProjectGroupsMenuItems();
            });
        } catch (e) {
            toast(e?.response?.data?.errors?.message ?? e.message, { type: toast.TYPE.ERROR });
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    createProjectGroupsMenuItems = () => {
        if (!this.list.length) {
            this.menuItems = [];
        }
        const menuItems = this.list.map((projectGroup) => ({
            id: projectGroup.id,
            icon: 'fas fa-folder-open',
            label: `${projectGroup.name} (${
                projectGroup.projects?.filter((project) => project.tag !== 'old').length ?? 0
            })`,
            to: `/projectGroup?id=${projectGroup.id}`
        }));
        this.menuItems = menuItems;
    };
}
