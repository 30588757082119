import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import AdvancedSettings from '.';

interface Props {
    handleAdvancedSettings: (maxDetections: number) => void;
    showAdvancedSettings: boolean;
    defaultMaxDetections: number;
}

const AdvancedSettingsModal: React.FC<Props> = ({
    handleAdvancedSettings,
    showAdvancedSettings,
    defaultMaxDetections
}) => {
    const [maxDetections, setMaxDetections] = useState<number>(defaultMaxDetections);
    const handleSettings = () => {
        handleAdvancedSettings(maxDetections);
    };

    const handleChange = (value: number) => {
        setMaxDetections(value);
    };

    return (
        <Modal
            isOpen={showAdvancedSettings}
            toggle={handleSettings}
            className="app-component-modal"
            size="lg"
        >
            <ModalHeader toggle={handleSettings}>
                Advanced Settings
            </ModalHeader>
            <ModalBody>
                <AdvancedSettings
                    onChange={handleChange}
                    maxDetections={maxDetections}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSettings}>
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AdvancedSettingsModal;
