import { useEffect, useState, FC } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import {
    Badge,
    FormGroup,
    Input,
    PopoverHeader,
    PopoverBody,
    UncontrolledPopover
} from 'reactstrap';
import { faInfoCircle, faMagic, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteConfirmation from '../../../components/Modals/DeleteConfirmation';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import { Classification, DatasetImage, ProjectType, Annotation } from '../../../models/api';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import prettyBytes from 'pretty-bytes';
import { AccessLevel } from '@aws-amplify/ui-components';
import { s3Util } from '../../../utils/s3';
import TableWithPagination from '../../../components/BootstrapTable/TableWithPagination';
import { CustomS3ImageWithSpinner } from '../../../components/AmplifyUi';
import { imageHashFormat } from '../../../utils/helper-functions';
import dayjs from 'dayjs';
import { dateConfig } from '../../../config/date';
import AnnotationModal from '../AnnotationModal';

const ImageListTable: FC = observer(() => {
    const { datasetImageStore, labelsStore, userStore, projectsStore, imageToZipStore } =
        useStores();

    const [deleteConfirmation, setDeleteConfirmation] = useState<DatasetImage[]>([]);
    const [checkedRows, setCheckedRows] = useState<DatasetImage[]>([]);

    const project = projectsStore.current!;
    const isClassificationProject = project.type === ProjectType.ObjectClassification;
    const isDetectionProject = project.type === ProjectType.ObjectDetection;

    const handleAnnotateButtonClick = (row: DatasetImage) => async () => {
        const offset = datasetImageStore.pagination.page
            ? datasetImageStore.pagination.page * 10
            : 0;
        datasetImageStore.fetchImagesForModal();
        await datasetImageStore.fetchImagesForAnnotation(10, offset);
        const activeAnnotatingImage = datasetImageStore.imagesForModal[row.id];
        if (!activeAnnotatingImage) {
            return;
        }
        datasetImageStore.annotate(activeAnnotatingImage);
    };

    const handleDelete = () => {
        datasetImageStore.deleteImage(checkedRows);
        setDeleteConfirmation([]);
        setCheckedRows([]);
    };

    const handleDeleteConfirmationClosed = () => {
        setDeleteConfirmation([]);
    };

    useEffect(() => {
        datasetImageStore.fetchImages({ isClearQuery: true });
        if (!labelsStore.isLoading) {
            labelsStore.fetchList();
        }
    }, [datasetImageStore, labelsStore, project.id]);

    const selectRowProp = {
        mode: 'checkbox',
        clickToSelect: false,
        selectColumnPosition: 'right',
        selected: checkedRows.map((el) => el.id),
        bgColor: '#c9c7c7',
        headerColumnStyle: { width: 80, textAlign: 'center' },
        onSelectAll: (isSelect: boolean, rows: any) => {
            const ids = rows.map((el: any) => el.id);

            checkedRows.length && setDeleteConfirmation(checkedRows);

            return !isSelect ? ids : [];
        },
        onSelect: (row: any, isSelect: boolean) => {
            if (isSelect) setCheckedRows((prev) => [row, ...prev]);
            else {
                const filterRows = checkedRows.filter((el) => el.id !== row.id);
                setCheckedRows(filterRows);
            }
        },
        selectionHeaderRenderer: () => {
            return (
                <ActionButton
                    id='pages-dataset-image-list-table_delete-selected-btn'
                    tooltipText='Delete Selected'
                    color={'danger'}
                    disabled={imageToZipStore.inProgress || checkedRows.length === 0}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </ActionButton>
            );
        },
        selectionRenderer: (props: any) => {
            return (
                <div className={'d-flex justify-content-center'}>
                    <FormGroup check className={'app-components-boostrap-table__action-button'}>
                        <Input type='checkbox' className={'ml-0 mt-0'} checked={props.checked} />
                    </FormGroup>
                </div>
            );
        }
    };
    const columns: ColumnDescription[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => {
                return rowIndex + datasetImageStore.pagination.firstIndexOnPage + 1;
            }
        },
        {
            dataField: 'thumbnail',
            text: 'Thumbnail',
            headerClasses: 'app-components-boostrap-table__th-sm',
            classes: 'app-components-boostrap-table__td-thumbnail',
            formatter: (_cel, row) => {
                return (
                    <CustomS3ImageWithSpinner
                        level={AccessLevel.Private}
                        imgKey={s3Util.getDatasetImageThumbnailKey(row)}
                        identityId={userStore.impersonationUser?.storagePath}
                    />
                );
            }
        },
        {
            dataField: 'hashedName',
            text: 'Image Name',
            sort: true,
            classes: 'app-components-boostrap-table__td-mw-md',
            formatter: (_cel, row) => {
                return imageHashFormat(row.hashedName, 4, 4);
            }
        },
        {
            dataField: 'createdAt',
            text: 'Date Added',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => dayjs(row.createdAt).format(dateConfig.formats.date)
        },
        {
            dataField: 'updatedAt',
            text: 'Last Updated',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => dayjs(row.updatedAt).format(dateConfig.formats.date)
        },
        {
            dataField: 'info',
            text: 'Image info',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'text-nowrap',
            formatter: (_cel, row) => {
                return (
                    <>
                        <div className="d-flex align-items-center justify-content-center">
                            <ActionButton
                                id={`PopoverFocus-${row.id}`}
                                color="info"
                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </ActionButton>
                        </div>
                        <UncontrolledPopover
                            placement='bottom'
                            target={`PopoverFocus-${row.id}`}
                            trigger='focus'
                        >
                            <PopoverHeader>Image information</PopoverHeader>
                            <PopoverBody className={'d-flex flex-column'}>
                                <div className={'d-flex justify-content-between text-nowrap'}>
                                    <span className={'py-1 px-2'}>Dimensions:</span>{' '}
                                    <span className={'py-1 px-2'}>{`${row.dimensionX} x ${row.dimensionY}`}</span>
                                </div>
                                <div className={'d-flex justify-content-between text-nowrap'}>
                                    <span className={'py-1 px-2'}>Aspect Ratio:</span>{' '}
                                    <span className={'py-1 px-2'}>{(row.dimensionX / row.dimensionY).toFixed(2)}</span>
                                </div>
                                <div className={'d-flex justify-content-between text-nowrap'}>
                                    <span className={'py-1 px-2'}>Size:</span>{' '}
                                    <span className={'py-1 px-2'}>{!!row.size ? prettyBytes(row.size) : ''}</span>
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </>
                );
            }
        },
        {
            dataField: 'annotated',
            text: 'Annotated',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const count = row.annotationAndClassificationCount;
                return count > 0 ? (
                    <Badge color='success' pill>
                        yes
                    </Badge>
                ) : (
                    <Badge color='danger' pill>
                        no
                    </Badge>
                );
            }
        },
        {
            dataField: 'label',
            text: 'Label(s)',
            align: 'center',
            headerAlign: 'center',
            hidden: !isClassificationProject,
            formatter: (_cel, row) => {
                const sortedClassifications = row.classifications?.sort(
                    (a: Classification, b: Classification) =>
                        (a.label?.name || '').localeCompare(b.label?.name || '')
                );
                const shouldShowCommaSeparator = (index: number) =>
                    index !== sortedClassifications.length - 1;
                return (
                    <>
                        {sortedClassifications?.map((item: Classification, index: number) => (
                            <span>
                                {`${item.label?.name}${
                                    shouldShowCommaSeparator(index) ? ', ' : ''
                                }`}
                            </span>
                        ))}
                    </>
                );
            }
        },
        {
            dataField: 'label',
            text: 'Label(s)',
            align: 'center',
            headerAlign: 'center',
            hidden: !isDetectionProject,
            formatter: (_cel, row) => {
                const sortedAnnotations = row.annotations?.sort((a: Annotation, b: Annotation) =>
                    (a.label?.name || '').localeCompare(b.label?.name || '')
                );
                const shouldShowCommaSeparator = (index: number) =>
                    index !== uniqAnnotations.length - 1;
                const uniqAnnotations: Array<string> = [];
                sortedAnnotations?.forEach((item: Classification, index: number) => {
                    if (item.label) {
                        if (!uniqAnnotations.includes(item.label.name)) {
                            uniqAnnotations.push(item.label.name);
                        }
                    }
                });
                return (
                    <>
                        {uniqAnnotations?.map((item: string, index: number) => (
                            <span>{`${item}${shouldShowCommaSeparator(index) ? ', ' : ''}`}</span>
                        ))}
                    </>
                );
            }
        },
        {
            dataField: 'annotate',
            text: 'Annotate',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const tooltipButtonId = `pages-dataset-image-list-table_annotate-button-${row.id}`;
                return (
                    <ActionButton
                        color='warning'
                        onClick={handleAnnotateButtonClick(row)}
                        id={tooltipButtonId}
                        tooltipText='Annotate item'
                        disabledTooltipText='Please wait for all the images to be uploaded'
                        disabled={
                            datasetImageStore.isImagesForAnnotationLoading ||
                            datasetImageStore.hasUnprocessedItems ||
                            imageToZipStore.inProgress ||
                            datasetImageStore.state.deleting['dataset-images']
                        }
                    >
                        <FontAwesomeIcon icon={faMagic} />
                    </ActionButton>
                );
            },
            formatExtraData: [
                datasetImageStore.isImagesForAnnotationLoading,
                datasetImageStore.hasUnprocessedItems,
                imageToZipStore.inProgress,
                datasetImageStore.state.deleting['dataset-images']
            ]
        }
    ];

    return (
        <>
            <TableWithPagination
                data={Object.values(datasetImageStore.images)}
                columns={columns}
                pagination={datasetImageStore.pagination}
                wrapperClasses='mt-3'
                loading={datasetImageStore.state.images.loading}
                disabled={datasetImageStore.hasUnprocessedItems}
                selectRow={selectRowProp}
                projectType={projectsStore.current!.type}
            />

            <DeleteConfirmation
                body={`Are you sure you want to delete ${deleteConfirmation.length} ${
                    deleteConfirmation.length > 1 ? 'images' : 'image'
                }?`}
                isOpen={!!deleteConfirmation.length}
                onConfirm={handleDelete}
                onClosed={handleDeleteConfirmationClosed}
                busyIndication={
                    !!deleteConfirmation && datasetImageStore.state.deleting['dataset-images']
                }
            />

            <AnnotationModal />
        </>
    );
});

export default ImageListTable;
