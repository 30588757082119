import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import VideoListTable from './VideoListTable';
import ProcessVideoPanel from './ProcessVideoPanel';
import Paper from '../../components/Paper';
import { useStores } from '../../store';
import { DetectionModelSimulationUpdateForm } from '../../models/api';
import { longOperationsConfig } from '../../config/long-operations';
import DnaLoader from '../../components/DnaLoader';
import { VideoErrorModal } from './VideoErrorModal';

export const ModelSimulationPage: React.FC = observer(() => {
    const { detectionModelSimulationsStore, detectionModelsStore, userStore, projectsStore } = useStores();
    const [ detectionSafari, setDetectionSafari ] = useState(false);

    const handleCreate = (params: DetectionModelSimulationUpdateForm) => {
        if (!params.detectionModelId) {
            return;
        }

        // Temporary for example
        detectionModelSimulationsStore.createItem(params);
    };

    const handleDelete = (id: string) => {
        detectionModelSimulationsStore.deleteItem(id);
    };

    useEffect(() => {
        detectionModelSimulationsStore.fetchList();
        const handle = setInterval(async () => {
            await detectionModelSimulationsStore.fetchList();
        }, longOperationsConfig.refreshInterval)
        return () => {
            clearInterval(handle)
        }
    }, [detectionModelSimulationsStore]);

    useEffect(() => {
        if (!detectionModelSimulationsStore.list.IsFetched) {
            detectionModelSimulationsStore.fetchList();
        }
        if (!detectionModelsStore.listIsFetched){
            detectionModelsStore.fetchList();
        }
    }, [
        detectionModelSimulationsStore,
        detectionModelsStore,
        detectionModelSimulationsStore.list.IsFetched,
        detectionModelsStore.listIsFetched
    ]);

    const processVideoDisabled = detectionModelSimulationsStore.item.isLoading || detectionModelsStore.isLoading;

    return (
        <Layout>
            <PageTitle
                heading='Model Simulation'
                icon='fas fa-play icon-gradient bg-warm-flame'
                subheading='Run your model on a video or image file'
                showCloseButton={true}
            />

            <Row>
                <Col sm={12}>
                    <ProcessVideoPanel
                        detectionModelList={detectionModelsStore.listOfCompleted}
                        disabled={processVideoDisabled}
                        onCreate={handleCreate}
                        projectType={projectsStore.current!.type}
                        detectionSafari={()=>{setDetectionSafari(true)}}
                    />
                </Col>
            </Row>

            {!detectionModelSimulationsStore.list.IsFetched &&
            <DnaLoader />
            }

            <Row>
                <Col sm={12}>
                    <Paper rounded className='mt-4' hidden={!detectionModelSimulationsStore.list.IsFetched}>
                        <VideoListTable
                            items={detectionModelSimulationsStore.list.items}
                            onDelete={handleDelete}
                            isLoading={detectionModelSimulationsStore.isLoading}
                            impersonationUser={userStore.impersonationUser}
                        />
                    </Paper>
                </Col>
            </Row>
            <VideoErrorModal
                error={detectionSafari}
                onClose={()=>{setDetectionSafari(false)}}
            />
        </Layout>
    );
});
