import { RgbStringColorPicker } from "react-colorful"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"

interface Props {
    isOpen: boolean;
    toggle: () => void;
    handleChange?: ((newColor: string) => void) | undefined;
    color?: string;
    className?: string
    toggleClassName?: string
}

const DropdownColorPicker: React.FC<Props> = ({ isOpen, toggle, handleChange, color, className, toggleClassName }) => {
    return (
        <Dropdown isOpen={isOpen} toggle={toggle} className={className ?? ''}>
            <DropdownToggle
                color='link'
                className={toggleClassName ?? ''}
            >  
                <div
                    className="app-components-boostrap-color-picker__color"
                    style={{ backgroundColor: color }}
                />
            </DropdownToggle>

            <DropdownMenu className='p-3' style={{ minWidth: 'auto' }}>
                <RgbStringColorPicker onChange={handleChange} color={color} />
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropdownColorPicker