import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import AdvancedSettings, { BoxLabelsEnum } from '.';
import { Settings } from '../ProcessVideoPanel';

interface Props {
    handleAdvancedSettings: (settings: Settings) => void;
    showAdvancedSettings: boolean;
    defaultMinConfidence: number;
}

const AdvancedSettingsModal: React.FC<Props> = ({
    handleAdvancedSettings,
    showAdvancedSettings,
    defaultMinConfidence
}) => {
    const [minConfidence, setMinConfidence] = useState<number>(defaultMinConfidence);
    const [boxLabel, setBoxLabel] = useState<string>(BoxLabelsEnum.NameAndConfidence);
    const handleSettings = () => {
        handleAdvancedSettings({minConfidence, boxLabel});
    };

    const handleConfidenceChange = (value: number) => {
        setMinConfidence(value);
    };

    const handleBoxLabelChange = (value: string) => {
        setBoxLabel(value);
    };

    return (
        <Modal
            isOpen={showAdvancedSettings}
            toggle={handleSettings}
            className="app-component-modal"
            size="md"
        >
            <ModalHeader toggle={handleSettings}>
                Advanced Settings
            </ModalHeader>
            <ModalBody>
                <AdvancedSettings
                    onConfidenceChange={handleConfidenceChange}
                    onBoxLabelChange={handleBoxLabelChange}
                    minConfidence={minConfidence}
                    boxLabel={boxLabel}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSettings}>
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AdvancedSettingsModal;
