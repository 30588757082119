import React, { FunctionComponent, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import {Field} from 'formik'
import { FormFieldProps } from './types';
import { useFormField } from './hooks/useFormField';
import { useFocusEffect } from './hooks/useFocusEffect';
import { InputType } from 'reactstrap/lib/Input';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PwdFormField: FunctionComponent<FormFieldProps> = (props) => {
    const {id, hasError, error} = useFormField(props.formName, props.field);
    const fieldRef = useFocusEffect(props.isFirst);

    const [inputType, setInputType] = useState<InputType>('password');

    const isPasswordHidden = inputType==='password';
    const handleShowPasswordClick = ()=>{
        setInputType(isPasswordHidden ? 'text' : 'password')
    }

    return (
        <FormGroup>
            {props.label && <Label htmlFor={id}>{props.label}</Label>}
            <div>
                <div className={'pwd-form-field__input-wrapper'}>
                    <Field
                        className={`pwd-form-field__input form-control ${hasError && 'is-invalid'}`}
                        id={id}
                        name={props.field}
                        type={inputType}
                        placeholder={props.placeholder}
                        innerRef={fieldRef}
                    />
                    <FormFeedback>{error}</FormFeedback>
                    <button className={'pwd-form-field__eye-icon btn btn-link'} type={'button'} onClick={handleShowPasswordClick} tabIndex={-1}>
                        <FontAwesomeIcon icon={isPasswordHidden ? faEye : faEyeSlash}/>
                    </button>
                </div>

            </div>
        </FormGroup>
    );
};
