import React, { useState } from 'react';
import { Button, Col, Form, Row, UncontrolledTooltip } from 'reactstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import getBlobDuration from 'get-blob-duration';
import { isMobileSafari, isSafari } from 'react-device-detect';
import {
    classificationModelSimulationSourceAttachmentMimeTypes,
    DetectionModel,
    DetectionModelDownload,
    DetectionModelFrameworkType,
    detectionModelSimulationSourceAttachmentMimeTypes,
    DetectionModelSimulationUpdateForm,
    ProjectType
} from '../../../models/api';
import * as Yup from 'yup';
import MimeTypes from '../../../models/mimeTypes';
import Dropzone from 'react-dropzone';
import DetectionModelDropdownWithIcon from '../../../components/DetectionModel/DetectionModelDropdownWithIcon';
import { ValidateOptions } from 'yup/lib/types';
import AdvancedSettingsModal from '../AdvancedSettings/advanced-settings-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { BoxLabelsEnum } from '../AdvancedSettings';
import { bytesToMb, isVideoFile } from '../../../utils/helper-functions';
import withSimulationDropdown from '../../../components/DetectionModel/SimulationDetectionModelDropdown';
import { Extensions } from '../../../models/extensions';

const MAX_FILE_SIZE_UPLOAD_MB = 300;
const MAX_VIDEO_DURATION_SECONDS = 60;

export type Settings = {
    minConfidence: number;
    boxLabel: string;
};

interface Values {
    detectionModelId: DetectionModelDownload['detectionModelId'];
    file?: File;
    minConfidence: number;
    boxLabel: string;
}

interface Props {
    detectionModelList: DetectionModel[];
    onCreate: (values: DetectionModelSimulationUpdateForm) => void;
    disabled: boolean;
    projectType: ProjectType;
    detectionSafari: () => void;
}

const validationSchema = Yup.object().shape({
    file: Yup.mixed()
        .test(
            'classificationFileType',
            'Only jpeg/png image formats are supported',
            (file?: File, config?: { options: ValidateOptions<any> }) => {
                const { projectType } = config?.options.context;
                if (!file || projectType !== ProjectType.ObjectClassification) {
                    return true;
                }

                return (
                    file &&
                    classificationModelSimulationSourceAttachmentMimeTypes.includes(file.type)
                );
            }
        )
        .test(
            'fileType',
            'Only mp4 video or jpeg/png image formats are supported',
            (file?: File) => {
                if (!file) {
                    return true;
                }

                if (isVideoFile(file)) {
                    const extension = file.name.split('.').pop();
                    if (extension && extension.toLowerCase() !== Extensions.Mp4) {
                        return false;
                    }
                }

                return (
                    file && detectionModelSimulationSourceAttachmentMimeTypes.includes(file.type)
                );
            }
        )
        .test(
            'durationIsNaN',
            `Couldn't recognize the video codec. Please try another video file.`,
            async (file?: File) => {
                if (!file) {
                    return true;
                }
                if (file.type !== MimeTypes.VideoType.Mp4) {
                    return true;
                }
                const duration = await getBlobDuration(URL.createObjectURL(file)).catch(
                    (err) => err
                );
                return typeof duration !== 'object';
            }
        )
        .test(
            'duration',
            `The video shouldn't exceed ${MAX_VIDEO_DURATION_SECONDS} seconds`,
            async (file?: File) => {
                if (!file) {
                    return true;
                }

                if (file.type !== MimeTypes.VideoType.Mp4) {
                    return true;
                }

                return await new Promise<boolean>((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        if (!reader.result) {
                            return resolve(false);
                        }

                        const audio = new Audio(reader.result.toString());

                        audio.onloadedmetadata = () => {
                            resolve(audio.duration <= MAX_VIDEO_DURATION_SECONDS);
                        };

                        audio.onerror = () => {
                            resolve(false);
                        };
                    };

                    reader.readAsDataURL(file);
                });
            }
        )
        .test(
            'fileSize',
            `The file shouldn't exceed ${MAX_FILE_SIZE_UPLOAD_MB} Mb`,
            (file?: File) => {
                if (!file) {
                    return true;
                }
                return bytesToMb(file.size) <= MAX_FILE_SIZE_UPLOAD_MB;
            }
        )
});

// TODO: remove "any" and do smth about types
const SimulationDropdown = withSimulationDropdown(DetectionModelDropdownWithIcon as any);

const ProcessVideoPanel: React.FC<Props> = ({
    detectionModelList,
    onCreate,
    disabled,
    projectType,
    detectionSafari
}) => {
    const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);

    const sortedDetectionModelList = [...detectionModelList].sort((a, b) => {
        const firstDate = new Date(a.createdAt!);
        const secondDate = new Date(b.createdAt!);
        return secondDate.getTime() - firstDate.getTime();
    });
    let DefaultMinConfidence = 50;

    const isClassificationProject = projectType === ProjectType.ObjectClassification;
    const isSegmentationProject = projectType === ProjectType.ObjectSegmentation;
    const isPoseDetectionProject = projectType === ProjectType.PoseDetection;

    if (isPoseDetectionProject) {
        DefaultMinConfidence = 30;
    }

    const handleAdvancedSettings = () => {
        setShowAdvancedSettings((prev) => !prev);
    };

    const handleAdvancedSettingsDone =
        (setFieldValue: (field: string, value: any) => void) =>
        ({ minConfidence, boxLabel }: Settings) => {
            setFieldValue('minConfidence', minConfidence);
            setFieldValue('boxLabel', boxLabel);
            handleAdvancedSettings();
        };

    const getFirstEnabledOption = (modelList: DetectionModel[]) => {
        if (modelList.length) {
            const enabledOption = modelList.find(
                (option) => option.frameworkType !== DetectionModelFrameworkType.TensorFlowV1
            );
            return enabledOption?.id || '';
        } else {
            return '';
        }
    };

    return (
        <Formik<Values>
            initialValues={{
                detectionModelId: getFirstEnabledOption(sortedDetectionModelList),
                minConfidence: DefaultMinConfidence,
                boxLabel: BoxLabelsEnum.NameAndConfidence
            }}
            onSubmit={(values) => {
                onCreate(values as DetectionModelSimulationUpdateForm);
            }}
            validate={async (values) => {
                if (values.file) {
                    if (isVideoFile(values.file) && (isSafari || isMobileSafari)) {
                        detectionSafari();
                        return {
                            file: 'Field has error'
                        };
                    } else {
                        try {
                            await validationSchema.validate(values, { context: { projectType } });
                        } catch (e) {
                            toast(e.message, { type: toast.TYPE.ERROR });
                            return {
                                file: 'Field has error'
                            };
                        }
                    }
                }
            }}
            enableReinitialize
        >
            {({ values, errors, handleSubmit, setFieldValue }) => {
                const chooseFileBtnOverlapId =
                    'pages-model-simulation-process-video-panel_choose-file-btn-overlap';
                const chooseFileIsDisabled = !values.detectionModelId;
                const fileNameMaxLength = 30;
                let shortFileName = values.file?.name;

                if (shortFileName && shortFileName.length > fileNameMaxLength) {
                    const [fileName, ext] = shortFileName.split('.');
                    shortFileName = `${fileName.slice(
                        0,
                        fileNameMaxLength - (shortFileName.length + 2)
                    )}...${ext}`;
                }

                return (
                    <Form onSubmit={handleSubmit} encType='multipart/form-data'>
                        <Row>
                            {!isPoseDetectionProject && (
                                <Col md={4}>
                                    <div className='d-flex align-items-center he-100'>
                                        <div className='font-weight-bold text-primary mr-2'>
                                            Model:
                                        </div>

                                        <SimulationDropdown
                                            detectionModelList={sortedDetectionModelList}
                                            dataKey='value'
                                            textField='text'
                                            filter={false}
                                            value={values.detectionModelId}
                                            onChange={(option) =>
                                                setFieldValue('detectionModelId', option.value)
                                            }
                                        />
                                    </div>
                                </Col>
                            )}

                            <Col md={8}>
                                <div className='d-flex align-items-center he-100'>
                                    <div className='font-weight-bold text-primary flex-shrink-0'>
                                        {isClassificationProject ? (
                                            <span>Image (jpg/png)</span>
                                        ) : (
                                            <span>Video (mp4) or Image (jpg/png)</span>
                                        )}
                                    </div>

                                    <div
                                        className='position-relative dropzone-wrapper h-auto w-auto ml-4'
                                        id={chooseFileBtnOverlapId}
                                    >
                                        <Dropzone
                                            onDrop={(files) => {
                                                if (files) {
                                                    setFieldValue('file', files[0]);
                                                }
                                            }}
                                            disabled={chooseFileIsDisabled}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    <div className='dropzone-content content-width-md p-2'>
                                                        {errors.file ? (
                                                            <span className='text-danger'>
                                                                {errors.file}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {values.file
                                                                    ? shortFileName
                                                                    : 'Drop files here or click to upload'}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>

                                        {chooseFileIsDisabled && (
                                            <UncontrolledTooltip target={chooseFileBtnOverlapId}>
                                                Choose Model First
                                            </UncontrolledTooltip>
                                        )}
                                    </div>

                                    {!isClassificationProject && !isSegmentationProject && (
                                        <Button
                                            color='light'
                                            onClick={handleAdvancedSettings}
                                            className='ml-4'
                                        >
                                            <FontAwesomeIcon icon={faCog} />
                                        </Button>
                                    )}
                                    <Button
                                        color='primary'
                                        className='ml-4 flex-shrink-0'
                                        type='submit'
                                        disabled={disabled || !values.file}
                                    >
                                        Process
                                    </Button>
                                </div>
                            </Col>

                            {/* FSAI-273 */}
                            {/* <Col md={2}>
                                <div className='d-flex align-items-center he-100 justify-content-end'>
                                    <div className='font-weight-bold'>
                                        File size limits:

                                        <span className='text-primary ml-2'>
                                            50 Mb
                                        </span>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                        <AdvancedSettingsModal
                            showAdvancedSettings={showAdvancedSettings}
                            handleAdvancedSettings={handleAdvancedSettingsDone(setFieldValue)}
                            defaultMinConfidence={DefaultMinConfidence}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ProcessVideoPanel;
