import React, { useEffect, useState } from 'react';
import { Button, CardText, Spinner } from 'reactstrap';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../store';
import { Project, ProjectType } from '../../../../models/api';

interface Props {
    projectType: ProjectType;
    handleProjectTemplate: (project: Project | null) => void;
}

const BLANK_PROJECT_IMG_URL = 'https://firststep.ai/content/templates/blank.jpg';

const ProjectTemplateCarousel: React.FC<Props> = observer(
    ({ projectType, handleProjectTemplate }) => {
        const { projectsStore } = useStores();
        const [activeIndex, setActiveIndex] = useState(0);
        const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
        const [templateList, setTemplateList] = useState<(Project | null)[]>([]);

        useEffect(() => {
            projectsStore.fetchTemplatesList();
        }, []);

        useEffect(() => {
            if (!projectsStore.templatesList.length) {
                return;
            }
            const newTemplateList = [
                null,
                ...projectsStore.templatesList.filter((p) => p.type === projectType),
            ];
            setActiveIndex(0);
            setIsLoading(newTemplateList.reduce((acc, _, i) => ({ ...acc, [i]: true }), {}));
            setTemplateList(newTemplateList);
            handleProjectTemplate(null);
        }, [projectsStore.templatesList, projectType]);

        const handleNext = () => {
            const nextIndex = activeIndex === templateList.length - 1 ? 0 : activeIndex + 1;
            setActiveIndex(nextIndex);
            handleProjectTemplate(templateList[nextIndex]);
        };

        const handlePrevious = () => {
            const nextIndex = activeIndex === 0 ? templateList.length - 1 : activeIndex - 1;
            setActiveIndex(nextIndex);
            handleProjectTemplate(templateList[nextIndex]);
        };

        const handleLoaded = (e: React.SyntheticEvent<HTMLImageElement>, i: number) => {
            if (!(e.target as HTMLImageElement).complete) {
                return;
            }
            setIsLoading({ ...isLoading, [i]: false });
        };

        return !!templateList.length ? (
            <>
                {templateList.map((item, i) => (
                    <div className={cx({ 'd-none': i !== activeIndex })}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="app-pages-projects-page__image-template">
                                <img
                                    style={{
                                        visibility: (isLoading[i] && i !== 0)
                                          ? 'hidden'
                                          : 'visible',
                                    }}
                                    src={
                                        item?.templateImageUrl
                                            ? item.templateImageUrl
                                            : BLANK_PROJECT_IMG_URL
                                    }
                                    onLoad={(e) => handleLoaded(e, i)}
                                    alt="Project image"
                                />
                                {(isLoading[i] && i !== 0) && (
                                    <CardText className="d-flex justify-content-center align-items-center position-absolute">
                                        <Spinner color="primary" />
                                    </CardText>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <div className="d-flex justify-content-center align-items-center text-center mt-4">
                    <Button
                        color="primary"
                        size="sm"
                        onClick={handlePrevious}
                        disabled={activeIndex === 0}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>

                    <span className="font-weight-bold ml-3 mr-3 w-50">
                        {templateList.map((item, i) => (
                            <div className={cx({ 'd-none': i !== activeIndex })}>
                                {item ? item.name : 'Blank Project'}
                            </div>
                        ))}
                    </span>

                    <Button
                        color="primary"
                        size="sm"
                        onClick={handleNext}
                        disabled={activeIndex === templateList.length - 1}
                    >
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                </div>
            </>
        ) : (
            <div className="d-flex justify-content-center my-5">
                <Spinner />
            </div>
        );
    }
);

export default ProjectTemplateCarousel;
