import React from "react";
import { Stage } from "react-konva";
import { MAXIMUM_HEIGHT, MAXIMUM_WIDTH, Points, StageProps } from "../index";
import { DatasetImage } from "../../../../models/api";
import Annotate from "../Annotate";
import ImageCanvas from "../ImageCanvas";
import Polygon from "../Polygon/index";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";

const ZOOM_IN_STEP = 1.2;
const ZOOM_OUT_STEP = 0.8;

const INITIAL_POSITION_POINTS = {x: 0, y: 0};

interface Props {
  setImage: React.Dispatch<React.SetStateAction<HTMLImageElement | undefined>>;
  image: HTMLImageElement | undefined;
  setScale: React.Dispatch<React.SetStateAction<number | undefined>>;
  scale: number | undefined;
  setPoints: React.Dispatch<React.SetStateAction<Points[]>>;
  points: Points[];
  setFlattenedPoints: React.Dispatch<React.SetStateAction<Points[]>>;
  flattenedPoints: Points[];
  setIsPolygonComplete: React.Dispatch<React.SetStateAction<boolean>>;
  isPolygonComplete: boolean;
  setIsMouseOverPoint: React.Dispatch<React.SetStateAction<boolean>>;
  isMouseOverPoint: boolean;
  setPosition: React.Dispatch<React.SetStateAction<Points>>
  position: Points;
  setLayouts: React.Dispatch<React.SetStateAction<DatasetImage['polygonAnnotations'] | undefined>>
  layouts: DatasetImage['polygonAnnotations'];
  isEdit: boolean;
  isDraggable: boolean;
  isStartMarkup: boolean;
  imageSource: HTMLImageElement;
  handleDeleteAnnotation: (id: string) => void;
  handleSaveNewLayout: () => void;
  onSave: (newAnnotationList: DatasetImage['polygonAnnotations']) => void;
  stageRef: React.RefObject<StageProps>
  setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
  isIncreasePointRadius: boolean;
  setStageHeight: React.Dispatch<React.SetStateAction<number>>;
  stageHeight: number;
  setStageWidth: React.Dispatch<React.SetStateAction<number>>;
  stageWidth: number;
  setStageOffset: React.Dispatch<React.SetStateAction<Points>>;
  stageOffset: Points;
}

type TransformedStageAttributes = {
  x: number;
  y: number;
  scale: number;
}

const Canvas: React.FC<Props> = (props) => {
  const {
    isEdit, image, setScale, setImage, points,
    flattenedPoints, isPolygonComplete, setPoints,
    setIsMouseOverPoint, isMouseOverPoint,
    setFlattenedPoints, setIsPolygonComplete,
    position, setPosition, layouts, scale,
    setLayouts, imageSource, handleDeleteAnnotation,
    handleSaveNewLayout, onSave, stageRef, isDraggable,
    isStartMarkup, setIsDraggable, isIncreasePointRadius,
    setStageHeight, setStageWidth, setStageOffset, stageOffset
  } = props;
 
  function getRelativePointerPosition(node: StageProps | null) {
    if (!node) return undefined;

    const transform = node.getAbsoluteTransform().copy();
    transform.invert();
    const pos = node.getStage().getPointerPosition();

    if (!pos) return undefined;

    return transform.point(pos);
  }

  function zoomStage(stage: StageProps, scaleBy: number, pos: Vector2d) {
    const oldScale = stage.scaleX();

    const mousePointTo = {
      x: pos.x / oldScale - stage.x() / oldScale,
      y: pos.y / oldScale - stage.y() / oldScale,
    };

    const newScale = Math.max(0.05, oldScale * scaleBy);

    const newPos = {
      x: -(mousePointTo.x - pos.x / newScale) * newScale,
      y: -(mousePointTo.y - pos.y / newScale) * newScale,
    };

    const newAttrs = limitAttributes(stage, { ...newPos, scale: newScale });

    stage.to({
      x: newAttrs.x,
      y: newAttrs.y,
      scaleX: newAttrs.scale,
      scaleY: newAttrs.scale,
      duration: 0.1,
    });
  }

  function limitAttributes(stage: StageProps, newAttrs: TransformedStageAttributes) {
    const box = stage.findOne('Image')?.getClientRect();

    if (!box) {
      return {x: -1, y: -1, scale: -1}
    }
    const minX = -box.width + stage.width() / 2;
    const maxX = stage.width() / 2;

    const x = Math.max(minX, Math.min(newAttrs.x, maxX));

    const minY = -box.height + stage.height() / 2;
    const maxY = stage.height() / 2;

    const y = Math.max(minY, Math.min(newAttrs.y, maxY));

    const scale = Math.max(0.05, newAttrs.scale);

    return { x, y, scale };
  }

  const handleWheel = (e: KonvaEventObject<WheelEvent>) => {
    let wheel = e.evt.deltaY < 0 ? 1 : -1;
    e.evt.preventDefault();

    const point = getRelativePointerPosition(e.target.getStage()) || INITIAL_POSITION_POINTS;

    if (wheel === 1) {
      zoomStage(stageRef.current!, ZOOM_IN_STEP, point);
    } else {
      zoomStage(stageRef.current!, ZOOM_OUT_STEP, point);
    }
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const isLeftButton = e.evt.which === 1;
    if(isLeftButton) {
      setIsDraggable(false);
    }
    if(isStartMarkup && isLeftButton) {
      const point = getRelativePointerPosition(e.target.getStage()) || INITIAL_POSITION_POINTS;
      if (isMouseOverPoint && points.length >= 3) {
        setIsPolygonComplete(true);
        handleSaveNewLayout();
      } else if (!isPolygonComplete) {
        if (points) {
          setPoints([...points, point]);
        } else {
          setPoints([point]);
        }
      }
    }
  }
  const handleMouseUp = (e: KonvaEventObject<MouseEvent>) => {
    const isLeftButton = e.evt.which === 1;
    if(isLeftButton) {
      setIsDraggable(true);
    }
  }
  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if(isStartMarkup) {
      const point = getRelativePointerPosition(e.target.getStage()) || INITIAL_POSITION_POINTS;
      setPosition(point);
    }
  }

  React.useEffect(() => {
    if (points) {
      setFlattenedPoints(
        points
          .concat(isPolygonComplete ? [] : position)
          .reduce((a, b: any) => a.concat(b), [])
      );
    }
  }, [points, isPolygonComplete, position, isStartMarkup])

  return (
    <Stage
      ref={stageRef}
      width={MAXIMUM_WIDTH}
      height={MAXIMUM_HEIGHT}
      scaleX={scale}
      scaleY={scale}
      x={stageOffset.x}
      y={stageOffset.y}
      className="canvas"
      onWheel={handleWheel}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      draggable={isDraggable}
    >
      <ImageCanvas
        width={MAXIMUM_WIDTH}
        height={MAXIMUM_HEIGHT}
        image={image!}
        imageSource={imageSource}
        setImage={setImage}
        setScale={setScale}
        setStageHeight={setStageHeight}
        setStageWidth={setStageWidth}
        setStageOffset={setStageOffset}
      />
      {isEdit === false && isStartMarkup && position ?
        <Annotate
          points={points}
          flattenedPoints={flattenedPoints}
          setIsMouseOverPoint={setIsMouseOverPoint}
          isPolygonComplete={isPolygonComplete}
        /> : <></>}
      {layouts?.length !== 0 && image ?
        <Polygon
          layouts={layouts}
          setLayouts={setLayouts}
          handleDeleteAnnotation={handleDeleteAnnotation}
          onSave={onSave}
          isEdit={isEdit}
          isIncreasePointRadius={isIncreasePointRadius}
        /> : <></>}
    </Stage>
  );
}

export default Canvas;