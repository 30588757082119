import React, { FunctionComponent, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import DnaLoader from '../../../components/DnaLoader';
import Paper from '../../../components/Paper';
import PageTitle from '../../../components/PageTitle';
import Layout from '../../../components/Layout';
import ModelListTable from '../../model-training/ModelListTable';
import { longOperationsConfig } from '../../../config/long-operations';

const RunningTrainings: FunctionComponent = observer(() => {
    const { detectionModelsStore } = useStores();

    useEffect(() => {
        detectionModelsStore.fetchRunningTrainings();
        detectionModelsStore.fetchTerminatedTrainings();
        const handle = setInterval(async () => {
            await detectionModelsStore.fetchRunningTrainings();
            await detectionModelsStore.fetchTerminatedTrainings();
        }, longOperationsConfig.refreshInterval);
        return () => {
            clearInterval(handle);
        };
    }, [detectionModelsStore]);

    return (
        <Layout>
            <>
                <PageTitle
                    heading="Running Trainings"
                    icon="fas fa-atom icon-gradient bg-warm-flame"
                    subheading="See all current running trainings"
                    showCloseButton={true}
                />

                {!detectionModelsStore.runningTrainingsList.isFetched && (
                    <DnaLoader />
                )}

                <Row>
                    <Col sm={12} hidden={!detectionModelsStore.runningTrainingsList.isFetched}>
                        <div className="card-title">Running Trainings</div>
                        <Paper rounded>
                            <ModelListTable
                                    items={Object.values(detectionModelsStore.runningTrainingsList.ListById)}
                                    isLoading={detectionModelsStore.runningTrainingsList.isLoading}
                                    isAllRunningTrainings={true}
                            />
                        </Paper>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className="mt-5" hidden={!detectionModelsStore.terminatedTrainingsList.isFetched}>
                        <div className="card-title">Latest 10 Terminated Trainings</div>
                        <Paper rounded>
                            <ModelListTable
                                    items={Object.values(detectionModelsStore.terminatedTrainingsList.ListById)}
                                    isLoading={detectionModelsStore.terminatedTrainingsList.isLoading}
                                    isAllRunningTrainings={true}
                            />
                        </Paper>
                    </Col>
                </Row>
            </>
        </Layout>
    );
});

export default RunningTrainings;
