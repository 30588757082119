import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
    rounded?: boolean;
}

const Paper: React.FC<Props> = (
    {
        children,
        className,
        rounded = false,
        ...rest
    }
) => {
    return (
        <div className={cx('app-components-paper', {rounded}, className)} {...rest}>
            {children}
        </div>
    );
};

export default Paper;
