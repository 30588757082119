import React, { useState } from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import {
    DetectionModelSimulation,
    FileUploadElement, User
} from '../../../models/api';
import { noDataText } from '../../../components/BootstrapTable';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

import { EditFileUploadModal } from '../EditFileUploadModal';
import { NewFileUploadModal } from '../NewFileUploadModal';

import { toast } from 'react-toastify';
import { useStores } from '../../../store';
import dayjs from 'dayjs';
import { dateConfig } from '../../../config/date';
import DeleteConfirmation from '../../../components/Modals/DeleteConfirmation';
import { compareByField } from '../../../utils/helper-functions';

interface Props {
    user: User,
    isLoading: boolean,
}

const FileUploadListTable: React.FC<Props> = ({user, isLoading}) => {
    const { usersStore } = useStores();
    const [ editModal, setEditModal ] = useState<FileUploadElement | null>(null);
    const [ addModal, setAddModal ] = useState(false);

    const [deleteConfirmation, setDeleteConfirmation] = useState<FileUploadElement>();


    let items = user.fileUploads ?? [];
    if(items.length > 0) {
        items = [...items].sort((b, a) => {
            if(a.date === b.date){
                return compareByField(a, b, 'createdAt')
            } else {
                return compareByField(a, b, 'date')
            }
        })
    }
    const columns: ColumnDescription<FileUploadElement>[] = [
        {
            dataField: 'date',
            text: 'Date',
            headerClasses: 'w-10',
            classes: 'text-nowrap',
            formatter: (_cel, row) => dayjs(row.date).format(dateConfig.formats.dateUSFormat)
        },
        {
            dataField: 'type',
            text: 'Type',
            headerClasses: 'w-10',
        },
        {
            dataField: 'title',
            text: 'Document Name',
            headerClasses: 'text-nowrap',
            classes: 'text-break'
        },
        {
            dataField: 'link',
            text: 'Download Link',
            formatter: (_cel, row) => {
                return (
                    <a className={'text-break'} target='_blank' href={row.link}>{row.link}</a>
                )
            }
        },

        {
            dataField: 'actions',
            text: 'Actions',
            align: 'right',
            headerAlign: 'right',
            formatter: (_cel, row) => {
                const tooltipEditButtonId = `pages-projects-projects-edit-button-${row.id}`;
                const tooltipDeleteButtonId = `pages-projects-projects-table_delete-button-${row.id}`;

                return (
                    <>
                        <div className="d-flex justify-content-end">
                            <ActionButton
                                color="warning"
                                onClick={() => setEditModal(row)}
                                id={tooltipEditButtonId}
                                tooltipText="Edit File Upload"
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </ActionButton>
                            <ActionButton
                                className="ml-2"
                                color="danger"
                                onClick={() => setDeleteConfirmation(row)}
                                id={tooltipDeleteButtonId}
                                tooltipText="Delete File Upload"
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </ActionButton>
                        </div>
                    </>
                );
            }
        },

    ];

    const handleAdd = async (
        values: FileUploadElement
    ) => {
        try {
            await usersStore.addFileUpload(user.cognitoId, values);
            setAddModal(false)
            return;
        } catch (error) {
            toast(error.message, { type: toast.TYPE.ERROR });
        }

    };

    const handleUpdate = async (
        id: string,
        values: FileUploadElement
    ) => {
        try {
            await usersStore.updateFileUpload(id, values);
            setEditModal(null)
            return;
        } catch (error) {
            toast(error.message, { type: toast.TYPE.ERROR });
        }
    };

    const handleDelete = async () => {
        try {
            await usersStore.deleteFileUpload(deleteConfirmation?.id ?? '');
            return;
        } catch (error) {
            toast(error.message, { type: toast.TYPE.ERROR });
        } finally {
            setDeleteConfirmation(undefined)
        }
    };

    const handleDeleteConfirmationClosed = () => {
        setDeleteConfirmation(undefined);
    };

    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={items}
                columns={columns}
                striped
                bordered={false}
                wrapperClasses="table-responsive"
                classes='m-0'
                noDataIndication={noDataText}
            />
            <Button
                className={'mt-2'}
                color="primary"
                type="button"
                disabled={false}
                onClick={()=>setAddModal(true)}>
                Add
            </Button>

            <EditFileUploadModal
                isOpen={!!editModal}
                onClosed={()=>{setEditModal(null)}}
                project={editModal}
                onConfirm={handleUpdate}
            />

            <NewFileUploadModal
                isOpen={addModal}
                onClosed={()=>{setAddModal(false)}}
                project={{}}
                onConfirm={handleAdd}
            />

            <DeleteConfirmation
                body={`Are you sure you want to delete the "${deleteConfirmation?.title}"?`}
                isOpen={!!deleteConfirmation}
                onConfirm={handleDelete}
                onClosed={handleDeleteConfirmationClosed}
                busyIndication={isLoading}
            />
        </>
    );
};

export default FileUploadListTable;
