import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import {
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
    Nav,
    Row, Spinner
} from 'reactstrap';
import { observer } from 'mobx-react-lite';
import AnnotateStatusBadge from '../../../components/AnnotateStatusBadge';
import { Classification, DatasetImage, Label, User } from '../../../models/api';
import DatasetImageNavigation from '../Components/DatasetImageNavigation';
import { AnnotateListProgress } from '../ImageAnnotateModal';
import LabelItem from '../Components/LabelItem';
import { useHotkeys } from 'react-hotkeys-hook';
import { hotkeyAnnotateHandler } from '../utils';
import { compareByField } from '../../../utils/helper-functions';
import Multiselect from 'react-widgets/Multiselect';
import { ActiveAnnotatingImage } from '../../../store/stores/datasetImagesStore';

const LABELS_N_THRESHOLD_TO_SHOW_DROPDOWN = 5;

interface Props extends ModalProps {
    labelList: Label[];
    annotatedDatasetImage: ActiveAnnotatingImage;
    annotateListProgress: AnnotateListProgress;
    onSave?: (classifications: Classification[]) => void;
    onNavNext?: () => Promise<void>;
    onNavPrev?: () => Promise<void>;
    isLoading?: boolean;
    error?: string;
    navPrevDisabled?: boolean;
    navNextDisabled?: boolean;
    impersonationUser?: User;
    isDisabled: boolean;
    isMultiLabel: boolean;
}

const ImageClassificationModal: React.FC<Props> = observer((
    {
        annotatedDatasetImage,
        annotateListProgress,
        labelList,
        onSave,
        onClosed,
        onNavNext,
        onNavPrev,
        isLoading,
        error,
        navPrevDisabled = false,
        navNextDisabled = false,
        impersonationUser,
        isDisabled,
        isMultiLabel,
        ...rest
    }
) => {
    const currentLabels =  annotatedDatasetImage.classifications
        ?.filter((c) => c.label)
        ?.map((c) => c.label as Label)
        ?? [];

    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const [isLoadingNext, setIsLoadingNext] = useState(false);
    const [isLoadingPrev, setIsLoadingPrev] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState<Label[]>(currentLabels);
    const [imageUrl, setImageUrl] = useState('');

    const sortedLabelList = labelList.slice().sort((a, b) => compareByField(a, b, 'name'));

    const handleNavNext = async () => {
        if (onNavNext && !navNextDisabled) {
            setIsLoadingNext(true);
            await onNavNext();
            setIsLoadingNext(false);
        }
    };

    const handleNavPrev = async () => {
        if (onNavPrev && !navPrevDisabled) {
            setIsLoadingPrev(true);
            await onNavPrev();
            setIsLoadingPrev(false);
        }
    };

    const handleImageLoaded = () => {
        setImageIsLoaded(true);
    };

    const getSelectedLabelsByToggledLabel = (label: Label) => {
        if (!isMultiLabel) {
            return selectedLabels[0]?.id === label.id ? [] : [label];
        }
        return selectedLabels.find((item) => item?.id === label.id)
            ? selectedLabels.filter((item) => item.id !== label.id)
            : [...selectedLabels, label];
    }

    const handleLabelClick = (label: Label) => {
        handleLabelsChange(getSelectedLabelsByToggledLabel(label));
    };

    const handleLabelsChange = (labels: Label[]) => {
        if (!onSave) {
            return;
        }
        const labelsToSave = labels.map((label) => ({
            labelId: label.id,
            datasetImageId: annotatedDatasetImage.id
        }));
        setSelectedLabels(labels);
        onSave(labelsToSave);
    };

    useHotkeys('a,d,e,q', hotkeyAnnotateHandler(
        sortedLabelList,
        handleNavPrev,
        handleNavNext,
        handleLabelsChange,
        selectedLabels,
        isLoadingPrev || isLoadingNext
    ), {}, [
        navNextDisabled,
        navPrevDisabled,
        selectedLabels,
        isLoadingPrev,
        isLoadingNext
    ]);

    useEffect(() => {
        setImageIsLoaded(false);
        setSelectedLabels(currentLabels);
        (async () => {
            setImageUrl(await annotatedDatasetImage.blobUrl);
        })();
    }, [annotatedDatasetImage]);

    return (
        <>
            <Modal
                toggle={onClosed}
                className={cx(
                    'app-pages-dataset-page__annotation-modal',
                    'app-component-modal',
                    'app-component-modal_full-height'
                )}
                centered
                {...rest}
            >
                <ModalHeader toggle={onClosed}>
                    Annotate
                </ModalHeader>

                <ModalBody className='d-flex align-items-center'>
                    <img
                        alt=''
                        src={imageUrl}
                        onLoad={handleImageLoaded}
                        className={cx(
                            'mw-100',
                            'mh-100',
                            'object-fit-contain',
                            'shadow',
                            {
                                'd-none': !imageIsLoaded
                            }
                        )}
                    />
                    {!imageIsLoaded && <Spinner />}
                </ModalBody>

                <div className='p-3'>
                    <Nav pills className='d-flex justify-content-center align-items-center m-0'>
                        {sortedLabelList.length <= LABELS_N_THRESHOLD_TO_SHOW_DROPDOWN ? (
                            sortedLabelList.map((label) => (
                                <LabelItem
                                    label={label}
                                    isActive={!!selectedLabels.find((item) => item?.id === label.id)}
                                    isDisabled={isDisabled}
                                    onClick={() => handleLabelClick(label)}
                                />
                            ))
                        ) : (
                            <div className='w-100'>
                                <span className='font-weight-bold text-primary'>Selected label(s):</span>
                                <Multiselect
                                    data={sortedLabelList}
                                    value={selectedLabels}
                                    dataKey='id'
                                    textField='name'
                                    filter='contains'
                                    placeholder='No label'
                                    className='w-100 mt-2'
                                    dropUp={true}
                                    disabled={isDisabled}
                                    open={isSelectOpen}
                                    onChange={(labels: Label[], metadata) => {
                                        setIsSelectOpen((prev) => !prev && metadata.action === 'remove');
                                        handleLabelsChange(labels);
                                    }}
                                    onToggle={() => setIsSelectOpen((prev) => !prev)}
                                />
                            </div>
                        )}
                    </Nav>
                </div>

                <div className='p-3'>
                    <Row>
                        <Col xs={12}>
                            <DatasetImageNavigation
                                imageName={annotatedDatasetImage.hashedName}
                                onNavNext={handleNavNext}
                                onNavPrev={handleNavPrev}
                                navNextDisabled={navNextDisabled}
                                navPrevDisabled={navPrevDisabled}
                                isLoadingNext={isLoadingNext}
                                isLoadingPrev={isLoadingPrev}
                            />
                        </Col>
                    </Row>
                </div>

                <ModalFooter>
                    <div className='w-100'>
                        <Row>
                            <Col md={3}>
                                <AnnotateStatusBadge isLoading={isLoading} error={error} />
                            </Col>

                            <Col md={6}>
                                <div className='d-flex justify-content-center align-items-center w-100 font-weight-bold'>
                                    {annotateListProgress && (
                                        <div className='ml-4'>
                                            Images: {annotateListProgress.current} / {annotateListProgress.total}
                                        </div>
                                    )}
                                </div>
                            </Col>

                            <Col md={3}>
                                <div className='d-flex justify-content-end align-items-center w-100'>
                                    <Button color='primary' onClick={onClosed}>
                                        Close
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
});

export default ImageClassificationModal;
