export enum Extensions {
    Mp4 = 'mp4',
    Png = 'png',
    Jpg = 'jpg',
    Jpeg = 'jpeg',
    Glb = 'glb',
    Obj = 'obj',
    Zip = 'zip',
    Csv = 'csv'
}
