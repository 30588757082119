import { faBan, faDownload, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { Badge } from "reactstrap";
import { noDataText } from "../../../components/BootstrapTable";
import ActionButton from "../../../components/BootstrapTable/ActionButton";
import CircularProgress from "../../../components/CircularProgress";
import DeleteConfirmation from "../../../components/Modals/DeleteConfirmation";
import { dateConfig } from "../../../config/date";
import { PredictiveMaintenance, PredictiveMaintenanceModelTrainingProgressStatus, PredictiveMaintenanceModelTrainingProgressStatusUI, Project } from "../../../models/api";
import { formatDurationWithUnitName } from "../../../utils/dateTime";
import PredictiveMaintenanceModelTrainingImageModal from "../PredictiveMaintenanceModelTrainingImageModal";

interface Props {
    items: PredictiveMaintenance[];
    project?: Project;
    identityId?: string;
    onDelete: (item: PredictiveMaintenance) => void;
    onDownload: (item: PredictiveMaintenance) => void;
    onGrab: (item: PredictiveMaintenance) => Promise<string>;
}

export const PredictiveMaintenanceModelTrainingTable:React.FC<Props> = ({items, onDelete, onDownload, onGrab}) => {
    const [deleteConfirmation, setDeleteConfirmation] = React.useState<PredictiveMaintenance>();
    const [activeModal, setActiveModal] = React.useState<boolean>(false);
    const [imageUrl, setImageUrl] = React.useState<string>('');

    const handleDelete = () => {
        if(!deleteConfirmation) {
            return;
        }
        onDelete && onDelete(deleteConfirmation);
        setDeleteConfirmation(undefined);
    }

    const handleDeleteConfirmationClosed = () => {
        setDeleteConfirmation(undefined);
    };

    const handleDeleteButtonClick = (row: PredictiveMaintenance) => () => {
        setDeleteConfirmation(row);
    };

    const handleGrabImage = async (item: PredictiveMaintenance) => {
        setImageUrl(await onGrab(item))
        setActiveModal(true);
    }

    const columns: ColumnDescription<PredictiveMaintenance>[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => {
                return (rowIndex + 1);
            }
        },
        {
            dataField: 'modelWithModelIndex',
            text: 'Model ID',
            sort: true,
        },
        {
            dataField: 'fileName',
            text: 'File Name',
            sort: true,
            formatter: (_cel, row) => row.filename,
        },
        {
            dataField: 'startTime',
            text: 'Start Time',
            sort: true,
            formatter: (_cel, row) => row.createdAt ? dayjs(row.createdAt).format(dateConfig.formats.date) : ''
        },
        {
            dataField: 'status',
            text: 'Status',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, { progressStatus, progress }) => {
                if (progressStatus === PredictiveMaintenanceModelTrainingProgressStatus.Error) {
                    return (
                        <Badge color='danger' pill>
                            {PredictiveMaintenanceModelTrainingProgressStatusUI.error}
                        </Badge>
                    );
                } else if (progressStatus === PredictiveMaintenanceModelTrainingProgressStatus.Success) {
                    return (
                        <Badge color='success' pill>
                            {PredictiveMaintenanceModelTrainingProgressStatusUI.success}
                        </Badge>
                    )
                } else if (progressStatus === PredictiveMaintenanceModelTrainingProgressStatus.Initializing) {
                    return (
                        <Badge color="warning" pill>
                            {PredictiveMaintenanceModelTrainingProgressStatusUI.initializing}
                        </Badge>
                    )
                } else if (progressStatus === PredictiveMaintenanceModelTrainingProgressStatus.Completing) {
                    return (
                        <Badge color="warning" pill>
                            {PredictiveMaintenanceModelTrainingProgressStatusUI.completing}
                        </Badge>
                    )
                } else if (progress < 60) {
                    return (
                        <Badge color='warning' pill>
                            <div className='d-flex justify-content-center align-items-center'>
                                <CircularProgress color='dark' />
                                <span className='ml-2'>
                                {progressStatus && PredictiveMaintenanceModelTrainingProgressStatusUI['training']}
                                {progressStatus === PredictiveMaintenanceModelTrainingProgressStatus.Training && ` ${progress} %`}
                            </span>
                            </div>
                        </Badge>
                    );
                } else if (progress === 60) {
                    return (
                        <Badge color="warning" pill>
                            <div className='d-flex justify-content-center align-items-center'>
                                <CircularProgress color='dark' />
                                <span className='ml-2'>
                                {progressStatus && `Generating forecast ${progress} %`}
                            </span>
                            </div>
                        </Badge>
                    )
                } else if (progress === 80) {
                    return (
                        <Badge color="warning" pill>
                            <div className='d-flex justify-content-center align-items-center'>
                                <CircularProgress color='dark' />
                                <span className='ml-2'>
                                {progressStatus && `Plotting ${progress} %`}
                            </span>
                            </div>
                        </Badge>
                    )
                } else {
                    return (
                        <Badge color="warning" pill>
                            <div className='d-flex justify-content-center align-items-center'>
                                <CircularProgress color='dark' />
                                <span className='ml-2'>
                                {progressStatus && `Exporting Model ${progress} %`}
                            </span>
                            </div>
                        </Badge>
                    )
                }
            }
        },
        {
            dataField: 'runtime',
            text: 'Runtime',
            formatter: (_cel, row) => { 
                return row.runtime
                    ? formatDurationWithUnitName(dayjs.duration(row.runtime, 'seconds'))
                    : '';
            }
        },
        {
            dataField: 'progress',
            text: 'Progress',
            formatter: (_cel, row) => {
                return row.progress ? `${Math.round(row.progress)}%` : '';
            }
        },
        {
            dataField: 'view',
            text: 'View',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const isDisabled =
                    row.progressStatus !== PredictiveMaintenanceModelTrainingProgressStatus.Success;
                return (
                    <ActionButton
                        color='success'
                        id={`pages-predictive-maintenance-model-training_view-button-${row.id}`}
                        tooltipText='View item'
                        disabledTooltipText='Training is not completed or some error occurred on generating'
                        disabled={isDisabled}
                        onClick={() => handleGrabImage(row)}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </ActionButton>
                );
            }
        },
        {
            dataField: 'download',
            text: 'Download',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const isDisabled =
                    row.progressStatus !== PredictiveMaintenanceModelTrainingProgressStatus.Success;
                return (
                    <ActionButton
                        color='success'
                        id={`pages-predictive-maintenance-model-training_download-button-${row.id}`}
                        tooltipText='Download item'
                        disabledTooltipText='Training is not completed or some error occurred on generating'
                        disabled={isDisabled}
                        onClick={() => onDownload(row)}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </ActionButton>
                );
            }
        },
        {
            dataField: 'delete',
            text: 'Actions',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                return (
                    <ActionButton
                        color='danger'
                        id={`pages-model-generator_delete-button-${row.id}`}
                        tooltipText='Delete item'
                        onClick={handleDeleteButtonClick(row)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </ActionButton>
                )
            }
        }
    ]

    return (
        <>
            <BootstrapTable 
                bootstrap4
                keyField='id'
                data={items}
                columns={columns}
                defaultSorted={[{ dataField: 'modelWithModelIndex', order: 'desc' }]}
                striped
                bordered={false}
                wrapperClasses='table-responsive'
                classes='m-0'
                noDataIndication={noDataText}
            />
            <DeleteConfirmation
                body={`Are you sure you want to delete the Model: "${deleteConfirmation?.filename} ${dayjs(deleteConfirmation?.createdAt).format(dateConfig.formats.date)}"?`}
                isOpen={!!deleteConfirmation}
                onConfirm={handleDelete}
                onClosed={handleDeleteConfirmationClosed}
            />
            <PredictiveMaintenanceModelTrainingImageModal 
                activeModal={activeModal}
                toggleModal={setActiveModal}
                imageUrl={imageUrl}
            />
        </>
    );
}
