import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import DnaLoader from '../../../components/DnaLoader';
import Paper from '../../../components/Paper';
import PageTitle from '../../../components/PageTitle';
import { ProjectTypeUI } from '../../../models/api';
import Layout from '../../../components/Layout';
import DropdownList from 'react-widgets/DropdownList';
import HelpVideosListTable from './HelpVideosListTable';
import { projectRoutes, Routes } from '../../../router/routes'
import { videoElement } from '../../../models/api'

export interface Videos {
	[key: string]: videoElement[]
}
interface HelpVideos {
	[key: string]: Videos
}
enum AdditionalHelpVideoTypes{
	dashboard = 'Dashboard'
}

const RedactHelpVideos: FunctionComponent = observer(() => {
	const {helpVideoStore} = useStores();
	const VideoTypesUi = {...AdditionalHelpVideoTypes, ...ProjectTypeUI}
	const projRoutes = projectRoutes.map((key:string)=>{ return key.substring(1) })
	const notProjectRoutes = [Routes.Dashboard.substring(1)]
	const [formVideo, setFormVideo] = useState<HelpVideos>({});
	const [currentProjectName, setCurrentProjectName] = useState<string>('');
	const [currentProjectVideo, setCurrentProjectVideo] = useState<Videos>({});
	const [currentProjectUI, setCurrentProjectUI] = useState<string>(VideoTypesUi.dashboard);


	useEffect(() =>{
		setCurrentProjectName(getProjectTypeUiKey(currentProjectUI))
		prepareFormData(helpVideoStore.videos)
	}, [helpVideoStore.isFetched])
	useEffect(() => {
		formVideo[currentProjectName] &&
			setCurrentProjectVideo(formVideo[currentProjectName])
	}, [currentProjectName, formVideo])

	const getProjectTypeUiKey= (value: string): string => {
		let index = 0
		Object.values(VideoTypesUi).forEach( (el, indx) => {
			if(el === value)
				index = indx
		})

		return Object.keys(VideoTypesUi)[index]
	}
	const changeOption = (option: string) => {
		setCurrentProjectUI(option)
		setCurrentProjectName(getProjectTypeUiKey(option))
	}
	const prepareFormData = (helpVideo:any)=>{

		const copyData = (projectType:{}, routes:string[])=>{
			let preparedData:any = {};
			Object.keys(projectType).forEach( (projectName, index)=>{
				const isProjectExists = projectName in helpVideo;

				//add project keys
				preparedData[projectName] = {};

				routes.forEach(pageName=>{
					const isPageInProject = isProjectExists && (pageName in helpVideo[projectName]);
					const isPageHasVideo = isPageInProject && helpVideo[projectName][pageName].length

					//add pages and videos
					if(isProjectExists && isPageHasVideo)
						preparedData[projectName][pageName] = [...helpVideo[projectName][pageName]]
					else
						preparedData[projectName][pageName] = [{title:'', url:''}]
				})
			});

			return preparedData
		}

		const data = {...copyData(ProjectTypeUI, projRoutes), ...copyData(AdditionalHelpVideoTypes, notProjectRoutes)}

		setFormVideo(data)
	}


	return (
		<Layout>
			<>
				<PageTitle
					heading='Help Videos'
					icon='fas fa-users icon-gradient bg-warm-flame'
					subheading='Manage your help videos'
					showCloseButton={true}
				/>

				<Row>
					<Col sm={12}>
						<Row>
							<Col md={6}>
								<div className='d-flex align-items-center he-100'>
									<div className='font-weight-bold text-primary mr-2 w-10'>
										Section:
									</div>

									<div className='w-50'>
										<DropdownList
											data={Object.values(VideoTypesUi)}
											dataKey='value'
											textField='type'
											value={currentProjectUI}
											filter={false}
											onChange={changeOption}
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>

				{
					!helpVideoStore.videos &&
                    <DnaLoader/>
				}

				<Row>
					<Col sm={12}>
						<Paper rounded className='mt-4'>
							<HelpVideosListTable
								items={currentProjectVideo}
								projectName={currentProjectName}
							/>
						</Paper>
					</Col>
				</Row>
			</>
		</Layout>
	);
});

export default RedactHelpVideos;