import container from '../../container/container';
import { IReactionDisposer, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { toast } from 'react-toastify';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import {
    DetectionModelFrameworkType,
    DetectionModelProgressStatus,
    DetectionModelCreate,
    Project,
    ProjectCreate,
    ProjectField,
    ProjectType,
    User,
    ProjectGroup,
    ProjectListRequest
} from '../../models/api';
import { RootStore } from './root';
import { CustomEventType } from '../../models/events';
import { createHardRefreshToast } from '../../components/Toasts/HardRefreshToast';
import { NETWORK_ERROR_MESSAGE } from '../../utils/errors';
import { compareByField, getGroupIdFromURL } from '../../utils/helper-functions';
import { s3Util } from '../../utils/s3';
import { AxiosError } from 'axios';

const api = container.apiClient;

export class ProjectsStore {
    list: Project[] = [];
    listOld: Project[] = [];
    projectCount: number = +(localStorage.getItem('fs_project-count') ?? 0);
    templatesList: Project[] = [];
    current: Project | undefined;

    isLoading: boolean = false;
    isFetched: boolean = false;

    rootStore: RootStore;

    private readonly disposers: IReactionDisposer[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.disposers.push(
            reaction(
                () => ({
                    projectCount: this.projectCount,
                    isLoading: this.isLoading
                }),
                ({ projectCount, isLoading }) => {
                    if (isLoading) {
                        return;
                    }

                    this.rootStore.statisticsStore.setDashboardStatistic({
                        ...this.rootStore.statisticsStore.project,
                        projectCount
                    });
                }
            )
        );
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    resetList() {
        runInAction(() => {
            this.list = [];
            this.listOld = [];
            this.isLoading = false;
            this.isFetched = false;
        });
    }

    async fetchList(userId?: User['id'], groupId?: ProjectGroup['id']) {
        this.list = [];
        this.listOld = [];
        this.isLoading = true;

        try {
            const params: ProjectListRequest = {
                filter: {
                    userId: userId ? userId : this.rootStore.userStore.user?.id
                }
            };

            if (groupId) {
                params.filter.groupId = groupId;
            }
            const { data } = await api.projectList(params);

            runInAction(() => {
                const list: Project[] = [];
                const oldList: Project[] = [];

                data.forEach((project) => {
                    if (project.tag === 'old') {
                        oldList.push(project);
                    } else {
                        list.push(project);
                    }
                });

                this.list = list;
                this.listOld = oldList;
                this.isFetched = true;
                if (data[0] && !groupId) {
                    this.projectCount = this.list.length;
                }
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async fetchTemplatesList() {
        this.templatesList = [];
        this.isLoading = true;

        try {
            const { data } = await api.projectTemplatesList();

            runInAction(() => {
                this.templatesList = data;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async createItem(params: ProjectCreate) {
        if (!this.rootStore.userStore.user) {
            return;
        }

        this.isLoading = true;

        const toastId = createProgressToast();
        const userId = this.rootStore.userStore.impersonationUser
            ? this.rootStore.userStore.impersonationUser.id
            : this.rootStore.userStore.user.id;

        try {
            const { data } = await api.projectCreate({ ...params, userId });

            const identityId = await this.rootStore.userStore.getIdentityIdByImpersonationUser();

            if (data.type === ProjectType.PoseDetection) {
                const params: DetectionModelCreate = {
                    frameworkType: DetectionModelFrameworkType.PyTorch,
                    progressStatus: DetectionModelProgressStatus.Saving,
                    accuracy: 100,
                    completedAt: data.createdAt,
                    s3Bucket: s3Util.getS3BucketName(),
                    s3Folder: await s3Util.getS3ProjectFolder(identityId, data.id)
                };

                await api.detectionModelCreate(data.id, params);
            }

            runInAction(() => {
                this.list = [...this.list, data].sort((a, b) => compareByField(a, b, 'name'));

                this.rootStore.eventsStore.addItem(CustomEventType.CreateProject, {
                    id: data.id,
                    name: data.name,
                    type: data.type,
                    templateId: params.templateProjectId
                });

                this.projectCount = this.projectCount + 1;
            });
            return data;
        } catch (e) {
            const error = e as AxiosError;
            const errorMessage = error?.response?.data?.errors?.message ?? error.message;
            if (errorMessage !== NETWORK_ERROR_MESSAGE) {
                toast(errorMessage, { type: toast.TYPE.ERROR });
            } else {
                createHardRefreshToast(() => (this.isFetched = false));
            }
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async deleteItem(id: Project['id']) {
        if (!this.rootStore.userStore.user) {
            return;
        }

        this.isLoading = true;
        const toastId = createProgressToast();

        try {
            await api.projectDelete(id);

            runInAction(() => {
                const project = this.list.find((item) => item.id === id);
                this.list = this.list.filter((item) => item.id !== id);
                this.listOld = this.listOld.filter((item) => item.id !== id);
                if (this.current?.id === id) {
                    this.setCurrentProject();
                }
                this.rootStore.projectsTrashStore.isFetched = false;
                if (project) {
                    this.rootStore.eventsStore.addItem(CustomEventType.DeleteProject, {
                        id: project.id,
                        name: project.name,
                        type: project.type
                    });
                }
                this.rootStore.projectGroupsStore.fetchList(
                    this.rootStore.userStore.impersonationUser?.id
                );
                if (project?.tag !== 'old') {
                    this.projectCount = this.projectCount - 1;
                }
            });
        } catch (e) {
            const error = e as AxiosError;
            toast(error?.response?.data?.errors?.message ?? error.message, {
                type: toast.TYPE.ERROR
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async updateItem(id: Project['id'], params: Partial<Project>) {
        if (!this.rootStore.userStore.user) {
            return;
        }

        this.isLoading = true;
        const toastId = createProgressToast();

        try {
            await api.projectUpdate(id, params);

            runInAction(() => {
                this.list = this.list.map((project) => {
                    return project.id === id ? { ...project, ...params } : project;
                });
                this.listOld = this.listOld.map((project) => {
                    return project.id === id ? { ...project, ...params } : project;
                });
                if (this.current?.id === id) {
                    this.current = { ...this.current, ...params };
                }
                if (params.groupId !== undefined) {
                    const groupId = getGroupIdFromURL();
                    this.fetchList(this.rootStore.userStore.impersonationUser?.id, groupId);
                }
                this.rootStore.projectGroupsStore.fetchList(
                    this.rootStore.userStore.impersonationUser?.id
                );
            });
        } catch (e) {
            const error = e as AxiosError;
            toast(error?.response?.data?.errors?.message ?? error.message, {
                type: toast.TYPE.ERROR
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async cloneItem(id: Project['id'], fieldsToClone: ProjectField[], projectGroupId?: string) {
        if (!this.rootStore.userStore.user) {
            return;
        }

        this.isLoading = true;

        const toastId = createProgressToast();
        const userId = this.rootStore.userStore.impersonationUser
            ? this.rootStore.userStore.impersonationUser.id
            : this.rootStore.userStore.user.id;

        const templateProject = this.list.find((item) => item.id === id);

        if (!templateProject) {
            return;
        }

        if (!templateProject.detectionModels?.length) {
            fieldsToClone = fieldsToClone.filter((item) => item !== ProjectField.TrainedModels);
        }

        try {
            const { data } = await api.projectClone(id, { fieldsToClone, userId, projectGroupId });

            runInAction(() => {
                // TODO find solution
                if (this.list) {
                    this.list.push(data);
                } else {
                    this.list = [data];
                }

                this.list.sort((a, b) => compareByField(a, b, 'name'));

                this.rootStore.eventsStore.addItem(CustomEventType.DuplicateProject, {
                    id: data.id,
                    name: data.name,
                    type: data.type,
                    templateId: templateProject.id
                });

                this.projectCount = this.projectCount + 1;

                this.rootStore.projectGroupsStore.fetchList(
                    this.rootStore.userStore.impersonationUser?.id
                );
            });
        } catch (e) {
            const error = e as AxiosError;
            const errorMessage = error?.response?.data?.errors?.message ?? error.message;
            if (errorMessage !== NETWORK_ERROR_MESSAGE) {
                toast(errorMessage, { type: toast.TYPE.ERROR });
            } else {
                createHardRefreshToast(() => (this.isFetched = false));
            }
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async setCurrentProject(id?: string) {
        this.rootStore.labelsStore.resetList();
        this.rootStore.datasetImageStore.resetList();
        this.rootStore.detectionModelsStore.resetList();
        this.rootStore.detectionModelDownloadsStore.resetList();
        this.rootStore.detectionModelSimulationsStore.resetList();
        this.rootStore.statisticsStore.resetProjectStatistic();

        if (!id) {
            this.current = undefined;
            return;
        }

        this.current =
            this.list.find((item) => item.id === id) || this.listOld.find((item) => item.id === id);

        await api.trackOpenProject({
            id: this.current!.id,
            name: this.current!.name,
            type: this.current!.type
        });
    }

    async canSwitchMultiLabel() {
        if (!this.current) {
            return false;
        }
        const {
            data: { canSwitch }
        } = await api.projectCanSwitchMultiLabel(this.current.id);
        return canSwitch;
    }

    setProjectCount(count: number) {
        this.projectCount = this.projectCount + count;
    }
}
