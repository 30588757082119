import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { Field } from 'formik';
import cx from 'classnames';
import { FormCheckboxProps } from './types';
import { useFormField } from './hooks/useFormField';

export const FormCheckbox: React.FC<FormCheckboxProps> = ({ formName, field, label, formGroupClasses }) => {
    const { id, error } = useFormField(formName, field);

    return (
        <FormGroup check className={formGroupClasses}>
            <Field
                id={id}
                type='checkbox'
                name={field}
                className={cx('form-check-input', { 'is-invalid': error })}
            />
            <Label check htmlFor={id}>{label}</Label>
            <FormFeedback>{error}</FormFeedback>
        </FormGroup>
    );
};
