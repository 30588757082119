import React from 'react';
import dayjs from 'dayjs';

const Copyright = () => {
    const currentYear = dayjs().year();
    return (
        <p className={'text-center text-white opacity-8'}>
            Copyright © 2021 - {currentYear}
        </p>
    );
};

export default Copyright;
