import React from 'react';
import Copyright from '../../components/Copyright';
import HeaderLogo from '../../components/Layout/AppLogo';

export const SystemLockPage: React.FC = () => {
    return (
        <div style={
                {
                    backgroundColor: '#6f5eba',
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '24px'
                }
            }>
            <div style={
                {
                    backgroundColor: 'white',
                    padding: '12px 12px 36px 12px',
                    borderRadius: '6px'
                }
            }>
                <div style={
                        {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }
                    }>
                    <div style={
                        {
                            width: '70%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '40px'
                        }
                    }>
                        <HeaderLogo />
                        <span style={
                            {
                                fontSize: '18px', 
                                textAlign: 'center'
                            }
                            }>
                            We are currently in maintenance mode. Our team is working round the clock to get you back online soon!
                        </span>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    );
};
