import React from 'react';
import { observer } from 'mobx-react-lite';

import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import { Col, Row } from 'reactstrap';
import Paper from '../../components/Paper';

import FileUploadListTable from './FileUploadListTable';
import { useStores } from '../../store';
import { compareByField } from '../../utils/helper-functions';

export const MyAccountPage: React.FC = observer(() => {
    const { userStore } = useStores();
    let fileUploads = userStore.user?.fileUploads ?? [];

    if(fileUploads.length > 0) {
        fileUploads = [...fileUploads].sort((b, a) => {
            if(a.date === b.date){
                return compareByField(a, b, 'createdAt')
            } else {
                return compareByField(a, b, 'date')
            }
        })
    }

    return (
        <Layout>
            <PageTitle
                heading='My Account'
                icon='fas fa-clipboard icon-gradient bg-warm-flame '
                showCloseButton={true}
            />

            <Row>
                <Col sm={12}>
                    <Paper rounded className='mt-4'>
                        <FileUploadListTable
                            items={fileUploads}
                        />
                    </Paper>
                </Col>
            </Row>

        </Layout>
    );
});
