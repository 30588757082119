import React, { useState } from 'react';
import cx from 'classnames';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
    heading?: string;
    subheading?: string;
    icon?: string;
    iconComponent?: React.ReactNode;
    actionComponent?: React.ReactNode;
    showCloseButton?: boolean;
    className?: string;
}

const PageTitle: React.FC<Props> = (
    {
        heading,
        subheading,
        icon,
        iconComponent,
        actionComponent,
        showCloseButton,
        className
    }
) => {
    const [showTitle, setShowTitle] = useState<boolean>(true);

    const handleTitleVisibility = () => {
        setShowTitle(false);
    };
    return (
        <>
            {showTitle && (
                <div className={cx('app-page-title', className)}>
                    <div className='page-title-wrapper'>
                        <div className='page-title-heading'>
                            {iconComponent ?
                                iconComponent
                                :
                                <div className={cx('page-title-icon', {
                                    'd-none': !icon
                                })}>
                                    <i className={icon} />
                                </div>
                            }
                            <div>
                                {heading}
                                <div className={cx('page-title-subheading', {
                                    'd-none': !subheading
                                })}>
                                    {subheading}
                                </div>
                            </div>
                        </div>
                        {actionComponent ?
                            <div className='page-title-actions'>
                                {actionComponent}
                            </div>
                            :
                            showCloseButton && (
                                <div className='page-title-actions'>
                                    <Button color='primary' onClick={handleTitleVisibility}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                </div>
            )}
        </>
    );
};

export default PageTitle;
