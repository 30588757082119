import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { createStore, combineReducers } from "redux";
import reducers from "./reducers";
import { Provider } from "react-redux";

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import StoreProvider from './store/StoreProvider';
import { RootStore } from './store/';

dayjs.extend(duration);
dayjs.extend(relativeTime);

Amplify.configure(awsconfig);

const themeStore = createStore(
    combineReducers({
        ...reducers,
    }),
    {}
);

const rootStore = new RootStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={themeStore}>
            <StoreProvider store={rootStore}>
                <App />
            </StoreProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
