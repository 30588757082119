import React from 'react';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import { Button } from 'reactstrap';
import { FormCheckbox } from '../../../components/Form/form-checkbox';
import { EmailPreferencesFormData } from '../../../models/user-profile/email-prefs';
import { User } from '../../../models/api';

export interface Props {
    user: User;
    onSave: (user: User) => void;
    isLoading?: boolean;
}

type FormProps = { isLoading: Props['isLoading'] } & FormikProps<EmailPreferencesFormData>;

const _EmailPreferencesForm: React.FC<FormProps> = (props) => {
    const formName = 'email-preferences-form';
    const isLoading = !props.isValidating && (props.isSubmitting || props.isLoading);

    return <Form>
        <FormCheckbox
            formName={formName}
            field='newFeatures'
            label='New Features'
            formGroupClasses='mb-3'
        />

        <FormCheckbox
            formName={formName}
            field='newTemplates'
            label='New Templates'
            formGroupClasses='mb-3'
        />

        <FormCheckbox
            formName={formName}
            field='caseStudies'
            label='Case Studies'
            formGroupClasses='mb-3'
        />

        <Button
            color='primary'
            type='submit'
            disabled={isLoading}
        >
            Save
        </Button>
    </Form>;
};

export const EmailPreferencesForm: React.FC<Props> = ({ user, onSave, isLoading }) => {
    const initialValues: EmailPreferencesFormData = {
        caseStudies: user.emailCaseStudies,
        newFeatures: user.emailNewFeatures,
        newTemplates: user.emailNewTemplates
    };

    const handleSubmit = async (
        values: EmailPreferencesFormData,
        formikHelpers: FormikHelpers<EmailPreferencesFormData>
    ) => {
        onSave({
            ...user,
            emailCaseStudies: values.caseStudies,
            emailNewFeatures: values.newFeatures,
            emailNewTemplates: values.newTemplates
        });

        formikHelpers.setSubmitting(false);
    };

    return <Formik<EmailPreferencesFormData>
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        {(props) =>
            <_EmailPreferencesForm
                {...props}
                isLoading={isLoading}
            />}
    </Formik>;
};
