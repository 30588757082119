import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../store';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import cx from 'classnames';
import { ProjectTagCSSClasses } from '../../../../models/api';
import { TagSort } from '../../../../config/tags';

const CurrentProjectSelector = observer(() => {
    const { projectsStore } = useStores();

    return projectsStore.current ? (
        <>
            <span className='font-weight-bold text-primary flex-shrink-0 mr-3'>
                Current project:
            </span>
            <UncontrolledDropdown style={{ width: '100%' }}>
                <DropdownToggle
                    style={{
                        backgroundColor: 'transparent',
                        color: '#343a40',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}
                    className={'project-tag-info'}
                    caret={true}
                >
                    <div className={'project-tag-info'}>
                        <span
                            className={cx(
                                'project-tag',
                                projectsStore.current.tag
                                    ? ProjectTagCSSClasses[projectsStore.current.tag]
                                    : 'project-tag-dropdown_transparent'
                            )}
                        >
                            {projectsStore.current.tag || 'Other'}
                        </span>
                        {projectsStore.current.name}
                    </div>
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
                    {[...projectsStore.list]
                        .sort((p1, p2) => {
                            const p1Weight =
                                p1.tag || '' in TagSort
                                    ? TagSort[p1.tag || 'other']
                                    : TagSort['other'];
                            const p2Weight =
                                p2.tag || '' in TagSort
                                    ? TagSort[p2.tag || 'other']
                                    : TagSort['other'];
                            return p1Weight - p2Weight;
                        })
                        .map((project) => (
                            <DropdownItem
                                key={`tag-option_${project.id}`}
                                className='pl-3'
                                onClick={() => projectsStore.setCurrentProject(project.id)}
                            >
                                <div className={'project-tag-info'}>
                                    <span
                                        className={cx(
                                            'project-tag',
                                            project.tag
                                                ? ProjectTagCSSClasses[project.tag]
                                                : 'project-tag-dropdown_transparent'
                                        )}
                                    >
                                        {project.tag || 'Other'}
                                    </span>
                                    {project.name}
                                </div>
                            </DropdownItem>
                        ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    ) : null;
});

export default CurrentProjectSelector;
