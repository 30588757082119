import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';

export interface HeaderWithTooltipProps {
    content?: React.ReactNode,
    tooltipContent: React.ReactNode,
    tooltipId: string,
    sortElement?: JSX.Element;
}

const HeaderWithTooltip: React.FC<HeaderWithTooltipProps> = (
    {
        content,
        tooltipContent,
        tooltipId,
        sortElement
    }
) => {
    return (
        <div>
            <span>
                {content}
            </span>

            <span className='text-info ml-1' id={tooltipId}>
                <FontAwesomeIcon icon={faQuestionCircle} />
            </span>

            <span>{sortElement}</span>

            <UncontrolledTooltip target={tooltipId}>
                {tooltipContent}
            </UncontrolledTooltip>
        </div>
    );
};

export default HeaderWithTooltip;