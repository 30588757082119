import React, { FunctionComponent } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FormField, PwdFormField } from '../../../components/Form';
import { SignInFormData } from '../../../models/login/sign-in';
import * as Yup from 'yup';
import { Button } from 'reactstrap';
import {Auth} from 'aws-amplify';
import history from '../../../router/history';
import { Routes } from '../../../router/routes';
import { toast } from 'react-toastify';
import { useStores } from '../../../store';
import DnaLoader from '../../../components/DnaLoader';
import Policy from '../SignUp/PrivacyPolicy';

interface Props {}
type FormProps = Props & FormikProps<SignInFormData>;

const InternalSignInForm: FunctionComponent<FormProps> = (props) => {
    const formName = 'sign-in-form';
    const isLoading = !props.isValidating && props.isSubmitting;
    const isSubmissionFailed =
        !props.isSubmitting && props.status && props.status.error;


    return (
        <>
            <Form
                noValidate
                className="sign-in-form"
                style={{ display: !isLoading ? 'block' : 'none' }}
            >
                <FormField
                    formName={formName}
                    field={'email'}
                    type={'email'}
                    placeholder={'Email'}
                    isFirst
                />

                <PwdFormField
                    formName={formName}
                    field={'pwd'}
                    placeholder={'Password'}
                />
                
                <div className={'my-4'}>
                    <Policy action={'continuing'} />
                </div>

                <div>
                    <Button
                        color={'primary'}
                        type={'submit'}
                        block
                        disabled={isLoading}
                        size='lg'
                    >
                        Login
                    </Button>
                </div>
            </Form>
            <div
                className="sign-in-form"
                style={{ display: isLoading ? 'block' : 'none' }}
            >
                <DnaLoader />
            </div>
        </>
    );
};


export const SignInForm: FunctionComponent<Props> = (props) => {
    const { userStore } = useStores();

    const initialValues: SignInFormData = {
        email: '',
        pwd: '',
    };

    const handleSubmit = async (
        values: SignInFormData,
        formikHelpers: FormikHelpers<SignInFormData>
    ) => {
        try {
            const user = await Auth.signIn({
                username: values.email,
                password: values.pwd,
            });

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                userStore.createTemporaryUser(user);
                toast('Please change password', { type: toast.TYPE.INFO });
                history.push(Routes.ChangeTemporaryPwd);
                return;
            }
            history.push(Routes.Dashboard);
            return;
        } catch (error) {
            toast(error.message, { type: toast.TYPE.ERROR });
        }

        formikHelpers.setSubmitting(false);
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('required').email('invalid email format'),
        pwd: Yup.string().required('required'),
    });

    return (
        <Formik<SignInFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {(props) => <InternalSignInForm {...props} />}
        </Formik>
    );
};
