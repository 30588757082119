import React, { FunctionComponent } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
} from 'reactstrap';

export const LeaveFeedbackModal: FunctionComponent<ModalProps> = ({
    isOpen,
    body,
    onSubmit,
    onClosed,
    form,
    ...rest
}) => {
    return (
        <Modal isOpen={isOpen} toggle={onClosed} {...rest}>
            <ModalHeader toggle={onClosed}>Leave Feedback</ModalHeader>
            <ModalBody className="app-component-modal-body__md p-4">
                {body}
            </ModalBody>
            <ModalFooter>
                <Button
                    color="success"
                    onClick={onSubmit}
                    form={form}
                    type="submit"
                >
                    Send Feedback
                </Button>
            </ModalFooter>
        </Modal>
    );
};
