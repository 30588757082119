import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import ModelListTable from './ModelListTable';
import TrainNewModelPanel from './TrainNewModelPanel';
import Paper from '../../components/Paper';
import { useStores } from '../../store';
import { observer } from 'mobx-react-lite';
import DnaLoader from '../../components/DnaLoader';
import { DetectionModel } from '../../models/api';
import { longOperationsConfig } from '../../config/long-operations';

export const ModelTrainingPage: React.FC = observer(() => {
    const { userStore, projectsStore, detectionModelsStore } = useStores();

    const handleDelete = (id: string) => {
        detectionModelsStore.deleteItem(id);
    };

    const handleEarlyStop = (id: DetectionModel['id']) => {
        detectionModelsStore.trainingEarlyStop(id);
    };

    const handleCancel = (id: DetectionModel['id']) => {
        detectionModelsStore.trainingCancel(id);
    };

    useEffect(() => {
        detectionModelsStore.fetchList();
        const handle = setInterval(async () => {
            await detectionModelsStore.fetchList();
        }, longOperationsConfig.refreshInterval)
        return () => {
            clearInterval(handle)
        }
    }, [detectionModelsStore])

    useEffect(() => {
        if(!detectionModelsStore.listIsFetched)
            detectionModelsStore.fetchList();
    }, [detectionModelsStore.listIsFetched])

    return (
        <Layout>
            <PageTitle
                heading='Model Training'
                icon='fas fa-atom icon-gradient bg-warm-flame'
                subheading='Train your object detection model'
                showCloseButton={true}
            />

            <Row>
                <Col sm={12}>
                    <TrainNewModelPanel />
                </Col>
            </Row>

            {!detectionModelsStore.listIsFetched && (
                <DnaLoader />
            )}

            <Row>
                <Col sm={12}>
                    <Paper rounded className='mt-4' hidden={!detectionModelsStore.listIsFetched}>
                        <ModelListTable
                            items={Object.values(detectionModelsStore.listById)}
                            onDelete={handleDelete}
                            isLoading={detectionModelsStore.isLoading}
                            onEarlyStop={handleEarlyStop}
                            onCancel={handleCancel}
                            projectType={projectsStore.current!.type}
                        />
                    </Paper>
                </Col>
            </Row>
        </Layout>
    );
});
