import dayjs from "dayjs";
import React from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { Col, Container } from "reactstrap";
import { noDataText } from "../../../../components/BootstrapTable";
import TableWithPagination from "../../../../components/BootstrapTable/TableWithPagination";
import { dateConfig } from "../../../../config/date";
import { DbEvent } from "../../../../models/events";
import { Pagination } from "../../../../models/pagination";

interface Props {
    items: DbEvent[];
    isLoading: boolean;
    pagination: Pagination;
}

const UserActivityListTable: React.FC<Props> = ({items, isLoading, pagination}) => {

    const columns:ColumnDescription<any>[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => rowIndex + pagination.firstIndexOnPage + 1
        },
        {
            dataField: 'name',
            text: 'User Name',
            formatter: (_cel, row) => {
                return `${row.user.email}`
            }
        },
        {
            dataField: 'type',
            text: 'Event Type',
            formatter: (_cel, row) => {
                return `${row.type}`
            }
        },
        {
            dataField: 'actionTarget',
            text: 'Action Target',
            formatter: (_cel, row) => {
                if(!row.targetName && !row.projectName && !row.labelName && !row.hashedName) return (
                    <Container fluid style={{paddingLeft: 0}}>
                        <Col style={{paddingLeft: 0}}>Action without target</Col>
                    </Container>
                ); else {
                    return (
                        <Container fluid style={{paddingLeft: 0}}>
                            {row.targetName ? <Col style={{paddingLeft: 0}}>Target Name: {row.targetName}</Col> : <></>}
                            {row.projectName ? <Col style={{paddingLeft: 0}}>Project Name: {row.projectName}</Col> : <></>}
                            {row.labelName ? <Col style={{paddingLeft: 0}}>Label Name: {row.labelName}</Col> : <></>}
                            {row.hashedName ? <Col style={{paddingLeft: 0}}>Hashed Name: {row.hashedName}</Col> : <></>}
                        </Container>
                    );
                }
            }
        },
        {
            dataField: 'updatedAt',
            text: 'Last Activity',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => dayjs(row.updatedAt).format(dateConfig.formats.date)
        }
    ];

    return (
        <>
            <TableWithPagination
                data={items}
                columns={columns}
                pagination={pagination}
                wrapperClasses="mt-3"
            />
        </>
    );
};

export default UserActivityListTable;