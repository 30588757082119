import { makeAutoObservable, runInAction } from 'mobx';
import container from '../../container/container';
import { RootStore } from './root';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import { toast } from 'react-toastify';
import { SetVideoForm, videoElement } from '../../models/api'

const api = container.apiClient;

export interface Videos {
    [key: string]: {
        [key: string]: videoElement[]
    }
}

export class HelpVideoStore {
    isError: boolean = false;

    videos:Videos={};
    isFetched:boolean = false;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async fetchVideo() {
        try{
            const {data}= await api.getHelpVideo();
            runInAction(() => {
                this.videos = data;
                this.isFetched = true
            });
        }catch (e){
            runInAction(() => {
                this.isError = true;
            });
        }
    }
    async addVideo(video:SetVideoForm) {
        const toastId = createProgressToast();
        try{
            await api.addVideo(video);
            runInAction(() => {
                this.isFetched = false
            });
        }catch (e){
            runInAction(() => {
                this.isError = true;
            });
        }finally{
            runInAction(() => {
                toast.dismiss(toastId);
            });
        }
    }
    async updateVideo(video:SetVideoForm) {
        const toastId = createProgressToast();
        try{
            await api.updateVideo(video);
            runInAction(() => {
                this.isFetched = false
            });
        }catch (e){
            runInAction(() => {
                this.isError = true;
            });
        }finally{
            runInAction(() => {
                toast.dismiss(toastId);
            });
        }
    }
    async deleteVideo(video:SetVideoForm) {
        const toastId = createProgressToast();
        try{
            await api.delVideo(video);
            runInAction(() => {
                this.isFetched = false
            });
        }catch (e){
            runInAction(() => {
                this.isError = true;
            });
        }finally{
            runInAction(() => {
                toast.dismiss(toastId);
            });
        }
    }

    resetVideo(){
        runInAction(() => {
            this.videos = {};
        });
    }
}