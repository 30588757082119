import React, { useState } from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';
import dayjs from 'dayjs';
import prettyBytes from 'pretty-bytes';
import { Storage } from 'aws-amplify';
import { AccessLevel } from '@aws-amplify/ui-components';
import { faDownload, faEye, faTimes, faFileVideo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteConfirmation from '../../../components/Modals/DeleteConfirmation';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import {
    DetectionModel,
    DetectionModelSimulation,
    DetectionModelSimulationProgressStatus,
    DetectionModelSimulationType, ProjectType,
    User
} from '../../../models/api';
import { CustomS3ImageWithSpinner } from '../../../components/AmplifyUi';
import { noDataText } from '../../../components/BootstrapTable';
import CircularProgress from '../../../components/CircularProgress';
import { dateConfig } from '../../../config/date';
import { formatDurationWithUnitName } from '../../../utils/dateTime';
import { imageHashFormat } from '../../../utils/helper-functions';
import ModelInfoModal from '../../../components/Modals/ModelInfoModal';
import DetectionModelFrameworkTypeIcon from '../../../components/DetectionModel/DetectionModelFrameworkTypeIcon';
import { useStores } from '../../../store';

interface Props {
    items: DetectionModelSimulation[];
    onDelete?: (id: DetectionModelSimulation['id']) => void;
    isLoading: boolean;
    //TODO from file relation
    impersonationUser?: User;
    isAllRunningSimulations?: boolean;
}

const VideoListTable: React.FC<Props> = ({items, onDelete, isLoading, impersonationUser, isAllRunningSimulations}) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState<DetectionModelSimulation>();
    const [previewModal, setPreviewModal] = useState<DetectionModelSimulation>();
    const [modelInfo, setModelInfo] = useState<DetectionModel>();
    const { projectsStore } = useStores();

    const isPoseDetection = projectsStore.current?.type === ProjectType.PoseDetection;
    const isObjectClassification = projectsStore.current?.type === ProjectType.ObjectClassification

    const handleDelete = () => {
        if (!deleteConfirmation){
            return;
        }

        onDelete && onDelete(deleteConfirmation?.id);
        setDeleteConfirmation(undefined);
    };

    const handleDeleteButtonClick = (row: DetectionModelSimulation) => () => {
        setDeleteConfirmation(row);
    };

    const handleDeleteConfirmationClosed = () => {
        setDeleteConfirmation(undefined);
    };

    const handlePreviewOpen = (detectionModelSimulation: DetectionModelSimulation) => () => {
        if (detectionModelSimulation.type === DetectionModelSimulationType.Image){
            setPreviewModal(detectionModelSimulation);
        } else {
            handleDownload(detectionModelSimulation)();
        }
    }

    const handlePreviewClose = () => {
        setPreviewModal(undefined);
    }

    const handleModelInfo = (detectionModel?: DetectionModel) => () => {
        setModelInfo(detectionModel);
    }

    const handleModelInfoClose = () => {
        setModelInfo(undefined);
    }

    const handleDownload = (detectionModelSimulation: DetectionModelSimulation) => async () => {
        if (!detectionModelSimulation.resultAttachment){
            return;
        }

        const link = await Storage.get(
            detectionModelSimulation.resultAttachment.path,
            {
                level: AccessLevel.Private,
                identityId: impersonationUser?.storagePath
            }
        );

        window.location.href = link as string;
    }

    let columns: ColumnDescription<DetectionModelSimulation>[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => {
                return (rowIndex + 1);
            }
        },
        {
            dataField: 'user',
            text: 'User',
            align: 'center',
            headerAlign: 'center',
            hidden: !isAllRunningSimulations,
            sort: true,
            formatter: (_cel, row) => {
                return row?.detectionModel?.project?.user?.email ? row.detectionModel?.project?.user?.email : ''
            },
            sortValue: (_cel, row) => {
                return row?.detectionModel?.project?.user?.email ? row.detectionModel?.project?.user?.email : ''
            }
        },
        {
            dataField: 'projectName',
            text: 'Project Name',
            align: 'center',
            headerAlign: 'center',
            hidden: !isAllRunningSimulations,
            sort: true,
            formatter: (_cel, row) => {
                return row?.detectionModel?.project?.name ? row.detectionModel?.project?.name : ''
            },
            sortValue: (_cel, row) => {
                return row?.detectionModel?.project?.name ? row.detectionModel?.project?.name : ''
            }
        },
        {
            dataField: 'thumbnail',
            text: 'Thumbnail',
            headerClasses: 'app-components-boostrap-table__th-sm',
            classes: 'app-components-boostrap-table__td-thumbnail',
            formatter: (_cel, row) => {
                return (
                    row.type === DetectionModelSimulationType.Image && impersonationUser ?
                        <CustomS3ImageWithSpinner
                            level={AccessLevel.Private}
                            imgKey={row.sourceAttachment.path}
                            identityId={impersonationUser?.storagePath}
                        /> : row.type === DetectionModelSimulationType.Image && !impersonationUser ? 
                        <CustomS3ImageWithSpinner
                            level={AccessLevel.Private}
                            imgKey={row.sourceAttachment.path}
                            identityId={row.detectionModel?.project?.user?.storagePath}
                        /> : 
                        <div className='text-primary'>
                            <FontAwesomeIcon icon={faFileVideo} size='2x' />
                        </div>
                );
            }
        },
        {
            dataField: 'sourceAttachment.originalName',
            text: 'File Name',
            sort: true,
            formatter: (_cel, row) => {
                const name = row.sourceAttachment.originalName;
                return name.length > 15 ? imageHashFormat(name, 4, 4) : name;
            }
        },
        {
            dataField: 'frameworkType',
            text: 'Framework',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const id = `pages-model-simulation-list-framework-type-${row.id}`;

                return (
                    row.detectionModel ? (
                        <DetectionModelFrameworkTypeIcon
                            id={id}
                            frameworkType={row.detectionModel.frameworkType}
                            className="img-w-sm"
                        />
                    ) : ''
                );
            }
        },
        {
            dataField: 'modelInfo',
            text: 'Model Info',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const tooltipId = `pages-model-simulation-list-modelinfo-button-${row.id}`;
                const modelInfo = { ...row.detectionModel! }
                
                !isObjectClassification && delete modelInfo.isMultiLabel
                return (
                    <div className="d-flex align-items-center justify-content-center">
                        <ActionButton
                            color="info"
                            onClick={handleModelInfo(modelInfo)}
                            id={tooltipId}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </ActionButton>
                    </div>
                )
            }
        },
        {
            dataField: 'createdAt',
            text: 'Start Time',
            classes: 'text-nowrap',
            headerClasses: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => row.createdAt ? dayjs(row.createdAt).format(dateConfig.formats.date) : ''
        },
        {
            dataField: 'progressStatus',
            text: 'Status',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, { progressStatus, completedAt, canceledAt }) => {
                if (progressStatus === DetectionModelSimulationProgressStatus.Error) {
                    return (
                        <Badge color='danger' pill>
                            Error
                        </Badge>
                    );
                } else if (canceledAt) {
                    return (
                        <Badge color='danger' pill>
                            Canceled
                        </Badge>
                    );
                } else if (completedAt) {
                    return (
                        <Badge color='success' pill>
                            Completed
                        </Badge>
                    );
                } else {
                    return (
                        <Badge color='warning' pill>
                            <div className='d-flex justify-content-center align-items-center'>
                                <CircularProgress color='dark' />
                                <span className='ml-2'>
                                Underway
                            </span>
                            </div>
                        </Badge>
                    );
                }
            }
        },
        {
            dataField: 'runtime',
            text: 'Runtime',
            // TODO common format, remove recalc
            formatter: (_cel, row) => (
                row.runtime ? formatDurationWithUnitName(dayjs.duration(row.runtime, 'seconds')) : ''
            ),
        },
        {
            dataField: 'size',
            text: 'File Size',
            classes: 'text-nowrap',
            headerClasses: 'text-nowrap',
            formatter: (_cel, row) => row.completedAt && row.resultAttachment ? prettyBytes(row.resultAttachment.size): ''
        },
        {
            dataField: 'view',
            text: 'View',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                if (
                    row.type === DetectionModelSimulationType.Video ||
                    row.progressStatus === DetectionModelSimulationProgressStatus.Error
                ) {
                    return '';
                }

                const tooltipButtonId = `pages-model-simulation-video-list-table_view-button-${row.id}`;
                return (
                    <ActionButton
                        color='success'
                        id={tooltipButtonId}
                        tooltipText='View item'
                        onClick={handlePreviewOpen(row)}
                        disabled={!row.completedAt}
                        disabledTooltipText='Please wait for simulation to be completed'
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </ActionButton>
                );
            }
        },
        {
            dataField: 'download',
            text: 'Download',
            align: 'center',
            headerAlign: 'center',
            hidden: isAllRunningSimulations,
            formatter: (_cel, row) => {
                const tooltipButtonId = `pages-model-simulation-video-list-table_download-button-${row.id}`;
                return (
                    (row.completedAt && row.progressStatus !== DetectionModelSimulationProgressStatus.Error) ?
                        <ActionButton
                            color='success'
                            id={tooltipButtonId}
                            tooltipText="Download item"
                            onClick={handleDownload(row)}
                        >
                            <FontAwesomeIcon icon={faDownload} />
                        </ActionButton>
                        :
                        ''
                );
            }
        },
        {
            dataField: 'delete',
            text: 'Delete',
            align: 'center',
            headerAlign: 'center',
            hidden: isAllRunningSimulations,
            formatter: (_cel, row) => {
                const tooltipButtonId = `pages-model-simulation-video-list-table_delete-button-${row.id}`;
                return (
                    <ActionButton
                        color='danger'
                        onClick={handleDeleteButtonClick(row)}
                        id={tooltipButtonId}
                        tooltipText="Delete item"
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </ActionButton>
                );
            }
        }
    ];

    if (isPoseDetection) {
        const arrayExceptionsDataFieldPoseDetection = ['frameworkType', 'modelInfo'];
        columns = columns.filter((column) => {
            if (arrayExceptionsDataFieldPoseDetection.indexOf(column.dataField) < 0) {
                return column;
            }
        })
    }

    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={items}
                columns={columns}
                defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
                striped
                bordered={false}
                wrapperClasses="table-responsive"
                classes='m-0'
                noDataIndication={noDataText}
            />

            <DeleteConfirmation
                body={`Are you sure you want to delete the "${deleteConfirmation?.sourceAttachment.originalName}" video?`}
                isOpen={!!deleteConfirmation}
                onConfirm={handleDelete}
                onClosed={handleDeleteConfirmationClosed}
                busyIndication={isLoading}
            />

            <Modal isOpen={!!previewModal} toggle={handlePreviewClose} size="lg">
                <ModalHeader toggle={handlePreviewClose}>View item</ModalHeader>
                <ModalBody className='app-component-modal-body__md'>
                    {previewModal?.resultAttachment &&
                        <div className='text-center'>
                            <CustomS3ImageWithSpinner
                                className='w-100'
                                imgKey={previewModal.resultAttachment.path}
                                level={AccessLevel.Private}
                                identityId={impersonationUser?.storagePath ? impersonationUser?.storagePath : previewModal.detectionModel?.project?.user?.storagePath}
                            />
                        </div>
                    }
                </ModalBody>
            </Modal>
            <ModelInfoModal isOpen={!!modelInfo} toggle={handleModelInfoClose} modelInfo={modelInfo}/>
        </>
    );
};

export default VideoListTable;
