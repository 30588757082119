export const fileReaderUtil = {
    readFileAsync: (file:Blob):Promise<ArrayBuffer> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result as ArrayBuffer);
            };

            reader.onerror = reject;

            reader.readAsArrayBuffer(file);
        })
    }
}
