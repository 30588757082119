import React from 'react';
import { DetectionModelDatasetInfo } from '../../../../models/api';

interface Props {
    datasetInfo?: DetectionModelDatasetInfo;
}

const DatasetInfo: React.FC<Props> = ({ datasetInfo }) => {
    return (
        <table className="table table-striped">
            <tbody>
                <tr>
                    <td>Dataset size:</td>
                    <td>{datasetInfo?.datasetLength} images</td>
                </tr>
                <tr>
                    <td>Annotated images:</td>
                    <td>{datasetInfo?.annotatedImagesCount} images</td>
                </tr>

                {datasetInfo?.labelsInfo.map((item) => {
                    return (
                        <tr>
                            <td>{item.name}</td>
                            <td>{item.labelAnnotationsCount} annotations</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default DatasetInfo;
