import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Bell from '../../../../assets/app/images/bell.svg';
import {
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';

import { useStores } from '../../../../store';
import { CustomEvent, CustomEventType } from '../../../../models/events';
import { colorForEvent, descriptionForEvent } from '../../../../utils/events';
import DnaLoader from '../../../DnaLoader';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export const NotificationsBox: React.FC = observer(() => {
    const { eventsStore } = useStores();

    useEffect(() => {
        (async () => {
            if (eventsStore.isFetched) {
                return;
            }
            await eventsStore.fetchList();
        })();
    }, [eventsStore]);

    const getIconForEvent = (eventType: CustomEventType) => {
        const iconCN = `badge badge-dot badge-dot-xl badge-${colorForEvent[eventType]}`;
        return <i className={iconCN}>_</i>;
    };

    const getEventList = (events: CustomEvent[]) => {
        return [...events].reverse().map((event) => {
            const eventDescription = descriptionForEvent[event.type](event);
            return (
                <VerticalTimelineElement
                    className="vertical-timeline-item"
                    icon={getIconForEvent(event.type)}
                >
                    <div className="d-flex pt-1">
                        <h4 className="flex-grow-1 timeline-title p-0">{event.type}</h4>
                        <small>{event.createdAt}</small>
                    </div>
                    <p>{eventDescription}</p>
                </VerticalTimelineElement>
            );
        });
    };

    const handleClick = async () => {
        if (eventsStore.list.length === 0) {
            return;
        }
        const lastEvent = eventsStore.list[eventsStore.list.length - 1];
        const lastEventDate = dayjs(lastEvent.createdAt, 'LT').get('date');
        const currentDate = dayjs().get('date');
        if (lastEventDate !== currentDate) {
            await eventsStore.fetchList();
        }
    }

    return (
        <UncontrolledButtonDropdown>
            <DropdownToggle className="p-0" color="white" onClick={handleClick}>
                <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                        width: '42px',
                        height: '42px',
                        background: '#F3D0DA',
                    }}
                >
                    <img src={Bell}  alt="notifications icon" />
                    {/*<div*/}
                    {/*    className="badge badge-dot badge-dot-sm badge-danger"*/}
                    {/*    style={{ top: '2px', right: '2px' }}*/}
                    {/*>*/}
                    {/*    _*/}
                    {/*</div>*/}
                </div>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-xl rm-pointers mt-1">
                <div className="dropdown-menu-header mb-0">
                    <div className="dropdown-menu-header-inner bg-deep-blue">
                        <div className="menu-header-image opacity-1 text-primary" />
                        <div className="menu-header-content text-dark">
                            <h5 className="menu-header-title">Events</h5>
                            {eventsStore.isLoading || (
                                <h6 className="menu-header-subtitle">
                                    You have <b>{eventsStore.list.length}</b>{' '}
                                    action(s) for today
                                </h6>
                            )}
                        </div>
                    </div>
                </div>
                {eventsStore.isLoading ? (
                    <DnaLoader />
                ) : !eventsStore.list.length ? (
                    <div className="d-flex justify-content-center mt-4 mb-3">
                        <FontAwesomeIcon className="text-muted" icon={faBan} />
                    </div>
                ) : (
                    <div className="scroll-area-sm">
                        <PerfectScrollbar>
                            <div className="p-3">
                                <VerticalTimeline
                                    className="vertical-without-time pt-3"
                                    layout="1-column"
                                >
                                    {getEventList(eventsStore.list)}
                                </VerticalTimeline>
                            </div>
                        </PerfectScrollbar>
                    </div>
                )}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
});
