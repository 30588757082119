import React from 'react';
import { Project, ProjectUpdate } from '../../../models/api';
import { EditProjectForm } from './EditProjectForm';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
} from 'reactstrap';

interface Props extends ModalProps {
    project: Project | null;
    onConfirm: (id: string, params: ProjectUpdate) => void;
}

export const EditProjectModal: React.FC<Props> = (props) => {
    const { isOpen, onClosed, project, onConfirm } = props;
    const formId = 'edit-project-form';
    return (
        <Modal isOpen={isOpen} toggle={onClosed}>
            <ModalHeader toggle={onClosed}>Edit Project</ModalHeader>
            <ModalBody>
                <EditProjectForm
                    project={project}
                    formId={formId}
                    onConfirm={onConfirm}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="success" form={formId}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};
