import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import ApkSettings from '.';
import { ModelDownloadAppTemplateEnum, ModelDownloadAppTemplateEnumUI } from '../../../models/api';

interface Props {
    toggleAdvancedSettings: () => void;
    showApkSettings: boolean;
    appTemplatesData: {
        value: ModelDownloadAppTemplateEnum
        text: ModelDownloadAppTemplateEnumUI
    }[]
}

const ApkSettingsModal: React.FC<Props> = ({
    toggleAdvancedSettings,
    showApkSettings,
    appTemplatesData
}) => {


    return (
        <Modal
            isOpen={showApkSettings}
            toggle={toggleAdvancedSettings}
            className="app-component-modal"
            size="lg"
        >
            <ModalHeader toggle={toggleAdvancedSettings}>
                Settings
            </ModalHeader>
            <ModalBody>
                <ApkSettings appTemplatesData={appTemplatesData} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggleAdvancedSettings}>
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ApkSettingsModal;
