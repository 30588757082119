import { HotkeysEvent } from 'hotkeys-js';
import { Label } from '../../../models/api';

type SingleLabelSaveFunction = (label: Label) => void;
type MultiLabelSaveFunction = (labels: Label[]) => void;

export const hotkeyAnnotateHandler = (
    labelList: Label[],
    handleNavPrev: () => void,
    handleNavNext: () => void,
    onSave: SingleLabelSaveFunction | MultiLabelSaveFunction,
    selectedLabels: Label | Label[],
    isLoading?: boolean,
    handleResetAnnotation?: () => void,
    handleNewLabel?: () => void
) => (e: KeyboardEvent, { key }: HotkeysEvent) => {
    if (isLoading) {
        return;
    }
    switch (key) {
        case 'a':
            handleNavPrev();
            break;
        case 'd':
            handleNavNext();
            break;
        case 'e':
        case 'q':
            if (!Array.isArray(selectedLabels)) {
                let newLabelIndex = labelList.findIndex(item => item.id === selectedLabels?.id);
                key === 'q' ? newLabelIndex-- : newLabelIndex++;
                if (newLabelIndex < 0 || labelList.length - 1 < newLabelIndex) {
                    return;
                }
                const typedOnSave = onSave as SingleLabelSaveFunction;
                typedOnSave(labelList[newLabelIndex]);
            }
            break;
        case 'esc':
            handleResetAnnotation && handleResetAnnotation();
            break;
        case 'n':
            handleNewLabel && handleNewLabel();
            break;
    }
};
