import React, { useEffect, useState } from 'react';
import { Button, ModalBody, ModalHeader, Card } from 'reactstrap';
import {
    faQuestion
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useStores } from '../../../store';
import { observer } from 'mobx-react-lite';
import { videoElement } from '../../../models/api';

import YouTubeVideo from './youTubeVideo';
import VideoListWrapper from './videoListWrapper';

interface helpVideoElement extends videoElement {
    id: string,
    paused: boolean,
}

export const youTubeGetID = (urlStr: string) => {
    const url = urlStr.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
};

const HelpVideo: React.FC = () => {
    const {helpVideoStore, projectsStore} = useStores();
    const [isOpen, setIsOpen] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [videos, setVideos] = useState<helpVideoElement[]>([]);
    const [playingFrameId, setPlayingFrameId] = useState<string>('');

    const location = useLocation().pathname.substring(1);
    const projectType = projectsStore.current?.type as string;


    useEffect(() => {
        if (!helpVideoStore.isFetched) {
            helpVideoStore.fetchVideo();
        }
    }, [helpVideoStore.isFetched]);
    useEffect(() => {
        setIsOpen(false);
        if (helpVideoStore.videos?.[projectType]?.[location]) {
            const projectVideos = prepareVideos(projectType, location);
            setVideos(projectVideos);
        }else if(helpVideoStore.videos?.['dashboard']?.[location]){
            const dashboardVideos = prepareVideos('dashboard', location);
            setVideos(dashboardVideos);
        }

        return () => {
            setVideos([]);
            setIsVideoLoaded(false);
        };
    }, [helpVideoStore.videos, location, projectType]);
    useEffect(() => {
        if (playingFrameId){
            const newVideoState = videos.map((vid => {
                return {
                    ...vid,
                    paused: isOpen ? vid.id !== playingFrameId : true
                };
            }));
            setVideos(newVideoState)
        }
    }, [playingFrameId, isOpen]);

    const prepareVideos = (project:string, page:string)=>{
        return helpVideoStore.videos[project][page].map(vid => {
            return {
                ...vid,
                id: youTubeGetID(vid.url),
                paused: true
            };
        });
    }
    const changePlayingFrameId = (id: string) => {
        setPlayingFrameId(id);
    };
    const toggle = () => {
        setIsOpen(prevState => !prevState);
        setIsVideoLoaded(true);
    };
    const renderVideoList = () => {
        return videos.map((video) => {
            return (
                <VideoListWrapper title={video.title}>
                    <YouTubeVideo
                        width={'100%'}
                        height={200}
                        paused={video.paused}
                        id={video.id}
                        changePlayingFrameId={changePlayingFrameId}
                    />
                </VideoListWrapper>
            );
        });
    };


    return (
        <div className={'right-slide-container ' + (!isOpen ? 'hide-r-slide' : 'show-r-slide')}>
            {
                videos.length &&
                <Button onClick={toggle} color="warning" className='btn-right-slide rounded-circle p-0'>
                    <FontAwesomeIcon
                        icon={faQuestion}
                    />
                </Button>
            }
            {
                isVideoLoaded &&
                <Card className='right-slide'>
                    <ModalHeader toggle={toggle}>Help Videos</ModalHeader>
                    <PerfectScrollbar>
                        <ModalBody className='p-3'>
                            {renderVideoList()}
                        </ModalBody>
                    </PerfectScrollbar>
                </Card>
            }
        </div>
    );
};

export default observer(HelpVideo);