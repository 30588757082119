import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface Props {
    annotationsNames: string[]
    annotationsValues: number[]
}

const AnnotationsChart: React.FC<Props> = observer(({annotationsNames, annotationsValues}) => {
    const [chartData, setChartData] = useState<{series: number[]; options: ApexOptions}>();

    useEffect(() => {
        setChartData({
            series: annotationsValues,
            options: {
                title: {
                    text: 'Images Annotated',
                    margin: 30,
                    style: {
                        fontWeight: 'bold',
                        fontFamily: 'inherit',
                    },
                },
                chart: {
                    type: 'pie',
                    background: '#fff',
                },
                colors: ['#3AC47D', '#D92550'],
                legend: {
                    position: 'top',
                },
                labels: annotationsNames,
            },
        });
    }, []);

    return (
        <>
            {chartData && (
                <div className="w-50">
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="pie"
                        width="100%"
                        height="400"
                    />
                </div>
            )}
        </>
    );
});

export default AnnotationsChart;
