import React from 'react';
import { ButtonProps } from 'reactstrap';
import cx from 'classnames';
import ButtonWithTooltip from '../../ButtonWithTooltip';

type Props = ButtonProps & {
    id: string;
    tooltipText?: string;
    tooltipPlacement?: string;
    disabledTooltipText?: string;
}

const ActionButton: React.FC<Props> = (
    {
        children,
        className,
        tooltipText,
        tooltipPlacement,
        disabledTooltipText,
        ...rest
    }
) => {

    return (
        <ButtonWithTooltip
            className={cx(`app-components-boostrap-table__action-button`, className)}
            tooltipProps={{
                placement: tooltipPlacement || 'right',
                tooltipText,
                disabledTooltipText
            }}
            {...rest}
        >
            {children}
        </ButtonWithTooltip>
    );
};

export default ActionButton;
