import React, { useEffect, useState } from 'react';
import { CardText, Spinner } from 'reactstrap';


interface Props {
    width: string,
    height: number,
    paused: boolean,
    id: string,
    changePlayingFrameId?: (id: string) => void,
}

const YouTubeVideo: React.FC<Props> = ({id, width, height, paused, changePlayingFrameId= ()=>{}}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [frame, setFrame] = useState<any>();


    useEffect(() => {
        if (frame && paused) frame.pauseVideo();
    }, [paused]);
    useEffect(() => {
        if (window.YT) getIframe();
    }, [window.YT]);

    const getIframe = () => {
        new window.YT.Player(`youtube-player-${id}`, {
            height: height,
            width: width,
            videoId: id,
            playerVars: {rel: 0, autoplay: 0},
            events: {
                onReady: playerReady,
                onStateChange: onPlayerStateChange
            }
        });
    };
    const onPlayerStateChange = (event: any) => {
        if (event.data === 1)
            changePlayingFrameId(id);
    };
    const playerReady = (event: any) => {
        if (event.target) {
            setIsLoading(false);
            setFrame(event.target);
        }
    };


    return (
        <div className='d-flex justify-content-center align-items-center' style={{height: height, width:width}}>
            <div style={{visibility: isLoading ? 'hidden' : 'visible'}} className='m-0 w-100'>
                <div id={`youtube-player-${id}`}/>
            </div>

            {
                isLoading &&
                <CardText className='d-flex justify-content-center align-items-center position-absolute'>
                    <Spinner color="primary"/>
                </CardText>
            }
        </div>
    );
};

export default YouTubeVideo;