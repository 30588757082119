import React from 'react';
import { Button, ButtonProps, UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';

export type ButtonWithTooltipProps = ButtonProps & {
    id: string;
    tooltipProps?: Omit<UncontrolledTooltipProps, "target"> & {
        tooltipText?: string;
        disabledTooltipText?: string;
    }
}

const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = (
    {
        children,
        id,
        disabled,
        tooltipProps,
        ...rest
    }
) => {
    const { disabledTooltipText, tooltipText, ...tooltipRest } = tooltipProps || {};
    const showTooltip = (disabled && disabledTooltipText) || (!disabled && tooltipText);

    return (
        <>
            <Button id={id} disabled={disabled} {...rest}>
                {children}
            </Button>

            {showTooltip &&
            <UncontrolledTooltip {...tooltipRest} target={id}>
                {disabled ? disabledTooltipText : tooltipText}
            </UncontrolledTooltip>
            }
        </>

    );
};

export default ButtonWithTooltip;
