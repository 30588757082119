import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

interface Props {
    onNavNext: () => void;
    onNavPrev: () => void;
    navNextDisabled: boolean;
    navPrevDisabled: boolean;
    isLoadingNext: boolean;
    isLoadingPrev: boolean;
    imageName: string;
}

const DatasetImageNavigation: React.FC<Props> = (
    {
        imageName,
        onNavNext,
        onNavPrev,
        navNextDisabled,
        navPrevDisabled,
        isLoadingNext,
        isLoadingPrev
    }) => {

    return (
        <div className='d-flex justify-content-between align-items-center mt-3'>
            <Button
                color='primary'
                size='sm'
                style={{ width: '10rem' }}
                disabled={navPrevDisabled || isLoadingPrev}
                onClick={onNavPrev}
            >
                {isLoadingPrev ? (
                    <Spinner size='sm' />
                ) : (
                    <>
                        <FontAwesomeIcon icon={faArrowLeft} className='mr-1' />
                        <span>Previous (press "A")</span>
                    </>
                )}
            </Button>

            <div className='ml-2 mr-2'>
                {imageName}
            </div>

            <Button
                color='primary'
                size='sm'
                style={{ width: '10rem' }}
                disabled={navNextDisabled || isLoadingNext}
                onClick={onNavNext}
            >
                {isLoadingNext ? (
                    <Spinner size='sm' />
                ) : (
                    <>
                        <span>Next (press "D")</span>
                        <FontAwesomeIcon icon={faArrowRight} className='ml-1' />
                    </>
                )}
            </Button>
        </div>
    );
};

export default DatasetImageNavigation;
