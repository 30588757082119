import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { DetectionModelGenerator, Project } from '../../../models/api';
import { s3Util } from '../../../utils/s3';
import { Storage } from 'aws-amplify';
import { AccessLevel } from '@aws-amplify/ui-components';
import axios, { CancelTokenSource } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import { AmbientLight, DirectionLight, GLTFModel } from 'react-3d-viewer';

const VIEWER_3D_MODAL_WIDTH = 850;
const VIEWER_3D_MODAL_HEIGHT = 650;
const VIEWER_3D_MODAL_PADDING = 50;
const VIEWER_3D_SCALE = 0.7;
const VIEWER_3D_MAX_LOAD_TIME_BEFORE_ERROR_MS = 10000;

interface Props {
    identityId?: string;
    project?: Project;
    openEvent: DetectionModelGenerator | null;
    setOpenEvent: (value: DetectionModelGenerator | null) => void;
}

export const Viewer3dModal: React.FC<Props> = (props) => {
    const { identityId, project, openEvent, setOpenEvent } = props;

    const [axiosCancelSource, setAxiosCancelSource] = useState<CancelTokenSource | null>(null);
    const [modelUrl, setModelUrl] = useState<string | null>(null);
    const [isGlbDownloading, setIsGlbDownloading] = useState(false);
    const [glbDownloadProgress, setGlbDownloadProgress] = useState(0);
    const [isGlbLoading, setIsGlbLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const onDownloadProgress = (progressEvent: { loaded: number; total: number }) => {
        const progressInPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setGlbDownloadProgress(progressInPercentage);
    };

    useEffect(() => {
        if (!identityId || !project || !openEvent) {
            return;
        }
        const outputFilePath = s3Util.getModelGeneratorResultKey(project.id, openEvent.id);
        const cancelSource = axios.CancelToken.source();
        setAxiosCancelSource(cancelSource);
        setIsGlbDownloading(true);
        setGlbDownloadProgress(0);
        setIsGlbLoading(true);
        setLoadingError(false);
        (async () => {
            const link = await Storage.get(outputFilePath, {
                level: AccessLevel.Private,
                identityId
            });
            if (typeof link !== 'string') {
                return;
            }
            const { data: blob } = await axios.get(link, {
                responseType: 'blob',
                onDownloadProgress,
                cancelToken: cancelSource.token
            });
            const modelUrl = (URL ?? webkitURL).createObjectURL(blob);
            setModelUrl(modelUrl);
            setIsGlbDownloading(false);
            setAxiosCancelSource(null);
        })();
        return () => cancelSource.cancel();
    }, [openEvent]);

    const handleCloseView = () => {
        setOpenEvent(null);
        setIsGlbDownloading(false);
        setIsGlbLoading(false);
        setModelUrl(null);
        axiosCancelSource?.cancel();
    };

    useEffect(() => {
        if (isGlbDownloading || !isGlbLoading) {
            return;
        }
        const timeoutId = setTimeout(() => {
            setLoadingError(true);
        }, VIEWER_3D_MAX_LOAD_TIME_BEFORE_ERROR_MS);
        return () => clearTimeout(timeoutId);
    }, [isGlbDownloading, isGlbLoading]);

    return (
        <Modal
            isOpen={!!openEvent}
            centered={true}
            toggle={handleCloseView}
            className='w-100'
            style={{ maxWidth: `${VIEWER_3D_MODAL_WIDTH}px` }}
        >
            <ModalHeader toggle={handleCloseView}>View Model</ModalHeader>
            <ModalBody
                className='d-flex justify-content-center align-items-center position-relative p-0'
                style={{ minHeight: `${VIEWER_3D_MODAL_HEIGHT}px` }}
            >
                {!openEvent ? null : loadingError ? (
                    <div className='app-pages-model-generation-page__viewer-3d-modal__spinner-wrapper'>
                        <FontAwesomeIcon
                            icon={faBan}
                            className='app-pages-model-generation-page__viewer-3d-modal__spinner'
                        />
                        <div className='text-center font-size-lg mt-4'>
                            An error occurred on loading 3D model
                        </div>
                    </div>
                ) : !modelUrl || isGlbDownloading ? (
                    <div className='app-pages-model-generation-page__viewer-3d-modal__spinner-wrapper'>
                        <Spinner className='app-pages-model-generation-page__viewer-3d-modal__spinner' />
                        <div className='text-center font-size-lg mt-4'>{glbDownloadProgress} %</div>
                    </div>
                ) : (
                    <>
                        {isGlbLoading && (
                            <div className='app-pages-model-generation-page__viewer-3d-modal__spinner-wrapper absolute-centered'>
                                <Spinner className='app-pages-model-generation-page__viewer-3d-modal__spinner' />
                                <div className='text-center font-size-lg mt-4'>
                                    Loading model...
                                </div>
                            </div>
                        )}
                        <GLTFModel
                            src={modelUrl}
                            width={VIEWER_3D_MODAL_WIDTH - VIEWER_3D_MODAL_PADDING}
                            height={VIEWER_3D_MODAL_HEIGHT - VIEWER_3D_MODAL_PADDING}
                            scale={{
                                x: VIEWER_3D_SCALE,
                                y: VIEWER_3D_SCALE,
                                z: VIEWER_3D_SCALE
                            }}
                            onLoad={() => setIsGlbLoading(false)}
                        >
                            <AmbientLight color={0xffffff} />
                            <DirectionLight
                                color={0xffffff}
                                position={{ x: 100, y: 200, z: 100 }}
                            />
                            <DirectionLight
                                color={0xff00ff}
                                position={{ x: -100, y: 200, z: -100 }}
                            />
                        </GLTFModel>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
};
