import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
    axis: 'x' | 'y';
    position: number;
}

const Line: React.FC<Props> = ({ axis, position, className, ...rest }) => {
    return (
        <div
            {...rest}
            className={cx(
                'app-components-cross-hair__line',
                axis === 'y' ? 'app-components-cross-hair__line_axis-x' : 'app-components-cross-hair__line_axis-y',
                className
            )}
            style={axis === 'y' ? { top: position } : { left: position }}
        />
    );
};

export default Line;
