import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import container from '../../container/container';
import {
    DetectionModelDownload,
    DetectionModelDownloadListRequest,
    DetectionModelDownloadTypeUI,
    DetectionModelDownloadUpdate
} from '../../models/api';
import apiRoutes from '../../providers/apiClient/routes';
import qs from 'qs';
import { RootStore } from './root';
import { CustomEventType } from '../../models/events';

const api = container.apiClient;

interface ListProperty {
    items: DetectionModelDownload[];
    isLoading: boolean;
    isFetched: boolean;
}

interface ItemProperty {
    isLoading: boolean;
}

interface DownloadFileParams {
    url?: string,
    fileName: string,
    s3Bucket?: string
}

export class DetectionModelDownloadsStore {
    list: ListProperty = {
        items: [],
        isLoading: false,
        isFetched: false
    };

    item: ItemProperty = {
        isLoading: false
    }

    rootStore: RootStore;

    private readonly disposers: IReactionDisposer[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    resetList() {
        runInAction(() => {
            this.list.items = [];
            this.list.isLoading = false;
            this.list.isFetched = false;
            this.item.isLoading = false;
        });

        this.disposers.push(
            reaction(() => ({
                runningModelDownloadCount: this.listOfRunning.length,
                modelDownloadCount: this.list.items.length
            }), ({ runningModelDownloadCount, modelDownloadCount }) => {
                this.rootStore.statisticsStore.setDashboardStatistic({
                    ...this.rootStore.statisticsStore.project,
                    runningModelDownloadCount,
                    modelDownloadCount
                });
            })
        );
    }

    get listOfRunning() {
        return this.list.items.filter(item => !item.buildCompletedAt && !item.buildCanceledAt);
    }

    get isLoading() {
        return this.list.isLoading || this.item.isLoading;
    }

    async fetchList() {
        //TODO find solution for check and waiting
        if (!this.rootStore.projectsStore.current || this.isLoading) {
            return;
        }

        this.list.isLoading = true;

        //TODO from params
        const params: DetectionModelDownloadListRequest = {
            filter: {
                projectId: this.rootStore.projectsStore.current.id
            }
        };

        try {
            const { data } = await api.detectionModelDownloadList(params);

            runInAction(() => {
                this.list.items = data;
                this.list.isFetched = true;
            });
        } finally {
            runInAction(() => {
                this.list.isLoading = false;
            });
        }
    }

    async createItem(
        detectionModelId: DetectionModelDownload['detectionModelId'],
        params: DetectionModelDownloadUpdate
    ) {
        this.item.isLoading = true;
        const toastId = createProgressToast();
        try {
            const { data } = await api.detectionModelDownloadCreate(detectionModelId, {
                ...params,
                projectId: this.rootStore.projectsStore.current!.id
            });

            runInAction(() => {
                this.list.items.push(data);
                this.rootStore.eventsStore.addItem(CustomEventType.CreateDownload, {
                    detectionModelId: data.detectionModel.id,
                    detectionModelIndex: data.detectionModel.modelIndex,
                    type: data.type,
                    projectId: this.rootStore.projectsStore.current!.id,
                    projectName: this.rootStore.projectsStore.current!.name
                });
            });

            const type = (params.type && DetectionModelDownloadTypeUI[params.type]) ?? 'model';
            toast(`Building you a brand new ${type}`, { type: toast.TYPE.SUCCESS });
        }
        catch(error){
            // TODO: common error handler
            toast(error?.response?.data?.errors?.message ?? error.message, {type:toast.TYPE.ERROR});
        }
        finally {
            runInAction(() => {
                this.item.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async deleteItem(id: string) {
        this.item.isLoading = true;
        const toastId = createProgressToast();
        try {
            await api.detectionModelDownloadDelete(id);
            runInAction(() => {
                this.list.items = this.list.items.filter(item => item.id !== id);
            });
        } finally {
            runInAction(() => {
                this.item.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async downloadItem(params: DownloadFileParams) {
        try {
            window.open(`${this.rootStore.apiUrl}${apiRoutes.detectionModelDownloads.download}?${qs.stringify(params)}`, '_self');
        } catch(error) {
            toast(error?.response?.data?.errors?.message ?? error.message, {type:toast.TYPE.ERROR});
        }
    }
}
