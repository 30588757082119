import {
    AccountStatistic,
    Annotation,
    ChatOptions,
    Classification,
    ClassificationListSyncRequest,
    CreateUser,
    DatasetImage,
    DatasetImageCreate,
    DatasetImageDelete,
    DatasetImageExport,
    DatasetImageImport,
    DatasetImageParams,
    DetectionModel,
    DetectionModelCreate,
    DetectionModelDownload,
    DetectionModelDownloadListRequest,
    DetectionModelDownloadUpdate,
    DetectionModelGenerator,
    DetectionModelGeneratorCreateRequest,
    DetectionModelGeneratorListRequest,
    DetectionModelGeneratorRunningListRequest,
    DetectionModelRunningList,
    DetectionModelSimulation,
    DetectionModelSimulationCreateRequest,
    DetectionModelSimulationListRequest,
    DetectionModelSimulationRunningListRequest,
    DetectionModelSimulationUpdateForm,
    DetectionModelTrainingAccuracyStatistic,
    DetectionModelTrainingReport,
    DetectionModelTrainingStartRequest,
    DetectionModelUpdate,
    DeviceLicense,
    Feedback,
    FileUploadElement,
    ImagesAnnotationsInfo,
    Label,
    LabelUpdate,
    PolygonAnnotation,
    PredictiveMaintenance,
    PredictiveMaintenanceCreateRequest,
    PredictiveMaintenanceListRequest,
    Project,
    ProjectClone,
    ProjectCreate,
    ProjectGroup,
    ProjectGroupListRequest,
    ProjectGroupUpdate,
    ProjectListRequest,
    ProjectStatistic,
    ProjectUpdate,
    SetDeviceLicense,
    SetVideoForm,
    TrackOpenProject,
    TrainingReportRequest,
    UpgradeLicense,
    User
} from '../../models/api';
import { AxiosPromise } from 'axios';
import { Paged, Pagination } from '../../models/pagination';
import { SortOrder } from '../../models/sort-order';
import { DbEvent } from '../../models/events';

//TODO types for delete, create forms
export abstract class AbstractApiClient {
    abstract projectList(params?: ProjectListRequest): AxiosPromise<Project[]>;

    abstract projectTemplatesList(): AxiosPromise<Project[]>;

    abstract projectCanSwitchMultiLabel(id: Project['id']): AxiosPromise;

    abstract projectUpdate(id: Project['id'], params: ProjectUpdate): AxiosPromise;

    abstract projectDelete(id: Project['id']): AxiosPromise;

    abstract projectClone(id: Project['id'], params: ProjectClone): AxiosPromise;
    
    abstract projectGroupList(params?: ProjectGroupListRequest): AxiosPromise<ProjectGroup[]>;
    
    abstract projectGroupCreate(params: ProjectGroupUpdate): AxiosPromise;

    abstract projectGroupUpdate(id: ProjectGroup['id'], params: ProjectGroupUpdate): AxiosPromise<ProjectGroup>;

    abstract projectGroupDelete(id: ProjectGroup['id']): AxiosPromise;

    abstract projectTrashList(params?: ProjectListRequest): AxiosPromise<Project[]>;

    abstract projectTrashRestore(id: Project['id']): AxiosPromise;

    abstract projectCreate(params: ProjectCreate): AxiosPromise;

    abstract datasetImageList(
        projectId: DatasetImage['projectId'],
        pagination: Pagination | null,
        sortOrder: SortOrder
    ): AxiosPromise<Paged<DatasetImage>>;

    abstract datasetImageById(id: DatasetImage['id']): AxiosPromise<DatasetImage>;

    abstract datasetImageCreate(params: DatasetImageCreate): AxiosPromise<DatasetImage>;

    abstract datasetImageDelete(params: DatasetImageDelete): AxiosPromise;

    abstract datasetImageImport(params: DatasetImageImport): AxiosPromise;

    abstract datasetImageExport(params: DatasetImageExport): AxiosPromise;

    abstract datasetImageAnnotationsInfo(
        projectId: DatasetImage['projectId']
    ): AxiosPromise<ImagesAnnotationsInfo>;

    abstract datasetImageHashedNameList(projectId: DatasetImage['projectId']): AxiosPromise;

    abstract datasetImageSortedIdList(
        projectId: DatasetImage['projectId'],
        sortOrder: SortOrder,
        params: DatasetImageParams
    ): AxiosPromise<{ count: number; rows: string[] }>;

    abstract labelList(projectId: Label['projectId']): AxiosPromise<Label[]>;

    abstract labelCreate(
        projectId: Label['projectId'],
        params: LabelUpdate[]
    ): AxiosPromise<Label[]>;

    abstract labelUpdate(
        id: Label['id'],
        params: LabelUpdate
    ): AxiosPromise<Label>;

    abstract labelDelete(id: Label['id']): AxiosPromise;

    abstract annotationListSync(
        datasetImageId: Annotation['datasetImageId'],
        items: Annotation[]
    ): AxiosPromise<Annotation[]>;

    abstract annotationsForLabel(
        labelId: string,
        offsetIndex: number
    ): AxiosPromise<Annotation[]>;

    abstract changeAnnotationLabel(
        annotationId: string,
        labelId: string
    ): AxiosPromise<Annotation>;

    /*abstract annotationList(datasetImageId: Annotation['datasetImageId']): AxiosPromise<Annotation[]>*/

    abstract detectionModelList(
        projectId: DetectionModel['projectId']
    ): AxiosPromise<DetectionModel[]>;

    abstract detectionModelRunningList(
        params?: DetectionModelRunningList
    ): AxiosPromise<DetectionModel[]>;

    abstract detectionModelTrainingReport(
        params: TrainingReportRequest
    ): AxiosPromise<DetectionModelTrainingReport[]>;

    abstract detectionModelCreate(
        projectId: DetectionModel['projectId'],
        params: DetectionModelCreate
    ): AxiosPromise<DetectionModel>;

    abstract detectionModelUpdate(
        id: DetectionModel['id'],
        params: DetectionModelUpdate
    ): AxiosPromise<DetectionModel>;

    abstract detectionModelDelete(id: DetectionModel['id']): AxiosPromise;

    abstract detectionModelTrainingStart(
        projectId: Label['projectId'],
        params: DetectionModelTrainingStartRequest
    ): AxiosPromise;

    abstract detectionModelTrainingEarlyStop(id: DetectionModel['id']): AxiosPromise;

    abstract detectionModelTrainingCancel(id: DetectionModel['id']): AxiosPromise;

    abstract detectionModelTrainingAccuracyStatistic(
        id: DetectionModel['id']
    ): AxiosPromise<DetectionModelTrainingAccuracyStatistic>;

    abstract detectionModelDownloadList(
        params?: DetectionModelDownloadListRequest
    ): AxiosPromise<DetectionModelDownload[]>;

    abstract detectionModelDownloadCreate(
        detectionModelId: DetectionModelDownload['detectionModelId'],
        params: DetectionModelDownloadUpdate
    ): AxiosPromise<DetectionModelDownload>;

    abstract detectionModelDownloadUpdate(
        id: DetectionModelDownload['id'],
        params: DetectionModelDownloadUpdate
    ): AxiosPromise<DetectionModelDownload>;

    abstract detectionModelDownloadDelete(id: DetectionModelDownload['id']): AxiosPromise;

    abstract userCreate(user: CreateUser): AxiosPromise;
    abstract userUpdate(user: User): AxiosPromise;
    abstract userGet(cognitoId: string): AxiosPromise<User>;

    abstract userList(): AxiosPromise<User[]>;

    abstract userDelete(id: User['id']): AxiosPromise<boolean>;

    abstract userAddFileUpload(cognitoId: string, params: FileUploadElement): AxiosPromise;
    abstract userUpdateFileUpload(cognitoId: string, params: FileUploadElement): AxiosPromise;

    abstract userGetFileUpload(cognitoId: string): AxiosPromise;
    abstract userGetLastFileUpload(cognitoId: string): AxiosPromise;

    abstract userDeleteFileUpload(cognitoId: string): AxiosPromise;

    abstract detectionModelSimulationList(
        params?: DetectionModelSimulationListRequest
    ): AxiosPromise<DetectionModelSimulation[]>;
    
    abstract detectionModelRunningSimulationList(
        params?: DetectionModelSimulationRunningListRequest
    ): AxiosPromise<DetectionModelSimulation[]>;

    abstract detectionModelSimulationCreate(
        detectionModelId: DetectionModelSimulation['detectionModelId'],
        params: DetectionModelSimulationCreateRequest
    ): AxiosPromise<DetectionModelSimulation>;

    abstract detectionModelSimulationUpdate(
        id: DetectionModelSimulation['id'],
        params: DetectionModelSimulationUpdateForm
    ): AxiosPromise<DetectionModelSimulation>;
    
    abstract detectionModelGeneratorRunningList(
        params: DetectionModelGeneratorRunningListRequest
    ): AxiosPromise<DetectionModelGenerator[]>;

    abstract detectionModelGeneratorList(
        params: DetectionModelGeneratorListRequest
    ): AxiosPromise<DetectionModelGenerator[]>;

    abstract detectionModelGeneratorCreate(
        params: DetectionModelGeneratorCreateRequest
    ): AxiosPromise<DetectionModelGenerator>;

    abstract detectionModelGeneratorCancelGenerating(
        id: DetectionModelGenerator['id']
    ): AxiosPromise<DetectionModelGenerator>;

    abstract detectionModelGeneratorDelete(
        id: DetectionModelGenerator['id']
    ): AxiosPromise<boolean>;

    abstract detectionModelSimulationDelete(id: DetectionModelSimulation['id']): AxiosPromise;

    abstract sendFeedback(params: Feedback): AxiosPromise;

    abstract getHelpVideo(): AxiosPromise;

    abstract addVideo(formData: SetVideoForm): AxiosPromise;

    abstract updateVideo(formData: SetVideoForm): AxiosPromise;

    abstract delVideo(formData: SetVideoForm): AxiosPromise;

    abstract datasetZipDownload(path: string): AxiosPromise;

    abstract sendUpgradeLicenseRequest(upgradeLicense: UpgradeLicense): AxiosPromise;

    abstract classificationListSync(
        datasetImageId: DatasetImage['id'],
        params: ClassificationListSyncRequest
    ): AxiosPromise<Classification[]>;

    abstract statisticsCommon(): AxiosPromise<AccountStatistic>;

    abstract statisticsProject(id: Project['id']): AxiosPromise<ProjectStatistic>;

    abstract trackLogin(): AxiosPromise;

    abstract trackLogout(): AxiosPromise;

    abstract eventList(): AxiosPromise;

    abstract usersEventList(pagination: Pagination | null, sortOrder: SortOrder): AxiosPromise<Paged<DbEvent>>

    abstract trackOpenProject(params: TrackOpenProject): AxiosPromise;
    
    abstract addLicense(formData: SetDeviceLicense): AxiosPromise;

    abstract licensesList(): AxiosPromise;

    abstract licenseDelete(deviceLicenseId: DeviceLicense['id']): AxiosPromise;
    
    abstract predictiveMaintenanceTrainingCreate(params: PredictiveMaintenanceCreateRequest): AxiosPromise;

    abstract predictiveMaintenanceTrainingDelete(id: PredictiveMaintenance['id']): AxiosPromise;

    abstract predictiveMaintenanceTrainingsList(params: PredictiveMaintenanceListRequest): AxiosPromise;

    abstract getChatOptions(): Promise<ChatOptions>;
    
    abstract polygonAnnotationListSync(
        datasetImageId: PolygonAnnotation['datasetImageId'],
        items: PolygonAnnotation[]
    ): AxiosPromise<PolygonAnnotation[]>;

    abstract __extendHeaders(headers: { [key: string]: string | undefined }): void;

    setAuthorizationHeader(token: string, type = 'Bearer'): void {
        this.__extendHeaders({ Authorization: `${type} ${token}` });
    }
}
