import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalProps,
} from 'reactstrap';
import { IncomingMessage, OutgoingMessage, SuggestionMessage, useChatStream } from '../../Chat';
import { useStores } from '../../../store';
import AILogo from '../../../assets/app/images/ai-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import container from '../../../container/container';
import { ChatOptions } from '../../../models/api';
import { AI_ASSISTANT_ERROR_MESSAGE } from '../../../utils/errors';
import TypingIndicator from '../../TypingIndicator';
import { toast } from 'react-toastify';
import { BOT_WELCOME_MESSAGE } from '../../Chat/hooks/utils/strings';

interface Props extends ModalProps {
    toggle: () => void;
    toggleNewProjectModal: (projectTitle?: string, aiType?: string) => void;
}


export const AIAssistantModal: React.FC<Props> = (props) => {
    const { isOpen, toggle, toggleNewProjectModal } = props;
    const { userStore } = useStores();
    const incomingMessageRef = useRef<HTMLDivElement | null>(null);
    const outgoingMessageRef = useRef<HTMLDivElement | null>(null);
    const [chatOptions, setChatOptions] = useState<ChatOptions>({ url: '', token: '' })
    const headers = useMemo(() => new Headers(), [])

    const { 
        messages,
        setMessages,
        input,
        handleInputChange,
        handleSubmit,
        isLoading,
        setIsLoading
    } = useChatStream({
        options: {
          url: chatOptions.url,
          method: 'POST',
          headers,
        },
        method: {
          type: 'body',
          key: 'messageHistory',
        },
      });

    const handleModalClose = () => {
        setMessages([{ id: '1', role: 'assistant', content: BOT_WELCOME_MESSAGE }]);
        setIsLoading(false);
    }

    const handleOpenNewProjectModal = (projectTitle?: string, aiType?: string) => {
        toggle();
        toggleNewProjectModal(projectTitle, aiType);
        setIsLoading(false);
    }
    
    useEffect(() => {
        (async () => {
            const chatOptions = await container.apiClient.getChatOptions();
            headers.set('Authorization', `Bearer ${chatOptions.token}`);
            headers.set('Content-Type', 'application/json');
            setChatOptions(chatOptions);
        })()
        setMessages([{ id: '1', role: 'assistant', content: BOT_WELCOME_MESSAGE }]);
    }, [])

    useEffect(() => {
        const assistantMessages = messages.filter((message) => message.role === 'assistant')
        const lastAssistantMessage = assistantMessages[assistantMessages.length - 1]

        if (lastAssistantMessage?.content === AI_ASSISTANT_ERROR_MESSAGE) {
            toast(AI_ASSISTANT_ERROR_MESSAGE, { type: toast.TYPE.ERROR })
            handleOpenNewProjectModal()
        }

        if (outgoingMessageRef.current && incomingMessageRef.current) {
            outgoingMessageRef.current.scrollIntoView()
            incomingMessageRef.current.scrollIntoView()
        }
    }, [messages])
    

    return (
        <Modal isOpen={isOpen} toggle={toggle} onClosed={handleModalClose}>
            <ModalHeader toggle={toggle}>AI Assistant</ModalHeader>
            <ModalBody>
                <div className='app-components-chat'>
                    <div className='app-components-chat__message-list'>
                        {messages.map((message) => {
                            return message.aiSuggestion ? (
                                <SuggestionMessage
                                    key={message.id}
                                    ref={outgoingMessageRef}
                                    message={message.content}
                                    avatarUrl={AILogo}
                                    handleOpenNewProjectModal={handleOpenNewProjectModal}
                                    aiSuggestion={message.aiSuggestion}
                                />
                            ) : message.role === 'user' ? (
                                <OutgoingMessage key={message.id} ref={outgoingMessageRef}  message={message.content} avatarUrl={userStore.avatar} />
                            ) : (
                                <IncomingMessage key={message.id} ref={incomingMessageRef} message={message.content} avatarUrl={AILogo} />
                            );
                        })}
                    </div>
                    <div className='app-components-chat__typing-indicator' style={{visibility: isLoading ? 'visible' : 'hidden'}}>
                        <TypingIndicator />
                        AI Assistant is typing...
                    </div>
                    <form className='app-components-chat__message-form' action="post" onSubmit={handleSubmit}>
                        <Input
                            type='text'
                            className='app-components-chat__message-form__input'
                            onChange={handleInputChange}
                            value={input}
                            placeholder='Message...'
                            disabled={isLoading}
                        />
                        <Button type='submit' color='primary' disabled={isLoading}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                    </form>
                </div>
            </ModalBody>
        </Modal>
    );
};
