import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Components } from '@aws-amplify/ui-components';

export type CustomS3ImageProps = Pick<Components.AmplifyS3Image, 'imgKey' | 'level'> &
    Partial<Pick<Components.AmplifyS3Image, 'identityId'>> &
    HTMLAttributes<HTMLImageElement>;

const CustomS3Image: React.FC<CustomS3ImageProps> = ({ imgKey, level, identityId, ...rest }) => {
    const [url, setUrl] = useState<string>();

    useEffect(() => {
        (async () => {
            const config = {level, identityId};

            if (!config.identityId){
                delete config.identityId;
            }

            setUrl(await Storage.get(imgKey, config) as string);
        })();
    }, [identityId, imgKey, level]);

    return (
        <img src={url} {...rest} />
    );
};

export default CustomS3Image;
