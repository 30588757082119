import { DatasetImage } from '../../../models/api';
import { useStores } from '../../../store';
import { observer } from 'mobx-react-lite';
import ImageAnnotateModal from './ModalObjectDetection';
import { FC } from 'react';

interface Props {
    inputLabelId: string | undefined;
    handleClose: () => void;
}

const AnnotationModal: FC<Props> = observer(({ inputLabelId, handleClose }) => {
    const { datasetImageStore, labelsStore, annotationsStore, annotationsEditorStore, userStore } =
        useStores();

    const refetchLabel = async () => {
        handleClose();
        if (inputLabelId) {
            await annotationsEditorStore.fetchAnnotationsForLabel(inputLabelId, 0, true);
        }
    }

    const handleAnnotateDialogClosed = () => {
        datasetImageStore.annotate();
    };

    const handleImageDelete = async () => {
        if (!datasetImageStore.annotated) {
            return;
        }

        await datasetImageStore.deleteImage([datasetImageStore.annotated]);
        await refetchLabel()
        
        handleAnnotateDialogClosed();
    };

    const handleAnnotateSave = (annotationList: DatasetImage['annotations']) => {
        if (!annotationList || !datasetImageStore.annotated) {
            return;
        }
        annotationsStore.syncList(annotationList);
    };

    return (
        <>
            {datasetImageStore.annotated && (
                <ImageAnnotateModal
                    isOpen={!!datasetImageStore.annotated}
                    onClosed={handleAnnotateDialogClosed}
                    annotatedDatasetImage={datasetImageStore.annotated}
                    labelList={labelsStore.list}
                    refetchLabel={refetchLabel}
                    onSave={handleAnnotateSave}
                    onDelete={handleImageDelete}
                    impersonationUser={userStore.impersonationUser}
                    isLoading={annotationsStore.isLoading}
                    error={annotationsStore.error}
                    annotateListProgress={{
                        current: (datasetImageStore.currentAnnotatedImageIndex || 0) + 1,
                        total: datasetImageStore.pagination.totalSize
                    }}
                    size='lg'
                />
            )}
        </>
    );
});

export default AnnotationModal;
