import React from 'react';
import { DetectionModel, DetectionModelFrameworkType } from '../../../models/api';
import DropdownList, { DropdownProps } from 'react-widgets/DropdownList';
import DetectionModelFrameworkTypeIcon from '../DetectionModelFrameworkTypeIcon';
import ModelOptionWithIcon from './ModelOptionWithIcon';

interface Props extends DropdownProps<any> {
    detectionModelList: {
        value: string,
        model: DetectionModel,
        text: string,
        frameworkType: DetectionModelFrameworkType
    }[];
}

const DetectionModelWithIconDropdown: React.FC<Props> = ({ detectionModelList, ...rest }) => {
    return (
        <DropdownList
            {...rest}
            data={detectionModelList}
            optionComponent={ModelOptionWithIcon}
            renderValue={({ item }) => {
                return item?.model ? (
                    <div>
                        <DetectionModelFrameworkTypeIcon
                            frameworkType={item.model.frameworkType}
                            className='mr-2 img-w-sm'
                        />

                        <span>{item.text}</span>
                    </div>
                ) : '';
            }}
        />
    );
};

export default DetectionModelWithIconDropdown;
