import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik, FormikProps } from 'formik';
import {
    DetectionModelBaseModelEnum,
    DetectionModelFrameworkType,
    DetectionModelInputSizeEnum,
    ModelTrainingSettings,
    ProjectType
} from '../../../models/api';
import DetectionModelFrameworkTypeIcon from '../../../components/DetectionModel/DetectionModelFrameworkTypeIcon';
import { SettingsPresets } from '../AdvancedSettings/dropdownSettingsData';
import AdvancedSettingsForm from '../AdvancedSettingsForm';

const mapProjectTypeToDefaultPresetName: Record<ProjectType, string | null> = {
    [ProjectType.ObjectClassification]: 'defaultClassification',
    [ProjectType.ObjectDetection]: 'mobile',
    [ProjectType.ObjectSegmentation]: 'defaultSegmentation',
    [ProjectType.Modeling3d]: null,
    [ProjectType.PoseDetection]: null,
    [ProjectType.PredictiveMaintenance]: null
};

const TrainNewModelPanel: React.FC = observer(() => {
    const { detectionModelsStore, projectsStore } = useStores();
    const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);
    const [activeTemplate, setActiveTemplate] = useState<string>('mobile');
    const [activeTabId, setActiveTabId] = useState<string>(
        'pages-model-training-advanced-settings-form-tabs-1'
    );
    const [isResetting, setIsResetting] = useState(false);
    const formRef = useRef<FormikProps<ModelTrainingSettings>>(null);

    const defaultPresetName = mapProjectTypeToDefaultPresetName[projectsStore.current!.type]!;
    const isClassificationProject = projectsStore.current?.type === ProjectType.ObjectClassification;
    const isSegmentationProject = projectsStore.current?.type === ProjectType.ObjectSegmentation;
    const isTF2Project = projectsStore.current?.type === ProjectType.ObjectDetection;

    useEffect(() => {
        if (!formRef.current) {
            return;
        }
        formRef.current.resetForm();
        formRef.current.setValues({ ...SettingsPresets.get(defaultPresetName)! });
        setActiveTemplate('mobile');
        setActiveTabId('pages-model-training-advanced-settings-form-tabs-1');
        setIsResetting(true);
    }, [projectsStore.current]);

    const handleAdvancedSettings = () => {
        setShowAdvancedSettings((prev) => !prev);
    };

    const isTrainingButtonDisabled =
        detectionModelsStore.isStartingTraining ||
        (detectionModelsStore.isLoading && !detectionModelsStore.listIsFetched);
    return (
        <Formik<ModelTrainingSettings>
            innerRef={formRef}
            initialValues={{ ...SettingsPresets.get(defaultPresetName)! }}
            onSubmit={(values) => {
                const trainConfig = {
                    frameworkType: values.frameworkType,
                    baseModel: projectsStore.current?.isMultiLabel
                        ? DetectionModelBaseModelEnum.ResNext50
                        : isClassificationProject
                        ? DetectionModelBaseModelEnum.EfficientNetB0
                        : isSegmentationProject
                        ? DetectionModelBaseModelEnum.RCNN
                        : values.baseModel,
                    modelInputSize: projectsStore.current?.isMultiLabel
                        ? DetectionModelInputSizeEnum.OCFast
                        : isClassificationProject
                        ? DetectionModelInputSizeEnum.OCVeryFast
                        : isSegmentationProject
                        ? DetectionModelInputSizeEnum.OSFast
                        : values.modelInputSize,
                    ignoreEmptyAnnotations: values.ignoreEmptyAnnotations,
                    augmentations: values.augmentations,
                    isMultiLabel: projectsStore.current?.isMultiLabel
                };
                detectionModelsStore.trainingStart(trainConfig);
            }}
        >
            {({ values, setValues }) => {
                return (
                    <Form>
                        <div className='d-flex align-items-center he-100'>
                            <Button
                                type='submit'
                                color='primary'
                                disabled={isTrainingButtonDisabled}
                            >
                                Train New Model
                            </Button>
                            <DetectionModelFrameworkTypeIcon
                                id='pages-model-training-train-new-model-panel-framework-type'
                                className='ml-4 img-w-sm'
                                frameworkType={values.frameworkType}
                            />
                            <Button color='light' onClick={handleAdvancedSettings} className='ml-2'>
                                <FontAwesomeIcon icon={faCog} />
                            </Button>
                            {/* FSAI-273 */}
                            {/* <div className='ml-4'>
                                <b>Limits:</b>
                                    <span className='text-primary font-size-md ml-4'>3</span> / 30 models
                                <span className='font-size-md ml-4'>0</span> / 5 models per day
                                <span className='ml-4'>
                                    5,000 epochs / model
                                </span>
                            </div> */}
                        </div>

                        <Modal
                            isOpen={showAdvancedSettings}
                            toggle={handleAdvancedSettings}
                            className='app-component-modal'
                        >
                            <ModalHeader toggle={handleAdvancedSettings}>Settings</ModalHeader>
                            <ModalBody>
                                <AdvancedSettingsForm
                                    settings={values}
                                    project={projectsStore.current!}
                                    onChange={(settings) => setValues(settings)}
                                    activeTemplate={activeTemplate}
                                    setActiveTemplate={setActiveTemplate}
                                    activeTabId={activeTabId}
                                    setActiveTabId={setActiveTabId}
                                    isResetting={isResetting}
                                    setIsResetting={setIsResetting}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' onClick={handleAdvancedSettings}>
                                    Done
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default TrainNewModelPanel;
