import React, { FunctionComponent, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
// @ts-ignore
import Tabs from 'react-responsive-tabs';
import { observer } from 'mobx-react-lite';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import ProjectsTable from './ProjectsTable';
import NewProjectPanel from './NewProjectPanel';
import Paper from '../../components/Paper';
import { useStores } from '../../store';
import DnaLoader from '../../components/DnaLoader';
import { ProjectCreate, ProjectType } from '../../models/api';
import { Routes } from '../../router/routes';
import { Badge } from 'reactstrap/lib';
import WelcomeVideo from './welcomeVideo';

export const DashboardPage: FunctionComponent = observer(() => {
    const { projectsStore, projectsTrashStore } = useStores();
    const history = useHistory();
    const [currentTab, setCurrentTab] = useState(0);

    const projectCount = useMemo(() => {
        return {
            currentProjectLength: projectsStore.list.length,
            oldProjectLength: projectsStore.listOld.length
        };
    }, [projectsStore.list, projectsStore.listOld]);

    const handleCreate = async (props: ProjectCreate) => {
        const data = await projectsStore.createItem(props);
        projectsStore.setCurrentProject(data.id);

        if (data.type === ProjectType.PoseDetection) {
            history.push(Routes.ModelSimulation);
        } else if (data.type === ProjectType.Modeling3d) {
            history.push(Routes.ModelGenerator);
        } else {
            history.push(Routes.Labels);
        }
    };

    const toggleTabColor = (data: number) => {
        setCurrentTab(data);
    };

    const tabsContent = [
        {
            title: (
                <Badge
                    color={currentTab === 0 ? 'primary' : 'link'}
                    className={'px-3 py-2 text-capitalize'}
                    pill
                >
                    Current Projects ({projectCount.currentProjectLength})
                </Badge>
            ),
            content: (
                <>
                    <Row>
                        <Col sm={12}>
                            <NewProjectPanel
                                onSubmit={handleCreate}
                                isLoading={projectsStore.isLoading}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Paper rounded className='mt-4' hidden={!projectsStore.isFetched}>
                                <ProjectsTable tableType={'projects'} />
                            </Paper>
                        </Col>
                    </Row>
                </>
            )
        },
        {
            title: (
                <Badge
                    color={currentTab === 1 ? 'primary' : 'link'}
                    className={'px-3 py-2 text-capitalize'}
                    pill
                >
                    Old Projects ({projectCount.oldProjectLength})
                </Badge>
            ),
            content: (
                <>
                    <Row>
                        <Col sm={12}>
                            <NewProjectPanel
                                onSubmit={handleCreate}
                                isLoading={projectsStore.isLoading}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Paper rounded className='mt-4' hidden={!projectsStore.isFetched}>
                                <ProjectsTable tableType={'oldProjects'} />
                            </Paper>
                        </Col>
                    </Row>
                </>
            )
        },
        {
            title: (
                <Badge
                    color={currentTab === 2 ? 'primary' : 'link'}
                    className={'px-3 py-2 text-capitalize'}
                    pill
                >
                    Trash
                </Badge>
            ),
            content: (
                <>
                    <Row>
                        <Col sm={12} className={'font-weight-bold'}>
                            <Button color='white' className={'text-dark font-weight-bold pl-0'}>
                                Items in the trash will be permanently deleted after 30 days
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Paper rounded className='mt-4' hidden={!projectsTrashStore.isFetched}>
                                <ProjectsTable tableType={'projectsTrash'} />
                            </Paper>
                        </Col>
                    </Row>
                </>
            )
        }
    ];

    const getTabs = () => {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index
        }));
    };

    return (
        <Layout>
            <>
                <PageTitle
                    heading='Your Projects'
                    icon='fas fa-folder-open icon-gradient bg-warm-flame'
                    subheading='Create your Machine Learning Projects'
                    showCloseButton={true}
                />

                <div className='app-inner-layout'>
                    <Tabs
                        tabsWrapperClass='project-tabs'
                        transform={false}
                        showInkBar={false}
                        onChange={toggleTabColor}
                        items={getTabs()}
                    />
                </div>

                {(((currentTab === 0 || currentTab === 1) && !projectsStore.isFetched) ||
                    (currentTab === 2 && !projectsTrashStore.isFetched)) && <DnaLoader />}

                {projectsStore.isFetched && <WelcomeVideo />}
            </>
        </Layout>
    );
});
