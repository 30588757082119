import React from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import HelpVideo from './AppHelpVideo';

interface Props {
    children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
    return (
        <>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        {children}
                    </div>
                    <div className="app-wrapper-footer">
                    </div>
                </div>
            </div>
            <HelpVideo/>
        </>
    );
};

export default Layout;