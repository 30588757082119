import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { DatePicker } from "react-widgets";
import { useStores } from "../../../../store";

const MonthPicker = observer(() => {
    const { detectionModelsStore } = useStores();
    const handleDateChange = async (date: Date | null | undefined) => {
        if (!date) {
            return;
        }

        detectionModelsStore.fetchTrainingReport({
            month: date.getMonth() + 1, year: date.getFullYear()
        });
    }

    useEffect(() => {
        detectionModelsStore.fetchTrainingReport({
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
        })
    }, [])
    return (
        <div className="d-flex align-items-center mb-3">
            <span className="font-weight-bold text-primary text-nowrap mr-3">Month</span>
            <DatePicker
                containerClassName="shadow-none"
                disabled={detectionModelsStore.trainingReport.isLoading}
                onChange={handleDateChange}
                inputProps={{readOnly: true}}
                defaultValue={new Date()}
                valueFormat={{ month: "long", year: "numeric" }}
                calendarProps={{ views: ["year", "decade"] }}
            />
        </div>
    )
})

export default MonthPicker
