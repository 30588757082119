import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import AdvancedSettings from '../AdvancedSettings';
import ProjectSettingsCarousel from './ProjectSettingsCarousel';
import Tabs, { TabItem } from '../../../components/Tabs';
import { useFormikContext } from 'formik';
import { ModelTrainingSettings, Project, ProjectType } from '../../../models/api';
import { SettingsPresets } from '../AdvancedSettings/dropdownSettingsData';
import ResetSettingsConfirmation from '../../../components/Modals/ResetSettingsConfirmation';

export interface Props {
    settings: ModelTrainingSettings;
    project: Project;
    onChange: (settings: ModelTrainingSettings) => void;
    activeTemplate: string;
    setActiveTemplate: (id: string) => void;
    activeTabId?: string;
    setActiveTabId: (id: string) => void;
    isResetting: boolean;
    setIsResetting: (value: boolean) => void;
}

const AdvancedSettingsForm: React.FC<Props> = (props) => {
    const {
        settings,
        project,
        onChange,
        activeTemplate,
        activeTabId,
        setActiveTabId,
        isResetting,
        setIsResetting
    } = props;
    const { values } = useFormikContext<ModelTrainingSettings>();
    const [viewConfirmation, setViewConfirmation] = useState(false);
    const [canChange, setCanChange] = useState<boolean>(true);

    const isClassificationProject = project.type === ProjectType.ObjectClassification;
    const isSegmentationProject = project.type === ProjectType.ObjectSegmentation;

    const handleTableChange = (tab: TabItem) => {
        if (!canChange) {
            setViewConfirmation(true);
            return;
        }
        setActiveTabId(tab.id);
    };

    const handleConfirm = () => {
        onChange({ ...settings, ...SettingsPresets.get(activeTemplate) });
        setViewConfirmation(false);
        setActiveTabId('pages-model-training-advanced-settings-form-tabs-1');
    };

    const handleCancel = () => {
        setViewConfirmation(false);
    };

    useEffect(() => {
        if (!isResetting) {
            return;
        }
        setCanChange(true);
        setIsResetting(false);
    }, [isResetting]);

    useEffect(() => {
        if (isClassificationProject) {
            return;
        }
        const isValuesEqual =
            JSON.stringify(values) === JSON.stringify(SettingsPresets.get(activeTemplate));
        setCanChange(isValuesEqual);
    }, [values, activeTemplate]);

    const tabs: TabItem[] = [
        {
            id: 'pages-model-training-advanced-settings-form-tabs-1',
            title: 'Recommended Applications',
            content: <ProjectSettingsCarousel {...props} />
        },
        {
            id: 'pages-model-training-advanced-settings-form-tabs-2',
            title: 'Custom',
            content: <AdvancedSettings {...props} />
        }
    ];

    return isClassificationProject || isSegmentationProject ? (
        <AdvancedSettings {...props} />
    ) : (
        <>
            <ResetSettingsConfirmation
                body={'Reset custom settings to defaults?'}
                isOpen={viewConfirmation}
                onConfirm={() => handleConfirm()}
                onCancel={() => handleCancel()}
            />
            <Form>
                <FormGroup>
                    <Tabs
                        items={tabs}
                        activeTabId={activeTabId}
                        className='app-components-tabs_theme_material'
                        onTabChange={handleTableChange}
                        canChange={canChange}
                        setActiveTabId={setActiveTabId}
                    />
                </FormGroup>
            </Form>
        </>
    );
};

export default AdvancedSettingsForm;
