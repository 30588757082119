import React, { FunctionComponent, useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import { loginPageConfigs } from './login-page.config';
import { Routes } from '../../router/routes';
import history from '../../router/history';
import {Auth} from '@aws-amplify/auth';
import { useStores } from '../../store';

type Props = RouteChildrenProps;

export const LoginPage: FunctionComponent<Props> = (props) => {
    const {userStore} = useStores()
    const path = props.location.pathname;

    useEffect(()=>{
        const logout = async () => {
            try{
                await Auth.signOut();
                userStore.clearUser();
                history.push(Routes.SignIn);
            }catch (e) {
                console.error(e);
            }
        }

        if(path === Routes.SignOut)
            logout();
    },[])

    if(path === Routes.SignOut)
        return null;

    const config = loginPageConfigs[path];
    return (
        <div className={'login-page bg-plum-plate'}>
            <div className={'modal-dialog login-panel'}>
                <div className={'modal-content'}>
                    <div className={'modal-header login-panel__header'}>
                        <div className={'app-logo login-panel__logo'} />
                        <span>Artificial Intelligence made easy</span>
                    </div>
                    <div className={'modal-body login-panel__body'}>{config.form}</div>
                    <div className={'modal-footer login-panel__footer'}>{config.footer}</div>
                </div>
            </div>

            <Copyright />
        </div>
    );
};