import { MenuItem } from '../config/sidebar';

export const sortByMenuItem = (arg: MenuItem[]): MenuItem[] => {
    const menuArray = arg.slice();
    return menuArray.sort((firstItem, secondItem) => {
        if (!firstItem.label || !secondItem.label) return 0;
        return firstItem.label
            .toString()
            .localeCompare(secondItem.label.toString(), undefined, { numeric: true });
    });
};

interface ItemName {
    id: string | null;
    name: string;
}

export const sortByItemName = (arg: ItemName[]): ItemName[] => {
    const menuArray = arg.slice();
    return menuArray.sort((firstItem, secondItem) =>
        firstItem.name
            .toString()
            .localeCompare(secondItem.name.toString(), undefined, { numeric: true })
    );
};
