import React, { FunctionComponent, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import DnaLoader from '../../../components/DnaLoader';
import Paper from '../../../components/Paper';
import PageTitle from '../../../components/PageTitle';
import UserListTable from './UserListTable';
import { User } from '../../../models/api';
import Layout from '../../../components/Layout';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import { createProgressToast } from '../../../components/Toasts/ProgressToast';
import { toast } from 'react-toastify';

const UserList: FunctionComponent = observer(() => {
    const { usersStore, userStore } = useStores();
    const history = useHistory();

    const handleDelete = (id: User['id']) => {
        usersStore.deleteItem(id);
    };

    const handleImpersonation = async (id: User['id']) => {
        const toastId = createProgressToast();
        await userStore.userImpersonation(id);
        history.push(Routes.Dashboard);
        toast.dismiss(toastId);
    };

    useEffect(() => {
        if (!usersStore.list.isFetched) {
            usersStore.fetchList();
        }
    }, [usersStore, usersStore.list.isFetched]);

    return (
        <Layout>
            <>
                <PageTitle
                    heading='Users'
                    icon='fas fa-users icon-gradient bg-warm-flame'
                    subheading='Add, edit and delete users. (Refresh page to refresh "Last Activity")'
                    showCloseButton={true}
                />

                {!usersStore.list.isFetched &&
                <DnaLoader />
                }

                <Row>
                    <Col sm={12}>
                        <Paper rounded hidden={!usersStore.list.isFetched}>
                            <UserListTable
                                items={usersStore.list.items}
                                isLoading={usersStore.isLoading}
                                onDelete={handleDelete}
                                onImpersonation={handleImpersonation}
                            />
                        </Paper>
                    </Col>
                </Row>
            </>
        </Layout>
    );
});

export default UserList;
