import React from 'react';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';

export const NotFoundPage: React.FC = () => {
    return (
        <Layout>
            <PageTitle
                heading='404'
                icon='fas fa-frown icon-gradient bg-warm-flame'
                subheading='The page you requested does not exist'
            />
        </Layout>
    );
};
