import React, { useState } from 'react';
import DropdownList from 'react-widgets/DropdownList';
import { Button, Col, Form, Row } from 'reactstrap';
import { Formik } from 'formik';

import {
    ApkConfig,
    DetectionModel,
    DetectionModelDownload,
    DetectionModelDownloadType,
    DetectionModelDownloadTypeUI,
    DetectionModelFrameworkType,
    LicenseTypeUI, ModelDownloadAppTemplateEnum, ModelDownloadAppTemplateEnumUI, ProjectType
} from '../../../models/api';
import { faCog, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptionComponentProp } from 'react-widgets/List';
import ListOption from 'react-widgets/ListOption';
import UpgradeLicense from '../../../components/Modals/UpgradeLicense';
import DetectionModelDropdownWithIcon from '../../../components/DetectionModel/DetectionModelDropdownWithIcon';
import AdvancedSettingsModal from '../AdvancedSettings/advanced-settings-modal';
import ApkSettingsModal from '../ApkSettings/apk-settings-modal';
import withDownloadDropdown from '../../../components/DetectionModel/DownloadDetectionModelDropdown';

const downloadTypeOptions = {
    TensorFlowDetection: [
        {
            value: DetectionModelDownloadType.ApkProDetection,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.ApkSourceCodeDetection,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.LabelMap,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.TFLiteFloat32,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.TFLiteFloat16,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.TFLite,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.FrozenGraphPBDetection,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.ONNXDetection,
            licenseTypeUI: LicenseTypeUI.pro,
        }
    ],
    TensorFlowV1Detection: [
        {
            value: DetectionModelDownloadType.FrozenGraphPBDetection,
            licenseTypeUI: LicenseTypeUI.pro,
        },
    ],
    TensorFlowClassification: [
        {
            value: DetectionModelDownloadType.ApkProClassification,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.ApkSourceCodeClassification,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.TFLite,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.LabelMap,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.FrozenGraphPBClassification,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.ONNXClassification,
            licenseTypeUI: LicenseTypeUI.pro,
        }
    ],
    PyTorch: [
        {
            value: DetectionModelDownloadType.PytorchModel,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.LabelMap,
            licenseTypeUI: LicenseTypeUI.pro,
        },
    ],
    PyTorch2: [
        {
            value: DetectionModelDownloadType.PytorchModel2,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.LabelMap,
            licenseTypeUI: LicenseTypeUI.pro,
        }
    ],
    PyTorchPoseDetection: [
        {
            value: DetectionModelDownloadType.PoseEstimationFree,
            licenseTypeUI: LicenseTypeUI.free,
        },
        {
            value: DetectionModelDownloadType.PoseEstimationPro,
            licenseTypeUI: LicenseTypeUI.pro,
        },
        {
            value: DetectionModelDownloadType.TFlite16PoseEstimation,
            licenseTypeUI: LicenseTypeUI.pro,
        },
    ],
    None: [
        {
            value: DetectionModelDownloadType.None,
            licenseTypeUI: LicenseTypeUI.free
        }
    ]
};

const odAppTemplatesData = [
    {
        value: ModelDownloadAppTemplateEnum.Continuous,
        text: ModelDownloadAppTemplateEnumUI.continuous
    },
    {
        value: ModelDownloadAppTemplateEnum.TakePhoto,
        text: ModelDownloadAppTemplateEnumUI.takePhoto
    },
    {
        value: ModelDownloadAppTemplateEnum.TakePhotoAndOCR,
        text: ModelDownloadAppTemplateEnumUI.takePhotoAndOCR
    },
]

const ocAppTemplatesData = [
    {
        value: ModelDownloadAppTemplateEnum.TakePhoto,
        text: ModelDownloadAppTemplateEnumUI.takePhoto
    }
]

const DefaultMaxDetections = 10;

const DownloadDropdown = withDownloadDropdown(DetectionModelDropdownWithIcon as any)

export interface ModelDownloadValues {
    type: DetectionModelDownload['type'];
    detectionModel?: DetectionModel;
    maxDetections: number;
    apkConfig: ApkConfig
}

interface ModelTypeOptionDataItem {
    value: DetectionModelDownloadType;
    licenseTypeUI: LicenseTypeUI;
}

interface Props {
    detectionModelList: DetectionModel[];
    onCreate: (values: ModelDownloadValues) => void;
    disabled: boolean;
    isLicenseTypePro: boolean;
    isObjectClassification: boolean;
    isObjectSegmentation: boolean;
    isObjectDetection: boolean;
    isPoseDetection: boolean;
}

const GeneratingPanel: React.FC<Props> = (
    {
        detectionModelList,
        onCreate,
        disabled,
        isLicenseTypePro,
        isObjectClassification,
        isObjectSegmentation,
        isObjectDetection,
        isPoseDetection,
    }
) => {
    const [upgradeLicenseModal, setUpgradeLicenseModal] = useState<boolean>(false);
    const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);
    const [showApkSettings, setShowApkSettings] = useState<boolean>(false);

    // Filter for Completed or Early Stopped only
    const filteredDetectionModelList = detectionModelList.sort((a, b) => {
        const firstDate = new Date(a.createdAt!);
        const secondDate = new Date(b.createdAt!);
        return secondDate.getTime() - firstDate.getTime();
    });

    const firstDetectionModel = filteredDetectionModelList[0];

    const isTfliteType = (type: DetectionModelDownloadType) => {
        const tfliteTypes = [DetectionModelDownloadType.TFLite, DetectionModelDownloadType.TFLiteFloat16, DetectionModelDownloadType.TFLiteFloat32];
        return tfliteTypes.includes(type);
    }

    const getDownloadTypeData = (detectionModel?: DetectionModel) => {
        let downloadTypeData: ModelTypeOptionDataItem[] = [];
        if (detectionModel) {
            const isTensorFlow = detectionModel.frameworkType === DetectionModelFrameworkType.TensorFlow;
            const isTF1 = detectionModel.frameworkType === DetectionModelFrameworkType.TensorFlowV1;
            const isPyTorch = detectionModel.frameworkType === DetectionModelFrameworkType.PyTorch;

            if (isTensorFlow && isObjectClassification && !isLicenseTypePro) {
                downloadTypeData = [...downloadTypeOptions.None, ...downloadTypeOptions.TensorFlowClassification];
            } else if (isTensorFlow && isObjectClassification) {
                downloadTypeData = downloadTypeOptions.TensorFlowClassification;
            } else if (isTensorFlow && isObjectDetection && !isLicenseTypePro) {
                downloadTypeData = [...downloadTypeOptions.None, ...downloadTypeOptions.TensorFlowDetection];
            } else if (isTensorFlow && isObjectDetection) {
                downloadTypeData = downloadTypeOptions.TensorFlowDetection;
            } else if (isTF1 && !isLicenseTypePro) {
                downloadTypeData = [...downloadTypeOptions.None, ...downloadTypeOptions.TensorFlowV1Detection];
            } else if (isTF1) {
                downloadTypeData = downloadTypeOptions.TensorFlowV1Detection;
            } else if (isPyTorch && isPoseDetection) {
                downloadTypeData = downloadTypeOptions.PyTorchPoseDetection;
            } else if (isPyTorch && isObjectSegmentation && !isLicenseTypePro) {
                downloadTypeData = [...downloadTypeOptions.None, ...downloadTypeOptions.PyTorch2];
            } else if (isPyTorch && isObjectSegmentation) {
                downloadTypeData = downloadTypeOptions.PyTorch2;
            } else if (isPyTorch && !isLicenseTypePro) {
                downloadTypeData = [...downloadTypeOptions.None, ...downloadTypeOptions.PyTorch];
            } else if (isPyTorch) {
                downloadTypeData = downloadTypeOptions.PyTorch;
            }
        }
        return downloadTypeData.map(type => ({
            ...type,
            text: DetectionModelDownloadTypeUI[type.value]
        }));
    }

    const handleDownloadTypeChange = (setFieldValue: (field: string, value: any) => void) => (
        (dataItem: ModelTypeOptionDataItem) => {
            if (dataItem.licenseTypeUI === LicenseTypeUI.pro && !isLicenseTypePro) {
                setUpgradeLicenseModal(true);
                return;
            }

            setFieldValue('type', dataItem.value);
        }
    );

    const handleUpgradeLicenseModalClose = () => setUpgradeLicenseModal(false);

    const handleAdvancedSettings = (type: DetectionModelDownloadType) => () => {
        ((isObjectDetection && type === DetectionModelDownloadType.ApkProDetection) ||
        (isObjectClassification && type === DetectionModelDownloadType.ApkProClassification)) &&
            setShowApkSettings((prev) => !prev);
                                           
        isTfliteType(type) && setShowAdvancedSettings(prev => !prev);
    };

    const handleApkSettings = () => {
        setShowApkSettings(prev => !prev);
    };

    const handleAdvancedSettingsDone = (setFieldValue: (field: string, value: any) => void, type: DetectionModelDownloadType) => (
        (dataItem: number) => {
            setFieldValue('maxDetections', dataItem);
            handleAdvancedSettings(type)();
        }
    );

    const ModelTypeOption: OptionComponentProp = ({ dataItem, selected, ...rest }) => {
        const { text, licenseTypeUI } = dataItem;

        return (
                <ListOption
                    selected={selected}
                    dataItem={dataItem}
                    {...rest}
                >
                    {(licenseTypeUI === LicenseTypeUI.pro && !isLicenseTypePro) &&
                    <FontAwesomeIcon
                        icon={faLock}
                        className={`mr-2${selected ? ' text-white' : ' text-secondary'}`}
                    />
                    }
                    <span>{text}</span>
                </ListOption>
        );
    }

    return (
        <>
            <Formik<ModelDownloadValues>
                initialValues={{
                    detectionModel: firstDetectionModel,
                    type: getDownloadTypeData(firstDetectionModel)[0]?.value,
                    maxDetections: DefaultMaxDetections,
                    apkConfig: {
                        appTemplate: isObjectDetection ? odAppTemplatesData[0].value : ocAppTemplatesData[0].value,
                        ocrField: undefined
                    }
                }}
                onSubmit={onCreate}
                enableReinitialize
            >
                {({ values, handleSubmit, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                {!isPoseDetection &&
                                    <Col md={4}>
                                        <div className='d-flex align-items-center he-100'>
                                            <div className='font-weight-bold text-primary mr-2'>
                                                Model:
                                            </div>
                                            <DownloadDropdown
                                                detectionModelList={filteredDetectionModelList}
                                                dataKey='value'
                                                textField='text'
                                                value={values.detectionModel?.id}
                                                filter={false}
                                                onChange={option => {
                                                    setFieldValue('detectionModel', option.model);
                                                    if (option.model.frameworkType !== values.detectionModel?.frameworkType) {
                                                        let newType;
                                                        if (option.model.frameworkType === DetectionModelFrameworkType.PyTorch) {
                                                            newType = isLicenseTypePro ? DetectionModelDownloadType.PytorchModel : DetectionModelDownloadType.None;
                                                        } else if (option.model.frameworkType === DetectionModelFrameworkType.TensorFlowV1)  {
                                                            newType = isLicenseTypePro ? DetectionModelDownloadType.FrozenGraphPBDetection : DetectionModelDownloadType.None;
                                                        } else if (isObjectClassification) {
                                                            newType = isLicenseTypePro ? DetectionModelDownloadType.TFLite : DetectionModelDownloadType.None;
                                                        } else {
                                                            newType = isLicenseTypePro ? DetectionModelDownloadType.ApkProDetection : DetectionModelDownloadType.None;
                                                        }
                                                        setFieldValue('type', newType);
                                                    }
                                                }}
                                            />
                                        </div>
                            </Col>
                                }
                                <Col md={6}>
                                    <div className='d-flex align-items-center he-100'>
                                        <div className='font-weight-bold text-primary flex-shrink-0 mr-2'>
                                            Download Type:
                                        </div>

                                        <Col md={7}>
                                            <DropdownList
                                                data={getDownloadTypeData(values.detectionModel)}
                                                dataKey='value'
                                                textField='text'
                                                value={values.detectionModel ? values.type : ''}
                                                filter={false}
                                                onChange={handleDownloadTypeChange(setFieldValue)}
                                                optionComponent={ModelTypeOption}
                                                groupBy={!isLicenseTypePro ? (item => item.licenseTypeUI) : undefined}
                                            />
                                        </Col>
                                        <Button
                                            color='light'
                                            onClick={handleAdvancedSettings(values.type)}
                                            hidden={
                                                !isTfliteType(values.type) && 
                                                !((isObjectDetection && values.type === DetectionModelDownloadType.ApkProDetection) ||
                                                (isObjectClassification && values.type === DetectionModelDownloadType.ApkProClassification)) 
                                            }
                                            className='ml-2'
                                        >
                                            <FontAwesomeIcon icon={faCog} />
                                        </Button>
                                        <Button
                                            color='primary'
                                            className='ml-3 flex-shrink-0'
                                            disabled={disabled || !values.detectionModel || values.type === DetectionModelDownloadType.None}
                                            type='submit'
                                        >
                                            Generate
                                        </Button>
                                    </div>
                                </Col>
                                {/* FSAI-273 */}
                                {/* <Col md={3}>
                                <div className='d-flex align-items-center justify-content-end he-100'>
                                    <span className='font-weight-bold'>
                                        Limits:
                                    </span>

                                    <span>
                                        <span className='text-danger font-size-md ml-4'>2</span> / 2 servers
                                    </span>
                                </div>
                            </Col> */}
                            </Row>
                            <AdvancedSettingsModal
                                showAdvancedSettings={showAdvancedSettings}
                                handleAdvancedSettings={handleAdvancedSettingsDone(setFieldValue, values.type)}
                                defaultMaxDetections={DefaultMaxDetections}
                            />
                            <ApkSettingsModal 
                                showApkSettings={showApkSettings} 
                                toggleAdvancedSettings={handleApkSettings}
                                appTemplatesData={isObjectDetection ? odAppTemplatesData : ocAppTemplatesData}
                            />
                        </Form>
                    );
                }}
            </Formik>
            <UpgradeLicense
                isOpen={upgradeLicenseModal}
                onClosed={handleUpgradeLicenseModalClose}
                onAccept={handleUpgradeLicenseModalClose}
            />
        </>
    );
};

export default GeneratingPanel;
