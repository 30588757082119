import React from "react";
import { Button } from "reactstrap";
import { useStores } from "../../../store";


export const PredictiveMaintenanceModelTrainingDownloadPanel = () => {
    const { predictiveMaintenanceModelTrainingStore } = useStores();

    const onDownloadCsvTemplate = () => {
        predictiveMaintenanceModelTrainingStore.getTemplateCSVFile();
    }

    return (
        <>
            <div className='font-weight-bold text-primary flex-shrink-0 mr-3'>
                Download: 
            </div>
            <div className="d-flex align-items-center flex-grow-1">
                <div className="mr-3">
                    <Button
                        className="btn mr-1 mr-lg-2 text-nowrap pl-5 pr-5"
                        color='primary'
                        id={'pages-dataset_open-modal-button'}
                        onClick={onDownloadCsvTemplate}
                    > CSV Template
                    </Button>
                </div>
            </div>
        </>
    );
}
