import React, { useState } from 'react';
import * as Yup from 'yup';
import { DEFAULT_LABEL_COLOR, Label, LabelUpdate } from '../../../../models/api';
import { useFormik } from 'formik';
import { Form, Input, Label as FormLabel, ModalProps } from 'reactstrap';
import DropdownColorPicker from '../../../../components/DropdownColorPicker';

interface Props extends ModalProps {
    label: Label | undefined;
    onConfirm: (labelUpdate: LabelUpdate) => void;
}

export const EditLabelForm: React.FC<Props> = (props) => {
    const { formId, label, onConfirm } = props;
    const [labelColorDropDown, setLabelColorDropDown] = useState<boolean>(false);

    const handleColorDropDownToggle = () => {
        setLabelColorDropDown(!labelColorDropDown);
    };

    const handleChangeColor = (color: string) => {
        formik.setFieldValue('color', color)
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Label name cannot be empty')
            .trim(),
    });
    const formik = useFormik<LabelUpdate>({
        initialValues: {
            id: label?.id ?? '',
            name: label?.name ?? '',
            color: label?.color ?? DEFAULT_LABEL_COLOR
        },
        validationSchema,
        onSubmit: (values) => {
            onConfirm(values);
        },
    });

    return (
        <Form
            onSubmit={formik.handleSubmit}
            id={formId}
            className="d-flex flex-column align-items-start py-4"
        >
            <div className='d-flex justify-content-between align-items-center w-100'>
                <FormLabel for="name" className="mb-0 w-50">
                    Name:
                </FormLabel>
                <div className='w-50'>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        className={`${
                            formik.errors.name ?
                            'is-invalid' : ''
                        }`}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    />
                    {formik.errors.name && (
                        <span className='text-danger'>
                            {formik.errors.name}
                        </span>
                    )}
                </div>
            </div>
            <div className='d-flex justify-content-between align-items-center w-100 mt-2'>
                <FormLabel for="name" className="mb-0 w-50">
                    Color:
                </FormLabel>
                <DropdownColorPicker
                    isOpen={labelColorDropDown}
                    toggle={handleColorDropDownToggle}
                    handleChange={handleChangeColor}
                    color={formik.values.color}
                    className='w-50'
                    toggleClassName='p-0'
                />
            </div>
        </Form>
    );
};
