import { makeAutoObservable, runInAction } from 'mobx';
import container from '../../container/container';
import { DatasetImage, PolygonAnnotation } from '../../models/api';
import { asyncDebounce } from '../../utils/debounce';
import { RootStore } from './root';
import { toast } from 'react-toastify';
import { CustomEventType } from '../../models/events';

const api = container.apiClient;

const debouncedSync = asyncDebounce((id: DatasetImage['id'], list: PolygonAnnotation[], ctx: PolygonAnnotationStore) => {
    ctx.queueLength++;
    return api.polygonAnnotationListSync(id, list);
}, 300);

export class PolygonAnnotationStore {
    isLoading: boolean = false;
    isFetched: boolean = false;
    error?: string;

    queueLength: number = 0;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async syncList(list: PolygonAnnotation[]) {
        const annotatedImage = this.rootStore.datasetImageStore.annotated;

        //TODO find solution for check and waiting
        if (!annotatedImage) {
            return;
        }

        const projectId = this.rootStore.projectsStore.current!.id;
        const projectName = this.rootStore.projectsStore.current!.name;

        try {
            this.isLoading = true;
            this.error = undefined;
                           
            const listWithoutRelations = list.map(({label, isPolygonComplete, flattenedPoints, color, ...props}) => props);
            const response = await debouncedSync(annotatedImage.id, listWithoutRelations, this);
            const annotationAndClassificationCount = response?.data.length + (annotatedImage.classifications?.length || 0);

            runInAction(() => {
                this.rootStore.datasetImageStore.images[annotatedImage.hashedName] = {
                    ...annotatedImage,
                    polygonAnnotations: response?.data,
                    annotationAndClassificationCount
                };

                if (response?.data.length > 0) {
                    this.rootStore.eventsStore.addItem(CustomEventType.Annotate, {
                        datasetImageId: annotatedImage.id,
                        datasetImageHash: annotatedImage.hashedName,
                        labelId: response.data[0].label!.id,
                        labelName: response.data[0].label!.name,
                        projectId,
                        projectName
                    });
                }
            });

        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
            this.error = e.message;
        } finally {
            runInAction(() => {
                this.queueLength--;
                if (!this.queueLength) {
                    this.isLoading = false;
                }
            });
        }
    }
}
