import React, { ReactText, useEffect } from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { toast } from 'react-toastify';
import { useStores } from '../../../store';
import { observer } from 'mobx-react-lite';
import CircularProgress from '../../../components/CircularProgress';
import { DatasetExportType, ProjectType } from '../../../models/api';

const ExportButton: React.FC = () => {
    const { 
        imageToZipStore,
        datasetImageStore,
        annotationsStore, 
        projectsStore,
        userStore
    } = useStores();

    const {isDataZipped, inProgress, isError} = imageToZipStore;

    const {state: {deleting}} = datasetImageStore;
    const {isLoading} = annotationsStore;
    const toastId = React.useRef<ReactText | null>(null);

    const project = projectsStore.current!;
    const isObjectDetection = project.type === ProjectType.ObjectDetection;
    const isObjectClassification = project.type === ProjectType.ObjectClassification;

    const isVOCButton = (project.isMultiLabel && isObjectClassification) || isObjectDetection;

    const isButtonDisabled =
        inProgress ||
        datasetImageStore.hasUnprocessedItems ||
        !!Object.keys(deleting).length ||
        isLoading

    useEffect(() => {
        return () => {
            toastId.current && toast.dismiss(toastId.current);
        };
    }, []);

    useEffect(() => {
        if (toastId.current && (isError || (isDataZipped && !inProgress)))
            toast.dismiss(toastId.current);
        inProgress && createToast();
    }, [inProgress, isDataZipped, isError]);

    const createToast = () => {
        toastId.current = toast.info(
            <div className='d-flex align-items-center'>
                <span><CircularProgress color={'light'}/></span>
                <span className="ml-2">Please wait a moment, we are preparing the archive for you...</span>
            </div>,
            {
                autoClose: false
            }
        );
    };

    const downloadImage = (path: DatasetExportType) => () => {
        if (isObjectDetection) {
            const userEmail = userStore.user?.email ?? 'N/A'
            datasetImageStore.exportDataset(project.id, path, userEmail)
        } else {
            imageToZipStore.zip(path);
        }
    };

    return (
        <UncontrolledDropdown>
            <DropdownToggle
                caret={true}
                color='primary'
                className='btn-exp pl-3'
                disabled={isButtonDisabled}
                style={{ height: '35px' }}
            >
                Export
            </DropdownToggle>
            <DropdownMenu
                right={true}
                className='btn-exp-dr-body mt-1'
                style={{ width: '13rem' }}
            >
                <DropdownItem onClick={downloadImage(DatasetExportType.Images)} className='pl-3'>
                    Images only
                </DropdownItem>
                {isVOCButton &&
                    <DropdownItem onClick={downloadImage(DatasetExportType.VOC)} className='pl-3'>
                        Annotated images (VOC)
                    </DropdownItem>
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    );

};

export default observer(ExportButton);
