import { RootStore } from './root';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import container from '../../container/container';
import { Annotation } from '../../models/api';
import { s3Util } from '../../utils/s3';
import { Storage } from 'aws-amplify';
import { AccessLevel } from '@aws-amplify/ui-components';
import * as cv from '@techstark/opencv-js';

const api = container.apiClient;

export class AnnotationsEditorStore {
    annotationsForLabel: Record<string, Annotation[]> = {};
    annotationsForLabelLoading: Record<string, boolean> = {};
    annotationsForLabelFetched: Record<string, boolean> = {};
    cachedImages: Record<string, Promise<HTMLImageElement>> = {};
    cachedCrops: Record<string, string> = {};

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    overwriteLabel(labelId: string) {
        this.annotationsForLabelFetched = {};
        this.annotationsForLabel[labelId] = [];
    }

    async fetchAnnotationsForLabel(
        labelId: string,
        offsetIndex: number,
        overwrite: boolean = false
    ) {
        if (overwrite) {
            this.overwriteLabel(labelId);
        }

        const batchKey = this.generateAnnotationsBatchKey(labelId, offsetIndex);
        if (!batchKey || this.annotationsForLabelFetched[batchKey]) {
            return;
        }
        try {
            this.annotationsForLabelLoading[labelId] = true;
            const { data } = await api.annotationsForLabel(labelId, offsetIndex);
            runInAction(() => {
                this.annotationsForLabel[labelId] = [
                    ...(this.annotationsForLabel[labelId] ?? []),
                    ...data
                ];

                this.annotationsForLabelFetched[batchKey] = true;
                this.generateCachedCrops(data);
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.annotationsForLabelLoading[labelId] = false;
            });
        }
    }

    private generateAnnotationsBatchKey(labelId: string, offsetIndex: number) {
        const projectId = this.rootStore.projectsStore.current?.id;
        if (!projectId) {
            return '';
        }
        return `${projectId}-${labelId}-${offsetIndex}`;
    }

    private async generateCachedCrops(annotationsForLabel: Annotation[]) {
        const identityId = this.rootStore.userStore.getIdentityIdSync();
        if (!identityId) {
            return;
        }
        for (const annotation of annotationsForLabel) {
            if (
                !annotation.datasetImage ||
                annotation.datasetImage.hashedName in this.cachedImages
            ) {
                continue;
            }
            const [imageW, imageH] = [
                annotation.datasetImage.dimensionX,
                annotation.datasetImage.dimensionY
            ];
            const s3Path = s3Util.getDatasetImageKey(annotation.datasetImage);
            this.cachedImages[annotation.datasetImage.hashedName] = new Promise<HTMLImageElement>(
                async (resolve) => {
                    let imageBlobResponse;
                    try {
                        imageBlobResponse = await Storage.get(s3Path, {
                            level: AccessLevel.Private,
                            identityId,
                            download: true
                        });
                    } catch (e) {
                        return;
                    }
                    if (!imageBlobResponse) {
                        return;
                    }
                    const imageBlob = (imageBlobResponse as { Body: Blob }).Body;
                    if (!imageBlob) {
                        return;
                    }
                    const imageBlobUrl = (URL ?? webkitURL).createObjectURL(imageBlob);
                    const imgElement = new Image(imageW, imageH);
                    imgElement.src = imageBlobUrl;
                    await new Promise((r) => (imgElement.onload = r));
                    resolve(imgElement);
                }
            );
        }

        const batchSize = 25;
        for (let i = 0; i < annotationsForLabel.length; i += batchSize) {
            const batch = annotationsForLabel.slice(
                i,
                Math.min(i + batchSize, annotationsForLabel.length)
            );
            await Promise.all(
                batch.map(async (annotation) => {
                    if (
                        !annotation.datasetImage ||
                        !this.cachedImages[annotation.datasetImage.hashedName]
                    ) {
                        return;
                    }
                    const [x, y, w, h] = [
                        annotation.x,
                        annotation.y,
                        annotation.width,
                        annotation.height
                    ];
                    let imageCv: cv.Mat | undefined;
                    let croppedImageCv: cv.Mat | undefined;
                    try {
                        const imageElement = await this.cachedImages[
                            annotation.datasetImage.hashedName
                        ];
                        imageCv = cv.imread(imageElement);
                        croppedImageCv = imageCv.roi(new cv.Rect(x, y, w, h));
                    } catch (e) {
                        croppedImageCv = this.handleROIOutOfBounds(annotation, imageCv);
                    } finally {
                        imageCv?.delete();
                    }
                    if (!croppedImageCv) {
                        return;
                    }
                    const tempCanvas = document.createElement('canvas');
                    tempCanvas.width = w;
                    tempCanvas.height = h;
                    cv.imshow(tempCanvas, croppedImageCv);
                    const croppedImageBlob = await new Promise<Blob | null>((r) =>
                        tempCanvas.toBlob(r)
                    );
                    croppedImageCv?.delete();
                    tempCanvas.remove();
                    if (!croppedImageBlob) {
                        return;
                    }
                    const croppedImageBlobUrl = (URL ?? webkitURL).createObjectURL(
                        croppedImageBlob
                    );
                    this.cachedCrops[annotation.id] = croppedImageBlobUrl;
                })
            );
        }
    }

    private handleROIOutOfBounds(annotation: Annotation, imageCv: cv.Mat | undefined) {
        try {
            if (!annotation.datasetImage) {
                return;
            }

            const [x, y, w, h] = [annotation.x, annotation.y, annotation.width, annotation.height];
            // Limit x and y to exclude negative values
            const x1 = Math.max(0, x);
            const y1 = Math.max(0, y);

            let w1 = w;
            let h1 = h;

            /* If annotation width/height is slightly out of image bounds
             * make width = max distance between right side of the image and annotation's x
             * OR
             * height = max distance between bottom side of the image and annotation's y
             */
            if (x1 + w1 > annotation.datasetImage.dimensionX) {
                w1 = annotation.datasetImage.dimensionX - x1;
            }

            if (y1 + h1 > annotation.datasetImage.dimensionY) {
                h1 = annotation.datasetImage.dimensionY - y1;
            }

            return imageCv?.roi(new cv.Rect(x1, y1, w1, h1));
        } catch (e) {
            this.cachedCrops[annotation.id] = 'error';
            return;
        }
    }

    async changeLabelForAnnotation(annotationId: string, newLabelId: string, oldLabelId: string) {
        try {
            const { data } = await api.changeAnnotationLabel(annotationId, newLabelId);
            if (data) {
                runInAction(() => {
                    this.annotationsForLabel[oldLabelId] = this.annotationsForLabel[
                        oldLabelId
                    ]?.filter(({ id }) => id !== annotationId);
                    if (this.annotationsForLabel[newLabelId]?.length) {
                        this.annotationsForLabel[newLabelId] = [
                            data,
                            ...this.annotationsForLabel[newLabelId]
                        ];
                    }
                    this.rootStore.labelsStore.updateLabelAnnotationCount(oldLabelId, -1);
                    this.rootStore.labelsStore.updateLabelAnnotationCount(newLabelId, 1);
                });
            }
            return !!data;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    filterAnnotation(labelId: string, annotationId: string) {
        const annotationsForLabel = this.annotationsForLabel[labelId];
        if (annotationsForLabel) {
            this.annotationsForLabel[labelId] = annotationsForLabel.filter(
                (annonation) => annonation.id !== annotationId
            );
        }

        this.rootStore.labelsStore.updateLabelAnnotationCount(labelId, -1);
    }

    addNewAnnotation(labelId: string, annotation: Annotation) {
        this.annotationsForLabel[labelId] = [...this.annotationsForLabel[labelId], annotation];
    }
}
