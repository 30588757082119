import React, { Fragment } from 'react';
import { NavLink as RouterNavLink} from 'react-router-dom';
import {
    DropdownToggle,
    DropdownMenu,
    UncontrolledButtonDropdown,
    Button,
    Nav,
    NavItem,
    Badge,
    NavLink,
} from 'reactstrap';

import {
    faUnlock,
    faSignOutAlt,
    faCommentAlt,
    faClipboard,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Routes } from '../../../../router/routes';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../store';
import ProfileImage from '../../../../pages/user-profile/ProfileImage';
import { LicenseType } from '../../../../models/api';
import { getFormattedLicenseExpiry } from '../../../../utils/dateTime';

interface Props{
    toggle: ()=>void
    toggleUpgradeLicense: () => void
}

export const UserBox:React.FC<Props> = observer((props) => {
    const {userStore} = useStores();

    const isUserPro = userStore.user?.licenseType === LicenseType.Pro;

    const [licenseExpiry, daysToExpiry, minutesToExpiry] = getFormattedLicenseExpiry(
        userStore.user?.licenseExpiry
    );

    const isLicenseExpired = minutesToExpiry < 0;

    return (
        <Fragment>
            <div className="header-btn-lg p-0 mr-2">
                <h6 className='m-0' style={{display: 'inline'}}>
                    <Badge color={isUserPro ? 'warning' : 'primary'} className={`mr-3 ${isUserPro && 'pro-color'}`}>
                        {userStore.user?.licenseType}
                    </Badge>
                </h6>
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle
                                    color="link"
                                    className="p-0"
                                >
                                    <ProfileImage avatarUrl={userStore.avatar} className={'user-box__avatar'}/>
                                </DropdownToggle>
                                <DropdownMenu
                                    right
                                    className="rm-pointers dropdown-menu-xl mt-1"
                                >
                                    <div className={`dropdown-menu-header bg-light ${isUserPro && 'pro-color'}`}>
                                        <div className="dropdown-menu-header-inner">
                                            <div className="menu-header-image opacity-2" />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <ProfileImage avatarUrl={userStore.avatar}/>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                {userStore.fullName}
                                                            </div>
                                                            <div className="widget-subheading opacity-8">
                                                                {userStore.user?.email}
                                                            </div>
                                                            {isUserPro && (
                                                                <>
                                                                    <div className="widget-heading mt-2">
                                                                        PROFESSIONAL License
                                                                    </div>
                                                                    {licenseExpiry && (
                                                                        <>
                                                                            <div className="widget-subheading opacity-8">
                                                                                Valid until: {licenseExpiry}
                                                                            </div>
                                                                            <div className="widget-subheading opacity-8">
                                                                                Days left: {daysToExpiry}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {isLicenseExpired && (
                                                                <div className="widget-subheading opacity-6 mt-1">
                                                                    Professional License has expired
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="widget-content-right mr-2">
                                                            <RouterNavLink to={Routes.UserProfile}>
                                                                <Button color="primary">
                                                                    Profile
                                                                </Button>
                                                            </RouterNavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Nav vertical>
                                        <NavItem>
                                            {
                                                !isUserPro &&
                                                <NavLink href="#" onClick={props.toggleUpgradeLicense}>
                                                    {/* TODO */}
                                                    <FontAwesomeIcon
                                                        icon={faUnlock}
                                                        className="mr-2"
                                                    />
                                                    Upgrade to PRO
                                                </NavLink>
                                            }
                                            {
                                                isUserPro &&
                                                <RouterNavLink to={Routes.MyAccount} className={"nav-link"}>
                                                    <FontAwesomeIcon
                                                        icon={faClipboard}
                                                        className="mr-2"
                                                    />
                                                    My Account
                                                </RouterNavLink>
                                            }
                                            <NavLink href="#" onClick={props.toggle}>
                                                <FontAwesomeIcon
                                                    icon={faCommentAlt}
                                                    className="mr-2"
                                                />
                                                Leave Feedback
                                            </NavLink>
                                            <NavLink href={Routes.SignOut}>
                                                <FontAwesomeIcon
                                                    icon={faSignOutAlt}
                                                    className="mr-2"
                                                />
                                                Logout
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});
