export const paths = {
    projects: 'projects',
    projectGroups: 'projectGroups',
    projectsTrash: 'projects-trash',
    labels: 'labels',
    datasetImage: 'dataset-images',
    annotations: 'annotations',
    detectionModels: 'detection-models',
    detectionModelDownloads: 'detection-model-downloads',
    users: 'users',
    detectionModelSimulations: 'detection-model-simulations',
    detectionModelGenerators: 'detection-model-generators',
    feedback: 'feedback',
    helpVideo: 'help',
    zipDownload: 'dataset-zip-download',
    license: 'license',
    classifications: 'classifications',
    statistics: 'statistics',
    track: 'track',
    events: 'events',
    fileUploads: 'file-uploads',
    deviceLicenses: 'device-licenses',
    predictiveMaintenance: 'predictive-maintenance',
    polygonAnnotations: 'polygon-annotations'
};

const apiRoutes = {
    projects: {
        list: `/${paths.projects}`,
        templatesList: `/${paths.projects}/templates`,
        item: (id: string) => `/${paths.projects}/${id}`,
        clone: (id: string) => `/${paths.projects}/${id}/clone`,
        canSwitchMultiLabel: (id: string) => `/${paths.projects}/${id}/can-switch-multi-label`
    },
    projectGroups: {
        list: `/${paths.projectGroups}`,
        item: (groupId: string) => `/${paths.projectGroups}/${groupId}`
    },
    projectsTrash: {
        list: `/${paths.projectsTrash}`,
        item: (id: string) => `/${paths.projectsTrash}/${id}`
    },
    datasetImages: {
        list: (projectId: string) => `/${paths.projects}/${projectId}/${paths.datasetImage}`,
        item: (id: string) => `/${paths.datasetImage}/${id}`,
        import: (projectId: string) => `/${paths.projects}/${projectId}/${paths.datasetImage}/dataset-import`,
        export: (projectId: string) => `/${paths.projects}/${projectId}/${paths.datasetImage}/dataset-export`,
    },
    labels: {
        list: (projectId: string) => `/${paths.projects}/${projectId}/${paths.labels}`,
        item: (id: string) => `/${paths.labels}/${id}`
    },
    annotations: {
        list: (datasetImageId: string) =>
            `/${paths.datasetImage}/${datasetImageId}/${paths.annotations}`,
        item: (id: string) => `/${paths.annotations}/${id}`,
        byLabel: (labelId: string) => `/${paths.annotations}/by-label/${labelId}`,
        changeLabel: `/${paths.annotations}/change-label`,
    },
    detectionModels: {
        list: (projectId: string) => `/${paths.projects}/${projectId}/${paths.detectionModels}`,
        runningTrainings: `/${paths.detectionModels}/running`,
        trainingReport: `/${paths.detectionModels}/training-report`,
        item: (id: string) => `/${paths.detectionModels}/${id}`
    },
    detectionModelDownloads: {
        list: `/${paths.detectionModelDownloads}`,
        shallowList: (detectionModelId: string) =>
            `/${paths.detectionModels}/${detectionModelId}/${paths.detectionModelDownloads}`,
        item: (id: string) => `/${paths.detectionModelDownloads}/${id}`,
        download: `/${paths.detectionModelDownloads}/download`
    },
    users: {
        list: `/${paths.users}`,
        item: (id: string) => `/${paths.users}/${id}`
    },
    detectionModelSimulations: {
        list: `/${paths.detectionModelSimulations}`,
        shallowList: (detectionModelId: string) =>
            `/${paths.detectionModels}/${detectionModelId}/${paths.detectionModelSimulations}`,
        item: (id: string) => `/${paths.detectionModelSimulations}/${id}`,
        runningList: `/${paths.detectionModelSimulations}/running`
    },
    detectionModelGenerators: {
        list: `/${paths.detectionModelGenerators}`,
        item: (id: string) => `/${paths.detectionModelGenerators}/${id}`,
        cancel: (id: string) => `/${paths.detectionModelGenerators}/${id}/cancel`,
        runningList: `/${paths.detectionModelGenerators}/running`
    },
    feedback: {
        list: `/${paths.feedback}`,
        item: (id: string) => `/${paths.feedback}/${id}`
    },
    helpVideo: {
        list: `/${paths.helpVideo}`,
        item: (id: string) => `/${paths.helpVideo}/${id}`
    },
    datasetZipDownload: {
        list: (path: string) => `/${paths.zipDownload}/${path}`,
        item: (id: string) => `/${paths.zipDownload}/${id}`
    },
    license: {
        upgradeLicense: `/${paths.license}/upgrade`
    },
    statistics: {
        common: `/${paths.statistics}`,
        project: (id: string) => `/${paths.statistics}/projects/${id}`
    },
    classifications: {
        list: `/${paths.classifications}`,
        shallowList: (datasetImageId: string) =>
            `/${paths.datasetImage}/${datasetImageId}/${paths.classifications}`,
        item: (id: string) => `/${paths.classifications}/${id}`
    },
    track: {
        login: `/${paths.track}/login`,
        logout: `/${paths.track}/logout`,
        openProject: `/${paths.track}/open-project`
    },
    events: {
        list: `/${paths.events}`,
        allEventsList: `/${paths.events}/${paths.users}/${paths.events}`
    },
    fileUploads: {
        item: (id: string) => `/${paths.fileUploads}/${id}`
    },
    deviceLicenses: {
        list: `/${paths.deviceLicenses}`,
        item: (id: string) => `/${paths.deviceLicenses}/${id}`
    },
    predictiveMaintenance: {
        list: `/${paths.predictiveMaintenance}`,
        item: (id: string) => `/${paths.predictiveMaintenance}/${id}`
    },
    polygonAnnotations: {
        list: (datasetImageId: string) =>
            `/${paths.datasetImage}/${datasetImageId}/${paths.polygonAnnotations}`,
        item: (id: string) => `/${paths.polygonAnnotations}/${id}`
    }
};

export default apiRoutes;
