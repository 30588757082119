import React from 'react';
import { Col, Row } from 'reactstrap';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import ProfileImage from './ProfileImage';
import { ProfileInfoForm } from './ProfileInfoForm';
import { ChangePasswordForm } from './ChangePasswordForm';
import { EmailPreferencesForm } from './EmailPreferencesForm';
import Paper from '../../components/Paper';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store';
import LicenseTypeForm from './LicenseTypeForm';
import { Redirect } from 'react-router';
import { Routes } from '../../router/routes';
import { User } from '../../models/api';

export const UserProfilePage: React.FC = observer(() => {
    const { userStore } = useStores();

    const handleUserUpdate = (user: User) => {
        userStore.updateUser(user);
    }

    return (
        <Layout>
            {userStore.user ?
                <>
                    <PageTitle
                        heading={userStore.fullName}
                        iconComponent={
                            <ProfileImage avatarUrl={userStore.avatar} />
                        }
                        subheading={userStore.user.email}
                    />

                    <Paper rounded className='p-4'>
                        <Row>
                            <Col>
                                <h6 className='font-weight-bold text-primary text-uppercase mb-4'>
                                    Profile Info
                                </h6>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <ProfileInfoForm
                                    user={userStore.user}
                                    onSave={handleUserUpdate}
                                    onUploadAvatar={userStore.uploadAvatar}
                                    avatarUrl={userStore.avatar}
                                />
                            </Col>
                        </Row>
                    </Paper>

                    <Paper rounded className='p-4 mt-4'>
                        <Row>
                            <Col>
                                <h6 className='font-weight-bold text-primary text-uppercase mb-4'>
                                    License type
                                </h6>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <LicenseTypeForm
                                    user={userStore.user}
                                    onSave={handleUserUpdate}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    </Paper>

                    <Paper rounded className='p-4 mt-4'>
                        <Row>
                            <Col>
                                <h6 className='font-weight-bold text-primary text-uppercase mb-4'>
                                    Change password
                                </h6>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <ChangePasswordForm />
                            </Col>
                        </Row>
                    </Paper>

                    <Paper rounded className='p-4 mt-4'>
                        <Row>
                            <Col>
                                <h6 className='font-weight-bold text-primary text-uppercase mb-4'>
                                    Email preferences
                                </h6>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <EmailPreferencesForm
                                    user={userStore.user}
                                    onSave={handleUserUpdate}
                                />
                            </Col>
                        </Row>
                    </Paper>
                </> :
                <Redirect to={Routes.SignIn} />
            }
        </Layout>
    );
});
