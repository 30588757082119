import { Badge } from 'reactstrap';
import CircularProgress from '../CircularProgress';
import React from 'react';

interface Props {
    isLoading?: boolean;
    error?: string;
}

const AnnotateStatusBadge: React.FC<Props> = (
    {
        isLoading,
        error
    }
) => {
    return (
        <div className='d-flex align-items-center w-100'>
            {isLoading ?
                <Badge pill color='warning'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <CircularProgress color='dark' />
                        <span className='ml-2'>
                            Saving
                        </span>
                    </div>
                </Badge>
                :
                error ?
                    <Badge pill color='danger'>
                        Error
                    </Badge>
                    :
                    <Badge pill color='success'>
                        Annotations saved
                    </Badge>
            }
        </div>
    );
};

export default AnnotateStatusBadge;
