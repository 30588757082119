import { CustomEvent, CustomEventTarget, CustomEventType } from '../models/events';
import {
    DetectionModelDownloadType,
    DetectionModelDownloadTypeUI,
    DetectionModelFrameworkType,
    DetectionModelFrameworkTypeUI,
    ProjectType,
    ProjectTypeUI
} from '../models/api';

const isSameProject = (target1?: CustomEventTarget, target2?: CustomEventTarget) => {
    if (!target1 || !target2 || !('projectId' in target1) || !('projectId' in target2)) {
        return false;
    }
    return target1.projectId === target2.projectId;
};

export const unvisibleEvents = [CustomEventType.Login, CustomEventType.OpenProject];

export const groupingCheckForEvent = {
    [CustomEventType.Login]: () => true,
    [CustomEventType.Logout]: () => true,
    [CustomEventType.CreateProject]: () => false,
    [CustomEventType.DuplicateProject]: () => false,
    [CustomEventType.DeleteProject]: () => false,
    [CustomEventType.OpenProject]: () => false,
    [CustomEventType.CreateProjectGroup]: () => false,
    [CustomEventType.DeleteProjectGroup]: () => false,
    [CustomEventType.StartTraining]: () => false,
    [CustomEventType.CancelTraining]: () => false,
    [CustomEventType.EarlyStopTraining]: () => false,
    [CustomEventType.DeleteTraining]: () => false,
    [CustomEventType.AddLabel]: isSameProject,
    [CustomEventType.UpdateLabel]: isSameProject,
    [CustomEventType.DeleteLabel]: isSameProject,
    [CustomEventType.ImageUpload]: isSameProject,
    [CustomEventType.DeleteImage]: isSameProject,
    [CustomEventType.Annotate]: isSameProject,
    [CustomEventType.StartSimulation]: () => false,
    [CustomEventType.CreateDownload]: () => false,
    [CustomEventType.Start3dModeling]: () => false,
    [CustomEventType.Cancel3dModeling]: () => false,
    [CustomEventType.Delete3dModeling]: () => false,
    [CustomEventType.StartPredictiveMaintenanceTraining]: () => false,
    [CustomEventType.DeletePredictiveMaintenanceTraining]: () => false
};

export const colorForEvent = {
    [CustomEventType.Login]: 'success',
    [CustomEventType.Logout]: 'success',
    [CustomEventType.CreateProject]: 'success',
    [CustomEventType.DuplicateProject]: 'success',
    [CustomEventType.DeleteProject]: 'danger',
    [CustomEventType.OpenProject]: 'info',
    [CustomEventType.CreateProjectGroup]: 'success',
    [CustomEventType.DeleteProjectGroup]: 'danger',
    [CustomEventType.AddLabel]: 'success',
    [CustomEventType.UpdateLabel]: 'success',
    [CustomEventType.DeleteLabel]: 'danger',
    [CustomEventType.ImageUpload]: 'success',
    [CustomEventType.DeleteImage]: 'danger',
    [CustomEventType.Annotate]: 'success',
    [CustomEventType.StartTraining]: 'info',
    [CustomEventType.CancelTraining]: 'warning',
    [CustomEventType.EarlyStopTraining]: 'danger',
    [CustomEventType.DeleteTraining]: 'danger',
    [CustomEventType.StartSimulation]: 'success',
    [CustomEventType.CreateDownload]: 'success',
    [CustomEventType.Start3dModeling]: 'success',
    [CustomEventType.Cancel3dModeling]: 'warning',
    [CustomEventType.Delete3dModeling]: 'danger',
    [CustomEventType.StartPredictiveMaintenanceTraining]: 'success',
    [CustomEventType.DeletePredictiveMaintenanceTraining]: 'danger'
};

interface CustomEventDescriptionParams extends CustomEvent {
    targets: any[];
}

export const descriptionForEvent = {
    [CustomEventType.Login]: () => null,
    [CustomEventType.Logout]: () => <>Showed time of last logout</>,
    [CustomEventType.CreateProject]: (params: CustomEventDescriptionParams) => (
        <>
            Created <b>{ProjectTypeUI[params?.targets?.[0]?.type as ProjectType]}</b> project{' '}
            <b>{params?.targets?.[0]?.name}</b>
            {params?.targets?.[0]?.templateId && ' from template'}
        </>
    ),
    [CustomEventType.DuplicateProject]: (params: CustomEventDescriptionParams) => (
        <>
            Duplicated <b>{ProjectTypeUI[params?.targets?.[0]?.type as ProjectType]}</b> project{' '}
            <b>{params?.targets?.[0]?.name.substring(0, params?.targets?.[0]?.name.length - 5)}</b>
        </>
    ),
    [CustomEventType.DeleteProject]: (params: CustomEventDescriptionParams) => (
        <>
            Deleted <b>{ProjectTypeUI[params?.targets?.[0]?.type as ProjectType]}</b> project{' '}
            <b>{params?.targets?.[0]?.name}</b>
        </>
    ),
    [CustomEventType.OpenProject]: () => null,
    [CustomEventType.CreateProjectGroup]: (params: CustomEventDescriptionParams) => (
        <>
            Created <b>{params?.targets?.[0]?.name}</b> project group
        </>
    ),
    [CustomEventType.DeleteProjectGroup]: (params: CustomEventDescriptionParams) => (
        <>
            Deleted <b>{params?.targets?.[0]?.name}</b> project group
        </>
    ),
    [CustomEventType.AddLabel]: (params: CustomEventDescriptionParams) => (
        <>
            Added <b>{params.targetsCount}</b> label(s) to project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.UpdateLabel]: (params: CustomEventDescriptionParams) => (
        <>
            Updated <b>{params.targetsCount}</b> label(s) from project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.DeleteLabel]: (params: CustomEventDescriptionParams) => (
        <>
            Deleted <b>{params.targetsCount}</b> label(s) from project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.ImageUpload]: (params: CustomEventDescriptionParams) => (
        <>
            Uploaded <b>{params.targetsCount}</b> image(s) to project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.DeleteImage]: (params: CustomEventDescriptionParams) => (
        <>
            Deleted <b>{params.targetsCount}</b> image(s) from project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.Annotate]: (params: CustomEventDescriptionParams) => (
        <>
            Annotated <b>{params.targetsCount}</b> image(s) in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.StartTraining]: (params: CustomEventDescriptionParams) => (
        <>
            Started model training{' '}
            <b>#{params?.targets?.[0]?.index}</b>{' '}
            with framework{' '}
            <b>{DetectionModelFrameworkTypeUI[
                    params?.targets?.[0]?.frameworkType as DetectionModelFrameworkType
                ]}
            </b>{' '}
            in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.CancelTraining]: (params: CustomEventDescriptionParams) => (
        <>
            Canceled model training{' '}
            <b>#{params?.targets?.[0]?.index}</b>{' '}
            with framework{' '}
            <b>{DetectionModelFrameworkTypeUI[
                    params?.targets?.[0]?.frameworkType as DetectionModelFrameworkType
                ]}
            </b>{' '}
            in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.EarlyStopTraining]: (params: CustomEventDescriptionParams) => (
        <>
            Training{' '}
            <b>#{params?.targets?.[0]?.index}</b>{' '}
            with framework{' '}
            <b>{DetectionModelFrameworkTypeUI[
                    params?.targets?.[0]?.frameworkType as DetectionModelFrameworkType
                ]}
            </b>{' '}
            stopped early in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.DeleteTraining]: (params: CustomEventDescriptionParams) => (
        <>
            Deleted model training{' '}
            <b>#{params?.targets?.[0]?.index}</b>{' '}
            with framework{' '}
            <b>{DetectionModelFrameworkTypeUI[
                    params?.targets?.[0]?.frameworkType as DetectionModelFrameworkType
                ]}
            </b>{' '}
            in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.StartSimulation]: (params: CustomEventDescriptionParams) => (
        <>
            Simulation with <b>{params.targets?.[0]?.type}</b> started in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.CreateDownload]: (params: CustomEventDescriptionParams) => (
        <>
            Created download of{' '}
            <b>
                {
                    DetectionModelDownloadTypeUI[
                        params?.targets[0].type as DetectionModelDownloadType
                    ]
                }
            </b>{' '}
            in project <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.Start3dModeling]: (params: CustomEventDescriptionParams) => (
        <>
            Started 3D model generation with file <b>{params?.targets?.[0]?.filename}</b> in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.Cancel3dModeling]: (params: CustomEventDescriptionParams) => (
        <>
            Cancelled 3D model generation with file <b>{params?.targets?.[0]?.filename}</b> in
            project <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.Delete3dModeling]: (params: CustomEventDescriptionParams) => (
        <>
            Deleted 3D model generation with file <b>{params?.targets?.[0]?.filename}</b> in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.StartPredictiveMaintenanceTraining]: (params: CustomEventDescriptionParams) => (
        <>
            Started Predictive Maintenance training with file <b>{params?.targets?.[0].filename}</b> in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    ),
    [CustomEventType.DeletePredictiveMaintenanceTraining]: (params: CustomEventDescriptionParams) => (
        <>
            Deleted Predictive Maintenance training with file <b>{params?.targets?.[0]?.filename}</b> in project{' '}
            <b>{params?.targets?.[0]?.projectName}</b>
        </>
    )
};
