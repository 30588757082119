import qs from 'qs';
import { API, Auth } from 'aws-amplify';
import axios, { AxiosPromise } from 'axios';
import { AbstractApiClient } from '../abstractApiClient';
import apiRoutes from '../routes';
import {
    Annotation,
    ClassificationListSyncRequest,
    CreateUser,
    DatasetImage,
    DatasetImageCreate,
    DatasetImageDelete,
    DatasetImageParams,
    DatasetImageImport,
    DetectionModel,
    DetectionModelCreate,
    DetectionModelDownload,
    DetectionModelDownloadListRequest,
    DetectionModelDownloadUpdate,
    DetectionModelGenerator,
    DetectionModelGeneratorCreateRequest,
    DetectionModelGeneratorListRequest,
    DetectionModelGeneratorRunningListRequest,
    DetectionModelRunningList,
    DetectionModelSimulation,
    DetectionModelSimulationCreateRequest,
    DetectionModelSimulationListRequest,
    DetectionModelSimulationRunningListRequest,
    DetectionModelSimulationUpdateForm,
    DetectionModelTrainingStartRequest,
    DetectionModelUpdate,
    Feedback,
    FileUploadElement,
    Label,
    LabelUpdate,
    PolygonAnnotation,
    PredictiveMaintenanceCreateRequest,
    PredictiveMaintenanceListRequest,
    Project,
    ProjectClone,
    ProjectCreate,
    ProjectListRequest,
    ProjectUpdate,
    SetDeviceLicense,
    SetVideoForm,
    TrackOpenProject,
    UpgradeLicense,
    User,
    DatasetImageExport,
    ProjectGroupListRequest,
    ProjectGroupUpdate,
    ProjectGroup,
    TrainingReportRequest
} from '../../../models/api';
import { Pagination } from '../../../models/pagination';
import { SortOrder } from '../../../models/sort-order';
import { setupAxios } from '../../../utils/setupAxios';
import client from '../remoteServer/client';

//TODO from env
const apiName = process.env.REACT_APP_PROVIDERS_AMPLIFY_APP_NAME;
setupAxios(axios);

interface InitParams {
    response: boolean;
    headers: Record<string, string | undefined>;
}

const init: InitParams = {
    response: true,
    headers: {}
};

export class AmplifyServerApiClient extends AbstractApiClient {
    __extendHeaders(headers: Record<string, string | undefined>) {
        for (const key of Object.keys(headers)) {
            init.headers[key] = headers[key];
        }
    }

    projectList(params: ProjectListRequest) {
        return API.get(apiName, `${apiRoutes.projects.list}?${qs.stringify(params)}`, init);
    }

    projectTemplatesList() {
        return API.get(apiName, apiRoutes.projects.templatesList, init);
    }

    projectCanSwitchMultiLabel(id: Project['id']) {
        return API.get(apiName, apiRoutes.projects.canSwitchMultiLabel(id), init);
    }

    projectCreate(params: ProjectCreate) {
        return API.post(apiName, `${apiRoutes.projects.list}`, { ...init, body: params });
    }

    projectUpdate(id: Project['id'], params: ProjectUpdate) {
        return API.put(apiName, apiRoutes.projects.item(id), { ...init, body: params });
    }

    projectDelete(id: Project['id']) {
        return API.del(apiName, apiRoutes.projects.item(id), init);
    }

    projectClone(id: Project['id'], params: ProjectClone) {
        return API.post(apiName, apiRoutes.projects.clone(id), { ...init, body: params });
    }

    projectGroupList(params?: ProjectGroupListRequest) {
        return API.get(apiName, `${apiRoutes.projectGroups.list}?${qs.stringify(params)}`, init);
    };
    
    projectGroupCreate(params: ProjectGroupUpdate) {
        return API.post(apiName, apiRoutes.projectGroups.list, {...init, body: params});
    };

    projectGroupUpdate(id: ProjectGroup['id'], params: ProjectGroupUpdate) {
        return API.put(apiName, apiRoutes.projectGroups.item(id), {...init, body: params});
    };

    projectGroupDelete(id: ProjectGroup['id']) {
        return API.del(apiName, apiRoutes.projectGroups.item(id), init);
    };

    projectTrashList(params: ProjectListRequest) {
        return API.get(apiName, `${apiRoutes.projectsTrash.list}?${qs.stringify(params)}`, init);
    }

    projectTrashRestore(id: Project['id']) {
        return API.put(apiName, apiRoutes.projectsTrash.item(id), init);
    }

    datasetImageList(
        projectId: DatasetImage['projectId'],
        pagination: Pagination | null,
        sortOrder: SortOrder
    ) {
        const queryStringParameters = pagination
            ? { ...pagination.queryObject, ...sortOrder.queryObject }
            : { ...sortOrder.queryObject };
        return API.get(apiName, apiRoutes.datasetImages.list(projectId), {
            ...init,
            queryStringParameters
        });
    }

    datasetImageById(id: DatasetImage['id']) {
        return API.get(apiName, apiRoutes.datasetImages.item(id), init);
    }

    datasetImageCreate(params: DatasetImageCreate) {
        return API.post(apiName, apiRoutes.datasetImages.list(params.image.projectId), {
            ...init,
            body: params
        });
    }

    datasetImageDelete(params: DatasetImageDelete) {
        return API.del(apiName, apiRoutes.datasetImages.list(params.project.id), {
            ...init,
            body: params
        });
    }

    datasetImageImport(params: DatasetImageImport) {
        return API.post(apiName, apiRoutes.datasetImages.import(params.projectId), {
            ...init,
            body: params
        });        
    }

    datasetImageExport(params: DatasetImageExport) {
        return API.post(apiName, apiRoutes.datasetImages.export(params.projectId), {
            ...init,
            body: params
        });        
    }

    datasetImageAnnotationsInfo(projectId: DatasetImage['projectId']) {
        return API.get(apiName, `${apiRoutes.datasetImages.list(projectId)}/annotationsInfo`, init);
    }

    datasetImageHashedNameList(projectId: DatasetImage['projectId']) {
        return API.get(apiName, `${apiRoutes.datasetImages.list(projectId)}/hashedNameList`, init);
    }

    datasetImageSortedIdList(projectId: DatasetImage['projectId'], sortOrder: SortOrder, params?: DatasetImageParams) {
        const queryStringParameters = { ...sortOrder.queryObject, ...params };
        return API.get(apiName, `${apiRoutes.datasetImages.list(projectId)}/sortedIdList`, {
            ...init,
            queryStringParameters,
        });
    }

    labelList(projectId: Label['projectId']) {
        return API.get(apiName, apiRoutes.labels.list(projectId), init);
    }

    labelCreate(projectId: Label['projectId'], params: LabelUpdate[]) {
        return API.post(apiName, apiRoutes.labels.list(projectId), { ...init, body: params });
    }

    labelUpdate(id: Label['id'], params: LabelUpdate) {
        return API.put(apiName, apiRoutes.labels.item(id), {...init, body: params});
    }

    labelDelete(id: Label['id']) {
        return API.del(apiName, apiRoutes.labels.item(id), init);
    }

    annotationListSync(datasetImageId: Annotation['datasetImageId'], items: Annotation[]) {
        return API.post(apiName, `${apiRoutes.annotations.list(datasetImageId)}/sync`, {
            ...init,
            body: items
        });
    }

    polygonAnnotationListSync(datasetImageId: PolygonAnnotation['datasetImageId'], items: PolygonAnnotation[]) {
        return API.post(apiName, `${apiRoutes.polygonAnnotations.list(datasetImageId)}/sync`, {
            ...init,
            body: items
        });
    }

    annotationsForLabel(labelId: string, offsetIndex: number) {
        return API.get(apiName, apiRoutes.annotations.byLabel(labelId), {
            ...init,
            queryStringParameters: { offsetIndex }
        });
    }

    changeAnnotationLabel(annotationId: string, labelId: string) {
        return API.post(apiName, apiRoutes.annotations.changeLabel, {
            ...init,
            body: { annotationId, labelId }
        });
    }

    detectionModelList(projectId: DetectionModel['projectId']) {
        return API.get(apiName, apiRoutes.detectionModels.list(projectId), init);
    }

    detectionModelRunningList(params?: DetectionModelRunningList) {
        return API.get(
            apiName,
            `${apiRoutes.detectionModels.runningTrainings}?${qs.stringify(params)}`,
            init
        );
    }

    detectionModelTrainingReport(params: TrainingReportRequest) {
        return API.get(
            apiName,
            `${apiRoutes.detectionModels.trainingReport}?${qs.stringify(params)}`,
            init
        )
    }

    detectionModelCreate(projectId: DetectionModel['projectId'], params: DetectionModelCreate) {
        return API.post(apiName, apiRoutes.detectionModels.list(projectId), {
            ...init,
            body: params
        });
    }

    detectionModelUpdate(id: DetectionModel['id'], params: DetectionModelUpdate) {
        return API.put(apiName, apiRoutes.detectionModels.item(id), { ...init, body: params });
    }

    detectionModelDelete(id: DetectionModel['id']) {
        return API.del(apiName, apiRoutes.detectionModels.item(id), init);
    }

    detectionModelTrainingStart(
        projectId: DetectionModel['projectId'],
        params: DetectionModelTrainingStartRequest
    ) {
        return API.post(apiName, `${apiRoutes.detectionModels.list(projectId)}/training/start`, {
            ...init,
            body: params
        });
    }

    detectionModelTrainingEarlyStop(id: DetectionModel['id']) {
        return API.put(apiName, `${apiRoutes.detectionModels.item(id)}/training/early-stop`, init);
    }

    detectionModelTrainingCancel(id: DetectionModel['id']) {
        return API.put(apiName, `${apiRoutes.detectionModels.item(id)}/training/cancel`, init);
    }

    detectionModelTrainingAccuracyStatistic(id: DetectionModel['id']) {
        return API.get(
            apiName,
            `${apiRoutes.detectionModels.item(id)}/training/accuracy/statistic`,
            init
        );
    }

    detectionModelDownloadList(params: DetectionModelDownloadListRequest) {
        return API.get(
            apiName,
            `${apiRoutes.detectionModelDownloads.list}?${qs.stringify(params)}`,
            init
        );
    }

    detectionModelDownloadCreate(
        detectionModelId: DetectionModelDownload['detectionModelId'],
        params: DetectionModelDownloadUpdate
    ) {
        return API.post(apiName, apiRoutes.detectionModelDownloads.shallowList(detectionModelId), {
            ...init,
            body: params
        });
    }

    detectionModelDownloadUpdate(
        id: DetectionModelDownload['id'],
        params: DetectionModelDownloadUpdate
    ) {
        return API.put(apiName, apiRoutes.detectionModelDownloads.item(id), {
            ...init,
            body: params
        });
    }

    detectionModelDownloadDelete(id: DetectionModelDownload['id']) {
        return API.del(apiName, apiRoutes.detectionModelDownloads.item(id), init);
    }

    userCreate(user: CreateUser) {
        return API.post(apiName, apiRoutes.users.list, { ...init, body: user });
    }
    userUpdate(user: User) {
        return API.put(apiName, apiRoutes.users.item(user.cognitoId), { ...init, body: user });
    }
    userGet(cognitoId: string) {
        return API.get(apiName, apiRoutes.users.item(cognitoId), init);
    }

    userAddFileUpload(cognitoId: string, params: FileUploadElement) {
        return API.post(apiName, `${apiRoutes.fileUploads.item(cognitoId)}`, {
            ...init,
            body: params
        });
    }

    userUpdateFileUpload(id: string, params: FileUploadElement) {
        return API.put(apiName, `${apiRoutes.fileUploads.item(id)}`, { ...init, body: params });
    }

    userGetFileUpload(cognitoId: string) {
        return API.get(apiName, `${apiRoutes.fileUploads.item(cognitoId)}`, init);
    }

    userGetLastFileUpload(cognitoId: string) {
        return API.get(apiName, `${apiRoutes.fileUploads.item(cognitoId)}/last`, init);
    }

    userDeleteFileUpload(id: string) {
        return API.del(apiName, `${apiRoutes.fileUploads.item(id)}`, init);
    }

    userList() {
        return API.get(apiName, apiRoutes.users.list, init);
    }

    userDelete(id: User['id']) {
        return API.del(apiName, apiRoutes.users.item(id), init);
    }

    detectionModelSimulationList(params: DetectionModelSimulationListRequest) {
        return API.get(
            apiName,
            `${apiRoutes.detectionModelSimulations.list}?${qs.stringify(params)}`,
            init
        );
    }

    detectionModelRunningSimulationList(params: DetectionModelSimulationRunningListRequest) {
        return API.get(
            apiName,
            `${apiRoutes.detectionModelSimulations.runningList}?${qs.stringify(params)}`,
            init
        );
    }

    detectionModelSimulationCreate(
        detectionModelId: DetectionModelSimulation['detectionModelId'],
        params: DetectionModelSimulationCreateRequest
    ) {
        return API.post(
            apiName,
            apiRoutes.detectionModelSimulations.shallowList(detectionModelId),
            {
                ...init,
                body: params
            }
        );
    }

    detectionModelSimulationUpdate(
        id: DetectionModelSimulation['id'],
        params: DetectionModelSimulationUpdateForm
    ) {
        return API.put(apiName, apiRoutes.detectionModelSimulations.item(id), {
            ...init,
            body: params
        });
    }

    detectionModelSimulationDelete(id: DetectionModelSimulation['id']) {
        return API.del(apiName, apiRoutes.detectionModelSimulations.item(id), init);
    }

    detectionModelGeneratorList(params: DetectionModelGeneratorListRequest) {
        const query = qs.stringify(params);
        const url = `${apiRoutes.detectionModelGenerators.list}?${query}`;
        return API.get(apiName, url, init);
    }

    detectionModelGeneratorRunningList(params: DetectionModelGeneratorRunningListRequest) {
        const query = qs.stringify(params);
        const url = `${apiRoutes.detectionModelGenerators.runningList}?${query}`;
        return API.get(apiName, url, init);
    }

    detectionModelGeneratorCreate(params: DetectionModelGeneratorCreateRequest) {
        return API.post(apiName, apiRoutes.detectionModelGenerators.list, {
            ...init,
            body: params
        });
    }

    detectionModelGeneratorCancelGenerating(id: DetectionModelGenerator['id']) {
        return API.put(apiName, apiRoutes.detectionModelGenerators.cancel(id), init);
    }

    detectionModelGeneratorDelete(id: DetectionModelGenerator['id']) {
        return API.del(apiName, apiRoutes.detectionModelGenerators.item(id), init);
    }

    sendFeedback(params: Feedback) {
        return API.post(apiName, apiRoutes.feedback.list, {
            ...init,
            body: params
        });
    }

    getHelpVideo() {
        return API.get(apiName, apiRoutes.helpVideo.list, init);
    }

    addVideo(formData: SetVideoForm) {
        return API.post(apiName, apiRoutes.helpVideo.list, {
            ...init,
            body: formData
        });
    }

    updateVideo(formData: SetVideoForm) {
        return API.put(apiName, apiRoutes.helpVideo.list, {
            ...init,
            body: formData
        });
    }

    delVideo(formData: SetVideoForm) {
        return API.del(apiName, apiRoutes.helpVideo.list, {
            ...init,
            body: formData
        });
    }

    datasetZipDownload(path: string) {
        return API.get(apiName, apiRoutes.datasetZipDownload.list(path), init);
    }

    sendUpgradeLicenseRequest(upgradeLicense: UpgradeLicense) {
        return API.post(apiName, apiRoutes.license.upgradeLicense, {
            ...init,
            body: upgradeLicense
        });
    }

    classificationListSync(
        datasetImageId: DatasetImage['id'],
        params: ClassificationListSyncRequest
    ) {
        return API.post(apiName, `${apiRoutes.classifications.shallowList(datasetImageId)}/sync`, {
            ...init,
            body: params
        });
    }
    statisticsCommon() {
        return API.get(apiName, apiRoutes.statistics.common, init);
    }

    statisticsProject(id: Project['id']) {
        return API.get(apiName, apiRoutes.statistics.project(id), init);
    }

    trackLogin() {
        return API.get(apiName, apiRoutes.track.login, init);
    }

    trackLogout() {
        return API.get(apiName, apiRoutes.track.logout, init);
    }

    trackOpenProject(params: TrackOpenProject) {
        return API.post(apiName, apiRoutes.track.openProject, { ...init, body: params });
    }

    eventList() {
        return API.get(apiName, apiRoutes.events.list, init);
    }

    addLicense(formData: SetDeviceLicense) {
        return API.post(apiName, apiRoutes.deviceLicenses.list, {...init, body: formData});
    }

    licensesList(): AxiosPromise {
        return API.get(apiName, apiRoutes.deviceLicenses.list, init);
    }

    licenseDelete(deviceLicenseId: string): AxiosPromise {
        return API.del(apiName, apiRoutes.deviceLicenses.item(deviceLicenseId), init);
    }

    usersEventList(pagination: Pagination | null, sortOrder: SortOrder) {
        const queryStringParams = pagination ? {...pagination.queryObject, ...sortOrder.queryObject} : {...sortOrder.queryObject}
        return API.get(apiName, `${apiRoutes.events.allEventsList}?${qs.stringify(queryStringParams)}`, init);
    }

    predictiveMaintenanceTrainingCreate(params: PredictiveMaintenanceCreateRequest) {
        return API.post(apiName, apiRoutes.predictiveMaintenance.list, { ...init, body: params });
    }

    predictiveMaintenanceTrainingDelete(id: string) {
        return API.del(apiName, apiRoutes.predictiveMaintenance.item(id), init)
    }

    predictiveMaintenanceTrainingsList(params: PredictiveMaintenanceListRequest) {
        const query = qs.stringify(params);
        const url = `${apiRoutes.predictiveMaintenance.list}?${query}`;
        return API.get(apiName, url, init);
    }

    async getChatOptions() {
        const baseUrl = await API.endpoint(apiName)

        return { 
            url: `${baseUrl}/chat`,
            token: (await Auth.currentSession()).getAccessToken().getJwtToken()
        } 
    }
}
