import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import DropdownList from 'react-widgets/DropdownList';

import ProjectTemplateCarousel from './ProjectTemplateCarousel';
import { FormField } from '../../../components/Form';
import { Project, ProjectType, ProjectTypeUI } from '../../../models/api';
import { useFormikContext } from 'formik';
import { NewProjectFormValues } from '../NewProjectPanel';

const projectTypeOptions = [
    {
        value: ProjectType.ObjectDetection,
        text: ProjectTypeUI.object
    },
    {
        value: ProjectType.ObjectClassification,
        text: ProjectTypeUI.objectClassification
    },
    {
        value: ProjectType.ObjectSegmentation,
        text: ProjectTypeUI.objectSegmentation
    },
    {
        value: ProjectType.PoseDetection,
        text: ProjectTypeUI.poseDetection
    },
    {
        value: ProjectType.Modeling3d,
        text: ProjectTypeUI.modeling3d
    },
    {
        value: ProjectType.PredictiveMaintenance,
        text: ProjectTypeUI.predictiveMaintenance
    },
];

const NewProjectForm: React.FC<{ formName: string }> = ({ formName }) => {
    const { setFieldValue, values } = useFormikContext<NewProjectFormValues>();

    const handleProjectTemplate = (project: Project | null) => {
        setFieldValue('templateProjectId', project ? project.id : null);
    };

    const handleTypeChange = (dataItem: { value: string }) => {
        setFieldValue('type', dataItem.value);
    };

    return (
        <>
            <FormField label="Project Name" formName={formName} field="name" isFirst />

            <FormGroup>
                <Label for="projectType">Project Type:</Label>
                <DropdownList
                    data={projectTypeOptions}
                    dataKey="value"
                    textField="text"
                    value={values.type}
                    onChange={handleTypeChange}
                    filter={false}
                />
            </FormGroup>

            <ProjectTemplateCarousel
                projectType={values.type}
                handleProjectTemplate={handleProjectTemplate}
            />
        </>
    );
};

export default NewProjectForm;
