import React from 'react';

import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import { SAFARI_NOT_SUPPORTED_ERROR } from '../../../utils/errors';

interface Props {
    error: boolean;
    onClose: () => void;
}


export const VideoErrorModal: React.FC<Props> = (props) => {
    const { error, onClose } = props;

    return (
        <Modal centered={true} isOpen={error}>
            <Form onSubmit={()=>{}}>
                <ModalHeader toggle={onClose}>
                    Video Simulation
                </ModalHeader>
                <ModalBody>
                    <div className="text-danger my-4">{SAFARI_NOT_SUPPORTED_ERROR}</div>
                </ModalBody>
                <ModalFooter className="flex-row-reverse justify-content-between">
                    <Button
                        color="secondary"
                        className="m-0"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
