import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
    isOpen: boolean;
    onAccept: () => void;
    onReject: () => void;
    onCancel: () => void;
}

export const NamingConventionConfirmation: React.FC<Props> = (props) => {
    const { isOpen, onAccept, onReject, onCancel } = props;
    return (
        <Modal
            isOpen={isOpen}
            toggle={onCancel}
            centered={true}
        >
            <ModalHeader toggle={onCancel}>
                Auto-label images?
            </ModalHeader>
            <ModalBody>
                <div className='py-4'>
                    <span>
                        We detected an underline in the name of your
                        files. Do you want to make use of auto-labelling
                        based on filename for this dataset?
                    </span>
                    <br/><br/>
                    <span>
                        Auto-label format:
                    </span>
                    <ul className='pl-4 mt-2'>
                        <li>label1_filename.jpg (e.g. cat_image.jpg)</li>
                        <li>label1_label2_filename.jpg (e.g. cat_white_image.jpg)</li>
                    </ul>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color='primary'
                    style={{ width: '5rem' }}
                    onClick={onAccept}
                >
                    Yes
                </Button>
                <Button
                    color='secondary'
                    style={{ width: '5rem' }}
                    onClick={onReject}
                >
                    No
                </Button>
            </ModalFooter>
        </Modal>
    );
}
