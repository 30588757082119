export enum Routes {
    Default = '/',

    SignIn = '/signin',
    SignUp = '/signup',
    SignOut = '/signout',
    ForgotPwd = '/forgot-pwd',
    ResetPwd = '/reset-pwd',
    ChangeTemporaryPwd = '/change-temporary-pwd',
    EmailConfirmation = '/email-confirmation',

    Dashboard = '/dashboard',
    ProjectGroup = '/projectGroup',
    Labels = '/labels',
    Dataset = '/dataset',
    ModelTraining = '/model-training',
    ModelSimulation = '/model-simulation',
    Download = '/download',
    ModelGenerator = '/model-generator',
    PredictiveMaintenanceModelTraining = '/predictive-maintenance-model-training',

    UserProfile = '/user-profile',

    AdminUserList = '/admin/users',
    AdminUserEdit = '/admin/users/:id/edit',
    AdminRunningTrainings = '/admin/running-trainings',
    AdminTrainingReport = '/admin/training-report',
    AdminHelpVideos = '/admin/help-videos',
    AdminDeviceLicense = '/admin/device-license',
    AdminUserActivity = '/admin/user-activity',
    AdminRunningSimulations = '/admin/running-simulations',

    MyAccount = '/my-account',

    SystemLock = '/system-lock'
}

export const projectRoutes = [
    Routes.Labels,
    Routes.Dataset,
    Routes.ModelTraining,
    Routes.ModelSimulation,
    Routes.Download,
    Routes.ModelGenerator,
    Routes.PredictiveMaintenanceModelTraining
];

export const adminRoutes = [
    Routes.AdminUserList,
    Routes.AdminUserEdit,
    Routes.AdminRunningTrainings,
    Routes.AdminHelpVideos,
    Routes.AdminDeviceLicense,
    Routes.AdminUserActivity,
    Routes.AdminRunningSimulations,
    Routes.AdminTrainingReport
];
