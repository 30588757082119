import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import DnaLoader from "../../components/DnaLoader";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Paper from "../../components/Paper";
import { longOperationsConfig } from "../../config/long-operations";
import { PredictiveMaintenance } from "../../models/api";
import { useStores } from "../../store";
import { PredictiveMaintenanceModelTrainingDownloadPanel } from "./PredictiveMaintenanceModelTrainingDownloadPanel";
import { PredictiveMaintenanceModelTrainingTable } from "./PredictiveMaintenanceModelTrainingTable";
import { PredictiveMaintenanceModelTrainingUploadPanel } from "./PredictiveMaintenanceModelTrainingUploadPanel";

export const PredictiveMaintenanceModelTrainingPage:React.FC = observer(() => {
    const [isStartingProcess, setIsStartingProcess] = React.useState<boolean>(false);
    const { predictiveMaintenanceModelTrainingStore, projectsStore } = useStores();

    const handleProcess = async (file: File) => {
        setIsStartingProcess(true);
        await predictiveMaintenanceModelTrainingStore.createItem(file);
        setIsStartingProcess(false);
    }

    const handleDelete = async (item: PredictiveMaintenance) => {
        await predictiveMaintenanceModelTrainingStore.deleteItem(item.id);
    }

    const handleDownload = async (item: PredictiveMaintenance) => {
        await predictiveMaintenanceModelTrainingStore.downloadPredictiveModel(item.projectId, item.id);
    }

    const handleGrabImage = async (item: PredictiveMaintenance) => {
        return await predictiveMaintenanceModelTrainingStore.getPredictiveModelImage(item.projectId, item.id);
    }

    React.useEffect(() => {
        predictiveMaintenanceModelTrainingStore.predictiveMaintenanceTrainingsList.isFetched = false; 
    }, [projectsStore.current?.id])

    React.useEffect(() => {
        if(!predictiveMaintenanceModelTrainingStore.predictiveMaintenanceTrainingsList.isFetched)
            predictiveMaintenanceModelTrainingStore.fetchPredictiveMaintenanceTrainingsList();
    }, [predictiveMaintenanceModelTrainingStore.predictiveMaintenanceTrainingsList.isFetched])

    React.useEffect(() => {
        const handle = setInterval(async () => {
            await predictiveMaintenanceModelTrainingStore.fetchPredictiveMaintenanceTrainingsList()
        }, longOperationsConfig.refreshInterval);
        return () => clearInterval(handle);
    }, [predictiveMaintenanceModelTrainingStore]);

    return (
        <Layout>
            <>
                <PageTitle 
                    heading="Model Training"
                    icon="fas fa-image icon-gradient bg-warm-flame"
                    subheading="Generate a predictive maintenance model from a CSV dataset"
                    showCloseButton={true}
                />

                <div className="d-flex align-items-center">
                    <PredictiveMaintenanceModelTrainingDownloadPanel />
                    <PredictiveMaintenanceModelTrainingUploadPanel 
                        onProcess={handleProcess}
                        isStartingProcess={isStartingProcess}                    
                    />
                    <div className="d-flex align-items-center justify-content-end">

                    </div>
                </div>

                {!predictiveMaintenanceModelTrainingStore.predictiveMaintenanceTrainingsList.isFetched && <DnaLoader />} 

                <Row className='mt-4'>
                    <Col sm={12}>
                        <Paper rounded hidden={!predictiveMaintenanceModelTrainingStore.predictiveMaintenanceTrainingsList.isFetched}>
                            <PredictiveMaintenanceModelTrainingTable 
                                items={predictiveMaintenanceModelTrainingStore.predictiveMaintenanceTrainingsList.list}
                                onDelete={handleDelete}
                                onDownload={handleDownload}
                                onGrab={handleGrabImage}
                            />
                        </Paper>
                    </Col>
                </Row> 
            </>
        </Layout>
    );
})
