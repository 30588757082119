import React from 'react';
import { useFormik } from 'formik';
import { Form, Input, Label, ModalProps } from 'reactstrap';
import { FileUploadElement } from '../../../../models/api';
import dayjs from 'dayjs';
import { dateConfig } from '../../../../config/date';

interface Props extends ModalProps {
    project: FileUploadElement | null;
    onConfirm: (id:string, values: FileUploadElement) => void;
}

export const EditFileUploadForm: React.FC<Props> = (props) => {
    const { formId, project, onConfirm } = props;

    const formik = useFormik({
        initialValues: {
            date: dayjs(project?.date ?? '').format(dateConfig.formats.dateOnlyDayTypeDate),
            title: project?.title ?? '',
            type: project?.type ?? '',
            link:  project?.link ??'',
        },
        onSubmit: (values) => {
            onConfirm(project?.id ?? '', {
                date: values.date,
                title: values.title,
                type: values.type,
                link: values.link,
            });
        },
    });
    return (
        <Form
            onSubmit={formik.handleSubmit}
            id={formId}
            className="d-flex flex-wrap justify-content-between align-items-center py-4"
        >
            <div className="form-group w-50 pr-2">
                <Label for="fileUploadDate" className="mb-0">
                    Date:
                </Label>
                <Input
                    id="fileUploadDate"
                    name="date"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.date}
                />
            </div>
            <div className="form-group w-50">
                <Label for="fileUploadType" className="mb-0">
                    Type:
                </Label>
                <Input
                    id="fileUploadType"
                    name="type"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.type}
                />
            </div>
            <div className="form-group w-100">
                <Label for="fileUploadTitle" className="mb-0">
                    Document Name:
                </Label>
                <Input
                    id="fileUploadTitle"
                    name="title"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                />

            </div>
            <div className="form-group w-100">
                <Label for="fileUploadLink" className="mb-0">
                    Download Link:
                </Label>
                <Input
                    id="fileUploadLink"
                    name="link"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.link}
                />
            </div>
        </Form>
    );
};
