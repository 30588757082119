import { Form } from "formik";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from "reactstrap";

export const NewProjectModal: React.FC<ModalProps> = ({ toggle, isOpen, onClosed, children }) => {
    return (
        <Modal toggle={toggle} isOpen={isOpen} onClosed={onClosed}>
            <Form>
                <ModalHeader toggle={toggle as React.MouseEventHandler<any>}>Create New Project</ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter className="justify-content-end">
                    <Button type="submit" color="success">
                        Create
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}