import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../router/routes';

export const ForgotPwdFooter = () => {
    return (
        <Container>
            <Row xs={6}>
                <Col>
                    <NavLink to={Routes.SignIn}><b>Log in</b></NavLink>
                </Col>
                <Col className={'text-right'}>
                    <NavLink to={Routes.SignUp}><b>Register now</b></NavLink>
                </Col>
            </Row>
        </Container>
    );
};
