import React from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomSlider from '../../../components/CustomSlider';

const sliderMarks = {
    10: '10',
    50: '50',
    200: '200'
};

interface Props {
    isOpen: boolean;
    handleToggle: () => void;
    frames: number;
    setFrames: (value: number) => void;
}

export const ModelGeneratorSettingsModal: React.FC<Props> = (props) => {
    const { isOpen, handleToggle, frames, setFrames } = props;
    return (
        <Modal isOpen={isOpen} toggle={handleToggle} className='app-component-modal' size='md'>
            <ModalHeader>Video Process Settings</ModalHeader>
            <ModalBody className='px-4 py-5'>
                <FormGroup>
                    <Label>
                        <b>Number of frames:</b> {frames}
                    </Label>
                    <div className='px-1'>
                        <CustomSlider
                            min={10}
                            max={200}
                            marks={sliderMarks}
                            value={frames}
                            onChange={(value) => setFrames(value)}
                            className='mb-4'
                        />
                    </div>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={handleToggle}>
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    );
};
