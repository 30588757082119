import React from 'react';
import { ProjectGroupForm } from './ProjectGroupForm';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
} from 'reactstrap';
import { ProjectGroup, ProjectGroupUpdate } from '../../../../models/api';

interface Props extends ModalProps {
    modalTitle: string;
    onConfirm: (params: ProjectGroupUpdate) => void;
    projectGroup?: ProjectGroup | null;
}

export const ProjectGroupModal: React.FC<Props> = (props) => {
    const { isOpen, onClosed, onConfirm } = props;
    const formId = 'project-group-form';
    return (
        <Modal isOpen={isOpen} toggle={onClosed}>
            <ModalHeader toggle={onClosed}>{props.modalTitle}</ModalHeader>
            <ModalBody>
                <ProjectGroupForm
                    formId={formId}
                    onConfirm={onConfirm}
                    projectGroup={props.projectGroup}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="success" form={formId}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};
