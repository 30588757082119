import { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { getStream } from './utils/streams';
import { v4 as uuidv4 } from 'uuid';
import { BOT_ERROR_MESSAGE, getSuggestionMessage } from './utils/strings';
import { AISuggestion } from '..';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type ChatMessage = {
  role: 'assistant' | 'user';
  content: string;
  id: string;
  aiSuggestion?: AISuggestion
}

type UseChatStreamResult = {
  messages: ChatMessage[];
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[] | []>>;
  input: string;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event?: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export type UseChatStreamOptions = {
  url: string;
  method: HttpMethod;
  query?: Record<string, string>;
  headers?: HeadersInit;
  body?: Record<string, string>;
}

export type UseChatStreamInputMethod = {
  type: 'body' | 'query',
  key: string;
}

type UseChatStreamInput = {
  options: UseChatStreamOptions,
  method: UseChatStreamInputMethod,
};

const useChatStream = (input: UseChatStreamInput): UseChatStreamResult => {
  const [messages, setMessages] = useState<ChatMessage[] | []>([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const addMessageToChat = (message: string, role: ChatMessage['role'] = 'user', aiSuggestion?: AISuggestion) => {
    setMessages(messages => [...messages, { role, content: message, id: uuidv4(), aiSuggestion }]);
  };

  const fetchAndUpdateAIResponse = async (message: string) => {
    const { data }: { data: { message: string } } = await getStream(
      [...messages, { role: 'user', content: message, id: uuidv4() }],
      input.options,
      input.method
    );
    if (!data) throw new Error();

    try {
      const parsedJSON: AISuggestion = JSON.parse(data.message)
      addMessageToChat(getSuggestionMessage(parsedJSON.ai_type), 'assistant', parsedJSON)
    } catch {
      addMessageToChat(data.message, 'assistant')
    }

  };

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!message) {
      return;
    }
    setIsLoading(true);
    addMessageToChat(message);
    setMessage('');

    try {
      await fetchAndUpdateAIResponse(message);
    } catch (error) {
      toast(error.message, { type: toast.TYPE.ERROR })
      console.warn(BOT_ERROR_MESSAGE)
    }

    setIsLoading(false);
  };
  

  return {
    messages,
    setMessages,
    input: message,
    handleInputChange,
    handleSubmit,
    isLoading,
    setIsLoading
  };
};

export default useChatStream;
