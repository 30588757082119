import container from '../../container/container';
import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './root';
import { groupingCheckForEvent, unvisibleEvents } from '../../utils/events';
import {
    AnnotationEventTarget,
    CustomEvent,
    CustomEventTarget,
    CustomEventType,
    DbEvent,
} from '../../models/events';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Pagination } from '../../models/pagination';
import { toast } from 'react-toastify';

dayjs.extend(localizedFormat);

const api = container.apiClient;

const EVENTS_PER_PAGE = 50;

export class EventsStore {
    list: CustomEvent[] = [];
    usersEventList: DbEvent[] = [];

    isLoading: boolean = false;
    isFetched: boolean = false;

    rootStore: RootStore;

    pagination: Pagination = new Pagination();

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async fetchList() {
        this.isLoading = true;

        try {
            const { data } = await api.eventList();
            runInAction(() => {
                this.list = EventsStore.groupEvents(data);
                this.isFetched = true;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public fetchUsersEvents = async () => {
        this.pagination.sizePerPage = EVENTS_PER_PAGE;

        try {
            if(!this.pagination.sortOrder.sortField && !this.pagination.sortOrder.sortOrder) {
                this.pagination.sortOrder.sortField = 'updatedAt';
                this.pagination.sortOrder.sortOrder = 'DESC';
            }

            const { data } = await container.apiClient.usersEventList(
                this.pagination,
                this.pagination.sortOrder
            );

            runInAction(() => {
                this.usersEventList = data.rows;

                this.pagination.totalSize = data.count;
                this.isFetched = true;
            })
        } catch(e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            this.isLoading = false;
        }
    }

    addItem(eventType: CustomEventType, eventTarget: CustomEventTarget) {
        const isRepeated = EventsStore.isEventRepeated(
            eventType,
            eventTarget,
            this.list[this.list.length - 1]
        );
        if (isRepeated) {
            return;
        }
        const isGrouping = EventsStore.isGroupingEvents(
            eventType,
            eventTarget,
            this.list[this.list.length - 1]
        );
        if (isGrouping) {
            this.list[this.list.length - 1].targetsCount += 1;
            this.list[this.list.length - 1].targets.push(eventTarget);
        } else {
            this.list.push({
                type: eventType,
                targetsCount: 1,
                targets: [eventTarget],
                createdAt: dayjs().format('LT')
            });
        }
    }

    private static groupEvents(dbEvents: DbEvent[]): CustomEvent[] {
        const customEvents: CustomEvent[] = [];
        let lastIdx = -1;
        dbEvents.forEach((dbEvent) => {
            if (!Object.values(CustomEventType).includes(dbEvent.type)) {
                return;
            }
            if (unvisibleEvents.includes(dbEvent.type) || !dbEvent.target) {
                return;
            }
            const isRepeated = EventsStore.isEventRepeated(
                dbEvent.type,
                dbEvent.target,
                customEvents[lastIdx]
            );
            if (isRepeated) {
                return;
            }
            const isGrouping = EventsStore.isGroupingEvents(
                dbEvent.type,
                dbEvent.target,
                customEvents[lastIdx]
            );
            if (isGrouping) {
                customEvents[lastIdx] = this.toCustomEventFromDb(
                    dbEvent,
                    customEvents[lastIdx]
                );
            } else {
                customEvents.push(this.toCustomEventFromDb(dbEvent));
                lastIdx++;
            }
        });
        return customEvents;
    }

    private static isGroupingEvents(
        currEventType: CustomEventType,
        currEventTarget: CustomEventTarget,
        prevEvent?: CustomEvent
    ) {
        if (!prevEvent || currEventType !== prevEvent.type) {
            return false;
        }
        return groupingCheckForEvent[currEventType](
            currEventTarget,
            prevEvent.targets[0]
        );
    }

    private static isEventRepeated(
        currEventType: CustomEventType,
        currEventTarget: CustomEventTarget,
        prevEvent?: CustomEvent
    ) {
        if (!prevEvent || prevEvent.type !== CustomEventType.Annotate || currEventType !== CustomEventType.Annotate) {
            return false;
        }
        const typedCurrTarget = currEventTarget as AnnotationEventTarget;
        const typedPrevTargets = prevEvent.targets as AnnotationEventTarget[];
        return typedPrevTargets.some((target) => (
            target.datasetImageId === typedCurrTarget.datasetImageId
        ));
    }

    private static toCustomEventFromDb(
        event: DbEvent,
        prevEvent?: CustomEvent
    ): CustomEvent {
        const targetsCount = prevEvent ? prevEvent.targetsCount + 1 : 1;
        const targets = prevEvent ? [...prevEvent.targets, event.target] : [event.target];
        return {
            type: event.type,
            targetsCount,
            targets,
            createdAt: dayjs(event.createdAt).format('LT')
        };
    }
}
