import React, { useState } from 'react';
import { Project, ProjectField } from '../../../models/api';
import { getConnectedProjectFields } from '../../../utils/project-dependencies';
import {
    capitalize,
    concatWithoutDuplicates,
} from '../../../utils/helper-functions';
import {
    Button,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
    Table,
} from 'reactstrap';

interface Props extends ModalProps {
    project: Project | null;
    onConfirm: (id: string, fieldsToClone: ProjectField[]) => void;
}

const allProjectFields = Object.values(ProjectField);

export const CloneProjectModal: React.FC<Props> = (props) => {
    const { isOpen, onClosed, project, onConfirm } = props;

    const [fieldsToClone, setFieldsToClone] = useState<ProjectField[]>(allProjectFields);

    const handleDuplicateClick = () => {
        if (!project) {
            return;
        }
        onConfirm(project.id, fieldsToClone);
    };

    const handleFieldsToCloneChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const connectedFields = getConnectedProjectFields(
            e.target.value as ProjectField,
            e.target.checked
        );
        const newFieldsToClone = e.target.checked
            ? concatWithoutDuplicates<ProjectField>(fieldsToClone, connectedFields)
            : fieldsToClone.filter((f) => !connectedFields.includes(f));

        setFieldsToClone(newFieldsToClone);
    };

    return (
        <Modal isOpen={isOpen} toggle={onClosed}>
            <ModalHeader toggle={onClosed}>Duplicate Project</ModalHeader>
            <ModalBody>
                <Table striped>
                    <tbody>
                        {allProjectFields.map((field, i) => (
                            <tr key={`${i}_${field}`}>
                                <td>{capitalize(field)}:</td>
                                <td>
                                    <FormGroup check inline>
                                        <Input
                                            type="checkbox"
                                            value={field}
                                            checked={fieldsToClone.includes(
                                                field
                                            )}
                                            defaultChecked={true}
                                            onChange={handleFieldsToCloneChange}
                                        />
                                    </FormGroup>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleDuplicateClick}>
                    Duplicate
                </Button>
            </ModalFooter>
        </Modal>
    );
};
