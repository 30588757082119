import React from 'react';
import cx from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface Props{
    className?: string;
    avatarUrl?: string;
}

const ProfileImage: React.FC<Props> = ({ avatarUrl, className }) => {

    return (
        avatarUrl
            ? <img className={cx('app-pages-user-profile__profile-image rounded-circle', className)} src={avatarUrl} alt='avatar'/>
            :
                <div className={cx('app-pages-user-profile__default-image rounded-circle', className)}>
                    <FontAwesomeIcon
                        icon={faUser}
                    />
                </div>

    );
};

export default ProfileImage;
