import React, { ReactNode } from 'react';
import { Routes } from '../../router/routes';
import { SignInFooter, SignInForm } from './SignIn';
import { SignUpFooter, SignUpForm } from './SignUp';
import { ForgotPwdFlow, ForgotPwdFooter } from './ForgotPwd';
import { ChangeTemporaryPwdForm } from './ForgotPwd/change-temporary-pwd-form';
import { EmailConfirmation, EmailConfirmationFooter } from './EmailConfirmation';

interface LoginPageConfig {
    form: ReactNode;
    footer?: ReactNode;
}

export const loginPageConfigs: { [key: string]: LoginPageConfig } = {
    [Routes.SignIn]: {
        form: <SignInForm />,
        footer: <SignInFooter />,
    },
    [Routes.SignUp]: {
        form: <SignUpForm />,
        footer: <SignUpFooter />,
    },
    [Routes.ForgotPwd]: {
        form: <ForgotPwdFlow />,
        footer: <ForgotPwdFooter />,
    },
    [Routes.ChangeTemporaryPwd]: {
        form: <ChangeTemporaryPwdForm />,
        footer: <ForgotPwdFooter />,
    },
    [Routes.EmailConfirmation]: {
        form: <EmailConfirmation />,
        // footer: <EmailConfirmationFooter />, Temporarily, until the resend functionality is implemented
    },
};
