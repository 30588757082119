import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Row } from "reactstrap";
import DnaLoader from "../../../components/DnaLoader";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Paper from "../../../components/Paper";
import { useStores } from "../../../store";
import DeviceLicenseButton from "./DeviceLicenseButton";
import DeviceLicenseTable from "./DeviceLicenseTable";


const DeviceLicense:React.FC = observer(() => {
    const { deviceLicensesStore } = useStores();

    React.useEffect(() => {
        deviceLicensesStore.fetchLicenses();
    }, [deviceLicensesStore, deviceLicensesStore.isCreated])
    
    return (
        <Layout>
            <>
                <PageTitle 
                    heading="Device License"
                    icon="fas fa-folder-open icon-gradient bg-warm-flame"
                    subheading="Generate EDGE Device Licenses"
                    showCloseButton={true}
                />
                <DeviceLicenseButton />
                {!deviceLicensesStore.isFetched && <DnaLoader />}
                <Row>
                    <Col sm={12}>
                        <Paper rounded hidden={!deviceLicensesStore.isFetched}>
                            <DeviceLicenseTable 
                                items={Object.values(deviceLicensesStore.list)}
                                isLoading={true}
                            />
                        </Paper>
                    </Col>
                </Row>
            </>
        </Layout>
    );
});

export default DeviceLicense;