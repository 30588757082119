export const dateConfig = {
    formats: {
        date: 'DD MMM YYYY, hh:mm a',
        dateOnlyDay: 'MM/DD/YYYY',
        dateOnlyDayTypeDate: 'YYYY-MM-DD',
        dateUSFormat: 'DD MMM YYYY',
        dateWithoutAmPm: 'DD MMM YYYY, HH:mm',
        dateLicenseFormat: 'YYYY-MM-DD HH:mm:ss.SSS'
    },
};
