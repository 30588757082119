import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'reactstrap';

interface Props extends ModalProps {
    body: string;
    onConfirm: () => void;
    busyIndication?: boolean;
    title?: string;
    buttonText?: string;
    buttonColor?: string;
}

const DeleteConfirmation: React.FC<Props> = ({
    isOpen,
    body,
    onConfirm,
    onClosed,
    busyIndication,
    title,
    buttonText,
    buttonColor,
    ...rest
}) => {
    return (
        <Modal isOpen={isOpen} toggle={onClosed} {...rest}>
            <ModalHeader toggle={onClosed}>{title ?? 'Confirming Deletion'}</ModalHeader>
            <ModalBody className='app-component-modal-body__md text-break'>{body}</ModalBody>
            <ModalFooter>
                <Button color={buttonColor ?? 'danger'} onClick={onConfirm} disabled={busyIndication}>
                    {buttonText ?? 'Delete'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteConfirmation;
