import { Routes } from '../router/routes';
import React from 'react';
import { ProjectType } from '../models/api';

export interface MenuItem {
    icon: string;
    label: React.ReactNode;
    to: string;
}

export const navigation: {
    [key: string]: {
        [key: string]: MenuItem;
    };
} = {
    dashboard: {
        projects: {
            icon: 'fas fa-folder-open',
            label: 'All Projects',
            to: Routes.Dashboard
        }
    },

    [ProjectType.ObjectDetection]: {
        labels: {
            icon: 'fas fa-tags',
            label: 'Labels',
            to: Routes.Labels
        },
        dataset: {
            icon: 'fas fa-images',
            label: 'Dataset',
            to: Routes.Dataset
        },
        modelTraining: {
            icon: 'fas fa-atom',
            label: 'Model Training',
            to: Routes.ModelTraining
        },
        modelSimulation: {
            icon: 'fas fa-play',
            label: 'Model Simulation',
            to: Routes.ModelSimulation
        },
        download: {
            icon: 'fas fa-download',
            label: 'Download',
            to: Routes.Download
        }
    },

    [ProjectType.PoseDetection]: {
        modelSimulation: {
            icon: 'fas fa-play',
            label: 'Model Simulation',
            to: Routes.ModelSimulation
        },
        download: {
            icon: 'fas fa-download',
            label: 'Download',
            to: Routes.Download
        }
    },

    [ProjectType.Modeling3d]: {
        modelSimulation: {
            icon: 'fas fa-play',
            label: 'Model Generator',
            to: Routes.ModelGenerator
        }
    },

    [ProjectType.PredictiveMaintenance]: {
        modelSimulation: {
            icon: 'fas fa-play',
            label: 'Model Generator',
            to: Routes.PredictiveMaintenanceModelTraining
        }
    },

    admin: {
        users: {
            icon: 'fas fa-users',
            label: 'Users',
            to: Routes.AdminUserList
        },
        runningTrainings: {
            icon: 'fas fa-atom',
            label: 'Running Trainings',
            to: Routes.AdminRunningTrainings
        },
        runningSimulations: {
            icon: 'fas fa-atom',
            label: 'Running Simulations',
            to: Routes.AdminRunningSimulations
        },
        helpVideos: {
            icon: 'fas fa-video',
            label: 'Help Videos',
            to: Routes.AdminHelpVideos
        },
        deviceLicense: {
            icon: "fas fa-folder-open",
            label: 'Device License',
            to: Routes.AdminDeviceLicense
        },
        userActivity: {
            icon: 'fas fa-users',
            label: 'User Activity',
            to: Routes.AdminUserActivity
        },
        trainingReport: {
            icon: 'fas fa-chart-pie',
            label: 'Training Report',
            to: Routes.AdminTrainingReport
        }
    }
};
