import Slider, { SliderProps } from 'rc-slider'
import { FC } from 'react';

const CustomSlider: FC<SliderProps> = ({ ...rest }) => {
    const primaryColor = '#545CD8';
    return (
        <Slider
            trackStyle={{ backgroundColor: primaryColor }}
            handleStyle={{ borderColor: primaryColor }}
            activeDotStyle={{ borderColor: primaryColor }}
            { ...rest }
        />
    );
};

export default CustomSlider;
