import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Routes } from '../../../router/routes';

export const SignInFooter = () => {
    return (
        <Container>
            {/* FSAI-271 */}
            {/* uncommented by grif - need a way to register one user for stage and prod */}
            {<Row xs={6}>
                <Col>
                    <NavLink to={Routes.ForgotPwd} className={'login-page__small-text'}>
                        <b>Forgot Password?</b>
                    </NavLink>
                </Col>
                <Col className={'text-right'}>
                    <NavLink to={Routes.SignUp} className={'login-page__small-text'}>
                        <b>Register now</b>
                    </NavLink>
                </Col>
            </Row>}
        </Container>
    );
};
