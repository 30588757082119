import React, { FunctionComponent } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalProps,
} from 'reactstrap';

interface Props extends ModalProps{
	children: JSX.Element,
}

export const WelcomeModal: FunctionComponent<Props> = ({
	isOpen,
	children,
	onSubmit,
	onClosed,
	...rest
}) => {
	return (
		<Modal isOpen={isOpen} toggle={onClosed} {...rest} size="lg">
			<ModalHeader toggle={onClosed}>Welcome!</ModalHeader>
			<ModalBody className="app-component-modal-body__md p-4">
				{children}
			</ModalBody>
			<ModalFooter>
				<Button
					color="success"
					onClick={onSubmit}
				>
					Get Started!
				</Button>
			</ModalFooter>
		</Modal>
	);
};