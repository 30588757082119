import React, { HTMLAttributes, MouseEventHandler, useRef, useState, useMemo } from 'react';
import { Coordinate } from '../../models/geometry';
import Box from './Box';

interface Props extends HTMLAttributes<HTMLDivElement> {
    show: boolean;
    boxWidth?: number;
    boxHeight?: number;
}

const GhostBox: React.FC<Props> = ({ children, show, boxWidth = 10, boxHeight = 10, ...rest }) => {
    const [position, setPosition] = useState<Coordinate>();
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [halfWidth, halfHeight] = useMemo(() => [boxWidth / 2, boxHeight / 2], [boxWidth, boxHeight]);

    const handleMouseEnter: MouseEventHandler<HTMLDivElement> = (e) => {
        setPosition(undefined);
    };

    const handleMouseLeave: MouseEventHandler<HTMLDivElement> = (e) => {
        setPosition(undefined);
    };

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
        if (!wrapperRef.current) {
            return;
        }
        const rect = wrapperRef.current.getBoundingClientRect();

        let x = e.nativeEvent.x - rect.x;
        let y = e.nativeEvent.y - rect.y;

        if (x + halfWidth > rect.width) {
            x = rect.width - halfWidth
        } else if (x - halfWidth < 0) {
            x = halfWidth
        }

        if (y + halfHeight > rect.height) {
            y = rect.height - halfHeight
        } else if (y - halfHeight < 0) {
            y = halfHeight
        }

        setPosition({
            x: x - halfWidth,
            y: y - halfHeight
        });
    };

    return (
        <div
            {...rest}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            ref={wrapperRef}
        >
            {children}

            {(show && position) && (
            <>
                <Box rect={{x: position.x, y: position.y, width: boxWidth, height: boxHeight}} />
            </>
            )}
        </div>
    );
};

export default GhostBox;
