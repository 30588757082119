import React from 'react';
import { NewFileUploadForm } from './NewFileUploadForm';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
} from 'reactstrap';
import { FileUploadElement } from '../../../models/api';

interface Props extends ModalProps {
    onConfirm: (values: FileUploadElement) => void;
}

export const NewFileUploadModal: React.FC<Props> = (props) => {
    const { isOpen, onClosed,  onConfirm } = props;
    const formId = 'add-file-upload-form';

    return (
        <Modal isOpen={isOpen} toggle={onClosed}>
            <ModalHeader toggle={onClosed}>Add File Upload</ModalHeader>
            <ModalBody>
                <NewFileUploadForm
                    formId={formId}
                    onConfirm={onConfirm}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="success" form={formId}>
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};
