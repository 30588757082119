import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import { DatasetAnalysisButton } from './DatasetAnalysisButton';
import ImageListTable from './ImageListTable';
import { ImportButton } from './ImportButton';
import ExportButton from './ExportButton';
import { SearchBox } from './SearchBox';
import { useStores } from '../../store';
import { observer } from 'mobx-react-lite';
import DnaLoader from '../../components/DnaLoader';
import { AddFilesModal } from './AddFilesModal';
import ImageUploadIndicator from './ImageUploadIndicator';
import { ProjectType } from '../../models/api';

export const DatasetPage: React.FC = observer(() => {
    const { datasetImageStore, imageToZipStore, projectsStore } = useStores();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const project = projectsStore.current!;

    const isObjectDetection = project.type === ProjectType.ObjectDetection;

    const uploadingDataStatus = datasetImageStore.uploadingDataForProject[project.id]?.status;
    const uploadingDataTotal = datasetImageStore.uploadingDataForProject[project.id]?.total;
    const uploadingDataCurrent = datasetImageStore.uploadingDataForProject[project.id]?.current;
    const uploadingDataChunk = datasetImageStore.uploadingDataForProject[project.id]?.chunk;

    const isAddButtonDisabled = imageToZipStore.inProgress || datasetImageStore.hasUnprocessedItems;

    useEffect(() => {
        if (uploadingDataStatus) {
            setOpenModal(false)
        }
    }, [uploadingDataStatus]);

    return (
        <Layout>
            <>
                <PageTitle
                    heading="Your Dataset"
                    icon="fas fa-image icon-gradient bg-warm-flame"
                    subheading="Upload and annotate your dataset"
                    showCloseButton={true}
                />
                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center flex-grow-1">
                        <div className="mr-3">
                            <Button
                                className="btn mr-1 mr-lg-2 text-nowrap pl-5 pr-5"
                                color='success'
                                id={'pages-dataset_open-modal-button'}
                                onClick={()=>setOpenModal(true)}
                                disabled={isAddButtonDisabled}>
                                Add
                            </Button>
                        </div>
                        <ImageUploadIndicator
                            status={uploadingDataStatus}
                            total={uploadingDataTotal}
                            current={uploadingDataCurrent}
                            chunk={uploadingDataChunk}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <SearchBox />
                        <DatasetAnalysisButton />
                        {isObjectDetection ? <ImportButton /> : null}
                        <ExportButton />
                    </div>
                </div>


                {!datasetImageStore.state.images.fetched && <DnaLoader />}

                <Row>
                    <Col sm={12}>{datasetImageStore.state.images.fetched && <ImageListTable />}</Col>
                </Row>

                <AddFilesModal openModal={openModal} onClose={() => setOpenModal(false)} />
            </>
        </Layout>
    );
});
