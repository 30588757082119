import { Duration } from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

//TODO add hours, days
export const formatDurationWithUnitName = (duration: Duration) => {
    const truncatedMinutes = Math.trunc(duration.asMinutes());
    return truncatedMinutes > 0
        ? `${truncatedMinutes} min`
        : `${Math.trunc(duration.asSeconds())} sec`;
};

export const getFormattedLicenseExpiry = (licenseExpiry?: string | null) => {
    if (!licenseExpiry) {
        return ['', ''];
    }
    const licenseExpiryDatetime = dayjs(
        `${licenseExpiry.substr(0, 10)} 23:59`,
        'YYYY-MM-DD HH:mm'
    );
    return [
        licenseExpiryDatetime.format('D MMM YYYY, HH:mm'),
        licenseExpiryDatetime.diff(dayjs(), 'day') + 1,
        licenseExpiryDatetime.diff(dayjs(), 'minutes')
    ];
}
