import { KonvaEventObject } from "konva/lib/Node";
import React from "react";
import { Circle, Group, Layer, Line } from "react-konva";
import { Points } from "../index";

interface Props {
  points: Points[];
  flattenedPoints: Points[];
  isPolygonComplete: boolean;
  setIsMouseOverPoint: React.Dispatch<React.SetStateAction<boolean>>;
}

const Annotate: React.FC<Props> = (props) => {
  const { points, isPolygonComplete, setIsMouseOverPoint, flattenedPoints } = props;

  const handleMouseOverStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    e.target.scale({ x: 2, y: 2 });
    setIsMouseOverPoint(true);
  };

  const handleMouseOutStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    e.target.scale({ x: 1, y: 1 });
    setIsMouseOverPoint(false);
  }

  return (
    <Layer>
      <Group
        name="polygon"
      >
        <Line
          points={flattenedPoints?.flatMap(p => [p.x, p.y])}
          stroke="#00F1FF"
          strokeWidth={2}
          closed={isPolygonComplete}
          fill="rgb(140,30,255,0.5)"
        />
        {points?.map((point: Points, index: number) => {
          const startPointAttr =
            index === 0
              ? {
                hitStrokeWidth: 8,
                onMouseOver: handleMouseOverStartPoint,
                onMouseOut: handleMouseOutStartPoint
              } : null;
          return (
            <Circle
              key={index}
              x={point.x}
              y={point.y}
              radius={3}
              fill="#FF019A"
              stroke="#00F1FF"
              strokeWidth={2}
              {...startPointAttr}
            />
          )
        })}
      </Group>
    </Layer>
  );
}

export default Annotate;