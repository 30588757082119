import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import EmailConfirmationForm from './form';
import { useStores } from '../../../store';
import { Routes } from '../../../router/routes';

export const EmailConfirmation = observer(() => {
    const history = useHistory();
    const { search } = useLocation();
    const { userStore } = useStores();

    const emailToVerify = userStore.requireSignUpVerifyEmail;
    const urlParams = new URLSearchParams(search);
    const emailToVerifyFromUrl = urlParams.get('email');
    const code = urlParams.get('code');

    const handleSubmit = async (code: string) => {
        if (!emailToVerify) {
            return history.push(Routes.Dashboard);
        }

        await userStore.confirmSignUp(code);
        return history.push(Routes.Dashboard);
    };

    useEffect(() => {
        if (emailToVerifyFromUrl) {
            userStore.setRequireSignUpVerifyEmail(emailToVerifyFromUrl);
        }
    }, [emailToVerifyFromUrl, userStore]);

    return (
        <EmailConfirmationForm
            onSubmit={handleSubmit}
            disabled={userStore.isLoading}
            email={emailToVerify}
            code={code}
        />
    );
});
