import mailbox from '../../../assets/app/images/mailbox.svg';
import { Button, Col } from 'reactstrap';
import { Form, Formik } from 'formik';
import { FormField } from '../../../components/Form';
import React from 'react';
import * as Yup from 'yup';

interface Values {
    code: string;
}

interface Props {
    onSubmit: (code: string) => void;
    disabled: boolean;
    email?: string;
    code: string | null;
}

const validationSchema = Yup.object().shape({
    code: Yup.string().required('required').trim()
});

const EmailConfirmationForm: React.FC<Props> = ({ email, onSubmit, disabled, code = null }) => {
    return (
        <>
            <div className='d-flex justify-content-center align-items-center mb-4'>
                <img src={mailbox} alt='mailbox image' />
            </div>
            <Col className={'text-center'}>
                <p>We’ve sent you an email to <span className='font-weight-bold'>{email}</span></p>
                <p>Enter the verification code you received by email</p>
            </Col>

            <p className='text-center'>
                If your inbox is still empty, take a look in your spam folder.
                If you still can’t spot an email, please double check that you
                entered in the correct email address
            </p>

            <Formik<Values>
                initialValues={{
                    code: code || ''
                }}
                onSubmit={(values) => {
                    onSubmit(values.code);
                }}
                validationSchema={validationSchema}
            >
                {() => {
                    return (
                        <Form>
                            <FormField
                                formName=''
                                field='code'
                                placeholder='Verification code'
                                isFirst
                            />

                            <Button
                                className='mt-4'
                                color='primary'
                                size='lg'
                                type='submit'
                                block
                                disabled={disabled}
                            >
                                Verify and Continue Sign In
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default EmailConfirmationForm;
