import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Row } from "reactstrap";
import DnaLoader from "../../../components/DnaLoader";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Paper from "../../../components/Paper";
import { useStores } from "../../../store";
import MonthPicker from "./MonthPicker";
import RuntimePie from "./RuntimePie";


const TrainingReport:React.FC = observer(() => {
    const { detectionModelsStore } = useStores();
    return (
        <Layout>
            <>
                <PageTitle 
                    heading="Training Report"
                    icon="fas fa-chart-pie icon-gradient bg-warm-flame"
                    subheading="Report in total training runtime, by account"
                    showCloseButton={true}
                />
                <Row>
                    <Col sm={12}>
                        <MonthPicker />
                        <Paper rounded>
                            <RuntimePie
                                accountNames={
                                    detectionModelsStore.trainingReport.ListByUserId.map(
                                        (val) => val.project.user?.email || ""
                                )} 
                                runtimeValues={
                                    detectionModelsStore.trainingReport.ListByUserId.map(
                                        (val) => +val.totalRuntime || 0
                                )}
                            />
                        </Paper>
                    </Col>
                </Row>
            </>
        </Layout>
    );
});

export default TrainingReport;