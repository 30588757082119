import * as Yup from 'yup';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Formik } from 'formik';

import NewProjectForm from '../NewProjectForm';
import { Project, ProjectType } from '../../../models/api';
import { useStores } from '../../../store';
import { NewProjectModal } from '../NewProjectModal';

export interface NewProjectFormValues {
    name: string;
    type: ProjectType;
    templateProjectId?: string;
}

interface Props {
    onSubmit: (values: NewProjectFormValues) => void;
    isLoading: boolean;
}

const NewProjectPanel: React.FC<Props> = ({ onSubmit, isLoading }) => {
    const formName = 'pages-dashboard-new-project-form';
    const { projectsStore } = useStores();

    const [newProjectModal, setNewProjectModal] = useState<boolean>(false);

    const handleSubmit = (values: NewProjectFormValues) => {
        const newValues = {
            ...values,
            name: values.name.trim(),
        };
        onSubmit && onSubmit(newValues);
        setNewProjectModal(false);
    };

    const handleToggle = () => {
        setNewProjectModal(!newProjectModal);
    };

    const handleCreateNewProjectButtonClick = () => {
        setNewProjectModal(true);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .test(
                'is-project-exists',
                'A project with the same name already exists',
                (value) => !projectsStore.list.some((project: Project) => project.name === value)
            )
            .required('Please fill in this field')
            .trim(),
    });

    return (
        <Formik<NewProjectFormValues>
            initialValues={{
                name: '',
                type: ProjectType.ObjectDetection
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ resetForm }) => {
                return (
                    <>
                        <div className="d-flex align-items-center he-100">
                            <Button
                                color="primary"
                                size="lg"
                                disabled={isLoading}
                                onClick={handleCreateNewProjectButtonClick}
                            >
                                Create New Project
                            </Button>
                        </div>
                        <NewProjectModal toggle={handleToggle} isOpen={newProjectModal} onClosed={resetForm}>
                            <NewProjectForm formName={formName} />
                        </NewProjectModal>
                    </>
                );
            }}
        </Formik>
    );
};

export default NewProjectPanel;
