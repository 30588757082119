import { Formik, FormikBag, FormikHelpers, FormikProps, withFormik } from 'formik';
import React, { FunctionComponent } from 'react';
import { Form } from 'formik';
import { SignUpFormData } from '../../../models/login/sign-up';
import * as Yup from 'yup';
import { FormField, PwdFormField } from '../../../components/Form';
import { Button, Col, Row } from 'reactstrap';
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify';
import history from '../../../router/history';
import { Routes } from '../../../router/routes';
import { UserUsageType, UserUsageTypeUI } from '../../../models/api';
import { useStores } from '../../../store';
import Policy from './PrivacyPolicy';
import DnaLoader from '../../../components/DnaLoader';

interface Props {}
type FormProps = Props & FormikProps<SignUpFormData>;

const _SignUpForm: FunctionComponent<FormProps> = (props) => {
    const formName = 'sign-up-form';
    const isLoading = !props.isValidating && props.isSubmitting;
    const isSubmissionFailed =
        !props.isSubmitting && props.status && props.status.error;

    return (
        <>
            <Form
                noValidate
                className="sign-up-form"
                style={{ display: !isLoading ? 'block' : 'none' }}
            >
                <Row xs={6} form>
                    <Col>
                        <FormField
                            formName={formName}
                            field={'name'}
                            type={'text'}
                            placeholder={'Name'}
                            isFirst
                        />
                    </Col>
                    <Col>
                        <FormField
                            formName={formName}
                            field={'surname'}
                            type={'text'}
                            placeholder={'Surname'}
                        />
                    </Col>
                </Row>
                <FormField
                    formName={formName}
                    field={'usageType'}
                    type={'select'}
                >
                    <option key="" value="">
                        What will you use this for?
                    </option>

                    {Object.values(UserUsageType).map(value => {
                        return (
                            <option value={value} key={value}>
                                {UserUsageTypeUI[value]}
                            </option>
                        )
                    })}
                </FormField>

                <hr className={'my-4'} />

                <FormField
                    formName={formName}
                    field={'email'}
                    type={'email'}
                    placeholder={'Email'}
                />

                <Row xs={6} form>
                    <Col>
                        <PwdFormField
                            formName={formName}
                            field={'pwd'}
                            placeholder={'Password'}
                        />
                    </Col>
                    <Col>
                        <PwdFormField
                            formName={formName}
                            field={'confirmPwd'}
                            placeholder={'Repeat Password'}
                        />
                    </Col>
                </Row>

                <Row xs={6} form>
                    <Col className="d-flex justify-content-center mt-4 mb-2">
                        <Policy action={'signing up'} />
                    </Col>
                </Row>

                <div className={'pt-4'}>
                    <Button
                        color={'primary'}
                        type={'submit'}
                        block
                        disabled={isLoading}
                        size='lg'
                    >
                        Register
                    </Button>
                </div>
            </Form>
            <div
                className="sign-up-form"
                style={{ display: isLoading ? 'block' : 'none' }}
            >
                <DnaLoader />
            </div>
        </>
    );
};

export const SignUpForm: FunctionComponent<Props> = (props) => {
    const {userStore} = useStores();

    const initialValues:SignUpFormData = {
        name: '',
        surname: '',
        email: '',
        usageType: undefined,
        pwd: '',
        confirmPwd: '',
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('required'),
        usageType: Yup.string().required('required'),
        email: Yup.string().required('required').email('invalid email format'),
        pwd: Yup.string().required('required').min(8, 'at least 8 chars'),
        confirmPwd: Yup.string()
            .required('required')
            .oneOf([Yup.ref('pwd')], `passwords don't match`),
        policy:  Yup.bool().oneOf([true], 'Accept Terms of Use & Privacy policy'),
    });

    const handleSubmit = async (
        values: SignUpFormData,
        formikHelpers: FormikHelpers<SignUpFormData>
    ) => {
        try {
            const user = await Auth.signUp({
                username: values.email,
                password: values.pwd,
                attributes:{
                    email: values.email,
                    name: values.name,
                    family_name: values.surname
                }
            });

            await userStore.createUser(user.userSub, values);

            history.push(Routes.EmailConfirmation);
        } catch (error) {
            toast(error.message, {type:toast.TYPE.ERROR});
        }

        formikHelpers.setSubmitting(false);
    }

    return <Formik<SignUpFormData> initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {(props) => (<_SignUpForm {...props} />)}
    </Formik>
}
