//@ts-ignore
import ReactStars from 'react-rating-stars-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';

import { HeaderWithTooltip } from '../../../components/BootstrapTable';
import { std, mean } from 'mathjs';

interface Props {
    labelsValues: number[];
}

const BalancingScoreChart: FC<Props> = observer(({ labelsValues }) => {
    const [score, setScore] = useState<number>();

    useEffect(() => {
        let scoreResult;
        if (labelsValues.length > 0) {
            const scoreValue = Math.round(10 * (1 - std(labelsValues) / mean(labelsValues))) / 2;
            scoreResult = scoreValue > 1 ? scoreValue : 1;
        }
        setScore(scoreResult);
    }, [labelsValues]);
    return (
        <div className="d-flex align-items-center w-100">
            <div className="mr-2 font-weight-bold">Balancing Score:</div>
            {score !== undefined ? (
                <>
                    <ReactStars
                        size={24}
                        value={score}
                        edit={false}
                        isHalf={true}
                        emptyIcon={<FontAwesomeIcon icon={farStar} />}
                        halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
                        filledIcon={<FontAwesomeIcon icon={fasStar} />}
                        color="#ffd700"
                        activeColor="#ffd700"
                    />
                    <div className="ml-2">
                        <HeaderWithTooltip
                            tooltipContent={'Watch the help video'}
                            tooltipId={`balancing-score_info-button`}
                        />
                    </div>
                </>
            ) : (
                <span className="text-danger">
                    You have no annotated images
                </span>
            )}
        </div>
    );
});

export default BalancingScoreChart;
