import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import container from '../../container/container';
import { RootStore } from './root';
import { Storage } from 'aws-amplify';
import { DatasetExportType } from '../../models/api';

const api = container.apiClient;

interface PathsToZip {
    [key: string]: string
}

export class ImageToZipStore {
    private rootStore: RootStore;
    private fileName = 'dataset.zip';
    private zipType = '';
    private pathsToZipFile: PathsToZip = {
        images: '',
        voc: '',
        csv: ''
    };
    private pathsToZipFolder = '';
    isZipExist = false;
    inProgress = false;
    isError = false;
    isDataZipped = false;
    isDataSetChanged = true;


    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async zip(formatType: DatasetExportType) {
        try{
            if (this.rootStore.projectsStore.current?.id === undefined || this.inProgress) {
                return;
            }

            if (this.rootStore.userStore.impersonationUser){
                toast('This function is not supported in impersonation mode', {type:toast.TYPE.WARNING});
                return;
            }

            runInAction(() => {
                this.inProgress = true;
            });

            this.zipType = formatType.toLowerCase();
            this.pathsToZipFolder = `projects/${this.rootStore.projectsStore.current.id}/zip`;
            this.pathsToZipFile = {
                images: `${this.pathsToZipFolder}/images_dataset.zip`,
                voc: `${this.pathsToZipFolder}/voc_dataset.zip`,
                csv: `${this.pathsToZipFolder}/csv_dataset.zip`
            };

            await this.checkIfZipExists();

            if (!this.isZipExist)
                await api.datasetZipDownload(`${this.rootStore.projectsStore.current.id}/${this.zipType}`);

            this.setZipName(formatType);
            this.getZip();
        }
        catch (e){
            toast(e?.response?.data?.errors?.message ?? e.message, {type:toast.TYPE.ERROR});

            runInAction(() => {
                this.isError = true;
                this.inProgress = false;
            });
        }
    }

    private async getZip() {
        const {Body} = await Storage.get(this.pathsToZipFile[this.zipType], {
            download: true,
            level: 'private'
        }) as any;

        runInAction(() => {
            this.inProgress = false;
            this.isDataZipped = true;
        });

        this.saveAs(Body, this.fileName);
    }

    private setZipName(typeName:string){
        if(!this.rootStore.projectsStore.current) return

        const projName = this.rootStore.projectsStore.current.name.replaceAll(' ', '_').toLowerCase();

        this.fileName = `${projName}_${typeName}.zip`;
    }

    private saveAs(blob: any, fileName: string) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    private async checkIfZipExists() {

        const result = await Storage.list(this.pathsToZipFolder, {level: 'private'});

        if (result.length) {
            const dataExists = result.filter((el: any) => el?.key === this.pathsToZipFile[this.zipType]);

            runInAction(() => {
                this.isZipExist = !!dataExists.length;
            });
        } else
            runInAction(() => {
                this.isZipExist = false;
            });
    }
}
