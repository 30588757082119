import React from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';

import {
    FileUploadElement
} from '../../../models/api';
import { noDataText } from '../../../components/BootstrapTable';
import dayjs from 'dayjs';
import { dateConfig } from '../../../config/date';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

interface Props {
    items: FileUploadElement[];
}

const FileUploadListTable: React.FC<Props> = ({items}) => {

    const handleButtonView = (link: string) => async () => {
        window.open(link,'_blank');
    }

    const columns: ColumnDescription<FileUploadElement>[] = [
        {
            dataField: 'date',
            text: 'Date',
            headerClasses: 'w-10',
            classes: 'text-nowrap',
            formatter: (_cel, row) => dayjs(row.date).format(dateConfig.formats.dateUSFormat)
        },
        {
            dataField: 'type',
            text: 'Type',
            headerClasses: 'w-10',
        },

        {
            dataField: 'title',
            text: 'Document Name',
            headerClasses: 'text-nowrap',
            classes: 'text-break'
        },

        {
            dataField: 'link',
            text: 'View',
            align: 'right',
            headerAlign: 'right',
            formatter: (_cel, row) => {
                const tooltipButtonId = `pages-model-simulation-video-list-table_view-button-${row.id}`;
                return (
                    <ActionButton
                        color='success'
                        id={tooltipButtonId}
                        tooltipText='View document'
                        disabled={!row.link}
                        onClick={handleButtonView(row.link)}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </ActionButton>
                );
            }
        },
    ];

    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={items ?? []}
                columns={columns}
                striped
                bordered={false}
                wrapperClasses="table-responsive"
                classes='m-0'
                noDataIndication={noDataText}
            />

        </>
    );
};

export default FileUploadListTable;
