import container from "../../container/container";
import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./root";
import { createProgressToast } from "../../components/Toasts/ProgressToast";
import { toast } from "react-toastify";
import { DeviceLicense, SetDeviceLicense } from "../../models/api";


const api = container.apiClient;

export class DeviceLicensesStore {

    list: DeviceLicense[] = [];

    isFetched: boolean = false;
    isLoading: boolean = false;
    isError: boolean = false;
    isCreated: boolean = false;

    rootStore: RootStore

    constructor (rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async fetchLicenses() {
        try {
            const { data }  = await api.licensesList();
            runInAction(() => {
                this.list = data;
                this.isFetched = true;
            })
        } catch(e) {
            runInAction(() => {
                this.isFetched = false;
            })
        } finally {
            runInAction(() => {
                this.isLoading = false;
            })
        }
    }

    async addItem(license: SetDeviceLicense) {
        const toastId = createProgressToast();
        try {
            await api.addLicense(license);
            runInAction(() => {
                this.isError = false;
                this.isCreated = true;
            })
        } catch(e) {
            runInAction(() => {
                this.isError = true;
                this.isCreated = false;
            })
        } finally {
            runInAction(() => {
                this.isCreated = false;
                toast.dismiss(toastId);
            })
        }
    }

    async deleteItem(id: DeviceLicense['id']) {
        const toastId = createProgressToast();
        try{
            await api.licenseDelete(id);
            runInAction(() => {
                this.list = this.list.filter(item => item.id !== id);
            })
        } catch(e) {
            this.isError = true;
        } finally {
            runInAction(() => {
                toast.dismiss(toastId);
            })
        }
    }
}