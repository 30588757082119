import { KonvaEventObject } from 'konva/lib/Node';
import { Line as LineType, LineConfig } from 'konva/lib/shapes/Line';
import React from 'react';
import { Line, Circle, Group, Layer, Path, Shape, Tag, Label, Text } from 'react-konva'
import { DatasetImage, PolygonAnnotation } from '../../../../models/api';
import { Points, StageProps } from '../index';

interface Props {
    layouts: any;
    setLayouts: React.Dispatch<React.SetStateAction<any>>;
    handleDeleteAnnotation: (id: string) => void;
    onSave: (newAnnotationList: DatasetImage['polygonAnnotations']) => void;
    isEdit: boolean;
    isIncreasePointRadius: boolean;
}

interface TooltipState {
    x: number,
    y: number,
    labelId: string
}

const TOOLTIP_X_OFFSET = 15
const TOOLTIP_Y_OFFSET = 5

const Polygon: React.FC<Props> = (props) => {
    const {
        layouts,
        setLayouts,
        handleDeleteAnnotation,
        onSave,
        isEdit,
        isIncreasePointRadius
    } = props;

    const [isDrag, setIsDrag] = React.useState<boolean>(true);
    const polygonRef = React.useRef<LineType<LineConfig> | null>(null)
    const [tooltipState, setTooltipState] = React.useState<TooltipState>({x: 0, y: 0, labelId: ''})

    const colors = React.useMemo(() => {
        return {
            primary: 'rgba(84, 92, 216, 0.5)',
            warning: 'rgba(247, 185, 36, 0.5)',
            secondary: 'rgba(108, 117, 125, 0.5)',
            success: 'rgba(58, 196, 125, 0.5)',
            info: 'rgba(48, 177, 255, 0.5)',
            danger: 'rgba(217, 37, 80, 0.5)'
        }
    }, []);

    const handleMouseDown = (e:KonvaEventObject<MouseEvent>) => {
        const isRightButton = e.evt.which === 3;
        if(isRightButton) {
            setIsDrag(false);
        }
    }

    const handleMouseUp = (e:KonvaEventObject<MouseEvent>) => {
        const isRightButton = e.evt.which === 3;
        if(isRightButton) {
            setIsDrag(true);
        }
    }

    function getRelativePointerPosition(node: StageProps | null) {
        if(!node) return null;

        const transform = node.getAbsoluteTransform().copy();
        transform.invert();
        const pos = node.getStage().getPointerPosition();

        if(!pos) return null;
        
        return transform.point(pos);
    }

    const handleMouseOverStartPoint = (e: KonvaEventObject<MouseEvent>) => {
        e.target.scale({ x: 2, y: 2 });
    };

    const handleMouseOutStartPoint = (e: KonvaEventObject<MouseEvent>) => {
        e.target.scale({ x: 1, y: 1 });
    }

    const handleGroupMouseOver = (e: KonvaEventObject<MouseEvent>, id: number) => {
        if (!layouts[id].isPolygonComplete) return;
        e.target.getStage()!.container().style.cursor = "crosshair";
    }

    const handleGroupMouseOut = (e: KonvaEventObject<MouseEvent>) => {
        e.target.getStage()!.container().style.cursor = "default";
    }

    const handleGroupDragEnd = (e: KonvaEventObject<DragEvent>, elementId: number, regionId: string) => {
        if (e.target.name() === 'polygon') {
            let result: any = [];
            let copyPoints = [...layouts[elementId].points];
            copyPoints.map((point) =>
                result.push({ x: point.x + e.target.x(), y: point.y + e.target.y() })
            );
            e.target.position({ x: 0, y: 0 });

            const newAnnotation = {
                id: regionId,
                points: result,
                flattenedPoints: result,
                isPolygonComplete: true,
                datasetImageId: layouts[elementId].datasetImageId,
                label: layouts[elementId].label,
                labelId: layouts[elementId].labelId
            };

            const newRegionState = layouts?.map((item: PolygonAnnotation) => (item.id === regionId ? newAnnotation : item));
            setLayouts(newRegionState);
            onSave && onSave(newRegionState);
        }
    }

    const handlePointDragMove = (e: KonvaEventObject<DragEvent>, elementId: number, regionId: string) => {
        const stage = e.target.getStage();

        if(!stage) return null;

        const pos = getRelativePointerPosition(stage);

        if(!pos) return null;

        const index = e.target.index - 1;

        const result = [...layouts[elementId].points.slice(0, index), pos, ...layouts[elementId].points.slice(index + 1)];

        const newAnnotation = {
            id: regionId,
            points: result,
            flattenedPoints: result,
            isPolygonComplete: true,
            datasetImageId: layouts[elementId].datasetImageId,
            label: layouts[elementId].label,
            labelId: layouts[elementId].labelId
        };

        const newRegionState = layouts?.map((item: PolygonAnnotation) => (item.id === regionId ? newAnnotation : item));
        setLayouts(newRegionState);
        onSave && onSave(newRegionState);
    }   

    const handleDeleleAnnotation = (layout: PolygonAnnotation) => (e: KonvaEventObject<MouseEvent>) => {
        const isLeftButton = e.evt.which === 1;
        if(isLeftButton) {
            handleDeleteAnnotation(layout.id);
        }
    }

    const handleTooltipMove = (e: KonvaEventObject<MouseEvent>) => {
        const relativePosition = getRelativePointerPosition(e.target.getStage())
        if (relativePosition) {
            setTooltipState((prevState) => ({...prevState, x: relativePosition.x + TOOLTIP_X_OFFSET, y: relativePosition.y - TOOLTIP_Y_OFFSET}))
        }
        
    }

    const handleTooltipEnter = (layout: PolygonAnnotation) => (e: KonvaEventObject<MouseEvent>) => {
        e.target.moveToBottom()
        setTooltipState((prevState) => ({...prevState, labelId: layout.labelId}))
    }
    
    const handleTooltipLeave = (e: KonvaEventObject<MouseEvent>) => {
        setTooltipState((prevState) => ({...prevState, labelId: ''}))
    }

    return (
        <Layer>
            {layouts?.length !== 0 ? layouts?.map((layout: PolygonAnnotation, regionIndex: number) => {
                return (
                    <Group
                    key={layout.id}
                    name="polygon"
                    draggable={isEdit && isDrag}
                    onDragEnd={(e) => handleGroupDragEnd(e, regionIndex, layout.id)}
                    onMouseOver={(e) => handleGroupMouseOver(e, regionIndex)}
                    onMouseOut={handleGroupMouseOut}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    >
                        <Label 
                            x={tooltipState.x} 
                            y={tooltipState.y} 
                            visible={tooltipState.labelId === layout.labelId}
                        >
                            <Tag fill='black' lineJoin='round' shadowColor='black' opacity={0.5} stroke="grey" />
                            <Text text={layout.label?.name} fontFamily='Calibri' fontSize={18} fill='white' padding={5} />
                        </Label>
                        <Line
                            ref={polygonRef}
                            points={layout.flattenedPoints!.flatMap((p: Points) => [p.x, p.y])}
                            stroke={layout.label?.color}
                            strokeWidth={2}
                            closed
                            fill={layout.label?.color}
                            onMouseMove={handleTooltipMove}
                            onMouseEnter={handleTooltipEnter(layout)}
                            onMouseLeave={handleTooltipLeave}
                        />
                        {layout.points!.map((point: Points, index: number) => {
                            const startPointAttr =
                                index === 0
                                    ? {
                                        hitStrokeWidth: 8,
                                        onMouseOver: handleMouseOverStartPoint,
                                        onMouseOut: handleMouseOutStartPoint
                                    } : null;
                            return (
                                <Circle
                                    key={index}
                                    x={point.x}
                                    y={point.y}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    radius={isIncreasePointRadius ? 5 : 3}
                                    fill="#FF019A"
                                    stroke="#00F1FF"
                                    strokeWidth={isIncreasePointRadius ? 3 : 2}
                                    draggable={isEdit && isDrag}
                                    onDragMove={(e) => handlePointDragMove(e, regionIndex, layout.id)}
                                    {...startPointAttr}
                                />
                            )
                        })}
                        {isEdit ?
                            <Path
                                x={layout.flattenedPoints![0].x - 25}
                                y={layout.flattenedPoints![0].y - 25}
                                data='M45.363,36.234l-13.158-13.16l12.21-12.21c2.31-2.307,2.31-6.049,0-8.358c-2.308-2.308-6.05-2.307-8.356,0l-12.212,12.21   L11.038,1.906c-2.309-2.308-6.051-2.308-8.358,0c-2.307,2.309-2.307,6.049,0,8.358l12.81,12.81L1.732,36.831   c-2.309,2.31-2.309,6.05,0,8.359c2.308,2.307,6.049,2.307,8.356,0l13.759-13.758l13.16,13.16c2.308,2.308,6.049,2.308,8.356,0   C47.673,42.282,47.672,38.54,45.363,36.234z'
                                fill='#000000'
                                onClick={handleDeleleAnnotation(layout)}
                                scaleX={0.25}
                                scaleY={0.25}
                            /> : <>
                            </>
                        }
                    </Group>
                )
            }) :
                <>
                </>
            }
        </Layer>
    );
};

export default Polygon;