import React, { FunctionComponent } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import {Field} from 'formik'
import { FormFieldProps } from './types';
import { useFormField } from './hooks/useFormField';
import { useFocusEffect } from './hooks/useFocusEffect';

export const FormField: FunctionComponent<FormFieldProps> = (props) => {
    const {id, hasError, error} = useFormField(props.formName, props.field);
    const fieldRef = useFocusEffect(props.isFirst);

    const isSelect = props.type === 'select';
    const type = !isSelect ? props.type : '';
    const as = isSelect ? 'select' : '';

    return (
        <FormGroup>
            {props.label && <Label htmlFor={id}>{props.label}</Label>}
            <div>
                <Field
                    className={`form-control ${hasError && 'is-invalid'}`}
                    id={id}
                    name={props.field}
                    type={type}
                    as={as}
                    placeholder={props.placeholder}
                    innerRef={fieldRef}
                    readOnly={props.readOnly}
                >
                    {props.children}
                </Field>
                <FormFeedback>{error}</FormFeedback>
            </div>
        </FormGroup>
    );
};
