import React from 'react';
import { Router } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import cx from "classnames";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import RouteList from './router/RouteList';
import history from './router/history';
import "./assets/base.scss";
import MobileDeviceWarning from './components/MobileDeviceWarning';
import ReactGA from 'react-ga';

const GATrackingId = process.env.REACT_APP_GA_TRACKING_ID ?? '';
ReactGA.initialize(GATrackingId);
console.log(`Google Analytics has been initialized for ${GATrackingId}`);

// Initialize google analytics page view tracking
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

const App: React.FC<any> = (props) => {
    const mobileDeviceWarningRestriction = 768;
    let { width } = useResizeDetector();
    width = width as number;
    let {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        closedSmallerSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
    } = props;

    return (
        <>
            {document.documentElement.clientWidth < mobileDeviceWarningRestriction && <MobileDeviceWarning />}
            <Router history={history}>
                <div
                    className={cx(
                        'app-container app-theme-' + colorScheme,
                        { 'fixed-header': enableFixedHeader },
                        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                        { 'fixed-footer': enableFixedFooter },
                        {
                            'closed-sidebar':
                                enableClosedSidebar || width < 1250,
                        },
                        {
                            'closed-sidebar-mobile':
                                closedSmallerSidebar || width < 1250,
                        },
                        { 'sidebar-mobile-open': enableMobileMenu },
                        { 'body-tabs-shadow-btn': enablePageTabsAlt }
                    )}
                >
                    <RouteList />
                    <ToastContainer
                        autoClose={5000}
                        hideProgressBar={true}
                        pauseOnHover={true}
                        closeOnClick={true}
                    />
                </div>
            </Router>
        </>
    );
};

const mapStateToProp = (state: any) => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default connect(mapStateToProp)(App);
