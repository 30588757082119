import React from 'react';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable, {
    PageButtonRendererOptions,
    TableChangeState,
    TableChangeType
} from 'react-bootstrap-table-next';
import { Pagination } from '../../../models/pagination';
import { observer } from 'mobx-react-lite';
import DnaLoader from '../../DnaLoader';
import { noDataText } from '../../../components/BootstrapTable';
import { ProjectType } from '../../../models/api';
import cn from 'classnames';

export interface TableWithPaginationProps {
    data: any[];
    columns: any[];
    wrapperClasses: string;
    pagination: Pagination;
    loading?: boolean;
    disabled?: boolean;
    selectRow?: any;
    projectType?: ProjectType;
}

const TableWithPagination: React.FC<TableWithPaginationProps> = observer((props) => {

    const handleTableChange = (type: TableChangeType, newState: TableChangeState<any>) => {
        if (type === 'sort') {
            const isSortingByAnnotated = newState.sortField === 'annotated';
            if (isSortingByAnnotated && props.projectType === ProjectType.ObjectClassification) {
                newState.sortField = 'classified';
            }
            const invertedSortOrder = newState.sortOrder === 'desc' ? 'asc' : 'desc';
            const sortOrder = isSortingByAnnotated ? invertedSortOrder : newState.sortOrder;
            props.pagination.update(1, props.pagination.sizePerPage, newState.sortField, sortOrder);
        }
    };

    const pageButtonRenderer = ({ page, active, disabled, title, onPageChange }: PageButtonRendererOptions) => {
        const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            onPageChange(page as number, props.pagination.sizePerPage);
        };
        return (
            <li
                className={cn('page-item', { disabled: disabled || props.disabled, active })}
                title={title}
            >
                <a href='#' className='page-link' onClick={handleClick}>{page}</a>
            </li>
        );
    };

    return (
        <PaginationProvider
            pagination={
                paginationFactory({
                    ...props.pagination.uiProps,
                    custom: true,
                    hideSizePerPage: true,
                    alwaysShowAllBtns: true,
                    onPageChange: props.pagination.update,
                    pageButtonRenderer
                })
            }
        >
            {({paginationProps, paginationTableProps}) => (
                <div className={props.wrapperClasses}>
                    <div className={`app-components__bootstrap-paginated-table-wrapper`}>
                        <BootstrapTable
                            {...paginationTableProps}
                            remote
                            bordered={false}
                            bootstrap4
                            keyField='id'
                            data={props.data}
                            columns={props.columns}
                            striped
                            wrapperClasses={`table-responsive app-components__bootstrap-paginated-table`}
                            classes='m-0'
                            onTableChange={handleTableChange}
                            noDataIndication={noDataText}
                            selectRow={ props.selectRow ? props.selectRow : { hideSelectColumn: true } }
                        />
                        {props.loading && props.data.length > 0 && (
                            <div className={'app-components__bootstrap-paginated-table-loading-overlay'}>
                                <DnaLoader />
                            </div>
                        )}
                    </div>
                    <PaginationListStandalone {...paginationProps} />
                </div>
            )}
        </PaginationProvider>

    )
})

export default TableWithPagination;
