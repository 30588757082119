import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps } from 'reactstrap';

interface Props extends ModalProps {
    body: string;
    onConfirm: () => void;
    onCancel: () => void;
    title?: string;
}

const ResetSettingsConfirmation: React.FC<Props> = ({ isOpen, body, onConfirm, onClosed, onCancel, title, ...rest }) => {

    return (
        <Modal isOpen={isOpen} toggle={onClosed} {...rest}>
            <ModalHeader toggle={onClosed}>{title || 'Warning'}</ModalHeader>
            <ModalBody className="app-component-modal-body__md">
                {body}
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={onConfirm}>
                    Ok
                </Button>
                <Button color="danger" onClick={onCancel}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ResetSettingsConfirmation;
