import React from 'react';
import { Button, NavItem, NavLink } from 'reactstrap';
import { Label } from '../../../../models/api';

interface Props {
    label: Label;
    isActive: boolean;
    isDisabled: boolean;
    onClick: () => void;
}

const LabelItem: React.FC<Props> = ({ label, isActive, isDisabled, onClick }) => {
    return (
        <NavItem key={label.id} className="mx-1">
            <Button
                style={{backgroundColor: isActive ? label.color : '', color: isActive ? '#fff' : label.color}}
                disabled={isDisabled}
                onClick={onClick}
            >
                {label.name}
            </Button>
        </NavItem>
    );
};

export default LabelItem;
