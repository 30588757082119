export const FreeLicenseTypes = [
  {
    name: 'Unlimited projects',
    default: true,
  },
  {
    name: '50 labels per project',
    default: true,
  },
  {
    name: '1000 images per datasets',
    default: true,
  },
  {
    name: 'Unlimited annotations',
    default: true,
  },
  {
    name: 'Unlimited trained models',
    default: true,
  },
  {
    name: 'Android App (30 day limit)',
    default: true,
  },
  {
    name: 'Android App Pro (unlimited)',
    available: false,
  },
    {
    name: 'PyTorch (PT / PLT)',
    available: false,
  },
  {
    name: 'TFLite (F32 / F16 / Int8)',
    available: false,
  },
  {
    name: 'Saved Model (.pb)',
    available: false,
  },
];

export const ProfessionalLicenseTypes = [
  {
    name: 'Unlimited projects',
    default: true,
  },
  {
    name: '50 labels per project',
    default: true,
  },
  {
    name: '1000 images per datasets',
    default: true,
  },
  {
    name: 'Unlimited annotations',
    default: true,
  },
  {
    name: 'Unlimited trained models',
    default: true,
  },
  {
    name: 'Android App (30 day limit)',
    default: true,
  },
  {
    name: 'Android App Pro (unlimited)',
    available: true,
  },
  {
    name: 'PyTorch (PT / PLT)',
    available: true,
  },
  {
    name: 'TFLite (F32 / F16 / Int8)',
    available: true,
  },
  {
    name: 'Saved Model (.pb)',
    available: true,
  },
]