import { DetectionModelBaseModelEnum, DetectionModelBaseModelEnumUI, DetectionModelFrameworkType, DetectionModelInputSizeEnum, DetectionModelInputSizeEnumUI } from "../../../models/api";
import { ModelTrainingSettings } from '../../../models/api';

export const frameworkTypeData = {
    PyTorch: {
        value: DetectionModelFrameworkType.PyTorch,
        text: 'PyTorch (v1.8)',
        frameworkType: DetectionModelFrameworkType.PyTorch,
        TrainingAndRation: '70% / 30%',
    },
    TF2: {
        value: DetectionModelFrameworkType.TensorFlow,
        text: 'Tensorflow (v2.4.1)',
        frameworkType: DetectionModelFrameworkType.TensorFlow,
        TrainingAndRation: '70% / 30%',
    },
    TF1: {
        value: DetectionModelFrameworkType.TensorFlowV1,
        text: 'Tensorflow (v1.15.2)',
        frameworkType: DetectionModelFrameworkType.TensorFlowV1,
        TrainingAndRation: '70% / 30%',
    }
};

export const baseModelData = [
    {
        value: DetectionModelBaseModelEnum.Yolov5s,
        text: DetectionModelBaseModelEnumUI.yolov5s,
        frameworkType: DetectionModelFrameworkType.PyTorch
    },
    {
        value: DetectionModelBaseModelEnum.Yolov5t,
        text: DetectionModelBaseModelEnumUI.yolov5t,
        frameworkType: DetectionModelFrameworkType.PyTorch
    },
    {
        value: DetectionModelBaseModelEnum.SsdMobileNetV2FPNLite,
        text: DetectionModelBaseModelEnumUI.ssdMobileNetV2FPNLite,
        frameworkType: DetectionModelFrameworkType.TensorFlow
    },
    {
        value: DetectionModelBaseModelEnum.SsdMobileNetV2Standard,
        text: DetectionModelBaseModelEnumUI.ssdMobileNetV2Standard,
        frameworkType: DetectionModelFrameworkType.TensorFlow
    },
    {
        value: DetectionModelBaseModelEnum.SsdMobileNetV2Standard,
        text: DetectionModelBaseModelEnumUI.ssdMobileNetV2Standard,
        frameworkType: DetectionModelFrameworkType.TensorFlowV1
    },
];

export const modelInputSizeData = [
    {
        value: DetectionModelInputSizeEnum.VeryFast,
        text: DetectionModelInputSizeEnumUI.veryFast,
        baseModels: [DetectionModelBaseModelEnum.SsdMobileNetV2Standard]
    },
    {
        value: DetectionModelInputSizeEnum.Fast,
        text: DetectionModelInputSizeEnumUI.fast,
        baseModels: [
            DetectionModelBaseModelEnum.Yolov5s,
            DetectionModelBaseModelEnum.Yolov5t,
            DetectionModelBaseModelEnum.SsdMobileNetV2FPNLite
        ]
    },
    {
        value: DetectionModelInputSizeEnum.Accurate,
        text: DetectionModelInputSizeEnumUI.accurate,
        baseModels: [
            DetectionModelBaseModelEnum.Yolov5s,
            DetectionModelBaseModelEnum.SsdMobileNetV2FPNLite
        ]
    },
];

export enum ModelTrainingSettingsTypes {
    mobile = 'Mobile (fast)',
    edgeIoTDevice = 'Edge IoT Device',
    cloudService ='Cloud server (accurate)',
}

const tenserflowModelsData = baseModelData.filter(baseModel => baseModel.frameworkType === DetectionModelFrameworkType.TensorFlow);
const tensorFlowV1ModelsData = baseModelData.filter(baseModel => baseModel.frameworkType === DetectionModelFrameworkType.TensorFlowV1);
const pytorchModelsData = baseModelData.filter(baseModel => baseModel.frameworkType === DetectionModelFrameworkType.PyTorch);

export const SettingsPresets = new Map<string, ModelTrainingSettings> (
    [
        ['mobile', {
            baseModels: tenserflowModelsData,
            baseModel: DetectionModelBaseModelEnum.SsdMobileNetV2Standard,
            modelInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.SsdMobileNetV2Standard))[0].value,
            modelsInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.SsdMobileNetV2Standard)),
            frameworkType: DetectionModelFrameworkType.TensorFlow,
            ignoreEmptyAnnotations: true,
            augmentations: {
                horizontalFlip: true,
                verticalFlip: false,
                randomScale: true,
                adjustBrightness: true,
                adjustHue: true,
                adjustSaturation: true,
                blur: 0,
                saltPepper: 0,
                randomLine: 0
            },
            isMultiLabel: false,
        }],
        ['edgeIoTDevice', {
            baseModels: tensorFlowV1ModelsData,
            baseModel: DetectionModelBaseModelEnum.SsdMobileNetV2Standard,
            modelInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.SsdMobileNetV2Standard))[0].value,
            modelsInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.SsdMobileNetV2Standard)),
            frameworkType: DetectionModelFrameworkType.TensorFlowV1,
            ignoreEmptyAnnotations: true,
            augmentations: {
                horizontalFlip: true,
                verticalFlip: true,
                randomScale: true,
                adjustBrightness: true,
                adjustHue: true,
                adjustSaturation: true,
                blur: 0,
                saltPepper: 0,
                randomLine: 0
            },
            isMultiLabel: false,
        }],
        ['cloudService', {
            baseModels: pytorchModelsData,
            baseModel: DetectionModelBaseModelEnum.Yolov5s,
            modelInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.Yolov5s))[1].value,
            modelsInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.Yolov5s)),
            frameworkType: DetectionModelFrameworkType.PyTorch,
            ignoreEmptyAnnotations: true,
            augmentations: {
                horizontalFlip: true,
                verticalFlip: true,
                randomScale: true,
                adjustBrightness: true,
                adjustHue: true,
                adjustSaturation: true,
                blur: 0,
                saltPepper: 0,
                randomLine: 0
            },
            isMultiLabel: false,
        }],
        ['defaultClassification', {
            baseModels: pytorchModelsData,
            baseModel: DetectionModelBaseModelEnum.Yolov5s,
            modelInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.Yolov5s))[0].value,
            modelsInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.Yolov5s)),
            frameworkType: DetectionModelFrameworkType.PyTorch,
            ignoreEmptyAnnotations: true,
            augmentations: {
                horizontalFlip: true,
                verticalFlip: true,
                randomScale: true,
                adjustBrightness: true,
                adjustHue: true,
                adjustSaturation: true,
                blur: 0,
                saltPepper: 0,
                randomLine: 0
            },
            isMultiLabel: false,
        }],
        ['defaultSegmentation', {
            baseModels: pytorchModelsData,
            baseModel: DetectionModelBaseModelEnum.Yolov5s,
            modelInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.Yolov5s))[0].value,
            modelsInputSize: modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes(DetectionModelBaseModelEnum.Yolov5s)),
            frameworkType: DetectionModelFrameworkType.PyTorch,
            ignoreEmptyAnnotations: true,
            augmentations: {
                horizontalFlip: true,
                verticalFlip: true,
                randomScale: false,
                adjustBrightness: false,
                adjustHue: false,
                adjustSaturation: false,
                blur: 0,
                saltPepper: 0,
                randomLine: 0
            },
            isMultiLabel: false,
        }]
    ] 
)
