import React from 'react';
import cx from 'classnames'
import { AISuggestion, MessageProps } from ".."
import ProfileImage from '../../../pages/user-profile/ProfileImage';
import { Button } from 'reactstrap';

interface Props extends MessageProps {
    handleOpenNewProjectModal: (projectTitle?: string, aiType?: string) => void;
    aiSuggestion: AISuggestion;
}

const SuggestionMessage = React.forwardRef<HTMLDivElement | null, Props>((
    { 
        message,
        avatarUrl,
        wrapperClassName,
        messageClassName,
        avatarClassName,
        handleOpenNewProjectModal,
        aiSuggestion
    },
    ref
) => {

    const handleConfirmClick = () => {
        handleOpenNewProjectModal(aiSuggestion.project_title, aiSuggestion.ai_type)
    }

    return (
        <div ref={ref} className={cx('app-components-chat-message app-components-chat-message_incoming flex-column', wrapperClassName)}>
            <div className='d-flex mb-2'>
                <ProfileImage avatarUrl={avatarUrl} className={avatarClassName} />
                <p className={cx('app-components-chat-message__text', messageClassName)}>
                    {message}
                </p>
            </div>
            <Button className='w-100' color='primary' onClick={handleConfirmClick}>Yes, create project.</Button>
        </div>
    );
})

export default SuggestionMessage