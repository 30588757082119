import md5 from 'md5';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { CodecType, ImageType, VideoType } from '../models/mimeTypes';
import { VideoData } from '../models/video-data';
import { bytesToMb, getFileMediaInfo, getRandomNumber } from './helper-functions';
import { fileReaderUtil } from './file-reader';
import {
    NOT_ENOUGH_UNIQUE_FRAMES,
    SAFARI_NOT_SUPPORTED_ERROR,
    VIDEO_DURATION_LIMIT_ERROR,
    VIDEO_FORMAT_NOT_SUPPORTED_ERROR,
    VIDEO_READ_ERROR,
    VIDEO_SIZE_LIMIT_ERROR
} from './errors';

const SUPPORTED_FORMATS = [VideoType.Mp4, VideoType.Mov, VideoType.M4v];

const MAX_VIDEO_DURATION = 120;
const MAX_VIDEO_SIZE_MB = 300;
const MAX_FAIL_ITERATIONS = 25;

const isSupportedCodec = (codecs?: string) => {
    return (
        !!codecs &&
        (codecs.includes(CodecType.Mp42) ||
            codecs.includes(CodecType.Avc1) ||
            codecs.includes(CodecType.Qt) ||
            codecs.includes(CodecType.M4v))
    );
};

export const videoUtil = {
    getVideoData: (video: File, ignoreCheck: boolean = false) => {
        return new Promise<VideoData>((resolve, reject) => {
            // Checking if browser is Safari
            if ((isSafari || isMobileSafari) && !ignoreCheck) {
                reject(new Error(SAFARI_NOT_SUPPORTED_ERROR));
            }

            // Checking if video format is supported
            if (!SUPPORTED_FORMATS.includes(video.type as VideoType)) {
                reject(new Error(VIDEO_FORMAT_NOT_SUPPORTED_ERROR(video.type)));
            }

            const videoHtmlElement = document.createElement('video');
            const videoUrlObject = URL.createObjectURL(video);

            // If an error occurs on reading Blob
            videoHtmlElement.addEventListener('error', () => {
                reject(new Error(VIDEO_READ_ERROR));
            });

            // If video Blob is read successfully
            videoHtmlElement.addEventListener('loadeddata', async () => {
                if (videoHtmlElement.duration > MAX_VIDEO_DURATION && !ignoreCheck) {
                    reject(new Error(VIDEO_DURATION_LIMIT_ERROR));
                }
                if (bytesToMb(video.size) > MAX_VIDEO_SIZE_MB && !ignoreCheck) {
                    reject(new Error(VIDEO_SIZE_LIMIT_ERROR));
                }
                const mediaInfo = await getFileMediaInfo(video);
                if (!isSupportedCodec(mediaInfo.CodecID_Compatible) && !ignoreCheck) {
                    reject(new Error(VIDEO_READ_ERROR));
                }
                resolve({
                    name: video.name.split('.').slice(0, -1).join('.'),
                    urlObject: videoUrlObject,
                    duration: videoHtmlElement.duration,
                    fps: parseFloat(mediaInfo.FrameRate),
                    framesTotal: parseInt(mediaInfo.FrameCount)
                });
            });

            // Trying to set video source from Blob
            videoHtmlElement.src = videoUrlObject;
            videoHtmlElement.load();
        });
    },

    getVideoDuration: (video: File) => {
        return new Promise<number>((resolve, reject) => {
            const videoHtmlElement = document.createElement('video');
            const videoUrlObject = URL.createObjectURL(video);
            videoHtmlElement.addEventListener('error', () => {
                reject(new Error(VIDEO_READ_ERROR));
            });
            videoHtmlElement.addEventListener('loadeddata', () => {
                resolve(videoHtmlElement.duration);
            });
            videoHtmlElement.src = videoUrlObject;
            videoHtmlElement.load();
        });
    },

    getVideoFrames: (
        videoData: VideoData,
        imagesToLoad: number,
        isRandom: boolean,
        setProgress: (value: number) => void
    ) => {
        return new Promise<File[]>(async (resolve, reject) => {
            const videoHtmlElement = document.createElement('video');
            const canvas = document.createElement('canvas');

            // Handling when current playback position is found (frame loaded)
            let seekResolve: any;
            videoHtmlElement.addEventListener('seeked', () => {
                if (seekResolve) {
                    seekResolve();
                }
            });

            videoHtmlElement.addEventListener('loadeddata', async () => {
                // Video size
                const [width, height] = [videoHtmlElement.videoWidth, videoHtmlElement.videoHeight];

                // Creating canvas context for getting frames
                const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
                canvas.width = width;
                canvas.height = height;

                // Random time of video
                const randomTime = () => {
                    return getRandomNumber(0, videoData.duration);
                };

                // Initializing variables
                const frames: File[] = [];
                const hashes: Record<string, boolean> = {};

                let fragments = imagesToLoad + 1;
                let interval = videoData.duration / fragments;
                let framesCount = 0;
                let currentTime = isRandom ? randomTime() : interval;
                let iteration = 0;
                let lastSuccessIteration = 0;

                const addFrameToArray = (blob: Blob) => {
                    const fileName = `${videoData.name}-${framesCount}.jpeg`;
                    const fileConfig = { type: ImageType.Jpeg };
                    frames.push(new File([blob], fileName, fileConfig));
                    framesCount++;
                    lastSuccessIteration = iteration;
                };

                const checkIfHashExists = async (blob: Blob) => {
                    const contents = await fileReaderUtil.readFileAsync(blob);
                    const md5Hash = await md5(new Uint8Array(contents));
                    const isHashExists = !!hashes[md5Hash];
                    if (!isHashExists) {
                        hashes[md5Hash] = true;
                    }
                    return isHashExists;
                };

                while (true) {
                    // Setting frame time
                    videoHtmlElement.currentTime = currentTime;

                    // Waiting for a seek event (current frame load)
                    await new Promise((r) => (seekResolve = r));

                    // Getting current frame from canvas
                    ctx.drawImage(videoHtmlElement, 0, 0, width, height);

                    // Waiting for a blob of current frame
                    const blob = await new Promise<Blob | null>((r) => canvas.toBlob(r));

                    // Adding image to array if it's hash doesn't already exists
                    const isAddingFrame = !!blob && !(await checkIfHashExists(blob));
                    if (isAddingFrame) {
                        addFrameToArray(blob as Blob);
                    } else if (!isRandom) {
                        framesCount++;
                    }

                    // Updating iterations total & time
                    iteration++;
                    currentTime = isRandom ? randomTime() : currentTime + interval;
                    setProgress(Math.round((framesCount / imagesToLoad) * 100));

                    // If all frames loaded or max attempts in uniform selection
                    if (framesCount === imagesToLoad) {
                        resolve(frames);
                        break;
                    }
                    // If there is not enough unique frames in all video frames
                    const isMaxAttempts = iteration - lastSuccessIteration > MAX_FAIL_ITERATIONS;
                    if (isRandom && isMaxAttempts) {
                        reject(new Error(NOT_ENOUGH_UNIQUE_FRAMES(imagesToLoad)));
                        break;
                    }
                }
            });

            videoHtmlElement.src = videoData.urlObject;
            videoHtmlElement.load();
        });
    },

    getMiddleFrameFromVideo: async (file: File): Promise<File | null> => {
        let videoData: VideoData;
        try {
            videoData = await videoUtil.getVideoData(file, true);
        } catch (e) {
            return null;
        }

        let middleFrameBlob: Blob;
        try {
            middleFrameBlob = await new Promise<Blob>((resolve, reject) => {
                const videoHtmlElement = document.createElement('video');
                const canvas = document.createElement('canvas');
                videoHtmlElement.addEventListener('error', () => {
                    reject(new Error(VIDEO_READ_ERROR));
                });
                videoHtmlElement.addEventListener('loadeddata', () => {
                    const [width, height] = [
                        videoHtmlElement.videoWidth,
                        videoHtmlElement.videoHeight
                    ];
                    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
                    canvas.width = width;
                    canvas.height = height;
                    videoHtmlElement.currentTime = videoData.duration / 2;
                    videoHtmlElement.addEventListener('seeked', async () => {
                        ctx.drawImage(videoHtmlElement, 0, 0, width, height);
                        const blob = await new Promise<Blob | null>((r) => canvas.toBlob(r));
                        blob ? resolve(blob) : reject();
                    });
                });
                videoHtmlElement.src = videoData.urlObject;
                videoHtmlElement.load();
            });
        } catch {
            return null;
        }

        return new File([middleFrameBlob], 'middleFrame.png', { type: ImageType.Png });
    }
};
