import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { Rect } from '../../../models/geometry';

interface Props extends HTMLAttributes<HTMLDivElement> {
    rect: Rect
}

const Box: React.FC<Props> = ({ rect, className, ...rest }) => {
    return (
        <div
            {...rest}
            className={cx(
                'app-components-cross-hair__box',
                className
            )}
            style={{
                top: rect.y,
                left: rect.x,
                width: rect.width,
                height: rect.height
            }}
        />
    );
};

export default Box;
