import React from 'react';
import { useFormik } from 'formik';
import { Form, Input, Label, ModalProps } from 'reactstrap';
import { ProjectGroup, ProjectGroupUpdate } from '../../../../../models/api';

interface Props extends ModalProps {
    onConfirm: (params: ProjectGroupUpdate) => void;
    projectGroup?: ProjectGroup | null;
}

export const ProjectGroupForm: React.FC<Props> = (props) => {
    const { formId, projectGroup, onConfirm } = props;

    const formik = useFormik({
        initialValues: {
            name: projectGroup?.name ?? '',
        },
        onSubmit: (values) => {
            onConfirm({ id: projectGroup?.id, name: values.name });
        },
    });
    return (
        <Form
            onSubmit={formik.handleSubmit}
            id={formId}
            className="d-flex justify-content-between align-items-center py-4"
        >
            <Label for="projectName" className="w-50 mb-0">
                Group Name:
            </Label>
            <Input
                id="name"
                name="name"
                type="text"
                className="w-50"
                onChange={formik.handleChange}
                value={formik.values.name}
            />
        </Form>
    );
};
