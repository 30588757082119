import { Classification, DatasetImage, ProjectType } from '../../../models/api';
import { useStores } from '../../../store';
import { observer } from 'mobx-react-lite';
import ImageAnnotateModal from '../ImageAnnotateModal';
import ImageClassificationModal from '../ImageClassificationModal';
import ImagePolygonAnnotateModal from '../ImagePolygonAnnotateModal';

const AnnotationModal = observer(() => {
    const {
        datasetImageStore,
        labelsStore,
        annotationsStore,
        userStore,
        projectsStore,
        classificationsStore,
        polygonAnnotationStore
    } = useStores();

    const project = projectsStore.current!;

    const handleAnnotateDialogClosed = () => {
        datasetImageStore.annotate();
    };

    const handleImageDelete = async () => {
        if (!datasetImageStore.annotated) return;
        await datasetImageStore.deleteImage([datasetImageStore.annotated]);
        handleAnnotateDialogClosed();
    }

    const handleAnnotateSave = (annotationList: DatasetImage['annotations']) => {
        if (!annotationList || !datasetImageStore.annotated) {
            return;
        }
        annotationsStore.syncList(annotationList);
    };

    const handleAnnotatedImageChange = (next: boolean) => async () => {
        if (!datasetImageStore.annotated) {
            return;
        }
        await datasetImageStore.annotateToggleImage(next);
    };

    const handlePolygonAnnotateSave = (annotationList: DatasetImage['polygonAnnotations']) => {
        if (!annotationList || !datasetImageStore.annotated) {
            return;
        }
        polygonAnnotationStore.syncList(annotationList);
    };

    const handleClassificationSave = (classifications: Classification[]) => {
        classificationsStore.syncList(classifications);
    };

    return (
        <>
            {datasetImageStore.annotated &&
                (projectsStore.current?.type === ProjectType.ObjectDetection ? (
                    <ImageAnnotateModal
                        isOpen={!!datasetImageStore.annotated}
                        onClosed={handleAnnotateDialogClosed}
                        annotatedDatasetImage={datasetImageStore.annotated}
                        labelList={labelsStore.list}
                        onSave={handleAnnotateSave}
                        onNavNext={handleAnnotatedImageChange(true)}
                        onNavPrev={handleAnnotatedImageChange(false)}
                        onDelete={handleImageDelete}
                        impersonationUser={userStore.impersonationUser}
                        navNextDisabled={
                            datasetImageStore.isAnnotatedImageLastInImages ||
                            datasetImageStore.state.images.loading
                        }
                        navPrevDisabled={
                            datasetImageStore.isAnnotatedImageFirstInImages ||
                            datasetImageStore.state.images.loading
                        }
                        isLoading={annotationsStore.isLoading}
                        error={annotationsStore.error}
                        annotateListProgress={{
                            current: (datasetImageStore.currentAnnotatedImageIndex || 0) + 1,
                            total: datasetImageStore.pagination.totalSize
                        }}
                        size='lg'
                    />
                ) : projectsStore.current?.type === ProjectType.ObjectSegmentation ? (
                    <ImagePolygonAnnotateModal
                        isOpen={!!datasetImageStore.annotated}
                        onClosed={handleAnnotateDialogClosed}
                        annotatedDatasetImage={datasetImageStore.annotated}
                        labelList={labelsStore.list}
                        onSave={handlePolygonAnnotateSave}
                        onNavNext={handleAnnotatedImageChange(true)}
                        onNavPrev={handleAnnotatedImageChange(false)}
                        onDelete={handleImageDelete}
                        impersonationUser={userStore.impersonationUser}
                        navNextDisabled={
                            datasetImageStore.isAnnotatedImageLastInImages ||
                            datasetImageStore.state.images.loading
                        }
                        navPrevDisabled={
                            datasetImageStore.isAnnotatedImageFirstInImages ||
                            datasetImageStore.state.images.loading
                        }
                        isLoading={polygonAnnotationStore.isLoading}
                        error={polygonAnnotationStore.error}
                        annotateListProgress={{
                            current: (datasetImageStore.currentAnnotatedImageIndex || 0) + 1,
                            total: datasetImageStore.pagination.totalSize
                        }}
                        size='lg'
                    />
                ) : (
                    <ImageClassificationModal
                        isOpen={!!datasetImageStore.annotated}
                        onClosed={handleAnnotateDialogClosed}
                        annotatedDatasetImage={datasetImageStore.annotated}
                        labelList={labelsStore.list}
                        onSave={handleClassificationSave}
                        onNavNext={handleAnnotatedImageChange(true)}
                        onNavPrev={handleAnnotatedImageChange(false)}
                        impersonationUser={userStore.impersonationUser}
                        navNextDisabled={
                            datasetImageStore.isAnnotatedImageLastInImages ||
                            datasetImageStore.state.images.loading
                        }
                        navPrevDisabled={
                            datasetImageStore.isAnnotatedImageFirstInImages ||
                            datasetImageStore.state.images.loading
                        }
                        isLoading={classificationsStore.isLoading}
                        error={classificationsStore.error}
                        annotateListProgress={{
                            current: (datasetImageStore.currentAnnotatedImageIndex || 0) + 1,
                            total: datasetImageStore.imagesForAnnotationCount
                        }}
                        isDisabled={
                            classificationsStore.processingClassificationsImages[
                                datasetImageStore.annotated.hashedName
                            ]
                        }
                        isMultiLabel={project.isMultiLabel}
                        size='lg'
                    />
                ))}
        </>
    );
});

export default AnnotationModal;
