import React, { FunctionComponent } from 'react';
import { Form, FormikBag, FormikProps, withFormik } from 'formik';
import { Button } from 'reactstrap';
import { ResetPwdFormData } from '../../../models/login/reset-pwd';
import { FormField, PwdFormField } from '../../../components/Form';
import { Auth } from 'aws-amplify';
import history from '../../../router/history';
import { Routes } from '../../../router/routes';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

interface Props {
    email:string
}
type FormProps = Props & FormikProps<ResetPwdFormData>;

const _ResetPwdForm:FunctionComponent<FormProps> = (props) => {
    const [readOnly, setReadOnly] = React.useState(true)
    const formName = 'reset-pwd-form';
    const isLoading = !props.isValidating && props.isSubmitting;

    React.useEffect(() => {
        const timeout = setTimeout(() => setReadOnly(false), 500)
        return () => clearTimeout(timeout)
    }, [])
    
    return <Form>
        <FormField formName={formName} readOnly={readOnly} field={'code'} type={'text'} placeholder={'Code'} isFirst/>
        <PwdFormField formName={formName} field={'pwd'} placeholder={'New password'}/>

        <div className={'pt-4'}>
            <Button
                ctolor={'primary'}
                type={'submit'}
                block
                disabled={isLoading}
            >
                Reset password
            </Button>
        </div>
    </Form>
}

const mapPropsToValues: () => ResetPwdFormData = () => {
    return {
        code: '',
        pwd: ''
    }
}

const handleSubmit = async (values:ResetPwdFormData, formikBag: FormikBag<Props, ResetPwdFormData>) =>{
    try{
        await Auth.forgotPasswordSubmit(formikBag.props.email, values.code, values.pwd);

        history.push(Routes.SignIn);
        return
    }catch(error){
        toast(error.message, {type:toast.TYPE.ERROR});
    }

    formikBag.setSubmitting(false);
}

const validationSchema = Yup.object().shape({
    code: Yup.string().required('required'),
    pwd: Yup.string().required('required').min(8, 'at least 8 chars')
});

export const ResetPwdForm = withFormik<Props, ResetPwdFormData>({
    mapPropsToValues,
    handleSubmit,
    validationSchema
})(_ResetPwdForm);
