import React from 'react';

const TERMS_URL = 'https://firststep.ai/terms';
const POLICY_URL = 'https://firststep.ai/privacy';

interface Props {
    action: string;
}

const Policy: React.FC<Props> = ({ action }) => {
    return (
        <div className="text-center login-page__small-text">
            <span>By {action}, you agree to our </span>
            <span>
                <a href={TERMS_URL} target="_blank">
                    Terms of Use
                </a>
                <span> and </span>
                <a href={POLICY_URL} target="_blank">
                    Privacy Policy
                </a>
            </span>
        </div>
    );
};

export default Policy;