import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { DetectionModelDatasetInfo } from '../../../models/api';
import DatasetInfo from './DatasetInfo';

interface Props {
    datasetInfo?: DetectionModelDatasetInfo;
    isOpen: boolean;
    toggle: () => void;
}

const DatasetInfoModal: React.FC<Props> = ({ isOpen, toggle, datasetInfo }) => {
    return (
        <Modal
            isOpen={!!isOpen}
            toggle={toggle}
            className="app-component-modal"
        >
            <ModalHeader toggle={toggle}>
                Dataset Info
            </ModalHeader>
            <ModalBody>
                <DatasetInfo datasetInfo={datasetInfo}/>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DatasetInfoModal;
