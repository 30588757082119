import React from 'react';
import { useFormik } from 'formik';
import { Form, Input, Label, ModalProps } from 'reactstrap';
import { FileUploadElement } from '../../../../models/api';
import dayjs from 'dayjs';

interface Props extends ModalProps {
    onConfirm: (values: FileUploadElement) => void;
}

export const NewFileUploadForm: React.FC<Props> = (props) => {

    const { formId, onConfirm } = props;
    const formik = useFormik({
        initialValues: {
            date: dayjs().format('YYYY-MM-DD'),
            title:'',
            type: '',
            link: '',
        },
        onSubmit: (values) => {
            onConfirm({
                date: values.date,
                title: values.title,
                type: values.type,
                link: values.link,
            });
        },
    });
    return (
        <Form
            onSubmit={formik.handleSubmit}
            id={formId}
            className="d-flex flex-wrap justify-content-between align-items-center py-4"
        >
            <div className="form-group w-50 pr-2">
                <Label for="fileUploadDate" className="mb-0">
                    Date:
                </Label>
                <Input
                    id="fileUploadDate"
                    name="date"
                    type="date"
                    className=""
                    onChange={formik.handleChange}
                    value={formik.values.date}

                />
            </div>
            <div className="form-group w-50">
                <Label for="fileUploadType" className="mb-0">
                    Type:
                </Label>
                <Input
                    id="fileUploadType"
                    name="type"
                    type="text"
                    className=""
                    onChange={formik.handleChange}
                    value={formik.values.type}
                />
            </div>
            <div className="form-group w-100">
                <Label for="fileUploadTitle" className="mb-0">
                    Document Name:
                </Label>
                <Input
                    id="fileUploadTitle"
                    name="title"
                    type="text"
                    className=""
                    onChange={formik.handleChange}
                    value={formik.values.title}
                />

            </div>
            <div className="form-group w-100">
                <Label for="fileUploadLink" className="mb-0">
                    Download Link:
                </Label>
                <Input
                    id="fileUploadLink"
                    name="link"
                    type="text"
                    className=""
                    onChange={formik.handleChange}
                    value={formik.values.link}
                />
            </div>
        </Form>
    );
};
