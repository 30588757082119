import React, { useState } from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { DetectionModelGenerator, DetectionModelGeneratorProgressStatus, Project } from '../../../models/api';
import { CustomS3ImageWithSpinner } from '../../../components/AmplifyUi';
import { AccessLevel } from '@aws-amplify/ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faDownload, faEye, faFileVideo, faTimes } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import dayjs from 'dayjs';
import { dateConfig } from '../../../config/date';
import { Badge } from 'reactstrap';
import CircularProgress from '../../../components/CircularProgress';
import { formatDurationWithUnitName } from '../../../utils/dateTime';
import { noDataText } from '../../../components/BootstrapTable';
import { s3Util } from '../../../utils/s3';
import { Viewer3dModal } from '../Viewer3dModal';
import DeleteConfirmation from '../../../components/Modals/DeleteConfirmation';
import { truncate } from '../../../utils/helper-functions';
import * as path from 'path';
// @ts-ignore
import { AmbientLight, DirectionLight, GLTFModel } from 'react-3d-viewer';

interface Props {
    identityId?: string;
    project?: Project;
    items: DetectionModelGenerator[];
    onDownload?: (item: DetectionModelGenerator) => Promise<void>;
    onCancel?: (item: DetectionModelGenerator) => Promise<void>;
    onDelete?: (item: DetectionModelGenerator) => Promise<void>;
    isAllRunningGenerators?: boolean;
}

export const ModelGeneratorsTable: React.FC<Props> = (props) => {
    const { identityId, project, items, onDownload, onCancel, onDelete, isAllRunningGenerators } = props;

    const [viewOpenEvent, setViewOpenEvent] = useState<DetectionModelGenerator | null>(null);

    const [cancelConfirmationEvent, setCancelConfirmationEvent] =
        useState<DetectionModelGenerator | null>(null);

    const [deleteConfirmationEvent, setDeleteConfirmationEvent] =
        useState<DetectionModelGenerator | null>(null);

    const handleCancel = () => {
        if (!cancelConfirmationEvent) {
            return;
        }
        onCancel!(cancelConfirmationEvent);
        setCancelConfirmationEvent(null);
    };

    const handleDelete = () => {
        if (!deleteConfirmationEvent) {
            return;
        }
        onDelete!(deleteConfirmationEvent);
        setDeleteConfirmationEvent(null);
    };

    const columns: ColumnDescription<DetectionModelGenerator>[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => {
                return rowIndex + 1;
            }
        },
        {
            dataField: 'user',
            text: 'User',
            align: 'center',
            headerAlign: 'center',
            hidden: !isAllRunningGenerators,
            sort: true,
            formatter: (_cel, row) => {
                return row.project?.user?.email ? row.project?.user?.email : ''
            },
            sortValue: (_cel, row) => {
                return row.project?.user?.email ? row.project?.user?.email : ''
            }
        },
        {
            dataField: 'projectName',
            text: 'Project Name',
            align: 'center',
            headerAlign: 'center',
            hidden: !isAllRunningGenerators,
            sort: true,
            formatter: (_cel, row) => {
                return row.project?.name ? row.project?.name : ''
            },
            sortValue: (_cel, row) => {
                return row.project?.name ? row.project?.name : ''
            }
        },
        {
            dataField: 'thumbnail',
            text: 'Thumbnail',
            headerClasses: 'app-components-boostrap-table__th-sm',
            classes: 'app-components-boostrap-table__td-thumbnail',
            formatter: (_cel, row) => {
                return identityId && project && row.hasThumbnail ? (
                    <CustomS3ImageWithSpinner
                        level={AccessLevel.Private}
                        imgKey={s3Util.getModelGeneratorThumbnailKey(project.id, row.id)}
                        identityId={identityId}
                    />
                ) :  !identityId && !project && row.hasThumbnail ? (
                    <CustomS3ImageWithSpinner
                        level={AccessLevel.Private}
                        imgKey={s3Util.getModelGeneratorThumbnailKey(row.projectId, row.id)}
                        identityId={row.project?.user?.storagePath}
                    />
                ) : (
                    <div className='text-primary'>
                        <FontAwesomeIcon icon={faFileVideo} size='2x' />
                    </div>
                );
            }
        },
        {
            dataField: 'filename',
            text: 'File Name',
            sort: true,
            formatter: (_cel, row) => {
                return truncate(row.filename, 50);
            }
        },
        {
            dataField: 'startedAt',
            text: 'Start Time',
            classes: 'text-nowrap',
            headerClasses: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => {
                return dayjs(row.startedAt).format(dateConfig.formats.date);
            }
        },
        {
            dataField: 'frames',
            text: 'Frames',
            classes: 'text-nowrap',
            headerClasses: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => {
                return row.frames ? `${row.frames} frames` : ''
            }
        },
        {
            dataField: 'progressStatus',
            text: 'Status',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                switch (row.progressStatus) {
                    case DetectionModelGeneratorProgressStatus.Initializing:
                        return (
                            <Badge color='warning' pill>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <CircularProgress color='dark' />
                                    <span className='ml-2'>initialising</span>
                                </div>
                            </Badge>
                        );
                    case DetectionModelGeneratorProgressStatus.Generating:
                    case DetectionModelGeneratorProgressStatus.Completing:
                    case DetectionModelGeneratorProgressStatus.Cancelling:
                        return (
                            <Badge color='warning' pill>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <CircularProgress color='dark' />
                                    <span className='ml-2'>{row.progressStatus}</span>
                                </div>
                            </Badge>
                        );
                    case DetectionModelGeneratorProgressStatus.Cancelled:
                        return (
                            <Badge color='danger' pill>
                                {row.progressStatus}
                            </Badge>
                        );
                    case DetectionModelGeneratorProgressStatus.Success:
                        return (
                            <Badge color='success' pill>
                                {row.progressStatus}
                            </Badge>
                        );
                    case DetectionModelGeneratorProgressStatus.Error:
                        return (
                            <Badge color='danger' pill>
                                {row.progressStatus}
                            </Badge>
                        );
                }
            }
        },
        {
            dataField: 'runtime',
            text: 'Runtime',
            formatter: (_cel, row) => {
                return row.runtime
                    ? formatDurationWithUnitName(dayjs.duration(row.runtime, 'seconds'))
                    : '';
            }
        },
        {
            dataField: 'progress',
            text: 'Progress',
            formatter: (_cel, row) => {
                return row.progress ? `${Math.round(row.progress)}%` : '';
            }
        },
        {
            dataField: 'view',
            text: 'View',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const isDisabled =
                    row.progressStatus !== DetectionModelGeneratorProgressStatus.Success;
                return (
                    <ActionButton
                        color='success'
                        id={`pages-model-generator_view-button-${row.id}`}
                        tooltipText='View item'
                        disabledTooltipText='3D modeling is not completed or some error occurred on generating'
                        disabled={isDisabled}
                        onClick={() => setViewOpenEvent(row)}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </ActionButton>
                );
            }
        },
        {
            dataField: 'download',
            text: 'Download',
            align: 'center',
            headerAlign: 'center',
            hidden: isAllRunningGenerators,
            formatter: (_cel, row) => {
                const isDisabled =
                    row.progressStatus !== DetectionModelGeneratorProgressStatus.Success;
                return (
                    <ActionButton
                        color='success'
                        id={`pages-model-generator_download-button-${row.id}`}
                        tooltipText='Download item'
                        disabledTooltipText='3D modeling is not completed or some error occurred on generating'
                        disabled={isDisabled}
                        onClick={() => onDownload!(row)}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </ActionButton>
                );
            }
        },
        {
            dataField: 'delete',
            text: 'Actions',
            align: 'center',
            headerAlign: 'center',
            hidden: isAllRunningGenerators,
            formatter: (_cel, row) => {
                const isProcessing =
                    row.progressStatus === DetectionModelGeneratorProgressStatus.Initializing ||
                    row.progressStatus === DetectionModelGeneratorProgressStatus.Generating ||
                    row.progressStatus === DetectionModelGeneratorProgressStatus.Completing ||
                    row.progressStatus === DetectionModelGeneratorProgressStatus.Cancelling;
                const isCancelling =
                    row.progressStatus === DetectionModelGeneratorProgressStatus.Cancelling;
                return isProcessing ? (
                    <ActionButton
                        color='warning'
                        id={`pages-model-generator_delete-button-${row.id}`}
                        tooltipText='Cancel generating'
                        disabled={isCancelling}
                        onClick={() => setCancelConfirmationEvent(row)}
                    >
                        <FontAwesomeIcon icon={faBan} />
                    </ActionButton>
                ) : (
                    <ActionButton
                        color='danger'
                        id={`pages-model-generator_delete-button-${row.id}`}
                        tooltipText='Delete item'
                        onClick={() => setDeleteConfirmationEvent(row)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </ActionButton>
                );
            }
        }
    ];

    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={items}
                columns={columns}
                defaultSorted={[{ dataField: 'startedAt', order: 'desc' }]}
                striped
                bordered={false}
                wrapperClasses='table-responsive'
                classes='m-0'
                noDataIndication={noDataText}
            />
            <Viewer3dModal
                identityId={identityId ? identityId : viewOpenEvent?.project?.user?.storagePath}
                project={project ? project : viewOpenEvent?.project}
                openEvent={viewOpenEvent}
                setOpenEvent={setViewOpenEvent}
            />
            <DeleteConfirmation
                title='Confirming Cancellation'
                body={`Are you sure you want to cancel 3d model generating for the "${cancelConfirmationEvent?.filename}" video?`}
                buttonText='Cancel'
                buttonColor='warning'
                isOpen={!!cancelConfirmationEvent}
                onConfirm={handleCancel}
                onClosed={() => setCancelConfirmationEvent(null)}
            />
            <DeleteConfirmation
                body={`Are you sure you want to delete the "${deleteConfirmationEvent?.filename}" video?`}
                isOpen={!!deleteConfirmationEvent}
                onConfirm={handleDelete}
                onClosed={() => setDeleteConfirmationEvent(null)}
            />
        </>
    );
};
