import * as Yup from 'yup';
import React from 'react';
import {
	Button,
	Col,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';
import { FieldArray, Formik } from 'formik';
import { FormField } from '../../../../components/Form';
import { videoElement } from '../../../../models/api'

interface Props {
	onSubmit: (data: {[kay: string]: videoElement[]}) => void;
	handleToggle: () => void;
	showModal: boolean;
	formData: videoElement[]
}


const EditVideoForm: React.FC<Props> = ({formData, onSubmit, handleToggle, showModal}) => {
	const validationSchema = Yup.object({
		pageData: Yup.array().of(
			Yup.object({
				title: Yup.string().required("This field is required"),
				url: Yup.string().required("This field is required")
			}),
		),
	})

	return (
		<Formik<{[kay: string]: videoElement[]}>
			enableReinitialize
			initialValues={{pageData: formData}}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
		>
			{({values, handleSubmit, resetForm, errors}) => (
				<Modal toggle={handleToggle} isOpen={showModal} onClosed={resetForm} centered={true} backdrop="static"  keyboard={false}>
					<ModalHeader toggle={handleToggle}>
						Edit Help Video
					</ModalHeader>
					<ModalBody className={'pb-5 pt-0'}>
						<FieldArray name="pageData">
							{({remove, push}) => (
								<>
									{
										values.pageData.length ?
											values.pageData.map((video, index) => {
												const isLast = values.pageData.length - 1 === index;
												return (
													<div key={index}>
														<Label className="font-weight-bold mt-5">
															Title
														</Label>
														<FormField
															formName={`pageData.${index}`}
															field={`pageData.${index}.title`}
															type={'text'}
														/>


														<Label className="font-weight-bold mt-1">
															Url
														</Label>
														<FormField
															formName={`pageData.${index}`}
															field={`pageData.${index}.url`}
															type={'text'}
														/>

														<Row form
															 className={`${isLast ? 'justify-content-between' : 'justify-content-end'}`}>
															{
																isLast &&
                                                                <Col xs={1.5}>
                                                                    <Button
																		color='primary'
																		className={'text-nowrap'}
																		onClick={() => push({title: '', url: ''})}
																	>
                                                                        Add video
                                                                    </Button>
                                                                </Col>
															}
															{
																<Col xs={1.5}>
																	<Button
																		color='danger'
																		className={'text-nowrap'}
																		onClick={() => remove(index)}>
																		Delete video
																	</Button>
																</Col>
															}
														</Row>
													</div>
												)
											})
											:
											<Col xs={1.5} className={'mt-5'}>
												<Button color='primary' onClick={() => push({title: '', url: ''})}>
													Add Video
												</Button>
											</Col>
									}
								</>
							)}
						</FieldArray>
					</ModalBody>
					<ModalFooter className='justify-content-end'>
						<Button color='primary' onClick={() => handleSubmit()}>Confirm</Button>
					</ModalFooter>
				</Modal>
			)}
		</Formik>
	);
};

export default EditVideoForm;