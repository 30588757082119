import React from 'react';
import { toast } from 'react-toastify';

interface Props {
    closeToast: (() => void) | undefined;
    callback: () => void;
}

const HardRefreshToast: React.FC<Props> = (props) => {
    const { closeToast, callback } = props;
    return (
        <>
            <div className='pr-3'>
                The operation requires some time to complete.
                Please wait for a minute and refresh the list.
            </div>
            <button
                className='btn btn-light btn-sm w-100 mt-2'
                onClick={() => {
                    if (closeToast) {
                        closeToast();
                    }
                    callback();
                }}
            >
                Refresh
            </button>
        </>
    );
}

export const createHardRefreshToast = (callback: () => void) => {
    toast(
        ({ closeToast }) => (
            <HardRefreshToast closeToast={closeToast} callback={callback} />
        ),
        {
            type: toast.TYPE.INFO,
            autoClose: false,
            closeOnClick: false,
            className: 'bg-primary'
        }
    );
}
