import { makeAutoObservable, runInAction } from 'mobx';
import container from '../../container/container';
import { Classification, DatasetImage } from '../../models/api';
import { RootStore } from './root';
import { toast } from 'react-toastify';
import { CustomEventType } from '../../models/events';

const api = container.apiClient;

export class ClassificationsStore {
    isLoading: boolean = false;
    isFetched: boolean = false;
    error?: string;

    queueLength: number = 0;

    processingClassificationsImages: Record<string, boolean> = {};

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async syncList(list: Classification[], image?: DatasetImage, preventTracking?: boolean) {
        const annotatedImage = image ? image : this.rootStore.datasetImageStore.annotated;

        // TODO: find solution for check and waiting
        if (!annotatedImage) {
            return;
        }

        const projectId = this.rootStore.projectsStore.current!.id;
        const projectName = this.rootStore.projectsStore.current!.name;
        const isMultiLabel = this.rootStore.projectsStore.current!.isMultiLabel;

        try {
            this.isLoading = true;
            this.error = undefined;
            this.queueLength++;

            if (isMultiLabel && !preventTracking) {
                this.processingClassificationsImages[annotatedImage.hashedName] = true;
            }

            const requestParams = {
                classifications: list.map(({ label, ...props }) => props),
                preventTracking
            };
            const { data } = await api.classificationListSync(annotatedImage.id, requestParams);

            runInAction(() => {
                if (isMultiLabel && !preventTracking) {
                    this.processingClassificationsImages[annotatedImage.hashedName] = false;
                }
                const updatedImage = {
                    ...annotatedImage,
                    classifications: data,
                    annotationAndClassificationCount: data.length
                } 

                if (this.rootStore.datasetImageStore.images[annotatedImage.hashedName]) {
                    this.rootStore.datasetImageStore.images[annotatedImage.hashedName] = updatedImage;
                }

                if (this.rootStore.datasetImageStore.imagesForModal[annotatedImage.id]) {
                    this.rootStore.datasetImageStore.imagesForModal[annotatedImage.id] = 
                        this.rootStore.datasetImageStore.getImagesBlobFromDatasetImage([updatedImage])[annotatedImage.id];
                }

                if (data && data[0] && !preventTracking) {
                    this.rootStore.eventsStore.addItem(CustomEventType.Annotate, {
                        datasetImageId: annotatedImage.id,
                        datasetImageHash: annotatedImage.hashedName,
                        labelId: data[0].label!.id,
                        labelName: data[0].label!.name,
                        projectId,
                        projectName
                    });
                }
            });

        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
            this.error = e.message;
        } finally {
            runInAction(() => {
                this.queueLength--;
                if (!this.queueLength) {
                    this.isLoading = false;
                }
            });
        }
    }
}
