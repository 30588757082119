import React, { useState } from 'react';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { Badge, Button } from 'reactstrap';
import { faCopy, faInfoCircle, faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import DeleteConfirmation from '../../../components/Modals/DeleteConfirmation';
import CircularProgress from '../../../components/CircularProgress';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import {
    DetectionModelClassTypeEnumUI,
    DetectionModelDownload,
    DetectionModelDownloadProgressStatus,
    DetectionModelDownloadType,
    DetectionModelDownloadTypeUI, ModelDownloadAppTemplateEnumUI, ProjectType
} from '../../../models/api';
import { dateConfig } from '../../../config/date';
import { noDataText } from '../../../components/BootstrapTable';
import { formatDurationWithUnitName } from '../../../utils/dateTime';
import ModelInfoModal, { ModelInfo } from '../../../components/Modals/ModelInfoModal';
import DetectionModelFrameworkTypeIcon from '../../../components/DetectionModel/DetectionModelFrameworkTypeIcon';
import { useStores } from '../../../store';

interface Props {
    items: DetectionModelDownload[];
    onDelete: (id: DetectionModelDownload['id']) => void;
    onDownload: (params: DetectionModelDownload) => void;
    isLoading: boolean;
}

const DownloadListTable: React.FC<Props> = ({items, onDelete, onDownload, isLoading}) => {
    const [modelInfo, setModelInfo] = useState<ModelInfo>();
    const { projectsStore } = useStores();

    const isPoseDetection = projectsStore.current?.type === ProjectType.PoseDetection;
    const isObjectClassification = projectsStore.current?.type === ProjectType.ObjectClassification

    const handleDelete = () => {
        if (!deleteConfirmation){
            return;
        }

        onDelete && onDelete(deleteConfirmation?.id);
        setDeleteConfirmation(undefined);
    };

    const handleDownloadButtonClick = (row: DetectionModelDownload) => () => {
        onDownload && onDownload(row);
    };

    const handleDeleteButtonClick = (row: DetectionModelDownload) => () => {
        setDeleteConfirmation(row);
    };

    const handleDeleteConfirmationClosed = () => {
        setDeleteConfirmation(undefined);
    };

    const handleTextCopy = () => {
        toast('Text copied to clipboard', {
            autoClose: 1000,
            position: 'bottom-center',
            type: 'success',
        });
    };

    const handleModelInfo = (detectionModel?: ModelInfo) => () => {
        setModelInfo(detectionModel);
    }

    const handleModelInfoClose = () => {
        setModelInfo(undefined);
    }


    let columns: ColumnDescription<DetectionModelDownload>[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => {
                return (rowIndex + 1);
            }
        },
        {
            dataField: 'downloadId',
            text: 'Download ID',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Type',
            headerClasses: 'text-nowrap',
            sort: true,
            //@ts-ignore
            formatter: (_cel, row: DetectionModelDownload) => {
                const types = [
                    DetectionModelDownloadType.ApkProClassification,
                    DetectionModelDownloadType.ApkProDetection
                ];

                if (types.includes(row.type) && row.apkConfig) {
                    return `${DetectionModelDownloadTypeUI[row.type]} [Template: ${ModelDownloadAppTemplateEnumUI[row.apkConfig.appTemplate]}]`;
                } else {
                    return DetectionModelDownloadTypeUI[row.type];
                }
            }
        },
        {
            dataField: 'frameworkType',
            text: 'Framework',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const id = `pages-model-download-list-framework-type-${row.id}`;

                return (
                    row.detectionModel ? (
                        <DetectionModelFrameworkTypeIcon
                            id={id}
                            frameworkType={row.detectionModel.frameworkType}
                            className="img-w-sm"
                        />
                    ) : ''
                );
            }
        },
        {
            dataField: 'modelInfo',
            text: 'Model Info',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const tooltipId = `pages-model-download-list-modelinfo-button-${row.id}`;
                const modelInfo = {
                    ...row.detectionModel,
                    maxDetections: row.maxDetections,
                };

                !isObjectClassification && delete modelInfo.isMultiLabel
                return (
                    <div className="d-flex align-items-center justify-content-center">
                        <ActionButton
                            color="info"
                            onClick={handleModelInfo(modelInfo)}
                            id={tooltipId}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </ActionButton>
                    </div>
                )
            }
        },
        {
            dataField: 'createdAt',
            text: 'Create Date',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            sort: true,
            formatter: (_cel, row: DetectionModelDownload) => dayjs(row.createdAt).format(dateConfig.formats.date)
        },
        {
            dataField: 'buildStatus',
            text: 'Status',
            headerClasses: 'text-nowrap',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, { buildCompletedAt, buildCanceledAt, progressStatus }) => {
                if (progressStatus === DetectionModelDownloadProgressStatus.Error){
                    return (
                        <Badge color='danger' pill>
                            Error
                        </Badge>
                    );
                }
                else if (buildCanceledAt) {
                    return (
                        <Badge color='danger' pill>
                            Canceled
                        </Badge>
                    );
                } else if (buildCompletedAt) {
                    return (
                        <Badge color='success' pill>
                            Completed
                        </Badge>
                    );
                } else {
                    return (
                        <Badge color='warning' pill>
                            <div className='d-flex justify-content-center align-items-center'>
                                <CircularProgress color='dark' />
                                <span className='ml-2'>
                                Underway
                            </span>
                            </div>
                        </Badge>
                    );
                }
            }
        },
        {
            dataField: 'buildTime',
            text: 'Build Time',
            headerClasses: 'text-nowrap',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row: DetectionModelDownload) => (
                row.buildTime ? formatDurationWithUnitName(dayjs.duration(row.buildTime, 'seconds')) : ''
            ),
        },
        {
            dataField: 'signedUrl',
            text: 'Download',
            headerAlign: 'center',
            classes: 'app-components-boostrap-table__td-mw-md',
            formatter: (_cel, row ) => {
                const tooltipButtonId = `pages-model-download-download-list-table_copy-download-url-${row.id}`;
                return (
                    row.url ?
                        <div className='d-flex align-items-center justify-content-center'>
                            <ActionButton
                                color='success'
                                id={tooltipButtonId}
                                tooltipText="Download item"
                                onClick={handleDownloadButtonClick(row)}
                            >
                                <FontAwesomeIcon icon={faDownload} />
                            </ActionButton>
                        </div>
                        :
                        ''
                );
            }
        },
        {
            dataField: 'password',
            text: 'Password',
            headerClasses: 'text-nowrap',
            hidden: true,
            formatter: (_cel, { id, password }) => {
                const tooltipButtonId = `pages-model-download-download-list-table_copy-password-${id}`;

                return (
                    password ?
                        <div className='d-flex align-items-center justify-content-between'>
                            <span>{password}</span>
                            <CopyToClipboard text={password} onCopy={handleTextCopy}>
                                <ActionButton color='primary' className='ml-2' id={tooltipButtonId}
                                              tooltipText='Copy password to clipboard'>
                                    <FontAwesomeIcon icon={faCopy} />
                                </ActionButton>
                            </CopyToClipboard>
                        </div>
                        :
                        ''
                );
            }
        },
        {
            dataField: 'downloadCount',
            text: 'Downloads',
            headerClasses: 'text-nowrap',
            headerAlign: 'center',
            hidden: true,
            align: 'center',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerClasses: 'text-nowrap',
            headerAlign: 'center',
            align: 'center',
            formatter: (_cel, row) => {
                const tooltipShareButtonId = `pages-model-download-download-list-table_share-button-${row.id}`
                const tooltipDeleteButtonId = `pages-model-download-download-list-table_delete-button-${row.id}`
                return (
                    <div className='d-flex justify-content-center'>
                        {/* FSAI-280 */}
                        {/* <ActionButton color='success' id={tooltipShareButtonId} tooltipText="Share item">
                            <FontAwesomeIcon icon={faShareAlt} />
                        </ActionButton> */}

                        <ActionButton
                            color='danger'
                            onClick={handleDeleteButtonClick(row)}
                            className='ml-2'
                            id={tooltipDeleteButtonId}
                            tooltipText="Delete item"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </ActionButton>
                    </div>
                );
            }
        }
    ];

    if (isPoseDetection) {
        const arrayExceptionsDataFieldPoseDetection = ['frameworkType', 'modelInfo'];
        columns = columns.filter((column) => {
            if (arrayExceptionsDataFieldPoseDetection.indexOf(column.dataField) < 0) {
                return column;
            }
        })
    }

    const [deleteConfirmation, setDeleteConfirmation] = useState<DetectionModelDownload>();
    const defaultSort: [{ dataField : any, order: SortOrder }] = [{
        dataField: 'createdAt',
        order: 'desc'
    }]
    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={items}
                columns={columns}
                defaultSorted={defaultSort}
                striped
                bordered={false}
                wrapperClasses="table-responsive"
                classes='m-0'
                noDataIndication={noDataText}
            />

            <DeleteConfirmation
                body={`Are you sure you want to delete the "${deleteConfirmation?.detectionModel?.modelWithDate}"?`}
                isOpen={!!deleteConfirmation}
                onConfirm={handleDelete}
                onClosed={handleDeleteConfirmationClosed}
                busyIndication={isLoading}
            />
            <ModelInfoModal
                isOpen={!!modelInfo}
                toggle={handleModelInfoClose}
                modelInfo={modelInfo}
            />
        </>
    );
};

export default DownloadListTable;
