import { Rect } from '../geometry';
import MimeTypes from '../mimeTypes';

import { DbEvent } from '../events';
import { Points } from '../../pages/dataset/ImagePolygonAnnotateModal/index';

export enum ProjectType {
    ObjectDetection = 'object',
    ObjectClassification = 'objectClassification',
    PoseDetection = 'poseDetection',
    Modeling3d = 'modeling3d',
    PredictiveMaintenance = 'predictiveMaintenance',
    ObjectSegmentation = 'objectSegmentation',
}

export enum ProjectTypeUI {
    object = 'Object Detection',
    objectClassification = 'Object Classification',
    poseDetection = 'Pose Estimation',
    modeling3d = '3D Modeling',
    predictiveMaintenance = 'Predictive Maintenance',
    objectSegmentation = 'Object Segmentation',
}

export enum ProjectTag {
    Dev = 'dev',
    Stage = 'stage',
    Prod = 'prod',
    Old = 'old',
}

export enum ProjectTagUI {
    dev = 'Dev',
    stage = 'Stage',
    prod = 'Prod',
    old = 'Old',
}

export enum ProjectTagCSSClasses {
    dev = 'btn-info',
    stage = 'btn-warning',
    prod = 'btn-success',
    old = 'btn-danger'
}

export interface Project {
    id: string;
    name: string;
    type: ProjectType;
    userId: User['id'];
    createdAt: string;
    updatedAt: string;
    isTemplate: boolean;
    isMultiLabel: boolean;
    templateImageUrl?: string;

    datasetImages: DatasetImage[];
    detectionModelGenerators: DetectionModelGenerator[];
    detectionModels: [{ accuracy: number }];
    tag: ProjectTag | null;
    groupId: string | null;
    projectCount: number;

    user?: User;
}

export enum ProjectField {
    Labels = 'labels',
    Dataset = 'dataset',
    Annotations = 'annotations',
    TrainedModels = 'trained models',
    Simulations = 'simulations',
    Downloads = 'downloads'
}

export interface ProjectListRequest {
    filter: Partial<Pick<Project, 'userId' | 'groupId'>>;
}
export interface ProjectGroupListRequest {
    filter: Partial<Pick<ProjectGroup, 'userId'>>;
}

export interface ProjectGroup {
    id: string,
    name: string,
    userId: User['id'],
    createdAt: string;
    updatedAt: string;

    projects?: Project[]
}

export interface DatasetImage {
    id: string;
    hashedName: string;
    dimensionX: number;
    dimensionY: number;
    size: number;
    createdAt: string;
    updatedAt: string;
    annotationAndClassificationCount?: number;

    projectId: Project['id'];

    annotations?: Annotation[];
    classifications?: Classification[];
    polygonAnnotations?: PolygonAnnotation[];
}

export interface DatasetImageParams {
    limit?: number;
    offset?: number;
}

export interface ImagesAnnotationsInfo {
    annotatedImages: number | null;
    notAnnotatedImages: number | null;
}

export enum ImagesAnnotationsInfoName {
    annotatedImages = 'Annotated',
    notAnnotatedImages = 'Not Annotated'
}

export const DEFAULT_LABEL_COLOR = 'rgb(84, 92, 216)'

export interface Label {
    id: string;
    name: string;
    color: string;
    createdAt: string;
    updatedAt: string;
    annotationAndClassificationCount?: number;

    projectId: Project['id'];

    annotations: Annotation[];
    classifications: Classification[];
}

export interface Annotation extends Rect {
    // TODO special interface for create new annotation
    id: string;
    createdAt?: string;
    updatedAt?: string;

    datasetImageId: DatasetImage['id'];
    labelId: Label['id'];

    label?: Label;
    datasetImage?: DatasetImage;
}

export interface PolygonAnnotation {
    id: string;
    createdAt?: string;
    updatedAt?: string;
    datasetImageId: DatasetImage['id'];
    labelId: Label['id'];
    label?: Label;
    points?: Points[];
    flattenedPoints?: Points[];
    isPolygonComplete?: boolean;
    color?: string;
    coordinates?: number[];
}

export interface Classification {
    labelId: Label['id'];
    datasetImageId: DatasetImage['id'];

    label?: Label;
    datasetImage?: DatasetImage;
}

export interface DetectionModel {
    id: string;
    startTime?: string;
    modelIndex?: number;
    progress?: number;
    progressStatus?: DetectionModelProgressStatus;
    runtime?: number;
    epochs?: number;
    accuracy?: number;
    confidence?: number;
    sessionId?: string;
    sagemakerJobId?: string;
    canceledAt?: string;
    earlyStoppedAt?: string;
    completedAt?: string;
    s3Bucket?: string;
    s3Folder?: string;
    frameworkType: DetectionModelFrameworkType;
    baseModel: DetectionModelBaseModelEnum;
    modelInputSize: DetectionModelInputSizeEnum;
    augmentations: ModelTrainingAugmentationSettings;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    projectId: Project['id'];
    modelWithDate?: string;
    modelWithModelIndex?: string;
    datasetInfo?: DetectionModelDatasetInfo;
    project?: Project;
    trainingRatio?: DetectionModelValidationRatioEnum;
    isMultiLabel?: boolean;
}

export interface DetectionModelRunningList {
    filter?: {
        canceledAt?: boolean;
        earlyStoppedAt?: boolean;
        completedAt?: boolean;
    };
    limit?: number;
    sortOrder?: string;
    sortField?: string;
}

export interface TrainingReportRequest {
    month: number;
    year: number;
}

export interface DetectionModelTrainingReport {
    totalRuntime: string;
    project: Partial<Project>
}

export interface DetectionModelDatasetInfo {
    annotatedImagesCount: number;
    datasetLength: number;
    labelsInfo: DetectionModelLabelsInfo[];
}

export interface DetectionModelLabelsInfo {
    labelAnnotationsCount: number;
    name: string;
}

export interface DetectionModelTrainingAccuracyStatistic {
    epochs: [number];
    metricsByName: {
        [id: string]: DetectionModelTrainingAccuracyStatisticMetric;
    };
}

export interface DetectionModelTrainingAccuracyStatisticMetric {
    name: string;
    data: number[];
}

export interface DetectionModelTrainingAccuracyStatisticSeries {
    name: string;
    data: number[];
    index: number;
}

export enum DetectionModelTrainingAccuracyStatisticSeriesGroup {
    Base = 'Base metrics',
    FScore = 'F-score',
    ClassMap = 'Class mAP **'
}

export enum DetectionModelTrainingAccuracyStatisticMetricName {
    TotalLoss = 'Validation Loss',
    Map = 'mAP *',
    Map50 = 'mAP **',
    FScoreSegmentation = 'FScore',
    MapSegmentation = 'mAP',
    FScoreLarge = 'F-score (Large)',
    FScoreMedium = 'F-score (Medium)',
    FScoreSmall = 'F-score (Small)',
    Accuracy = 'Accuracy',
    MapOrFScore = 'mAP | F-Score'
}

export enum DetectionModelProgressStatus {
    Initialising = 'initialising',
    DownloadingData = 'downloadingData',
    Training = 'training',
    Saving = 'saving',
    Cancelling = 'cancelling',
    EarlyStopping = 'earlyStopping',
    Error = 'error'
}

export enum DetectionModelFrameworkType {
    TensorFlow = 'TF2',
    TensorFlowV1 = 'TF1',
    PyTorch = 'PyTorch'
}

export enum DetectionModelFrameworkTypeUI {
    TF2 = 'TensorFlow (v2.4.1)',
    TF1 = 'TensorFlow (v1.15.2)',
    PyTorch = 'PyTorch',
}

export enum DetectionModelBaseModelEnum {
    Yolov5s = 'yolov5s',
    Yolov5t = 'yolov5t',
    SsdMobileNetV2FPNLite = 'ssdMobileNetV2FPNLite',
    SsdMobileNetV2Standard = 'ssdMobileNetV2Standard',
    ResNext50 = 'resNext50',
    EfficientNetB0 = 'efficientNetB0',
    RCNN = 'rcnn'
}

export enum DetectionModelBaseModelEnumUI {
    yolov5s = 'YOLOv5 Small',
    yolov5t = 'YOLOv5 Tiny',
    ssdMobileNetV2FPNLite = 'SSD MobileNet v2 FPN Lite',
    ssdMobileNetV2Standard = 'SSD MobileNet v2 Standard',
    resNext50 = 'ResNext50',
    efficientNetB0 = 'EfficientNet b0',
    rcnn = 'Fast R-CNN + Mask R-CNN'
}

export enum DetectionModelInputSizeEnum {
    VeryFast = 'veryFast',
    Fast = 'fast',
    Accurate = 'accurate',
    OCVeryFast = 'ocVeryFast',
    OCFast = 'ocFast',
    OSFast = 'osFast'
}

export enum DetectionModelInputSizeEnumUI {
    veryFast = 'Very fast (300x300)',
    fast = 'Fast (320x320)',
    accurate = 'Accurate (640x640)',
    ocVeryFast= '224x224',
    ocFast = '256x256',
    osFast = '320x320'
}

export enum DetectionModelValidationRatioEnum {
    Standart = '70/30',
}

export enum DetectionModelValidationRatioEnumUI {
    '70/30' = '70% / 30%'
}

export enum DetectionModelClassTypeEnumUI {
    Single = 'Single-class model',
    Multi = 'Multi-class model'
}

export interface DetectionModelBaseModelType {
    value: DetectionModelBaseModelEnum;
    text: DetectionModelBaseModelEnumUI;
    frameworkType: DetectionModelFrameworkType;
}

export interface DetectionModelInputSizeType {
    value: DetectionModelInputSizeEnum;
    text: DetectionModelInputSizeEnumUI;
    baseModels: DetectionModelBaseModelEnum[];
}

export interface ModelTrainingSettings {
    frameworkType: DetectionModelFrameworkType;
    baseModel: DetectionModelBaseModelEnum;
    modelInputSize: DetectionModelInputSizeEnum;
    baseModels: DetectionModelBaseModelType[];
    modelsInputSize: DetectionModelInputSizeType[];
    ignoreEmptyAnnotations: boolean;
    augmentations: ModelTrainingAugmentationSettings;
    isMultiLabel: boolean;
}

export interface ModelTrainingAugmentationSettings {
    horizontalFlip: boolean;
    verticalFlip: boolean;
    randomScale: boolean;
    adjustBrightness: boolean;
    adjustHue: boolean;
    adjustSaturation: boolean;
    blur: number;
    saltPepper: number;
    randomLine: number;
}

export enum ModelTrainingAugmentationSettingsUI {
    horizontalFlip = 'Horizontal Flip',
    verticalFlip = 'Vertical Flip',
    randomScale = 'Random Scale',
    adjustBrightness = 'Adjust Brightness',
    adjustHue = 'Adjust Hue',
    adjustSaturation = 'Adjust Saturation',
    blur = 'Blur',
    saltPepper = 'Noise (salt and pepper)',
    randomLine = 'Noise (random lines)'
}

export enum DetectionModelProgressStatusUI {
    initialising = 'Initialising',
    downloadingData = 'Downloading Data',
    training = 'Training',
    saving = 'Saving',
    cancelling = 'Cancelling',
    earlyStopping = 'Early Stopping',
    error = 'Error'
}

export interface ApkConfig {
    appTemplate: ModelDownloadAppTemplateEnum,
    ocrField: string | undefined
}

export interface DetectionModelDownload {
    id: string;
    type: DetectionModelDownloadType;
    createdAt: string;
    downloadId: string;
    updatedAt?: string;
    buildTime?: number;
    buildCompletedAt?: string;
    buildCanceledAt?: string;
    progressStatus?: DetectionModelDownloadProgressStatus;
    url?: string;
    password?: string;
    downloadCount?: number;
    deletedAt?: string;
    projectId?: Project['id'];
    maxDetections: number;

    detectionModelId: DetectionModel['id'];

    detectionModel: DetectionModel;
    apkConfig: ApkConfig
}

export enum ModelDownloadAppTemplateEnum {
    Continuous = 'continuous',
    TakePhoto = 'takePhoto',
    TakePhotoAndOCR = 'takePhotoAndOCR'
}

export enum ModelDownloadAppTemplateEnumUI {
    continuous = 'Continuous',
    takePhoto = 'Take Photo',
    takePhotoAndOCR = 'Take Photo + OCR'
}

export enum DetectionModelDownloadProgressStatus {
    Error = 'error'
}

export interface AdminStatistic {
    userCount?: number;
    runningTrainingCount?: number;
    runningAllSimulationsCount?: number;
}

export interface ProjectStatistic {
    projectCount?: number;
    labelCount?: number;
    datasetImageCount?: number;
    modelTrainingCount?: number;
    runningModelTrainingCount?: number;
    modelSimulationCount?: number;
    runningModelSimulationCount?: number;
    modelDownloadCount?: number;
    runningModelDownloadCount?: number;
    running3DModelSimulationCount?: number;
    model3DSimulationCount?: number;
    runningPredictiveMaintenanceCount?: number;
    predictiveMaintenanceModelCount?: number;
}

export interface AccountStatistic {
    admin: AdminStatistic;
    project: ProjectStatistic;
}

export enum DetectionModelDownloadType {
    ApkProDetection = 'apkProDetection',
    ApkProClassification = 'apkProClassification',
    PoseEstimationFree = 'peFree',
    PoseEstimationPro = 'pePro',
    TFlite16PoseEstimation = 'peTFlite16',
    ApkSourceCodeDetection = 'apkSourceCodeDetection',
    ApkSourceCodeClassification = 'apkSourceCodeClassification',
    TFLite = 'tflite',
    TFLiteFloat16 = 'tfliteFloat16',
    TFLiteFloat32 = 'tfliteFloat32',
    FrozenGraphPBDetection = 'frozenGraphPB',
    FrozenGraphPBClassification = 'frozenGraphPBClassification',
    ONNXDetection = 'onnxDetection',
    ONNXClassification = 'onnxClassification',
    LabelMap = 'labelMap',
    PytorchModel = 'yolov5',
    PytorchModel2 = 'pytorchModel',
    None = 'none'
}

export enum DetectionModelDownloadTypeUI {
    peFree = 'Android App (APK) Free Version',
    pePro = 'Android App (APK) Pro Version',
    peTFlite16 = 'TFLite (Float16)',
    apkProDetection = 'Android App (APK)',
    apkProClassification = 'Android App (APK)',
    apkSourceCodeDetection = 'APK source code (Android studio)',
    apkSourceCodeClassification = 'APK source code (Android studio)',
    tflite = 'TFLite (Quantized Int8)',
    tfliteFloat16 = 'TFLite (Quantized Float16)',
    tfliteFloat32 = 'TFLite (Non-quantized Float32)',
    frozenGraphPB = 'TensorFlow Saved Model (.pb)',
    frozenGraphPBClassification = 'TensorFlow Saved Model (.pb)',
    onnxDetection = 'ONNX (.onnx)',
    onnxClassification = 'ONNX (.onnx)',
    labelMap = 'Label Map (labelmap.txt)',
    yolov5 = 'PyTorch Saved Model (.pt)',
    pytorchModel = 'PyTorch Saved Model (.pt)',
    none = 'None'
}

export interface DetectionModelSimulation {
    id: string;
    type: DetectionModelSimulationType;
    progress: number;
    progressStatus?: DetectionModelSimulationProgressStatus;
    runtime: number;
    expectedResultAttachmentPath?: string;

    createdAt: string;
    deletedAt: string;
    updatedAt: string;

    completedAt?: string;
    canceledAt?: string;

    detectionModel?: DetectionModel;
    detectionModelId: DetectionModel['id'];
    sourceAttachment: Attachment;
    resultAttachment?: Attachment;
}

export interface DetectionModelGenerator {
    id: string;
    filename: string;
    hash: string;
    hasThumbnail: boolean;
    frames: number;
    
    jobId: string;
    progressStatus: DetectionModelGeneratorProgressStatus;
    progress: number;
    startedAt: string;
    completedAt: string | null;
    runtime: number | null;

    createdAt: string;
    updatedAt: string;

    projectId: Project['id'];
    project?: Project;
}

export enum DetectionModelSimulationProgressStatus {
    Error = 'error'
}

export enum DetectionModelSimulationType {
    Video = 'video',
    Image = 'image'
}

export enum DetectionModelGeneratorProgressStatus {
    Initializing = 'initializing',
    Generating = 'generating',
    Completing = 'completing',
    Cancelling = 'cancelling',
    Cancelled = 'cancelled',
    Success = 'success',
    Error = 'error'
}

export enum PredictiveMaintenanceModelTrainingProgressStatus {
    Initializing = 'initializing',
    Training = 'training',
    Completing = 'completing',
    Cancelling = 'cancelling',
    Cancelled = 'cancelled',
    Success = 'success',
    Error = 'error'
}

export enum PredictiveMaintenanceModelTrainingProgressStatusUI {
    initializing = 'Initializing',
    training = 'Training',
    completing = 'Completing',
    cancelling = 'Cancelling',
    cancelled = 'Cancelled',
    success = 'Success',
    error = 'Error'
}

export interface Attachment {
    id: string;
    entityId: string;
    entityType: string;

    path: string;
    originalName: string;
    size: number;

    createdAt: string;
    updatedAt: string;
}

//TODO move to requests

// Dataset image
export interface DatasetImageCreate {
    image: DatasetImage;
    project: Pick<Project, 'id' | 'name'>;
}
export interface DatasetImageDelete {
    ids: string[];
    project: Pick<Project, 'id' | 'name'>;
}

export interface DatasetImageImport {
    projectId: Project['id'];
    s3ObjectKey: string;
    replyToEmail: string;
}

export interface DatasetImageExport {
    projectId: Project['id'];
    exportType: DatasetExportType;
    replyToEmail: string;
}

// Project
export type ProjectCreate = {
    name: Project['name'];
    type: Project['type'];
    userId?: string;
    templateProjectId?: string;
};
export interface ProjectClone {
    fieldsToClone: ProjectField[];
    userId?: string;
    projectGroupId?: string;
}
export type ProjectUpdate = Partial<Project>;
export type ProjectGroupUpdate = Partial<ProjectGroup>;
export type TrackOpenProject = Pick<Project, 'id' | 'name' | 'type'>;

// Label
export type LabelUpdate = Partial<Label>;

// Annotation & classification
export interface ClassificationListSyncRequest {
    classifications: Classification[];
    preventTracking?: boolean;
}

// Detection model download
export type DetectionModelUpdate = Partial<DetectionModel>;
export type DetectionModelDownloadUpdate = Partial<DetectionModelDownload>;
export type DetectionModelDownloadListRequest = {
    filter: {
        projectId: Project['id'];
    };
};

// Detection model simulation
export type DetectionModelSimulationListRequest = {
    filter: {
        projectId: Project['id'];
    };
    s3bucketName: string;
    s3privateFolder: string;
};

export type DetectionModelSimulationRunningListRequest = {
    limit?: number;
    sortOrder?: string;
    sortField?: string;
};

export type DetectionModelSimulationUpdateForm = {
    detectionModelId: DetectionModelSimulation['detectionModelId'];
    file: File;
    minConfidence: number;
    boxLabel: string;
};
export type DetectionModelSimulationCreateRequest = {
    type: DetectionModelSimulationType;
    sourceAttachment: Partial<Attachment>;
    s3privateFolder: string;
    s3bucketName: string;
    resultFileKey: string;
    simulationOptions: DetectionModelSimulationCreateRequestOptions;
};
export type DetectionModelSimulationCreateRequestOptions = {
    confThreshold: string;
    label: string;
};
export const detectionModelSimulationSourceAttachmentMimeTypes = [
    MimeTypes.VideoType.Mp4 as string,
    MimeTypes.ImageType.Jpeg as string,
    MimeTypes.ImageType.Png as string
];
export const classificationModelSimulationSourceAttachmentMimeTypes = [
    MimeTypes.ImageType.Jpeg as string,
    MimeTypes.ImageType.Png as string
];

// Detection model generator
export interface DetectionModelGeneratorListRequest {
    projectId: Project['id'];
}

export interface DetectionModelGeneratorRunningListRequest {
    projectId?: Project['id'];
    limit?: number;
    sortOrder?: string;
    sortField?: string;
}

export interface DetectionModelGeneratorCreateRequest {
    model: Partial<DetectionModelGenerator>;
    s3Bucket: string;
    inputFilePath: string;
    outputFolder: string;
    frames: number;
}

// Detection model
export interface DetectionModelTrainingStartRequest {
    sourceS3BucketName: string;
    sourceS3ProjectFolder: string;
    trainConfig: Partial<ModelTrainingSettings>;
}

export interface DetectionModelCreate {
    s3Bucket: string;
    s3Folder: string;
    frameworkType: DetectionModelFrameworkType;
    progressStatus: DetectionModelProgressStatus;
    accuracy: 100;
    completedAt: string;
}

export enum LicenseType {
    Free = 'free',
    Pro = 'pro'
}

export enum LicenseTypeUI {
    free = 'Free',
    pro = 'Professional'
}

export interface UserInfo {
    id: string;
    cognitoId: string;

    email: string;
    name: string;
    surname: string;
    usageType: UserUsageType;
    licenseType: LicenseType;
    licenseExpiry: string | null;
    isAdmin: boolean;

    createdAt: string;
    updatedAt: string;
    lastActivity: string;
    events: DbEvent[] | null;
    fileUploads: FileUploadElement[] | null;
}
export interface UserEmailPrefs {
    emailCaseStudies: boolean;
    emailNewFeatures: boolean;
    emailNewTemplates: boolean;
}
export interface UserStoragePrefs {
    storagePath: string;
}

export enum UserUsageType {
    Student = 'student',
    Teacher = 'teacher',
    SmallBusiness = 'smallBusiness',
    LargeCompany = 'largeCompany',
    NonProfitOrCharity = 'nonProfitOrCharity',
    Personal = 'personal'
}

export enum UserUsageTypeUI {
    student = 'Student',
    teacher = 'Teacher',
    smallBusiness = 'Small Business',
    largeCompany = 'Large Company',
    nonProfitOrCharity = 'Non-profit or Charity',
    personal = 'Personal'
}

export type User = UserInfo & UserEmailPrefs & UserStoragePrefs;
export type CreateUser = Omit<
    UserInfo,
    'id' | 'licenseType' | 'licenseExpiry' | 'isAdmin' | 'createdAt' | 'updatedAt'
>;

export interface Feedback {
    email: string;
    message: string;
}

export interface UpgradeLicense {
    email: string;
}

export interface videoElement {
    title: string;
    url: string;
}
export interface SetVideoForm {
    projectName: string;
    pageName: string;
    videoList: videoElement[];
}

export enum SettingsDefinition {
    'pages-model-training-advanced-settings-form-tabs-1' = 'presets',
    'pages-model-training-advanced-settings-form-tabs-2' = 'custom'
}

export interface FileUploadElement {
    id?: string;
    date: string;
    title: string;
    type: string;
    link: string;
}

export interface SetDeviceLicense {
    clientInfo: string;
    deviceName: string;
    machine_id: string;
    expire: string;
}

export interface DeviceLicense {
    id: string;
    clientInfo: string;
    deviceName: string;
    machine_id: string;
    licenseKey: string;
    expire: string;
    createdAt: string;
    updatedAt?: string;
}

export type PredictiveMaintenance = {
    id: string;
    filename: string;
    hash: string;
    progress: number;
    runtime: number;
    progressStatus: PredictiveMaintenanceModelTrainingProgressStatus;
    projectId: string;
    createdAt: string;
    completedAt: string;
}

export interface SetPredictiveMaintenanceModel {
    filename: string;
    hash: string;
    progress: number;
    runtime: number;
    progressStatus: PredictiveMaintenanceModelTrainingProgressStatus;
    projectId: string;
}

export interface PredictiveMaintenanceCreateRequest {
    model: Partial<PredictiveMaintenance>;
    s3Bucket: string;
    inputFilePath: string;
    outputFolder: string;
}

export interface PredictiveMaintenanceListRequest {
    projectId: Project['id'];
}

export interface ChatOptions {
    url: string,
    token: string
}

export enum DatasetExportType {
    Images = 'images',
    VOC = 'VOC'
}
