import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify'
import { useStores } from '../../store';

export const useAuthCheck = () => {
    const {userStore} = useStores();
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const [checkingAuth, setCheckingAuth] = useState<boolean>(true);

    useEffect(() => {
        const checkAuth = async () => {
            try{
                const accessToken = (await Auth.currentSession()).getAccessToken();
                await userStore.authUserByJWT(accessToken.payload.sub, accessToken.getJwtToken());

                setAuthenticated(true);
            }catch(e) {
                setAuthenticated(false);
            }
            setCheckingAuth(false);
        }

        checkAuth();
    }, [])

    return {
        authenticated,
        checkingAuth
    }
}
