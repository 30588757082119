import React, { FormEvent, useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { DEFAULT_LABEL_COLOR, LabelUpdate } from '../../../models/api';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import { isTextFile } from '../../../utils/helper-functions';
import { toast } from 'react-toastify';
import { LabelBulkUploadConfirmation } from '../../../components/Modals/LabelBulkUploadConfirmation';
import DropdownColorPicker from '../../../components/DropdownColorPicker';

interface Props {
    onCreate: (params: LabelUpdate[]) => void;
    isLoading: boolean;
}

const NewLabelPanel: React.FC<Props> = ({ onCreate, isLoading }) => {
    // TODO Formik
    const [formState, setFormState] = useState<LabelUpdate>({ name: '', color: DEFAULT_LABEL_COLOR });
    const [labelColorDropDown, setLabelColorDropDown] = useState<boolean>(false);
    const [bulkUploadConfirmation, setBulkUploadConfirmation] = useState<LabelUpdate[] | null>(null);

    const handleLabelColorChange = (color: string) => {
        setFormState({ ...formState, color });
    };

    const handleColorDropDownToggle = () => {
        setLabelColorDropDown(!labelColorDropDown);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onCreate([formState]);
        setFormState({ ...formState, name: '' });
    };

    const handleFileDrop = async (file: File) => {
        if (!isTextFile(file)) {
            toast('Wrong file type, please specify text file', { type: toast.TYPE.ERROR });
            return;
        }
        const text = await file.text();
        const labelNames = text.split('\n').filter(label => !!label);
        const labels = labelNames.map((name, i) => {
            return { name, color: DEFAULT_LABEL_COLOR };
        });
        if (labels.length < 2) {
            toast('File need to contain more than 1 label for bulk upload', { type: toast.TYPE.ERROR });
            return;
        }
        setBulkUploadConfirmation(labels);
    };

    const handleBulkLabelUploadAccept = () => {
        onCreate(bulkUploadConfirmation!);
        setBulkUploadConfirmation(null);
    };

    const handleBulkLabelUploadClose = () => {
        setBulkUploadConfirmation(null);
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex flex-wrap'>
                    <div className='d-flex align-items-center mr-4 mb-3'>
                        <Input
                            type='text'
                            name='name'
                            placeholder='e.g. boat, car, person, hat'
                            onChange={(e) => setFormState({ ...formState, name: e.target.value })}
                            value={formState?.name}
                            required={true}
                            style={{ width: '15rem' }}
                        />

                        <div className='flex-shrink-0 ml-3'>Choose color</div>
                        <DropdownColorPicker 
                            isOpen={labelColorDropDown}
                            toggle={handleColorDropDownToggle}
                            handleChange={handleLabelColorChange}
                            color={formState.color}
                            toggleClassName='p-0 ml-2'
                        />
                        <Button color='primary' size='lg' className='flex-shrink-0 ml-3' disabled={isLoading}>
                            Create New Label
                        </Button>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                        <div className="font-weight-bold text-primary text-nowrap">
                            Bulk Upload (txt):
                        </div>
                        <div
                            className={cx('dropzone-wrapper h-auto w-auto ml-4', {
                                'dropzone-disabled': false
                            })}
                        >
                            <Dropzone
                                onDrop={(files) => files.length && handleFileDrop(files[0])}
                                disabled={false}
                                multiple={false}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="dropzone-content p-2">
                                            Drop files here or click to upload
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                </div>
            </Form>
            <LabelBulkUploadConfirmation
                isOpen={!!bulkUploadConfirmation}
                onAccept={handleBulkLabelUploadAccept}
                onReject={handleBulkLabelUploadClose}
                onCancel={handleBulkLabelUploadClose}
                data={bulkUploadConfirmation}
            />
        </>
    );
};

export default NewLabelPanel;
