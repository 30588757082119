import React from 'react';
import { DropdownList } from 'react-widgets';
import { DetectionModelDatasetInfo, ModelDownloadAppTemplateEnum, ModelDownloadAppTemplateEnumUI } from '../../../models/api';
import { useFormikContext } from 'formik';
import { ModelDownloadValues } from '../GeneratingPanel';

interface Props {
    appTemplatesData: {
        value: ModelDownloadAppTemplateEnum
        text: ModelDownloadAppTemplateEnumUI
    }[]
}

const ApkSettings: React.FC<Props> = ({ appTemplatesData }) => {

    const { setValues, values } = useFormikContext<ModelDownloadValues>()

    

    const getOCRFieldData = (datasetInfo: DetectionModelDatasetInfo | undefined) => {
        return datasetInfo?.labelsInfo.map((labelInfo) => ({ value: labelInfo.name }))
    }

    const handleAppTemplateChange = ({ value }: { value: ModelDownloadAppTemplateEnum }) => {
        setValues({
            ...values,
            apkConfig: {
                appTemplate: value,
                ocrField: value === ModelDownloadAppTemplateEnum.TakePhotoAndOCR ? values.detectionModel?.datasetInfo?.labelsInfo[0].name : undefined
            }
        })
    };

    const handleOCRTemplateChange = ({ value }: { value: string }) => {
        setValues({
            ...values,
            apkConfig: {
                ...values.apkConfig,
                ocrField: value
            }
        })
    };

    return (
        <table className='table table-striped'>
                <tbody>
                    <tr>
                        <td className='w-40'>Application Template:</td>
                        <td>
                            <DropdownList
                                data={appTemplatesData}
                                dataKey='value'
                                textField='text'
                                filter={false}
                                value={values.apkConfig.appTemplate}
                                onChange={handleAppTemplateChange}
                            
                            />
                        </td>
                    </tr>

                    {values.apkConfig.appTemplate === ModelDownloadAppTemplateEnum.TakePhotoAndOCR && (
                        <tr>
                            <td>OCR Field:</td>
                            <td>
                                <DropdownList
                                    data={getOCRFieldData(values.detectionModel?.datasetInfo)}
                                    dataKey='value'
                                    textField='value'
                                    filter={false}
                                    value={values.apkConfig.ocrField}
                                    onChange={handleOCRTemplateChange}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
    );
};

export default ApkSettings;
