import React from "react";
import { Layer, Image } from "react-konva";
import { Points } from "..";

interface Props {
    width: number;
    height: number;
    image: HTMLImageElement;
    imageSource: HTMLImageElement;
    setScale: React.Dispatch<React.SetStateAction<number | undefined>>;
    setImage: React.Dispatch<React.SetStateAction<HTMLImageElement | undefined>>;
    setStageHeight: React.Dispatch<React.SetStateAction<number>>;
    setStageWidth: React.Dispatch<React.SetStateAction<number>>;
    setStageOffset: React.Dispatch<React.SetStateAction<Points>>;
}

const ImageCanvas: React.FC<Props> = (props) => {
    const { width, height, image, imageSource, setScale, setImage, setStageHeight, setStageWidth, setStageOffset } = props;

    const layoutRef = React.useRef(null);

    React.useEffect(() => {
        if (imageSource) {
            const onload = () => {
                setImage(imageSource)
            }

            imageSource.addEventListener('load', onload);
            return () => {
                imageSource.removeEventListener('load', onload);
            }
        }
    }, [imageSource, image])

    React.useEffect(() => {
        let stageOffsetX: number = 0;
        let stageOffsetY: number = 0;
        const imageWidth = image?.width ?? 1;
        const imageHeight = image?.height ?? 1;
        const scale = Math.min(width / imageWidth, height / imageHeight);
        const stageHeight = imageHeight * scale;
        const stageWidth = imageWidth * scale;
        setStageHeight(stageHeight);
        setStageWidth(stageWidth);
        if(Math.round(stageWidth) < width) {
            stageOffsetX = (width - stageWidth) / 2;
        }
        if(Math.round(stageHeight) < height) {
            stageOffsetY = (height - stageHeight) / 2;
        }
        setStageOffset({x: stageOffsetX, y: stageOffsetY});
        setScale(scale);
    }, [image, width, height])

    return (
        <Layer ref={layoutRef}>
            {imageSource && <Image image={image} shadowColor='black' shadowBlur={10} shadowOffset={{x: 0, y: 0}} shadowOpacity={0.3} />}
        </Layer>
    );
}

export default ImageCanvas;