import { DatasetImage, DetectionModelGenerator, PredictiveMaintenance, Project } from '../models/api';
import awsconfig from '../aws-exports';
import { AccessLevel } from '@aws-amplify/ui-components';
import { Extensions } from '../models/extensions';

export interface s3StorageConfig {
    level: AccessLevel;
    identityId?: string;
}

export const MODEL_3D_EXTENSION = Extensions.Glb;

export const saveFromS3As = (blob: Blob | MediaSource, fileName: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export const s3Util = {
    getDatasetImageKey: (image: DatasetImage) => {
        return `projects/${image.projectId}/images/${image.hashedName}`;
    },

    getDatasetImportZipKey: (
        projectId: Project['id'],
        zipFileName: string
    ) => {
        return `projects/${projectId}/dataset-import/${zipFileName}`;
    },

    getDatasetImageThumbnailKey: (image: DatasetImage) => {
        return `projects/${image.projectId}/images/thumbnails/${image.hashedName}`;
    },

    getModelGeneratorVideoKey: (
        projectId: Project['id'],
        generatorId: DetectionModelGenerator['id']
    ) => {
        return `projects/${projectId}/modeling3d/${generatorId}/video.${Extensions.Mp4}`;
    },

    getModelGeneratorZipImagesKey: (
        projectId: Project['id'],
        generatorId: DetectionModelGenerator['id']
    ) => {
        return `projects/${projectId}/modeling3d/${generatorId}/images.${Extensions.Zip}`;
    },

    getModelGeneratorThumbnailKey: (
        projectId: Project['id'],
        generatorId: DetectionModelGenerator['id']
    ) => {
        return `projects/${projectId}/modeling3d/${generatorId}/thumbnail.${Extensions.Png}`;
    },

    getModelGeneratorOutputFolder: (
        projectId: Project['id'],
        generatorId: DetectionModelGenerator['id']
    ) => {
        return `projects/${projectId}/modeling3d/${generatorId}/results/`;
    },

    getModelGeneratorResultKey: (
        projectId: Project['id'],
        generatorId: DetectionModelGenerator['id']
    ) => {
        return `projects/${projectId}/modeling3d/${generatorId}/results/model.${MODEL_3D_EXTENSION}`;
    },

    getDetectionModelSimulationFolder: (projectId: Project['id']) => {
        return `projects/${projectId}/detectionModels/simulations`;
    },

    getDetectionModelSimulationSourcesFolder: (projectId: Project['id']) => {
        return `${s3Util.getDetectionModelSimulationFolder(projectId)}/sources`;
    },

    getDetectionModelSimulationResultFolder: (projectId: Project['id']) => {
        return `${s3Util.getDetectionModelSimulationFolder(projectId)}/results`;
    },

    getS3BucketName: () => {
        return awsconfig.aws_user_files_s3_bucket;
    },

    getS3PrivateFolder: (identityId: string) => {
        return `private/${identityId}`;
    },

    getS3ProjectFolder: async (identityId: string, projectId: string) => {
        return `private/${identityId}/projects/${projectId}`;
    },

    getPredictiveMaintenanceOutputFolder: (
        projectId: Project['id'],
        predictiveMaintenanceTrainingId: PredictiveMaintenance['id']
    ) => {
        return `projects/${projectId}/predictiveMaintenance/${predictiveMaintenanceTrainingId}/results/`;
    },

    getPredictiveMaintenanceResultPictureKey: (
        projectId: Project['id'],
        predictiveMaintenanceTrainingId: PredictiveMaintenance['id']
    ) => {
        return `projects/${projectId}/predictiveMaintenance/${predictiveMaintenanceTrainingId}/results/predictive.${Extensions.Png}`;
    },

    getPredictiveMaintenanceResultOutputFileKey: (
        projectId: Project['id'],
        predictiveMaintenanceTrainingId: PredictiveMaintenance['id']
    ) => {
        return `projects/${projectId}/predictiveMaintenance/${predictiveMaintenanceTrainingId}/results/model.fstep`;
    },

    getPredictiveMaintenanceFileKey: (
        projectId: Project['id'],
        predictiveMaintenanceTrainingId: PredictiveMaintenance['id']
    ) => {
        return `projects/${projectId}/predictiveMaintenance/${predictiveMaintenanceTrainingId}/data.${Extensions.Csv}`;
        
    },

    getPredictiveMaintenanceTemplateKey() {
        return `downloads/firststep_predictive_maintenance_template.${Extensions.Csv}`;
    }
};
