import React, { useState } from 'react';
import { ForgotPwdForm } from './forgot-pwd-form';
import { ResetPwdForm } from './reset-pwd-form';

export const ForgotPwdFlow = () => {
    const [isRequesting, setIsRequesting] = useState<boolean>(true);
    const [email, setEmail] = useState<string>('');

    const handleForgotPwdSubmit = (email:string) => {
        setIsRequesting(false);
        setEmail(email);
    }

    return isRequesting ? <ForgotPwdForm onSubmit={handleForgotPwdSubmit}/> : <ResetPwdForm email={email}/>;
}
