import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import { VideoFramingModal } from '../VideoFramingModal';
import { Label, ProjectType } from '../../../models/api';
import { isImageFile, isVideoFile, concatFilesNameWithMaxLength } from '../../../utils/helper-functions';
import ImageUploadIndicator from '../ImageUploadIndicator';
import Dropzone from 'react-dropzone';
import Multiselect from 'react-widgets/Multiselect';
import cx from 'classnames';
import { MultiLabelConfirmationModal } from '../../../components/Modals/MultiLabelConfirmationModal';
import { faTags, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import { NamingConventionConfirmation } from '../../../components/Modals/NamingConventionConfirmation';
import { toast } from 'react-toastify';
import path from 'path';

const NewImagePanel: React.FC = observer(() => {
    const { datasetImageStore, imageToZipStore, projectsStore, labelsStore } = useStores();

    const [labels, setLabels] = useState<Label[]>([]);
    const [uploadedVideo, setUploadedVideo] = useState<File | null>(null);

    const [multiLabelConfirmation, setMultiLabelConfirmation] = useState<Label[] | null>(null);
    const [namingConventionConfirmation, setNamingConventionConfirmation] = useState<File[] | null>(null);

    const [isMultiLabelLoading, setIsMultiLabelLoading] = useState(false);
    const [isMultiLabelBlocked, setIsMultiLabelBlocked] = useState(false);
    const [isDisabledButtonUpload, setIsDisabledButtonUpload] = useState(true);

    const [textDropZone, setTextDropZone] = useState('Drop files here or click to upload');
    const [filesObject, setFilesObject] = useState<File[] | null>(null);


    const project = projectsStore.current!;
    const labelList = [...labelsStore.list] as Label[];
    const isDropzoneDisabled = imageToZipStore.inProgress || datasetImageStore.hasUnprocessedItems;
    const isObjectClassification = project.type === ProjectType.ObjectClassification;

    useEffect(() => {
        setLabels([]);
    }, [datasetImageStore.state.images.fetched]);

    const handleDrop = (files: File[]) => {
        if (!files.length) {
            return;
        }

        setFilesObject(files)
        setTextDropZone(concatFilesNameWithMaxLength(files))
        setIsDisabledButtonUpload(false)
    }


    const isMatchingNameConventionPattern = (file: File) => {
        const splittedName = path.basename(file.name).split('_');
        return splittedName.length > 1;
    }

    const checkOnSwitchingMultiLabelMode = (newLabels: Label[]) => {
        return !project.isMultiLabel && labels.length === 1 && newLabels.length > 1;
    };

    const handleLabelsChange = (newLabels: Label[]) => {
        if (checkOnSwitchingMultiLabelMode(newLabels)) {
            setMultiLabelConfirmation(newLabels);
        } else {
            setLabels(newLabels);
        }
    };

    const handleIsMultiLabelChange = async () => {
        setIsMultiLabelLoading(true);
        const canSwitch = await projectsStore.canSwitchMultiLabel();
        setIsMultiLabelLoading(false);
        if (!canSwitch) {
            setIsMultiLabelBlocked(true);
            return;
        }
        setMultiLabelConfirmation(null);
        setIsMultiLabelBlocked(false);
        setLabels(multiLabelConfirmation!);
        await projectsStore.updateItem(project.id, { isMultiLabel: !project.isMultiLabel });
    };

    const handleIsMultiLabelCancel = () => {
        setMultiLabelConfirmation(null);
    };

    const handleNamingConventionAccept = () => {
        datasetImageStore.uploadImages(namingConventionConfirmation!, labels, true);
        setNamingConventionConfirmation(null);
    };

    const handleNamingConventionReject = () => {
        datasetImageStore.uploadImages(namingConventionConfirmation!, labels, false);
        setNamingConventionConfirmation(null);
    };

    const handleNamingConventionCancel = () => {
        setNamingConventionConfirmation(null);
    };

    const handleUploadFiles = () => {

        if(!filesObject) return;

        setIsDisabledButtonUpload(true)

        const files = filesObject;

        const isEveryFileImage = files.every(isImageFile);
        const isShowingNamingConventionModal =
            isObjectClassification &&
            project.isMultiLabel &&
            files.every(isMatchingNameConventionPattern);

        if (isEveryFileImage && isShowingNamingConventionModal) {
            setNamingConventionConfirmation(files);
        } else if (isEveryFileImage) {
            datasetImageStore.uploadImages(files, labels);
        } else if (isVideoFile(files[0])) {
            setUploadedVideo(files[0]);
        } else {
            toast('Please specify either image(s) or video for upload', {
                type: toast.TYPE.ERROR
            });
        }

    }


    return (
        <>
            <div className="d-flex">
                <div className="d-flex flex-grow-1 flex-wrap">
                    <div className="d-flex w-100 align-items-center mb-2">
                        <div className="w-40 flex-shrink-0 font-weight-bold text-primary text-nowrap">
                            Add Image(s) or Video:
                        </div>
                        <div
                            className={cx('dropzone-wrapper h-auto w-100 ml-3', {
                                'dropzone-disabled': isDropzoneDisabled
                            })}
                        >
                            <Dropzone
                                onDrop={(files) => handleDrop(files)}
                                disabled={isDropzoneDisabled}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="dropzone-content p-2">
                                            {textDropZone}
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                    {isObjectClassification && (
                        <div className="d-flex w-100 align-items-center mb-2 ">
                            <div className="w-40 flex-shrink-0 font-weight-bold text-primary text-nowrap mr-3">
                                Bulk Label:
                            </div>
                            <Multiselect
                                data={labelList}
                                value={labels}
                                dataKey="id"
                                textField="name"
                                filter="contains"
                                placeholder="No label"
                                style={{ flexGrow: 1 }}
                                disabled={isDropzoneDisabled}
                                onChange={handleLabelsChange}
                            />
                            {project.isMultiLabel ? (
                              <ActionButton
                                color="light"
                                id="classification-mode-info-button"
                                className="w-auto h-auto ml-2"
                                tooltipText="Classification Model Type"
                                tooltipPlacement="top"
                                disabled={isDropzoneDisabled}
                                onClick={() => {
                                    setIsMultiLabelBlocked(false);
                                    setMultiLabelConfirmation([])
                                }}
                              >
                                  <div className="px-3 p-2">
                                      <FontAwesomeIcon icon={faTags} />
                                  </div>
                              </ActionButton>
                            ) : (
                              <ActionButton
                                color="light"
                                id="classification-mode-info-button"
                                className="w-auto h-auto ml-2"
                                tooltipText="Single label classification"
                                tooltipPlacement="top"
                                onClick={() => {
                                    setMultiLabelConfirmation([])
                                }}
                              >
                                  <div className="px-3 p-2">
                                      <FontAwesomeIcon icon={faTag} />
                                  </div>
                              </ActionButton>
                            )}
                        </div>
                    )}
                    <ActionButton
                        color="success"
                        id="classification-mode-upload-button"
                        className="w-auto h-auto ml-auto"
                        disabled={isDisabledButtonUpload}
                        onClick={handleUploadFiles}
                    >
                        <div className="px-3 p-2">
                            Upload
                        </div>
                    </ActionButton>
                </div>



            </div>
            <VideoFramingModal
                video={uploadedVideo}
                onClose={() => setUploadedVideo(null)}
                onProcess={handleDrop}
            />
            <MultiLabelConfirmationModal
                isMultiLabel={project.isMultiLabel}
                isOpen={!!multiLabelConfirmation}
                onCancel={handleIsMultiLabelCancel}
                onAccept={handleIsMultiLabelChange}
                isLoading={isMultiLabelLoading}
                isBlocked={isMultiLabelBlocked}
            />
            <NamingConventionConfirmation
                isOpen={!!namingConventionConfirmation}
                onAccept={handleNamingConventionAccept}
                onReject={handleNamingConventionReject}
                onCancel={handleNamingConventionCancel}
            />

        </>
    );
});

export default NewImagePanel;
