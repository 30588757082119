import React, { useState } from 'react';
import cn from 'classnames';
import ApexCharts from 'apexcharts';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { truncate } from '../../../../utils/helper-functions';
import {
    DetectionModelTrainingAccuracyStatisticSeries,
    DetectionModelTrainingAccuracyStatisticSeriesGroup
} from '../../../../models/api';

interface Props {
    chartId: string;
    chartColors: string[];
    seriesGroups: Record<string, DetectionModelTrainingAccuracyStatisticSeries[]>;
    seriesToggleStatuses: Record<string, boolean>;
    setSeriesToggleStatuses: (value: Record<string, boolean>) => void;
    isObjectDetection: boolean;
}

const DISPLAY_ITEMS = 4;
const MAX_SERIES_LEN = 12;

export const TrainingAccuracyLegend: React.FC<Props> = (props) => {
    const {
        chartId,
        chartColors,
        seriesGroups,
        seriesToggleStatuses,
        setSeriesToggleStatuses,
        isObjectDetection
    } = props;

    const [startIdx, setStartIdx] = useState(0);

    const toggleSeries = (seriesName: string) => {
        setSeriesToggleStatuses({ ...seriesToggleStatuses, [seriesName]: !seriesToggleStatuses[seriesName] });
        ApexCharts.exec(chartId, 'toggleSeries', seriesName);
    };

    return (
        <div
            className="app-pages-model-training__accuracy-statistic__legend__wrapper"
            style={{ height: isObjectDetection ? '22.5%' : '15%' }}
        >
            <div className="app-pages-model-training__accuracy-statistic__legend__container">
                {Object.entries(seriesGroups).map(([key, group]) => {
                    const isShowingIndicators =
                        key === DetectionModelTrainingAccuracyStatisticSeriesGroup.ClassMap &&
                        group.length > DISPLAY_ITEMS;
                    const groupToDisplay = isShowingIndicators
                        ? group.slice(startIdx, startIdx + DISPLAY_ITEMS)
                        : group;
                    return  (
                        <div className="d-flex align-items-center mb-2">
                            <div className="app-pages-model-training__accuracy-statistic__legend__group-title">
                                <span className="font-weight-bold text-nowrap">{key}:</span>
                            </div>
                            {groupToDisplay.map((series) => {
                                const seriesNameToDisplay = series.name.startsWith(key)
                                    ? series.name.slice(key.length + 2, -1)
                                    : series.name
                                return (
                                    <div
                                        className="d-flex align-items-center c-pointer mr-3"
                                        onClick={() => toggleSeries(series.name)}
                                    >
                                        <div
                                            className="app-pages-model-training__accuracy-statistic__dot mr-2"
                                            style={{ background: chartColors[series.index % chartColors.length] }}
                                        />
                                        <span
                                            className={cn('text-nowrap text-select-none', {
                                                'text-black-50': !seriesToggleStatuses[series.name]
                                            })}
                                            id={`series-${series.index}-span`}
                                        >
                                            {truncate(seriesNameToDisplay, series.index === 0 ? MAX_SERIES_LEN * 2 : MAX_SERIES_LEN)}
                                        </span>
                                        {seriesNameToDisplay.length > MAX_SERIES_LEN && (
                                            <UncontrolledTooltip
                                                target={`series-${series.index}-span`}
                                                placement="bottom"
                                            >
                                                {seriesNameToDisplay}
                                            </UncontrolledTooltip>
                                        )}
                                    </div>
                                );
                            })}
                            {isShowingIndicators && (
                                <>
                                    <Button
                                        color="white"
                                        size="sm"
                                        className="border ml-3"
                                        onClick={() => setStartIdx((prev) => prev - 1)}
                                        disabled={startIdx === 0}
                                    >
                                        <FontAwesomeIcon size="sm" icon={faArrowLeft} />
                                    </Button>
                                    <Button
                                        color="white"
                                        size="sm"
                                        className="border ml-1"
                                        onClick={() => setStartIdx((prev) => prev + 1)}
                                        disabled={startIdx === group.length - DISPLAY_ITEMS}
                                    >
                                        <FontAwesomeIcon size="sm" icon={faArrowRight} />
                                    </Button>
                                </>
                            )}
                        </div>
                    );
                })}
                {isObjectDetection && (
                    <>
                        <hr className="my-3" />
                        <div className="d-flex">
                            <span className="text-black-50 mr-3">mAP * (IoU@0.05:0.95)</span>
                            <span className="text-black-50">mAP ** (IoU@0.5)</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
