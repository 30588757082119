import { useState, useEffect } from 'react';
import { useStores } from '../../../store';
import PageTitle from '../../../components/PageTitle';
import {
    ImagesAnnotationsInfo,
    ImagesAnnotationsInfoName,
    Label,
} from '../../../models/api';
import BalancingScoreChart from '../../dataset-analysis/BalancingScoreChart';
import AnnotationsChart from '../../dataset-analysis/AnnotationsChart';
import LabelsChart from '../../dataset-analysis/LabelsChart';
import DnaLoader from '../../../components/DnaLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { compareByField } from '../../../utils/helper-functions';

export const DatasetAnalysisButton = observer(() => {
    const { datasetImageStore, labelsStore } = useStores();
    const [isOpen, setIsOpen] = useState(false);

    const sortedLabelList = [...labelsStore.list].sort((a, b) => compareByField(a, b, 'name'));

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        datasetImageStore.fetchImagesAnnotationsInfo();
        labelsStore.fetchList();
    }, [isOpen]);

    const toggleModal = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <>
            <div>
                <Button
                    className="btn mr-1 mr-lg-2 text-nowrap"
                    color="success"
                    onClick={toggleModal}
                    style={{ height: '35px' }}
                    disabled={datasetImageStore.hasUnprocessedItems}
                >
                    <FontAwesomeIcon icon={faChartPie} className="mr-1" />
                    Dataset Analysis
                </Button>
            </div>
            <Modal isOpen={isOpen} toggle={toggleModal} className="w-100" centered size={'xl'}>
                <ModalHeader toggle={toggleModal}>
                    <PageTitle
                        heading="Dataset Analysis"
                        icon="fas fa-chart-pie icon-gradient bg-warm-flame"
                        subheading="Analysis of your dataset"
                        className="m-0 p-0"
                    />
                </ModalHeader>
                <ModalBody className="modal-body__athens-gray pt-3">
                    <div className="app-pages-dataset-analysis-page-wrapper">
                        {datasetImageStore.imagesAnnotationsIsFetched && labelsStore.listIsFetched ? (
                            <div className="app-pages-dataset-analysis-page-content w-100">
                                <div className="balance-score-wrapper">
                                    <BalancingScoreChart
                                        labelsValues={sortedLabelList
                                            .filter(
                                                (val: Label) => (val.annotationAndClassificationCount || 0) > 0
                                            )
                                            .map(
                                                (val: Label) => val.annotationAndClassificationCount || 0
                                            )}
                                    />
                                </div>
                                <div className="d-flex justify-content-between" style={{ gap: '30px' }}>
                                    <AnnotationsChart
                                        annotationsNames={Object.keys(
                                            datasetImageStore.imagesAnnotationsInfo
                                        ).map(
                                            (val) =>
                                                ImagesAnnotationsInfoName[
                                                    val as keyof ImagesAnnotationsInfo
                                                    ]
                                        )}
                                        annotationsValues={Object.values(
                                            datasetImageStore.imagesAnnotationsInfo
                                        )}
                                    />
                                    <LabelsChart
                                        labelsValues={sortedLabelList.map(
                                            (val: Label) => val.annotationAndClassificationCount || 0
                                        )}
                                        labelsNames={sortedLabelList.map(
                                            (val: Label) => val.name
                                        )}
                                        colors={sortedLabelList.map(
                                            (val: Label) => val.color
                                        )}
                                    />
                                </div>
                            </div>
                        ) : (
                            <DnaLoader />
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
});
