import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import container from '../../container/container';
import { CreateUser, LicenseType, User } from '../../models/api';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Storage } from 'aws-amplify';
import { RootStore } from './root';
import { SignUpFormData } from '../../models/login/sign-up';
import { ChangePwdFormData } from '../../models/user-profile/change-pwd';
import path from 'path';
import { imageUtil } from '../../utils/image';
import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from '../../config/image';
import { toast } from 'react-toastify';
import { AccessLevel } from '@aws-amplify/ui-components';
import { createProgressToast } from '../../components/Toasts/ProgressToast';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const api = container.apiClient;

export class UserStore {
    user?: User;
    avatar?: string;
    rootStore: RootStore;
    requireSignUpVerifyEmail?: string;
    cognitoUserForNewPassword?: CognitoUser;
    impersonationUser?: User;
    isLoading: boolean = false;

    private readonly disposers: IReactionDisposer[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.disposers.push(
            reaction(
                () => this.user,
                async (user?: User) => {
                    if (!!user) {
                        try {
                            await Storage.get('avatar', {
                                level: AccessLevel.Private,
                                download: true
                            });
                            this.avatar = (await Storage.get('avatar', {
                                level: AccessLevel.Private
                            })) as string;
                        } catch (e) {
                            this.avatar = undefined;
                        }
                    } else {
                        this.avatar = undefined;
                    }
                }
            )
        );
    }

    dispose() {
        for (const disposer of this.disposers) disposer();
    }

    get isUserFetched() {
        return !!this.user;
    }
    get fullName() {
        if (!this.isUserFetched) return '';

        return `${this.user?.name} ${this.user?.surname}`;
    }

    setRequireSignUpVerifyEmail(email: string) {
        this.requireSignUpVerifyEmail = email;
    }

    async getIdentityIdByImpersonationUser() {
        const userInfo = await Auth.currentUserInfo();
        return this.impersonationUser ? this.impersonationUser.storagePath : userInfo.id;
    }

    getIdentityIdSync() {
        return this.impersonationUser ? this.impersonationUser.storagePath : this.user?.storagePath;
    }

    async createUser(cognitoId: string, values: SignUpFormData) {
        if (!values.usageType) {
            return;
        }

        const newUser: CreateUser = {
            cognitoId: cognitoId,
            email: values.email,
            name: values.name,
            surname: values.surname,
            usageType: values.usageType,
            events: null,
            fileUploads: null,
            lastActivity: ''
        };

        await api.userCreate(newUser);
        this.requireSignUpVerifyEmail = values.email;
    }

    async confirmSignUp(code: string) {
        if (!this.requireSignUpVerifyEmail) {
            return;
        }
        const toastId = createProgressToast();

        this.isLoading = true;

        try {
            await Auth.confirmSignUp(this.requireSignUpVerifyEmail, code);
            toast('Your email has been verified', { type: toast.TYPE.SUCCESS });
            this.requireSignUpVerifyEmail = undefined;
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            this.isLoading = false;
            toast.dismiss(toastId);
        }
    }

    async authUserByJWT(userId: string, token: string) {
        if (this.isUserFetched) return;
        try {
            api.setAuthorizationHeader(token);
            const { data } = await api.userGet(userId);

            runInAction(() => {
                this.user = data;
                api.trackLogin();
            });

            if (this.user && this.user.cognitoId) {
                const storagePath = this.user.storagePath ?? (await Auth.currentUserInfo()).id;

                const licenseExpiry = this.user.licenseExpiry
                    ? this.user.licenseExpiry.substr(0, 10)
                    : dayjs().format('YYYY-MM-DD');

                const licenseExpiryDatetime = dayjs(`${licenseExpiry} 23:59`, 'YYYY-MM-DD HH:mm');

                const isLicenseExpired =
                    this.user.licenseType === LicenseType.Pro &&
                    licenseExpiryDatetime.diff(dayjs(), 'minutes') < 0;

                const licenseType = isLicenseExpired ? LicenseType.Free : this.user.licenseType;

                if (this.user.storagePath !== storagePath || isLicenseExpired) {
                    await api.userUpdate({ ...this.user, licenseType, storagePath });
                }

                runInAction(() => {
                    this.user!.storagePath = storagePath;
                    this.user!.licenseType = licenseType;
                });
            }
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        }
    }
    async updateUser(user: User) {
        const toastId = createProgressToast();
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, {
                email: user.email,
                name: user.name,
                family_name: user.surname
            });

            await api.userUpdate(user);

            runInAction(() => {
                this.user = user;
                toast.dismiss(toastId);
            });

            toast('User information updated successfully', { type: toast.TYPE.SUCCESS });
        } catch (e) {
            toast('Update user information failed. Try again later', { type: toast.TYPE.ERROR });
            runInAction(() => {
                toast.dismiss(toastId);
            });
        }
    }

    async uploadAvatar(file: File) {
        const toastId = createProgressToast();
        try {
            const ext = path.extname(file.name);
            const thumbnail = (await imageUtil.resizeFile(
                file,
                ext,
                THUMBNAIL_WIDTH,
                THUMBNAIL_HEIGHT
            )) as File;
            await Storage.put('avatar', thumbnail, { level: AccessLevel.Private });

            const newUrl = (await Storage.get('avatar', { level: AccessLevel.Private })) as string;

            runInAction(() => {
                this.avatar = newUrl;
                toast.dismiss(toastId);
            });

            toast('Avatar uploaded successfully', { type: toast.TYPE.SUCCESS });
        } catch (e) {
            toast('Uploading failed. Try again later', { type: toast.TYPE.ERROR });
            runInAction(() => {
                toast.dismiss(toastId);
            });

            console.log(e);
        }
    }

    async changePwd(values: ChangePwdFormData) {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(currentUser, values.oldPassword, values.newPassword);
    }

    async userImpersonation(userId?: User['id']) {
        if (!userId){
            this.impersonationUser = undefined;
            await this.rootStore.projectsStore.setCurrentProject();
            this.rootStore.projectsStore.resetList();
            return;
        }

        try {
            const { data } = await api.userGet(userId);

            runInAction(() => {
                this.rootStore.projectsStore.setCurrentProject();
                this.rootStore.projectsStore.resetList();
                this.impersonationUser = data;
            });
        } catch (e) {
            //TODO error
        }
    }

    clearUser() {
        api.trackLogout();
        this.user = undefined;
        api.__extendHeaders({ Authorization: undefined });
    }

    createTemporaryUser(user: CognitoUser) {
        try {
            runInAction(() => (this.cognitoUserForNewPassword = user));
        } catch (e) {}
    }
}
