import React from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button } from 'reactstrap';
import { PwdFormField } from '../../../components/Form';
import { ChangePwdFormData } from '../../../models/user-profile/change-pwd';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

interface Props{}
type FormProps = Props & FormikProps<ChangePwdFormData>

const _ChangePasswordForm: React.FC<FormProps> = (props) => {
    const formName = 'change-password-form';
    const isLoading = !props.isValidating && props.isSubmitting;

    return <Form>
        <PwdFormField formName={formName} field={'oldPassword'} label={'Old password'}/>
        <PwdFormField formName={formName} field={'newPassword'} label={'New password'}/>
        <PwdFormField formName={formName} field={'verifyPassword'} label={'Verify password'}/>

        <Button
            color='primary'
            type='submit'
            disabled={isLoading}
        >
            Save
        </Button>
    </Form>
}

export const ChangePasswordForm:React.FC<Props> = observer((props)=>{
    const {userStore} = useStores();

    const initialValues:ChangePwdFormData = {
        oldPassword: '',
        newPassword: '',
        verifyPassword: ''
    };

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('required').min(6, 'The password is not valid'), // Workaround: 
        //when Cognito receives a password with length < 6 it returns the error message that's hard to interpret
        newPassword: Yup.string().required('required').min(8, 'The password should contain at least 8 symbols'),
        verifyPassword: Yup.string()
            .required('required')
            .oneOf([Yup.ref('newPassword')], `The passwords don't match`),
    });

    const handleSubmit = async (
        values: ChangePwdFormData,
        formikHelpers: FormikHelpers<ChangePwdFormData>
    ) => {
        try {
            await userStore.changePwd(values);

            toast('The password has been changed successfully', {type:toast.TYPE.SUCCESS})

            formikHelpers.resetForm();
        } catch (error) {
            if(error.name === 'NotAuthorizedException')
                formikHelpers.setErrors({oldPassword:'The password is not valid'})
            else
                toast(error.message, { type: toast.TYPE.ERROR });
        }

        formikHelpers.setSubmitting(false);
    }

    return <Formik<ChangePwdFormData>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
    >
        {(props)=><_ChangePasswordForm {...props}/>}
    </Formik>
});
