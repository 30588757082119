import React, { HTMLAttributes } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { DetectionModelFrameworkType, DetectionModelFrameworkTypeUI } from '../../../models/api';
import pyTorchIcon from '../../../assets/app/images/model-frameworks/pytorch.svg';
import TF1Icon from '../../../assets/app/images/model-frameworks/tf1.svg';
import TF2Icon from '../../../assets/app/images/model-frameworks/tf2.svg';

const frameworkTypeData = {
    TF2: {
        img: TF2Icon,
        text: DetectionModelFrameworkTypeUI.TF2,
    },
    TF1: {
        img: TF1Icon,
        text: DetectionModelFrameworkTypeUI.TF1,
    },
    PyTorch: {
        img: pyTorchIcon,
        text: DetectionModelFrameworkTypeUI.PyTorch,
    }
};

interface Props extends HTMLAttributes<HTMLImageElement>{
    frameworkType: DetectionModelFrameworkType;
}

const DetectionModelFrameworkTypeIcon: React.FC<Props> = ({ id, frameworkType, ...rest }) => {
    const data = frameworkTypeData[frameworkType];

    return (
        <>
            <img id={id} src={data.img} {...rest} />

            {id &&
            <UncontrolledTooltip target={id}>
                {data.text}
            </UncontrolledTooltip>
            }
        </>
    );
};

export default DetectionModelFrameworkTypeIcon;
