import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { observer } from 'mobx-react-lite';
import { ApexOptions } from 'apexcharts';

interface Props {
    labelsNames: string[]
    labelsValues: number[]
    colors: string[]
}

const LabelsChart: React.FC<Props> = observer(({labelsNames, labelsValues, colors}) => {
    const [chartData, setChartData] = useState<{series: { name: string; data: number[] }[]; options: ApexOptions}>();

    useEffect(() => {

        setChartData({
            series: [
                {
                    name: 'Number of Annotations',
                    data: labelsValues,
                },
            ],
            options: {
                title: {
                    text: 'Number of Annotations by Label',
                    margin: 30,
                    style: {
                        fontWeight: 'bold',
                        fontFamily: 'inherit',
                    },
                },
                chart: {
                    type: 'bar',
                    background: '#fff',
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        columnWidth: '50%',
                        distributed: true,
                    }
                },
                yaxis: {
                    labels: {
                        formatter: (value: any) => value,
                    },
                },
                xaxis: {
                    categories: labelsNames,
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    show: false
                },
                colors: colors,
            },
        });
    }, []);

    return (
        <>
            {chartData && (
                <div className="w-50">
                    <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="bar"
                        width="100%"
                        height="400"
                    />
                </div>
            )}
        </>
    );
});

export default LabelsChart;
