import React from 'react';
import { DropdownList } from 'react-widgets/cjs';
import CustomSlider from '../../../components/CustomSlider';
import { ProjectType } from '../../../models/api';
import { useStores } from '../../../store';

interface Props {
    onConfidenceChange: (value: number) => void;
    onBoxLabelChange: (value: string) => void;
    minConfidence: number;
    boxLabel: string;
}

const marks = {
    0: '0%',
    50: '50%',
    100: '100%',
};

export enum BoxLabelsEnum {
    None = 'none',
    Name = 'label',
    NameAndConfidence = 'full',
    Confidence = 'conf',
};

const boxLabelOptions = [
    { value: BoxLabelsEnum.None, text: 'None'},
    { value: BoxLabelsEnum.Name, text: 'Name only'},
    { value: BoxLabelsEnum.NameAndConfidence, text: 'Name and confidence'},
    { value: BoxLabelsEnum.Confidence, text: 'Confidence only'},
];

const AdvancedSettings: React.FC<Props> = ({ onConfidenceChange, onBoxLabelChange, minConfidence, boxLabel}) => {
    const { projectsStore } = useStores();

    const handleConfidenceChange = (value: number) => {
        onConfidenceChange(value);
    };

    const handleBoxLabelChange = (value: string) => {
        onBoxLabelChange(value);
    };

    const isPoseDetection = projectsStore.current?.type === ProjectType.PoseDetection;


    return (
        <div className="px-3 py-4">
            <div className="d-flex">
                <span className="mb-3 font-weight-bold">
                    Minimum Confidence: <span className="font-weight-normal">{minConfidence}%</span>
                </span>
            </div>
            <CustomSlider
                min={0}
                max={100}
                defaultValue={minConfidence}
                marks={marks}
                onChange={handleConfidenceChange}
                className="mb-4"
            />

            {!isPoseDetection &&
                <div className="mt-5 pt-3">
                    <div className='font-weight-bold flex-shrink-0 mr-2 mb-3'>
                        Box Label:
                    </div>
                    <DropdownList
                        data={boxLabelOptions}
                        dataKey='value'
                        textField='text'
                        value={boxLabel}
                        filter={false}
                        onChange={option => handleBoxLabelChange(option.value)}
                    />
                </div>
            }
        </div>
    );
};

export default AdvancedSettings;
