import React from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import { Col, Container, Row } from 'reactstrap';

export const SignUpFooter = () => {
    return (
        <Container>
            <Row xs={12}>
                <Col className={'text-center login-page__small-text'}>
                    <span>Already registered?</span>
                    {' '}
                    <NavLink to={Routes.SignIn}><b>Log in</b></NavLink>
                </Col>
            </Row>
        </Container>
    );
};
