import { faClone, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { Col, Container } from "reactstrap";
import { noDataText } from "../../../../components/BootstrapTable";
import ActionButton from "../../../../components/BootstrapTable/ActionButton";
import DeleteConfirmation from "../../../../components/Modals/DeleteConfirmation";
import { dateConfig } from "../../../../config/date";
import { DeviceLicense } from "../../../../models/api";
import { useStores } from "../../../../store";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface Props {
    items: DeviceLicense[];
    isLoading: boolean
}

const DeviceLicenseTable:React.FC<Props> = ({items}) => {
    const [deviceLicensesDeleteConfirmation, setDeviceLicensesDeleteConfirmation] = React.useState<DeviceLicense | null>(null);

    const { deviceLicensesStore } = useStores();

    const handleDeleteClose = () => {
        setDeviceLicensesDeleteConfirmation(null);
    }

    const handleCopy = (licenseKey:string) => {
        navigator.clipboard.writeText(`${licenseKey}`);
    }

    const handleDeleteConfirm = () => {
        if(!deviceLicensesDeleteConfirmation) {
            return;
        }
        deviceLicensesStore.deleteItem(deviceLicensesDeleteConfirmation.id);
        setDeviceLicensesDeleteConfirmation(null);
    }

    const columns: ColumnDescription<DeviceLicense>[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => rowIndex + 1
        },
        {
            dataField: 'Client',
            text: 'Client',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') {
                  return 1;
                }
                return -1; 
            },
            formatter: (_cel, row) => {
                return (
                    <Container fluid style={{paddingLeft: 0, width: 200, wordBreak: 'break-all'}}>
                        <Col style={{paddingLeft: 0}}>
                            {row.clientInfo}
                        </Col>
                    </Container>
                );
            }
        },
        {
            dataField: 'deviceName',
            text: 'Device Name',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') {
                  return 1;
                }
                return -1; 
            },
            formatter: (_cel, row) => {
                return (
                    <Container fluid style={{paddingLeft: 0, width: 200, wordBreak: 'break-all'}}>
                        <Col style={{paddingLeft: 0}}>
                            {row.deviceName}
                        </Col>
                    </Container>
                );
            }
        },
        {
            dataField: 'machineId',
            text: 'Machine Id',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                return (
                    <Container fluid style={{paddingLeft: 0, width: 200, wordBreak: 'break-all'}}>
                        <Col style={{paddingLeft: 0}}>
                            {row.machine_id}
                        </Col>
                    </Container>
                );
            }
        }, 
        {
            dataField: 'dateCreated',
            text: 'Date Created',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') {
                  return 1;
                }
                return -1; 
            },
            formatter: (_cel, row) => dayjs(row.createdAt).format(dateConfig.formats.dateWithoutAmPm)
        },
        {
            dataField: 'licenseExpiry',
            text: 'License Expiry Date',
            sort: true,
            formatter: (_cel, row) => {
                let licenseExpiry = new Date(row.expire);
                return dayjs(licenseExpiry).utc().format(dateConfig.formats.dateWithoutAmPm);
            }
        },
        {
            dataField: 'licenseKey',
            text: 'License Key',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const tooltipCloneButtonId = `pages-device-licenses-clone-button-${row.id}`;

                return (
                    <div className="d-flex justify-content-center">
                        <ActionButton
                            className="ml-2"
                            color="primary"
                            onClick={() => handleCopy(row.licenseKey)}
                            id={tooltipCloneButtonId}
                            tooltipText='Copy to clipboard'
                        >
                            <FontAwesomeIcon icon={faClone} />
                        </ActionButton>
                    </div>
                );
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            headerAlign: 'center',
            align: 'center',
            formatter: (_cel, row) => {
                const tooltipDeleteButtonId = `pages-device-licenses-delete-button-${row.id}`;

                return (
                    <div className="d-flex justify-content-center">
                        <ActionButton
                            className="ml-2"
                            color="danger"
                            onClick={() => {setDeviceLicensesDeleteConfirmation(row)}}
                            id={tooltipDeleteButtonId}
                            tooltipText='Delete'
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </ActionButton>
                    </div>
                );
            }
        }
    ]
    return (
        <>
            <BootstrapTable 
                bootstrap4
                keyField="id"
                data={items}
                columns={columns}
                striped
                bordered={true}
                wrapperClasses='table-responsive'
                defaultSorted={[{ dataField: 'id', order: 'asc' }]}
                classes='m-0'
                noDataIndication={noDataText}
            />

            <DeleteConfirmation 
                body={`Are you sure you want to delete the ${deviceLicensesDeleteConfirmation?.clientInfo} device license?`}
                isOpen={!!deviceLicensesDeleteConfirmation}
                onConfirm={handleDeleteConfirm}
                onClosed={handleDeleteClose}
            />
        </>
    );
};

export default DeviceLicenseTable;