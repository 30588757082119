import React from 'react';
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap';

import NewImagePanel from '../NewImagePanel';

interface Props {
    openModal: boolean,
    onClose: () => void;
}


export const AddFilesModal: React.FC<Props> = ({ openModal, onClose }) => {


    return (
        <Modal centered={true} isOpen={openModal} toggle={onClose}>
            <Form>
                <ModalHeader toggle={onClose}>
                    Upload Dataset
                </ModalHeader>
                <ModalBody>
                    <NewImagePanel />
                </ModalBody>
            </Form>
        </Modal>
    );
};
