import { makeAutoObservable, runInAction } from 'mobx';
import container from '../../container/container';
import { AdminStatistic, ProjectStatistic } from '../../models/api';
import { RootStore } from './root';

const api = container.apiClient;

export class StatisticsStore {
    project?: ProjectStatistic;
    admin?: AdminStatistic;
    isLoading: boolean = false;
    adminIsFetched: boolean = false;
    projectIsFetched: boolean = false;
    error?: string;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async fetchStatistic() {
        if (this.isLoading) {
            return;
        }

        try {
            const { data } = await api.statisticsCommon();
            runInAction(() => {
                this.admin = data.admin;
                this.project = data.project;
                this.adminIsFetched = true;
            })
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async fetchProjectStatistic() {
        if (this.isLoading || !this.rootStore.projectsStore.current) {
            return;
        }

        this.isLoading = true;

        const projectId = this.rootStore.projectsStore.current.id;

        try {
            const { data } = await api.statisticsProject(projectId);
            runInAction(() => {
                //TODO remove projectCount
                this.project = {
                    ...data,
                    projectCount: this.project?.projectCount
                };
                this.projectIsFetched = true;
            })
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    setDashboardStatistic(statistic: ProjectStatistic) {
        /* Save total project count, to later retrieve it from LS, if projectCount === undefined
         * E.g. when requesting an empty project group  
         */
        if (statistic.projectCount) {
            localStorage.setItem('fs_project-count', statistic.projectCount.toString())
        }
        this.project = statistic;
    }

    setAdminStatistic(statistic: AdminStatistic) {
        this.admin = statistic;
    }

    resetProjectStatistic() {
        runInAction(() => {
            this.projectIsFetched = false;
        })
    }
}
