import React, { ChangeEvent, MouseEventHandler, useEffect, useRef, useState } from 'react';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalProps,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    UncontrolledDropdown
} from 'reactstrap';
import ReactCrop, { Crop } from 'react-image-crop';
import { useHotkeys } from 'react-hotkeys-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faHandPaper } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare, faEdit } from '@fortawesome/free-regular-svg-icons';
import cx from 'classnames';
import { AnnotateLayout } from '../../../components/AnnotateTool';
import { Annotation, DatasetImage, Label, User } from '../../../models/api';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';
import GhostBox from '../../../components/GhostBox';
import AnnotateStatusBadge from '../../../components/AnnotateStatusBadge';
import DatasetImageNavigation from '../Components/DatasetImageNavigation';
import { hotkeyAnnotateHandler } from '../utils';
import { compareByField } from '../../../utils/helper-functions';
import DropdownList from 'react-widgets/DropdownList';
import { ActiveAnnotatingImage } from '../../../store/stores/datasetImagesStore';
import CrossHair from '../../../components/Crosshair';

export interface AnnotateListProgress {
    current?: number;
    total?: number;
}

interface ReactCropNode extends ReactCrop {
    imageRef: HTMLImageElement;
}

enum ToolBar {
    NewAnnotationOneClick = 'newAnnotationOneClick',
    NewAnnotation = 'newAnnotation',
    RndLayout = 'rndLayout'
}

//TODO move to specific lib/interface/model
const scaleRect = (annotation: Annotation, scale: number) => ({
    x: annotation.x / scale,
    y: annotation.y / scale,
    width: annotation.width / scale,
    height: annotation.height / scale
});

const unscaleRect = (annotation: Annotation, scale: number) => ({
    x: annotation.x * scale,
    y: annotation.y * scale,
    width: annotation.width * scale,
    height: annotation.height * scale
});

interface Props extends ModalProps {
    labelList: Label[];
    annotatedDatasetImage: ActiveAnnotatingImage;
    annotateListProgress?: AnnotateListProgress;
    onSave?: (newAnnotationList: DatasetImage['annotations']) => void;
    onNavNext?: () => Promise<void>;
    onNavPrev?: () => Promise<void>;
    onDelete?: () => Promise<void>;
    isLoading?: boolean;
    error?: string;
    navPrevDisabled?: boolean;
    navNextDisabled?: boolean;
    impersonationUser?: User;
}

interface loadedImage {
    width: number;
    height: number;
    naturalWidth: number;
    naturalHeight: number;
    scale: number;
}

const LABELS_N_THRESHOLD_TO_SHOW_DROPDOWN = 5;
const DEFAULT_VALUE_SIZE_PX = 20;

const ImageAnnotateModal: React.FC<Props> = ({
    annotatedDatasetImage,
    labelList,
    annotateListProgress,
    onSave,
    onClosed,
    onNavNext,
    onNavPrev,
    onDelete,
    isLoading,
    error,
    navPrevDisabled = false,
    navNextDisabled = false,
    impersonationUser,
    ...rest
}) => {
    const [layoutState, setLayoutState] = useState<DatasetImage['annotations']>();
    const [selectedLabel, setSelectedLabel] = useState<Label>(labelList[0]);
    const [loadedImage, setLoadedImage] = useState<loadedImage>();
    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
    const [isLoadingNext, setIsLoadingNext] = useState<boolean>(false);
    const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);
    const [newAnnotationSelection, setNewAnnotationSelection] = useState<Crop>({});
    const [imageUrl, setImageUrl] = useState<string>('');
    const [toolbar, setToolbar] = useState<ToolBar>(ToolBar.NewAnnotation);
    const [size, setAuto] = useState<boolean>(true);
    const [oneClickAdditionSide, setOneClickAdditionSide] = useState(DEFAULT_VALUE_SIZE_PX);
    const [boxSize, setBoxSize] = useState<'px' | '%'>('px');

    const wrapperRef = useRef<HTMLDivElement>(null);
    const cropRef = useRef<ReactCropNode>(null);

    const sortedLabelList = labelList.slice().sort((a, b) => compareByField(a, b, 'name'));

    const sizeChecker = (key: 'width' | 'height', size: number) => {
        const value = wrapperRef.current?.getBoundingClientRect()[key] || DEFAULT_VALUE_SIZE_PX;
        return value < size ? value : size;
    };

    const handleRemoveLabelsClick = () => {
        const newLayoutState: Annotation[] = [];
        setLayoutState(newLayoutState);
        handleNewLayoutSave(newLayoutState);
    };

    const handleAnnotationChange = (changedAnnotation: Annotation) => {
        if (!loadedImage?.scale) {
            return;
        }

        const newLayoutState =
            layoutState?.map((item) =>
                item.id === changedAnnotation.id ? changedAnnotation : item
            ) || [];

        setLayoutState(newLayoutState);
        handleNewLayoutSave(newLayoutState);
    };

    const handleAnnotationDelete = (deletedAnnotation: Annotation) => {
        let newLayoutState = layoutState?.filter((item) => item.id !== deletedAnnotation.id) || [];
        setLayoutState(newLayoutState);
        handleNewLayoutSave(newLayoutState);
    };

    const handleSaveNewMark = () => {
        const { x, y, width, height } = newAnnotationSelection;
        if (!selectedLabel || !width || width < 1 || !height || height < 1) {
            return;
        }

        const newAnnotation = {
            id: uuidv4(),
            color: selectedLabel.color,
            labelId: selectedLabel.id,
            datasetImageId: annotatedDatasetImage.id,
            x: x || 0,
            y: y || 0,
            width,
            height,
            label: selectedLabel
        };

        let newLayout: DatasetImage['annotations'];

        if (layoutState && layoutState?.length > 0) {
            newLayout = [...layoutState];
            newLayout.push(newAnnotation);
        } else {
            newLayout = [newAnnotation];
        }

        setLayoutState(newLayout);
        setNewAnnotationSelection({});
        handleNewLayoutSave(newLayout);
    };

    const handleNewLayoutSave = (newLayout: Annotation[]) => {
        if (!loadedImage?.scale) {
            return;
        }

        const naturalLayout = newLayout?.map((item) => ({
            ...item,
            ...unscaleRect(item, loadedImage?.scale)
        }));

        onSave && onSave(naturalLayout);
    };

    const handleNavNext = async () => {
        if (onNavNext && !navNextDisabled) {
            setIsLoadingImage(true);
            setIsLoadingNext(true);
            await onNavNext();
            setIsLoadingNext(false);
        }
    };

    const handleNavPrev = async () => {
        if (onNavPrev && !navPrevDisabled) {
            setIsLoadingImage(true);
            setIsLoadingPrev(true);
            await onNavPrev();
            setIsLoadingPrev(false);
        }
    };

    const normalizeOneClickAdditionSide = () => {
        const value = oneClickAdditionSide;

        let normalizedValue;
        if (boxSize === 'px') {
            normalizedValue = value < 10 ? 10 : value > 500 ? 500 : value;
        } else {
            normalizedValue = value < 1 ? 1 : value > 100 ? 100 : value;
        }

        if (value !== normalizedValue) {
            setOneClickAdditionSide(normalizedValue);
        }
        return normalizedValue;
    };

    const normalizeSizePercent = () => {
        const rect = wrapperRef.current?.getBoundingClientRect();
        const width = rect?.width || DEFAULT_VALUE_SIZE_PX;
        const height = rect?.height || DEFAULT_VALUE_SIZE_PX;
        return Math.max(
            (width * oneClickAdditionSide) / 100,
            (height * oneClickAdditionSide) / 100
        );
    };

    const handleAnnotateWrapperMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
        if (
            !selectedLabel &&
            (toolbar === ToolBar.NewAnnotation || toolbar === ToolBar.NewAnnotationOneClick)
        ) {
            e.preventDefault();
            toast('Please create at least one label first', {
                type: toast.TYPE.WARNING
            });
            return;
        }
        if (!cropRef.current || toolbar !== ToolBar.NewAnnotationOneClick) {
            return;
        }
        e.preventDefault();
        let normalizedOneClickAdditionSide = normalizeOneClickAdditionSide();
        if (boxSize === '%') {
            normalizedOneClickAdditionSide = normalizeSizePercent();
        }
        let x = (e.nativeEvent as any)?.layerX ?? 0;
        let y = (e.nativeEvent as any)?.layerY ?? 0;
        const { width, height } = cropRef.current.imageRef;

        if (x + normalizedOneClickAdditionSide / 2 > width) {
            x = width - normalizedOneClickAdditionSide / 2;
        } else if (x - normalizedOneClickAdditionSide / 2 < 0) {
            x = normalizedOneClickAdditionSide / 2;
        }

        if (y + normalizedOneClickAdditionSide / 2 > height) {
            y = height - normalizedOneClickAdditionSide / 2;
        } else if (y - normalizedOneClickAdditionSide / 2 < 0) {
            y = normalizedOneClickAdditionSide / 2;
        }
        const newAnnotation = {
            id: uuidv4(),
            color: selectedLabel.color,
            labelId: selectedLabel.id,
            datasetImageId: annotatedDatasetImage.id,
            x: x - normalizedOneClickAdditionSide / 2,
            y: y - normalizedOneClickAdditionSide / 2,
            width: sizeChecker('width', normalizedOneClickAdditionSide),
            height: sizeChecker('height', normalizedOneClickAdditionSide),
            label: selectedLabel
        };
        const newLayout = [...(layoutState ?? []), newAnnotation];
        setLayoutState(newLayout);
        setNewAnnotationSelection({});
        handleNewLayoutSave(newLayout);
    };

    const handleOneClickAdditionSideChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setOneClickAdditionSide(isNaN(value) ? 1 : value <= 0 ? 1 : value);
    };

    const handleChangeSizeValue = (sizeValue: 'px' | '%') => {
        setBoxSize(sizeValue);
        if (sizeValue === 'px') {
            setOneClickAdditionSide(DEFAULT_VALUE_SIZE_PX);
        } else {
            setOneClickAdditionSide(5);
        }
    };

    useHotkeys(
        'a,d,e,q',
        hotkeyAnnotateHandler(
            sortedLabelList,
            handleNavPrev,
            handleNavNext,
            setSelectedLabel,
            selectedLabel,
            isLoadingPrev || isLoadingNext
        ),
        {},
        [navNextDisabled, navPrevDisabled, selectedLabel, isLoadingPrev, isLoadingNext]
    );

    // Changes from outside
    useEffect(() => {
        const { annotations } = annotatedDatasetImage;
        if (!loadedImage?.scale || !annotations) {
            return;
        }

        setLayoutState(
            annotations.map((item) => ({
                ...item,
                ...scaleRect(item, loadedImage?.scale)
            }))
        );
    }, [annotatedDatasetImage, loadedImage]);

    useEffect(() => {
        setIsLoadingImage(true);
        setLoadedImage(undefined);
        (async () => {
            setImageUrl(await annotatedDatasetImage.blobUrl);
        })();
    }, [annotatedDatasetImage]);

    useEffect(() => {
        if (!cropRef.current?.imageRef || isLoadingImage) {
            return;
        }

        const { width, height, naturalWidth, naturalHeight } = cropRef.current.imageRef;

        if (height > wrapperRef.current?.offsetHeight!) {
            setAuto(false);
        }

        setLoadedImage({
            width,
            height,
            naturalWidth,
            naturalHeight,
            scale: naturalWidth / width
        });
    }, [cropRef.current?.imageRef.height, isLoadingImage]);

    return (
        <Modal
            toggle={onClosed}
            className={cx('app-pages-dataset-page__annotation-modal', 'app-component-modal')}
            style={{ minHeight: '100%' }}
            centered
            {...rest}
        >
            <ModalHeader toggle={onClosed}>Annotate</ModalHeader>

            <div className='p-3'>
                <Row>
                    <Col sm={9}>
                        <Nav pills className='m-0'>
                            {sortedLabelList.length <= LABELS_N_THRESHOLD_TO_SHOW_DROPDOWN ? (
                                sortedLabelList.map((item) => {
                                    const isActive = selectedLabel?.id === item.id;
                                    return (
                                        <NavItem key={item.id}>
                                            <NavLink
                                                active={isActive}
                                                style={{ color: isActive ? '#fff' : item.color }}
                                                onClick={() => {
                                                    setSelectedLabel(item);
                                                    if (toolbar !== ToolBar.NewAnnotationOneClick) {
                                                        setToolbar(ToolBar.NewAnnotation);
                                                    }
                                                }}
                                            >
                                                {item.name}
                                            </NavLink>
                                        </NavItem>
                                    );
                                })
                            ) : (
                                <DropdownList
                                    data={sortedLabelList}
                                    value={selectedLabel}
                                    dataKey='id'
                                    textField='name'
                                    style={{ width: '400px' }}
                                    onChange={(label) => {
                                        setSelectedLabel(label);
                                        if (toolbar !== ToolBar.NewAnnotationOneClick) {
                                            setToolbar(ToolBar.NewAnnotation);
                                        }
                                    }}
                                />
                            )}
                        </Nav>
                    </Col>

                    <Col sm={3}>
                        <div className='d-flex justify-content-end align-items-center he-100'>
                            <ButtonWithTooltip
                                id='pages-dataset-image-annotate-modal-add-btn'
                                tooltipProps={{ tooltipText: 'Draw square' }}
                                color={
                                    toolbar === ToolBar.NewAnnotationOneClick ? 'primary' : 'link'
                                }
                                className='rounded-pill d-flex align-items-center px-3'
                                onClick={() => setToolbar(ToolBar.NewAnnotationOneClick)}
                            >
                                <FontAwesomeIcon icon={faPlusSquare} size={'lg'} />
                                {toolbar === ToolBar.NewAnnotationOneClick && (
                                    <>
                                        <input
                                            type={'number'}
                                            value={oneClickAdditionSide}
                                            onChange={handleOneClickAdditionSideChange}
                                            onBlur={normalizeOneClickAdditionSide}
                                            className={
                                                'app-pages-dataset-page__one-click-addition-input mx-2'
                                            }
                                        />
                                        <div className={'change-size'}>
                                            <span
                                                onClick={() => handleChangeSizeValue('px')}
                                                className={cx('size-variable', {
                                                    'size-variable-current': boxSize === 'px'
                                                })}
                                            >
                                                px
                                            </span>
                                            <span
                                                onClick={() => handleChangeSizeValue('%')}
                                                className={cx('size-variable', {
                                                    'size-variable-current': boxSize === '%'
                                                })}
                                            >
                                                %
                                            </span>
                                        </div>
                                    </>
                                )}
                            </ButtonWithTooltip>

                            <ButtonWithTooltip
                                id='pages-dataset-image-annotate-modal-crop-btn'
                                tooltipProps={{ tooltipText: 'Add new annotation' }}
                                color={toolbar === ToolBar.NewAnnotation ? 'primary' : 'link'}
                                className='rounded-pill px-3 ml-2'
                                onClick={() => setToolbar(ToolBar.NewAnnotation)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </ButtonWithTooltip>

                            <ButtonWithTooltip
                                id='pages-dataset-image-annotate-modal-rnd-btn'
                                size='sm'
                                tooltipProps={{ tooltipText: 'Edit annotations' }}
                                color={toolbar === ToolBar.RndLayout ? 'primary' : 'link'}
                                className='rounded-pill px-3 ml-2'
                                onClick={() => setToolbar(ToolBar.RndLayout)}
                            >
                                <FontAwesomeIcon icon={faHandPaper} />
                            </ButtonWithTooltip>

                            <UncontrolledDropdown>
                                <DropdownToggle caret={true} className={'ml-2'} color={'danger'}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </DropdownToggle>
                                <DropdownMenu className='btn-exp-dr-body mt-1'>
                                    <DropdownItem
                                        className='pl-3'
                                        id='pages-dataset-image-annotate-modal-remove-btn'
                                        onClick={handleRemoveLabelsClick}
                                    >
                                        Deletes all boxes
                                    </DropdownItem>
                                    <DropdownItem
                                        className='pl-3'
                                        onClick={() => onDelete && onDelete()}
                                    >
                                        Delete image
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </Col>
                </Row>
            </div>

            <ModalBody className='d-flex align-items-center position-relative'>
                <div ref={wrapperRef} className='d-flex justify-content-center align-items-center'>
                    {toolbar === ToolBar.NewAnnotation ? (
                        <CrossHair
                            show={true}
                            className={cx(
                                'app-pages-dataset-page__annotation-modal__image-wrapper',
                                { 'he-auto': size },
                                { invisible: !loadedImage }
                            )}
                            onMouseDown={handleAnnotateWrapperMouseDown}
                        >
                            <ReactCrop
                                src={imageUrl}
                                crop={newAnnotationSelection}
                                onChange={(newCrop) => setNewAnnotationSelection(newCrop)}
                                onDragEnd={() => handleSaveNewMark()}
                                disabled={!selectedLabel}
                                ref={cropRef}
                                className={'he-100 shadow'}
                                onImageLoaded={() => setIsLoadingImage(false)}
                            />
                            {layoutState && (
                                <AnnotateLayout
                                    annotationList={layoutState}
                                    onChange={handleAnnotationChange}
                                    boxClassName={'pe-none'}
                                    isLoadingImage={isLoadingImage}
                                    disableDragging={true}
                                />
                            )}
                        </CrossHair>
                    ) : (
                        <GhostBox
                            show={toolbar === ToolBar.NewAnnotationOneClick}
                            className={cx(
                                'app-pages-dataset-page__annotation-modal__image-wrapper',
                                { 'he-auto': size },
                                { invisible: !loadedImage }
                            )}
                            boxWidth={sizeChecker(
                                'width',
                                boxSize === 'px' ? oneClickAdditionSide : normalizeSizePercent()
                            )}
                            boxHeight={sizeChecker(
                                'height',
                                boxSize === 'px' ? oneClickAdditionSide : normalizeSizePercent()
                            )}
                            onMouseDown={handleAnnotateWrapperMouseDown}
                        >
                            <ReactCrop
                                src={imageUrl}
                                crop={newAnnotationSelection}
                                onChange={(newCrop) =>
                                    toolbar !== ToolBar.NewAnnotationOneClick &&
                                    setNewAnnotationSelection(newCrop)
                                }
                                onDragEnd={() =>
                                    toolbar !== ToolBar.NewAnnotationOneClick && handleSaveNewMark()
                                }
                                disabled={!selectedLabel}
                                ref={cropRef}
                                className={cx('he-100 shadow', {
                                    'pe-none': toolbar === ToolBar.RndLayout
                                })}
                                onImageLoaded={() => setIsLoadingImage(false)}
                            />
                            {layoutState && (
                                <AnnotateLayout
                                    annotationList={layoutState}
                                    onChange={
                                        toolbar === ToolBar.RndLayout
                                            ? handleAnnotationChange
                                            : undefined
                                    }
                                    onDelete={
                                        toolbar === ToolBar.RndLayout
                                            ? handleAnnotationDelete
                                            : undefined
                                    }
                                    isLoadingImage={isLoadingImage}
                                    disableDragging={toolbar !== ToolBar.RndLayout}
                                />
                            )}
                        </GhostBox>
                    )}
                </div>

                {!loadedImage && (
                    <Spinner className='app-pages-dataset-page__annotation-modal__spinner' />
                )}
            </ModalBody>

            <div className='p-3'>
                <Row>
                    <Col xs={12}>
                        <DatasetImageNavigation
                            imageName={annotatedDatasetImage.hashedName}
                            onNavNext={handleNavNext}
                            onNavPrev={handleNavPrev}
                            navNextDisabled={navNextDisabled}
                            navPrevDisabled={navPrevDisabled}
                            isLoadingNext={isLoadingNext}
                            isLoadingPrev={isLoadingPrev}
                        />
                    </Col>
                </Row>
            </div>

            <ModalFooter>
                <div className='w-100'>
                    <Row>
                        <Col md={3}>
                            <AnnotateStatusBadge isLoading={isLoading} error={error} />
                        </Col>

                        <Col md={6}>
                            <div className='d-flex justify-content-center align-items-center w-100 font-weight-bold'>
                                Annotations: {layoutState?.length || 0}
                                {annotateListProgress && (
                                    <div className='ml-4'>
                                        Images: {annotateListProgress.current} /{' '}
                                        {annotateListProgress.total}
                                    </div>
                                )}
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className='d-flex justify-content-end align-items-center w-100'>
                                <Button color='primary' onClick={onClosed}>
                                    Close
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </ModalFooter>
        </Modal>
    );
};
export default ImageAnnotateModal;
