export const VIDEO_READ_ERROR =
    "Couldn't process the video. Please, ensure the video is one of the supported formats (mp4, mov, m4v) with supported codecs (mp42, avc1, qt, m4v) and streamable.";

export const VIDEO_FORMAT_NOT_SUPPORTED_ERROR = (type: string) => {
    const videoFormat = type.split('/')[1].toUpperCase();
    return `${videoFormat} files are not supported. Please, try to upload video in one of the supported formats (MP4, MOV, M4V).`;
};

export const NOT_ENOUGH_UNIQUE_FRAMES = (imagesToLoad: number) => {
    return `Couldn't extract ${imagesToLoad} different frames from the video. Please, ensure the video is neither a static image nor contains too many similar frames.`;
};

export const VIDEO_DURATION_LIMIT_ERROR =
    'Video length should not exceed 2 minutes. Please, try another video.';

export const VIDEO_SIZE_LIMIT_ERROR =
    "Video file size shouldn't exceed 300 MB. Please, try another video.";

export const FEWER_FRAMES_PROCESSED_WARNING = (framesCount: number, imagesToLoad: number) => {
    return `It seems there are repeatable frames in the video (either static or blank frames), so we were able to extract only ${framesCount} images out of requested ${imagesToLoad}.`;
};

export const SAFARI_NOT_SUPPORTED_ERROR =
    'Video processing is not supported in Safari yet, please, use another browser (e.g. Google Chrome).';

export const NETWORK_ERROR_MESSAGE = 'Network Error';

export const IMAGES_DUPLICATES_ERROR = (existingImagesCount: number) => {
    return `Oops, found ${existingImagesCount} ${
        existingImagesCount > 1 ? 'images that are already exist' : 'image that is already exists'
    } in this dataset or ${existingImagesCount > 1 ? 'have' : 'has'} duplicate in uploading files`;
};

export const START_MODELING_3D_IDENTITY_ERROR =
    'Some error occurred on starting 3D modeling, make sure you are in project and logged in';

export const START_MODELING_3D_SAME_HASH_ERROR =
    '3D model for video with same hash and success status was already generated in this project';

export const START_MODELING_3D_NOT_VALID_ARCHIVE_ERROR = 
    'The archive contains extraneous files or not enough images, valid image extensions: jpeg, jpg, png and minimum 10 images in archive ';

export const MODELING_3D_WRONG_FILE_TYPE_ERROR = (correctType: string) => `Wrong file type, please, provide correct ${correctType} file.`;

export const MODELING_3D_MAX_FILE_DURATION_ERROR = 'Video length should not exceed 2 minutes. Please, try another video.';

export const PREDICTIVE_MAINTENANCE_FILE_TYPE_ERROR = (correctType: string) => `Wrong file type, please, provide correct ${correctType} file.`;

export const PREDICTIVE_MAINTENANCE_SAME_HEADINGS_ERROR = () => `Template file not recognized. Please download a new template file.`;

export const PREDICTIVE_MAINTENANCE_MINIMUM_DATA_ERROR = () => `Please provide a minimum of 100 rows of data.`;

export const PREDICTIVE_MAINTENANCE_MAXIMUM_DATA_ERROR = () => `Please limit upload to a maximum of 500,000 rows of data.`;

export const PREDICTIVE_MAINTENANCE_IDENTITY_ERROR = () => `Not found user or project`;

export const AI_ASSISTANT_ERROR_MESSAGE = 'Either an error occured during calling OpenAI API or conversation has stuck in a loop.'

export const ERROR_IMPORTING_DATASET = 'Something went wrong. Please contact the support team.'