import { makeAutoObservable, runInAction } from 'mobx';
import container from '../../container/container';
import { RootStore } from './root';
import { Feedback } from '../../models/api';

const api = container.apiClient;

export class FeedbackStore {
    isLoading: boolean = false;
    isSent: boolean = false;
    isError: boolean = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async sendEmail(params: Feedback) {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            await api.sendFeedback(params);
            runInAction(() => {
                this.isSent = true;
            });
        } catch (e) {
            runInAction(() => {
                this.isError = true;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }
}
