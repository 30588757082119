import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import path from 'path';
import { toast } from 'react-toastify';
import { Storage } from 'aws-amplify';
import container from '../../container/container';
import {
    DetectionModelSimulation,
    DetectionModelSimulationListRequest,
    DetectionModelSimulationRunningListRequest,
    DetectionModelSimulationType,
    DetectionModelSimulationUpdateForm
} from '../../models/api';
import { RootStore } from './root';
import { s3Util } from '../../utils/s3';
import MimeTypes, { VideoType } from '../../models/mimeTypes';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import { AccessLevel } from '@aws-amplify/ui-components';
import { CustomEventType } from '../../models/events';

const api = container.apiClient;

interface ListProperty {
    items: DetectionModelSimulation[];
    isLoading: boolean;
    IsFetched: boolean;
}

interface TerminatedAndRunningListProperty {
    items: DetectionModelSimulation[];
    isLoading: boolean;
    isFetched: boolean;
}

interface ItemProperty {
    isLoading: boolean;
}

export class DetectionModelSimulationsStore {
    list: ListProperty = {
        items: [],
        isLoading: false,
        IsFetched: false
    };

    terminatedSimulationsList: TerminatedAndRunningListProperty = {
        items: [],
        isLoading: false,
        isFetched: false
    };

    runningSimulationsList: TerminatedAndRunningListProperty = {
        items: [],
        isLoading: false,
        isFetched: false
    }

    // For CRUD operations
    item: ItemProperty = {
        isLoading: false
    }

    rootStore: RootStore;

    private readonly disposers: IReactionDisposer[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.disposers.push(
            reaction(() => ({
                runningModelSimulationCount: this.listOfRunning.length,
                modelSimulationCount: this.list.items.length,
            }), ({ runningModelSimulationCount, modelSimulationCount }) => {
                this.rootStore.statisticsStore.setDashboardStatistic({
                    ...this.rootStore.statisticsStore.project,
                    runningModelSimulationCount,
                    modelSimulationCount
                })
            })
        );

        this.disposers.push(
            reaction(
                () => ({
                    runningAllSimulationsCount: this.listOfAllRunning.length
                }),
                ({ runningAllSimulationsCount }) => {
                    this.rootStore.statisticsStore.setAdminStatistic({
                        ...this.rootStore.statisticsStore.admin,
                        runningAllSimulationsCount
                    });
                }
            )
        );
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    resetList() {
        runInAction(() => {
            this.list.items = [];
            this.list.isLoading = false;
            this.list.IsFetched = false;
        });
    }

    get listOfRunning() {
        return this.list.items.filter(item => !item.completedAt && !item.canceledAt);
    }

    public get listOfAllRunning() {
        if (!this.runningSimulationsList.items) {
            return [];
        }

        return Object.values(this.runningSimulationsList.items);
    }

    get isLoading() {
        return this.list.isLoading || this.item.isLoading;
    }

    async fetchList() {
        //TODO find solution for check and waiting
        if (!this.rootStore.projectsStore.current || this.isLoading) {
            return;
        }

        this.list.isLoading = true;

        const identityId = await this.rootStore.userStore.getIdentityIdByImpersonationUser();

        //TODO from params
        const params: DetectionModelSimulationListRequest = {
            filter: {
                projectId: this.rootStore.projectsStore.current.id
            },      
            s3bucketName: s3Util.getS3BucketName(),
            s3privateFolder: await s3Util.getS3PrivateFolder(identityId)
        };

        try {
            const { data } = await api.detectionModelSimulationList(params);

            runInAction(() => {
                this.list.items = data;
                this.list.IsFetched = true;
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.list.isLoading = false;
            });
        }
    }

    async fetchTerminatedSimulations() {
        if (this.terminatedSimulationsList.isLoading) {
            return;
        }
        this.terminatedSimulationsList.isLoading = true;

        const params: DetectionModelSimulationRunningListRequest = {
            limit: 10,
            sortField: 'createdAt',
            sortOrder: 'DESC'
        };
        try {
            const { data } = await api.detectionModelRunningSimulationList(params);
            runInAction(() => {
                this.terminatedSimulationsList.items = data;
                this.terminatedSimulationsList.isFetched = true;
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.terminatedSimulationsList.isLoading = false;
            });
        }
    }

    async fetchRunningSimulations() {
        if (this.runningSimulationsList.isLoading) {
            return;
        }
        this.runningSimulationsList.isLoading = true;

        const params = {
            filter: {
                completedAt: null
            },
            limit: 10,
            sortField: 'createdAt',
            sortOrder: 'DESC'
        }
        try {
            const { data } = await api.detectionModelRunningSimulationList(params);
            runInAction(() => {
                this.runningSimulationsList.items = data;
                this.runningSimulationsList.isFetched = true;
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.runningSimulationsList.isLoading = false;
            });
        }
    }

    async createItem(
        params: DetectionModelSimulationUpdateForm
    ) {
        if (!this.rootStore.projectsStore.current) {
            return;
        }

        const { detectionModelId, file, minConfidence, boxLabel } = params;
        const simulationOptions = {
            confThreshold: (minConfidence / 100).toString(),
            label: boxLabel
        };

        const toastId = createProgressToast();
        this.item.isLoading = true;

        try {
            const identityId = await this.rootStore.userStore.getIdentityIdByImpersonationUser();
            const ext = path.extname(file.name);
            const folder = s3Util.getDetectionModelSimulationSourcesFolder(this.rootStore.projectsStore.current.id);
            const resultFolder = s3Util.getDetectionModelSimulationResultFolder(this.rootStore.projectsStore.current.id);
            const fileName = uuidv4();
            const uploadKey = `${folder}/${fileName}${ext}`;
            const resultFileKey = `${resultFolder}/${fileName}${ext}`;
            const type = Object.values(MimeTypes.VideoType).includes(file.type as VideoType) ?
                DetectionModelSimulationType.Video :
                DetectionModelSimulationType.Image;

            await Storage.put(
                uploadKey,
                file,
                { level: AccessLevel.Private, identityId }
            );

            const sourceAttachment = {
                path: uploadKey,
                originalName: file.name,
                size: file.size
            };

            const { data } = await api.detectionModelSimulationCreate(detectionModelId, {
                type,
                s3privateFolder: await s3Util.getS3PrivateFolder(identityId),
                s3bucketName: s3Util.getS3BucketName(),
                resultFileKey,
                sourceAttachment,
                simulationOptions
            });

            toast('Simulation started! Sit back an enjoy your coffee.', { type: toast.TYPE.SUCCESS });

            runInAction(() => {
                this.list.items.push(data);
                this.incrementRunningSimulationsStatisticsCount();
                this.rootStore.eventsStore.addItem(CustomEventType.StartSimulation, {
                    detectionModelId: data.detectionModel!.id,
                    detectionModelIndex: data.detectionModel!.modelIndex,
                    type: data.type,
                    projectId: this.rootStore.projectsStore.current!.id,
                    projectName: this.rootStore.projectsStore.current!.name
                });
            });
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            toast.dismiss(toastId);
            runInAction(() => {
                this.item.isLoading = false;
            });
        }
    }

    async deleteItem(id: string) {
        this.item.isLoading = true;

        try {
            await api.detectionModelSimulationDelete(id);
            runInAction(() => {
                this.list.items = this.list.items.filter(item => item.id !== id);
            });
        } finally {
            runInAction(() => {
                this.item.isLoading = false;
            });
        }
    }

    incrementRunningSimulationsStatisticsCount() {
        if (this.rootStore.statisticsStore.admin?.runningAllSimulationsCount !== undefined) {
            this.rootStore.statisticsStore.admin.runningAllSimulationsCount += 1;
        }
    }

    decrementRunningSimulationsStatisticsCount() {
        if (this.rootStore.statisticsStore.admin?.runningAllSimulationsCount) {
            this.rootStore.statisticsStore.admin.runningAllSimulationsCount -= 1;
        }
    }
}
