import { RootStore } from './root';
import { ReactText } from 'react';
import { IReactionDisposer, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { DatasetExportType, DatasetImage, ImagesAnnotationsInfo, Label, Project, ProjectType } from '../../models/api';
import container from '../../container/container';
import { s3StorageConfig, s3Util } from '../../utils/s3';
import { Storage } from 'aws-amplify';
import { AccessLevel } from '@aws-amplify/ui-components';
import path from 'path';
import { imageUtil } from '../../utils/image';
import keyBy from 'lodash.keyby';
import { toast } from 'react-toastify';
import { createProgressBarToast, createProgressToast } from '../../components/Toasts/ProgressToast';
import { Pagination } from '../../models/pagination';
import { CustomEventType } from '../../models/events';
import { concatWithoutDuplicatesByOption } from '../../utils/helper-functions';
import { createHardRefreshToast } from '../../components/Toasts/HardRefreshToast';
import { ERROR_IMPORTING_DATASET, IMAGES_DUPLICATES_ERROR, NETWORK_ERROR_MESSAGE } from '../../utils/errors';
import { retry } from '@lifeomic/attempt';
import {
    COMPRESSED_IMAGE_EXT,
    IMAGES_UPLOAD_CHUNK_SIZE,
    THUMBNAIL_HEIGHT,
    THUMBNAIL_WIDTH
} from '../../config/image';

const { v4: uuidv4 } = require('uuid');
const api = container.apiClient;

interface StoreState {
    images: {
        loading: boolean;
        fetched: boolean;
    };
    deleting: {
        [key: string]: boolean;
    };
}

const initialStoreState = {
    images: {
        loading: false,
        fetched: false
    },
    deleting: {}
};

export enum UploadingStatus {
    Compressing = 1,
    Uploading = 2,
    Annotating = 3
}

export interface UploadingData {
    status: UploadingStatus;
    total: number;
    current: number;
    chunk?: number;
}

interface UploadProgressInfo {
    loaded: string,
    total: string
}

export interface ActiveAnnotatingImage extends DatasetImage {
    blobUrl: Promise<string>;
}

export class DatasetImagesStore {
    private rootStore: RootStore;

    public state: StoreState = initialStoreState;
    public images: Record<string, DatasetImage> = {};
    public imagesForModal: Record<string, ActiveAnnotatingImage> = {};
    public imagesCount: number = 0;
    public imagesForAnnotation: string[] = [];
    public imagesForAnnotationCount: number = 0;
    public annotated?: ActiveAnnotatingImage;
    public imagesAnnotationsInfo: ImagesAnnotationsInfo = {
        annotatedImages: null,
        notAnnotatedImages: null
    };
    public imagesAnnotationsIsFetched: boolean = false;
    public prevProject: Project | null = null;

    public pagination: Pagination = new Pagination();
    public uploadingDataForProject: Record<string, UploadingData> = {};
    public hasUnprocessedItems: boolean = false;
    public hashedNameList: string[] = [];
    public isImagesForAnnotationLoading: boolean = false;
    private readonly disposers: IReactionDisposer[] = [];
    private sortedIdListOffset: number = 0;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;

        this.disposers.push(
            reaction(
                () => ({
                    isFetched: this.pagination.isFetched
                }),
                () => {
                    if (this.pagination.isFetched) {
                        return;
                    }
                    this.pagination.isFetched = true;
                    this.fetchImages();
                }
            )
        );

        this.disposers.push(
            reaction(
                () => ({
                    datasetImageCount: this.imagesCount
                }),
                ({ datasetImageCount }) => {
                    this.rootStore.statisticsStore.setDashboardStatistic({
                        ...this.rootStore.statisticsStore.project,
                        datasetImageCount
                    });
                }
            )
        );
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    resetList() {
        this.images = {};
        this.pagination = new Pagination();
        this.state = initialStoreState;
    }

    public get isAnnotatedImageFirstInImages() {
        return this.currentAnnotatedImageIndex === 0;
    }

    public get isAnnotatedImageLastInImages() {
        if (!this.currentAnnotatedImageIndex) {
            return false;
        }
        return (this.currentAnnotatedImageIndex + 1) === this.imagesForAnnotationCount;
    }

    public get currentAnnotatedImageIndex() {
        if (!this.annotated) {
            return undefined;
        }
        const index = this.imagesForAnnotation.indexOf(this.annotated.id);
        return this.sortedIdListOffset + index;
    }

    getImagesBlobFromDatasetImage(datasetImages: DatasetImage[]) {
        const identityId = this.rootStore.userStore.impersonationUser?.storagePath;
        const accessStorageParams = {
            level: AccessLevel.Private,
            identityId: identityId,
            download: true
        }
        const updatedDatasetImages: Record<string, ActiveAnnotatingImage> = {};
        datasetImages.forEach((datasetImage) => {
            const blobUrl = new Promise<string>(async (resolve) => {
                const data = await Storage.get(s3Util.getDatasetImageKey(datasetImage), accessStorageParams);
                const typedData = data as { Body: Blob };
                const url = (URL ?? webkitURL).createObjectURL(typedData.Body);
                resolve(url);
            });
            updatedDatasetImages[datasetImage.id] = { ...datasetImage, blobUrl };
        });
        return updatedDatasetImages;
    }

    public fetchImages = async (params?: { query?: string; isClearQuery?: boolean, isForAnnotate?: boolean, page?: number }) => {
        if (this.state.images.loading) {
            return;
        }

        if (params?.isClearQuery) {
            this.pagination.sortOrder.searchQuery = '';
            this.pagination.page = 0;
        } else if (params?.query) {
            this.pagination.sortOrder.searchQuery = params.query;
            this.pagination.page = 0;
        }

        if (this.rootStore.projectsStore.current?.id === undefined) {
            runInAction(() => {
                this.images = {};
            });
            return;
        }

        try {
            if (!params?.isForAnnotate) {
                this.state.images.loading = true;
            }

            if (
                !this.pagination.sortOrder.sortField &&
                !this.pagination.sortOrder.sortOrder &&
                !params?.isForAnnotate
            ) {
                this.pagination.sortOrder.sortField = 'createdAt';
                this.pagination.sortOrder.sortOrder = 'ASC';
            }

            let pagination = this.pagination;
            if (params?.page !== undefined) {
                pagination = this.clonePagination(params.page);
            }

            const { data: datasetImages } = await container.apiClient.datasetImageList(
                this.rootStore.projectsStore.current.id,
                pagination,
                this.pagination.sortOrder
            );

            const isProjectChanged = (
                !this.prevProject ||
                this.prevProject.id !== this.rootStore.projectsStore.current.id
            );

            if (!params?.isForAnnotate && isProjectChanged) {
                this.prevProject = this.rootStore.projectsStore.current;
                const {
                    data: { hashedNameList }
                } = await container.apiClient.datasetImageHashedNameList(
                    this.rootStore.projectsStore.current.id
                );
                runInAction(() => {
                    this.hashedNameList = hashedNameList;
                });
            }

            runInAction(() => {
                if (params?.isForAnnotate) {
                    this.imagesForModal = this.getImagesBlobFromDatasetImage(datasetImages.rows);
                } else {
                    this.images = keyBy(datasetImages.rows, 'hashedName');

                    this.imagesForModal = this.getImagesBlobFromDatasetImage(datasetImages.rows);

                    this.imagesCount = this.pagination.sortOrder.searchQuery
                        ? this.imagesCount
                        : datasetImages.count;

                    this.pagination.totalSize = datasetImages.count;

                    this.state.images.fetched = true;
                }
            });
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            if (!params?.isForAnnotate) {
                this.state.images.loading = false;
            }
        }
    };

    private clonePagination(page: number): Pagination {
        const pagination = new Pagination();
        pagination.page = page;
        pagination.sizePerPage = this.pagination.sizePerPage;
        pagination.sortOrder = this.pagination.sortOrder;
        pagination.isFetched = this.pagination.isFetched;
        return pagination;
    }

    public uploadImages = async (
        files: File[],
        labels: Label[],
        isUsingNamingConvention?: boolean
    ) => {
        if (this.rootStore.projectsStore.current?.id === undefined) {
            return;
        }

        const projectId = this.rootStore.projectsStore.current!.id;
        const projectName = this.rootStore.projectsStore.current!.name;
        const isClassificationProject =
            this.rootStore.projectsStore.current!.type === ProjectType.ObjectClassification;
        const isBulkUpload =
            isClassificationProject && (labels.length > 0 || isUsingNamingConvention);
        const allLabels = this.rootStore.labelsStore.list;

        try {
            this.hasUnprocessedItems = true;

            this.pagination.page = 0;
            await this.fetchImages({ isClearQuery: true });

            const storageConfig = await this.getUserPrivateStorageConfig();

            for (let i = 0; i < files.length; i += IMAGES_UPLOAD_CHUNK_SIZE) {
                const filesChunk = files.slice(i, i + IMAGES_UPLOAD_CHUNK_SIZE);

                this.uploadingDataForProject[projectId] = {
                    status: UploadingStatus.Compressing,
                    total: files.length,
                    current: i,
                    chunk: filesChunk.length
                };

                const compressedImages = await Promise.all(
                    filesChunk.map((file) => imageUtil.compressFile(file))
                );

                const compressedImagesWithHashes = await Promise.all(
                    compressedImages.map((image) => this.getImageWithHash(image))
                );

                const compressedImagesWithUniqueHashes = compressedImagesWithHashes.reduce(
                    (acc, [hashedName, file]) => {
                        if (hashedName) {
                            acc[hashedName] = file;
                        }
                        return acc;
                    },
                    {} as Record<string, File>
                );

                const uniqueImagesCount = Object.keys(compressedImagesWithUniqueHashes).length;

                if (uniqueImagesCount < filesChunk.length) {
                    const existingImagesCount = filesChunk.length - uniqueImagesCount;
                    toast(IMAGES_DUPLICATES_ERROR(existingImagesCount), {
                        type: toast.TYPE.WARNING
                    });
                }

                if (uniqueImagesCount === 0) {
                    continue;
                }

                this.uploadingDataForProject[projectId] = {
                    status: UploadingStatus.Uploading,
                    total: files.length,
                    current: i
                };

                const uploadedDatasetImagesWithFiles = await Promise.all(
                    Object.entries(compressedImagesWithUniqueHashes).map(([hashedName, file]) =>
                        this.uploadImage(
                            file,
                            hashedName,
                            labels,
                            projectId,
                            projectName,
                            storageConfig
                        )
                    )
                );

                if (isBulkUpload) {
                    this.uploadingDataForProject[projectId] = {
                        status: UploadingStatus.Annotating,
                        total: files.length,
                        current: i
                    };
                    await Promise.all(
                        uploadedDatasetImagesWithFiles.map(([datasetImage, file]) =>
                            this.classificateImage(
                                datasetImage,
                                file,
                                labels,
                                allLabels,
                                projectId,
                                isUsingNamingConvention
                            )
                        )
                    );
                }
            }
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            runInAction(() => {
                delete this.uploadingDataForProject[projectId];
                this.hasUnprocessedItems = false;
            });
        }
    };

    private getImageWithHash = async (file: File): Promise<[string | null, File]> => {
        const hashedName = await imageUtil.getHashedName(file);
        const isExistsInDataset = this.hashedNameList.find((el) => el === hashedName);
        return [isExistsInDataset ? null : hashedName, file];
    };

    private classificateImage = async (
        datasetImage: DatasetImage,
        file: File,
        labels: Label[],
        allLabels: Label[],
        projectId: string,
        isUsingNamingConvention?: boolean
    ) => {
        if (labels.some((label) => !label.id)) {
            return;
        }
        let classifications = labels.map((label) => ({
            labelId: label.id,
            datasetImageId: datasetImage.id
        }));
        if (isUsingNamingConvention) {
            const namesOfClassifications = path
                .basename(file.name)
                .split('_')
                .slice(0, -1)
                .map((name) => name.toLowerCase());
            const classificationsFromNamingConvention = allLabels
                .filter((label) => namesOfClassifications.includes(label.name.toLowerCase()))
                .map((label) => ({ labelId: label.id, datasetImageId: datasetImage.id }));
            classifications = concatWithoutDuplicatesByOption(
                classifications,
                classificationsFromNamingConvention,
                'labelId'
            );
        }
        if (!classifications.length) {
            return;
        }
        await this.rootStore.classificationsStore.syncList(classifications, datasetImage, true);
        runInAction(() => {
            this.uploadingDataForProject[projectId].current++;
        });
    };

    private uploadImage = async (
        file: File,
        hashedName: string,
        labels: Label[],
        projectId: Project['id'],
        projectName: Project['name'],
        storageConfig: s3StorageConfig
    ): Promise<[DatasetImage, File]> => {
        const imageSize = await imageUtil.getDimensions(file);
        const image: DatasetImage = {
            id: '',
            projectId,
            dimensionX: imageSize.width,
            dimensionY: imageSize.height,
            size: file.size,
            hashedName,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
        };

        const thumbnail = await imageUtil.resizeFile(
            file,
            COMPRESSED_IMAGE_EXT,
            THUMBNAIL_WIDTH,
            THUMBNAIL_HEIGHT
        );

        await retry(
            async () => {
                await Storage.put(s3Util.getDatasetImageKey(image), file, storageConfig);
                await Storage.put(
                    s3Util.getDatasetImageThumbnailKey(image),
                    thumbnail,
                    storageConfig
                );
            },
            { delay: 1000, maxAttempts: 5 }
        );

        const { data } = await api.datasetImageCreate({
            image,
            project: {
                id: projectId,
                name: projectName
            }
        });

        runInAction(() => {
            if (projectId === this.rootStore.projectsStore.current?.id) {
                this.imagesCount++;
                this.pagination.totalSize++;
                if (
                    Object.keys(this.images).length < this.pagination.sizePerPage &&
                    this.uploadingDataForProject[projectId].status === UploadingStatus.Uploading &&
                    this.uploadingDataForProject[projectId].current < this.pagination.sizePerPage
                ) {
                    this.images[hashedName] = data;
                }
                this.updateProjectsStore();
            }

            this.hashedNameList.push(hashedName);
            this.uploadingDataForProject[projectId].current++;

            this.rootStore.eventsStore.addItem(CustomEventType.ImageUpload, {
                id: data.id,
                hashedName,
                projectId,
                projectName
            });
        });

        return [data, file];
    };

    public deleteImage = async (images: DatasetImage[]) => {
        if (this.rootStore.projectsStore.current?.id === undefined) {
            return;
        }

        const identityId = await this.rootStore.userStore.getIdentityIdByImpersonationUser();
        const toastId = createProgressToast();

        const projectId = this.rootStore.projectsStore.current!.id;
        const projectName = this.rootStore.projectsStore.current!.name;

        try {
            this.state.deleting['dataset-images'] = true;
            const ids = images.map((image) => image.id);

            await api.datasetImageDelete({
                ids,
                project: {
                    id: projectId,
                    name: projectName
                }
            });

            const config: s3StorageConfig = {
                level: AccessLevel.Private,
                identityId: identityId
            };

            await Promise.all(
                images.map((image) => Storage.remove(s3Util.getDatasetImageKey(image), config))
            );
            await Promise.all(
                images.map((image) =>
                    Storage.remove(s3Util.getDatasetImageThumbnailKey(image), config)
                )
            );

            let deletedPages = Math.floor(ids.length / this.pagination.sizePerPage);
            if (Object.keys(this.images).length === images.length && deletedPages === 0) {
                deletedPages++;
            }
            this.pagination.prevSeveralPages(deletedPages);

            runInAction(() => {
                this.hashedNameList = this.hashedNameList.filter(
                    (hashedName) => !images.find((image) => image.hashedName == hashedName)
                );
                images.forEach((image) => {
                    this.rootStore.eventsStore.addItem(CustomEventType.DeleteImage, {
                        id: image.id,
                        hashedName: image.hashedName,
                        projectId,
                        projectName
                    });
                });
            });
        } catch (e) {
            const errorMessage = e?.response?.data?.errors?.message ?? e.message;
            if (errorMessage !== NETWORK_ERROR_MESSAGE) {
                toast(errorMessage, { type: toast.TYPE.ERROR });
            } else {
                createHardRefreshToast(() => this.fetchImages());
            }
        } finally {
            runInAction(() => {
                delete this.state.deleting['dataset-images'];
                toast.dismiss(toastId);
            });
        }
    };

    async fetchImagesAnnotationsInfo() {
        if (this.rootStore.projectsStore.current?.id === undefined) {
            return;
        }
        this.imagesAnnotationsIsFetched = false;

        try {
            const { data } = await api.datasetImageAnnotationsInfo(
                this.rootStore.projectsStore.current.id
            );
            runInAction(() => {
                this.imagesAnnotationsInfo = data;
                this.imagesAnnotationsIsFetched = true;
            });
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        }
    }

    async fetchImagesForAnnotation(limit?: number, offset?: number) {
        if (this.rootStore.projectsStore.current?.id === undefined) {
            this.imagesForAnnotation = [];
            return;
        }

        this.sortedIdListOffset = offset ?? 0;

        if (!this.pagination.sortOrder.sortField && !this.pagination.sortOrder.sortOrder) {
            this.pagination.sortOrder.sortField = 'createdAt';
            this.pagination.sortOrder.sortOrder = 'ASC';
        }

        this.isImagesForAnnotationLoading = true;

        const {
            data: { rows, count }
        } = await container.apiClient.datasetImageSortedIdList(
            this.rootStore.projectsStore.current.id,
            this.pagination.sortOrder,
            { limit, offset }
        );

        runInAction(() => {
            this.imagesForAnnotationCount = count;
            this.imagesForAnnotation = rows;
            this.isImagesForAnnotationLoading = false;
        });
    }

    fetchImagesForModal() {
        const currentImages = Object.values(this.images);
        this.imagesForModal = this.getImagesBlobFromDatasetImage(currentImages);
    }

    async fetchDatasetImageById(id: string) {
        try {
            const { data } = await container.apiClient.datasetImageById(id);
            const dataAddBlobImg = this.getImagesBlobFromDatasetImage([data]);
            return dataAddBlobImg[data.id];
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        }
    }

    public async annotateToggleImage(next: boolean) {
        if (!this.annotated || this.rootStore.projectsStore.current?.id === undefined) {
            return;
        }

        const imageIds = [...this.imagesForAnnotation];
        const newIndex = imageIds.indexOf(this.annotated.id) + (next ? 1 : -1);
        const isNotFoundInBatch = imageIds.indexOf(this.annotated.id) === -1;
        
        let newImageId;
        if (isNotFoundInBatch || newIndex >= 10) {
            const [{ data: { rows } }, _] = await Promise.all([
                container.apiClient.datasetImageSortedIdList(
                    this.rootStore.projectsStore.current.id,
                    this.pagination.sortOrder,
                    { limit: 10, offset: this.sortedIdListOffset + 10 }
                ),
                this.fetchImages({ isForAnnotate: true, page: Math.floor(this.sortedIdListOffset / 10) + 1 })
            ])
            runInAction(() => {
                this.sortedIdListOffset += 10;
                this.imagesForAnnotation = rows;

                if (this.imagesForAnnotation.length) {
                    newImageId = this.imagesForAnnotation[0]
                }
            });
        } else if (isNotFoundInBatch || newIndex < 0) {
            const [{ data: { rows } }, _] = await Promise.all([
                container.apiClient.datasetImageSortedIdList(
                    this.rootStore.projectsStore.current.id,
                    this.pagination.sortOrder,
                    { limit: 10, offset: this.sortedIdListOffset - 10 }
                ),
                this.fetchImages({ isForAnnotate: true, page: Math.floor(this.sortedIdListOffset / 10) - 1 })
            ])
            runInAction(() => {
                this.sortedIdListOffset -= 10;
                this.imagesForAnnotation = rows;

                if (this.imagesForAnnotation.length) {
                    newImageId = this.imagesForAnnotation[this.imagesForAnnotation.length - 1]
                }
            });
        } else {
            newImageId = imageIds[newIndex] || imageIds[0];
        }

        if (!newImageId) {
            return;
        }

        const newImageIdTypes = newImageId;
        let targetImage = this.imagesForModal[newImageIdTypes];

        if (!targetImage) {
        }

        this.annotate(targetImage);
    }

    public annotate(image?: ActiveAnnotatingImage) {
        this.annotated = image;
    }

    private importDatasetProgressCallback = (progress: UploadProgressInfo, toastId: ReactText) => {
        const progressPercentage = +progress.loaded / +progress.total;
        toast.update(toastId, { progress: progressPercentage });
    }

    public importDataset = async (projectId: Project['id'], file: File, replyToEmail: string) => {
        try {
            const forderName = uuidv4();
            const filePath = `${forderName}/${file.name}`;
            const s3ObjectKey = s3Util.getDatasetImportZipKey(projectId, filePath);
            const storageConfig = await this.getUserPrivateStorageConfig();
            const toastId = createProgressBarToast('Dataset is being uploaded...');

            await retry(
                async () => {
                    await Storage.put(s3ObjectKey, file, {
                        ...storageConfig,
                        progressCallback: (progress: UploadProgressInfo) => this.importDatasetProgressCallback(progress, toastId) 
                    });
                },
                { delay: 1000, maxAttempts: 5 }
            );

            toast.done(toastId)
            
            const identityId = await this.rootStore.userStore.getIdentityIdByImpersonationUser();
            const s3PrivateFolder = s3Util.getS3PrivateFolder(identityId);
            
            const { data } = await api.datasetImageImport({
                projectId: projectId,
                s3ObjectKey: `${s3PrivateFolder}/${s3ObjectKey}`,
                replyToEmail: replyToEmail
            });

            if (data.status === 'ok') {
                toast("The dataset is being imported. You'll receive an email upon completion.", { type: toast.TYPE.SUCCESS });
            } else {
                throw new Error(ERROR_IMPORTING_DATASET)
            }
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        }
    };

    public exportDataset = async (projectId: Project['id'], exportType: DatasetExportType, replyToEmail: string) => {
        const toastId = createProgressToast();
        try {

            const { data } = await api.datasetImageExport({ projectId, exportType, replyToEmail });

            if (data.status === 'ok') {
                toast(`The dataset is being exported. The results will be sent to ${replyToEmail}`, { type: toast.TYPE.SUCCESS });
            } else {
                throw new Error(ERROR_IMPORTING_DATASET)
            }
        } catch (e) {
            toast(e.message, { type: toast.TYPE.ERROR });
        } finally {
            runInAction(() => {
                toast.dismiss(toastId);
            });
        }
    };

    private updateProjectsStore() {
        if (this.imagesCount <= 3) {
            runInAction(() => {
                this.rootStore.projectsStore.isFetched = false;
            });
        }
    }

    private getUserPrivateStorageConfig = async () => {
        const storageConfig: s3StorageConfig = {
            level: AccessLevel.Private,
            identityId: await this.rootStore.userStore.getIdentityIdByImpersonationUser()
        };

        return storageConfig;
    }
}
