import React from 'react';
import { CardText,ListGroupItem } from 'reactstrap';


interface Props {
    title: string,
    children: JSX.Element,
}


const VideoListWrapper: React.FC<Props> = ({title, children}) => {

    return (
        <ListGroupItem className='text-center mb-3'>
            <CardText tag="h6" className='text-primary font-weight-bold'>{title}</CardText>
            <CardText className='d-flex justify-content-center align-items-center'>
                {children}
            </CardText>
        </ListGroupItem>
    );
};

export default VideoListWrapper;