import { ImageType } from '../models/mimeTypes';

export const MAX_IMAGE_WIDTH = 1000;
export const MAX_IMAGE_HEIGHT = 1000;
export const MAX_IMAGE_SIDE = Math.max(MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT);

export const THUMBNAIL_WIDTH = 80;
export const THUMBNAIL_HEIGHT = 80;

export const MAX_COMPRESSED_IMAGE_SIZE_B = 100000; // 100 Kb
export const COMPRESSED_IMAGE_QUALITY = 0.8;
export const COMPRESSED_IMAGE_MIME_TYPE = ImageType.Jpeg;
export const COMPRESSED_IMAGE_EXT = 'jpg';

export const IMAGES_UPLOAD_CHUNK_SIZE = 25;
