import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Storage } from 'aws-amplify';
import { AccessLevel } from '@aws-amplify/ui-components';
import BootstrapTable, { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import dayjs from 'dayjs';
import { faBan, faStop, faTimes, faChartLine, faInfoCircle, faChessBoard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Extensions } from '../../../models/extensions'
import DeleteConfirmation from '../../../components/Modals/DeleteConfirmation';
import { HeaderWithTooltip } from '../../../components/BootstrapTable';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import { DetectionModelDatasetInfo, DetectionModel, DetectionModelProgressStatus, DetectionModelProgressStatusUI, ProjectType, DetectionModelFrameworkTypeUI, DetectionModelFrameworkType } from '../../../models/api';
import { dateConfig } from '../../../config/date';
import CircularProgress from '../../../components/CircularProgress';
import { noDataText } from '../../../components/BootstrapTable';
import TrainingAccuracyStatistic from '../TrainingAccuracyStatistic';
import DatasetInfoModal from '../DatasetInfoModal';
import DetectionModelFrameworkTypeIcon from '../../../components/DetectionModel/DetectionModelFrameworkTypeIcon';
import ModelInfoModal from '../../../components/Modals/ModelInfoModal';
import { useStores } from '../../../store';
import { saveFromS3As } from '../../../utils/s3';
import BestEpochTooltip from './BestEpochTooltip';

interface Props {
    items: DetectionModel[];
    onDelete?: (id: string) => void;
    onEarlyStop?: (id: string) => void;
    onCancel?: (id: string) => void;
    isLoading: boolean;
    isAllRunningTrainings?: boolean;
    projectType?: ProjectType;
}

const ModelListTable: React.FC<Props> = observer((
    {
        items,
        onDelete,
        isLoading,
        onEarlyStop,
        onCancel,
        isAllRunningTrainings,
        projectType
    }
) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState<DetectionModel>();
    const [showModelStatistic, setShowModelStatistic] = useState<DetectionModel>();
    const [showDatasetInfo, setShowDatasetInfo] = useState<DetectionModelDatasetInfo>();
    const [modelInfo, setModelInfo] = useState<DetectionModel>();
    const { userStore, projectsStore } = useStores()

    const isProject = !!projectType;
    const isObjectClassification = projectType === ProjectType.ObjectClassification;
    const isObjectDetection = projectType === ProjectType.ObjectDetection;

    const handleDelete = () => {
        if (!deleteConfirmation) {
            return;
        }
        onDelete && onDelete(deleteConfirmation.id);
        setDeleteConfirmation(undefined);
    };

    const handleEarlyStop = (id: string) => () => {
        onEarlyStop && onEarlyStop(id);
    };

    const handleCancel = (id: string) => () => {
        onCancel && onCancel(id);
    };

    const handleDeleteButtonClick = (row: DetectionModel) => () => {
        setDeleteConfirmation(row);
    };

    const handleDeleteConfirmationClosed = () => {
        setDeleteConfirmation(undefined);
    };

    const handleModelStatisticClose = () => {
        setShowModelStatistic(undefined);
    };

    const handleModelStatisticShow = (detectionModel: DetectionModel) => () => {
        setShowModelStatistic(detectionModel);
    };

    const handleConfusionMatrixDownload = (detectionModel: DetectionModel) => async () => {
        if (!detectionModel.s3Folder) return
        try {
            
            const index = detectionModel.s3Folder.indexOf('projects/')
            const path = detectionModel.s3Folder.slice(index, detectionModel.s3Folder.length)
            toast('Your download will begin shortly.', { type: toast.TYPE.SUCCESS })

            const confusionMatrixLink = (await Storage.get(
                `${path}/confusion_matrix.${Extensions.Csv}`,
                {
                  level: AccessLevel.Private,
                  identityId: await userStore.getIdentityIdByImpersonationUser(),
                  download: true
                }
              )) as { Body: Blob };
            saveFromS3As(confusionMatrixLink.Body, `confusionmatrix_OC_${projectsStore.current?.name}_${detectionModel.modelIndex}.${Extensions.Csv}`)
        } catch (error) {
            toast(error.message, {type: toast.TYPE.ERROR})    
        }
        
    };

    const handleDatasetInfoClose = () => {
        setShowDatasetInfo(undefined);
    };

    const handleDatasetInfoShow = (datasetInfo: DetectionModelDatasetInfo) => () => {
        setShowDatasetInfo(datasetInfo)
    }

    const handleModelInfo = (detectionModel?: DetectionModel) => () => {
        setModelInfo(detectionModel);
    }

    const handleModelInfoClose = () => {
        setModelInfo(undefined);
    }

    const columns: ColumnDescription<DetectionModel>[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => {
                return (rowIndex + 1);
            }
        },
        {
            dataField: 'modelWithModelIndex',
            text: 'Model ID',
            hidden: isAllRunningTrainings,
            sort: true,
        },
        {
            dataField: 'user',
            text: 'User',
            sort: true,
            hidden: !isAllRunningTrainings,
            formatter: (_cel, row) => row.project?.user ? row.project?.user?.email : '',
            sortValue: (_cel, row) => row.project?.user ? row.project?.user?.email : ''
        },
        {
            dataField: 'projectName',
            text: 'Project Name',
            sort: true,
            hidden: !isAllRunningTrainings,
            formatter: (_cel, row) => row.project ? row.project.name : '',
            sortValue: (_cel, row) => row.project ? row.project.name : ''
        },
        {
            dataField: 'startTime',
            text: 'Start Time',
            sort: true,
            formatter: (_cel, row) => row.startTime ? dayjs(row.startTime).format(dateConfig.formats.date) : ''
        },
        {
            dataField: 'frameworkType',
            text: 'Framework',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const id = `pages-model-training-model-list-framework-type-${row.id}`;

                return(
                    <DetectionModelFrameworkTypeIcon
                        id={id}
                        frameworkType={row.frameworkType}
                        className='img-w-sm'
                    />
                );
            }
        },
        {
            dataField: 'modelInfo',
            text: 'Model Info',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const tooltipId = `pages-model-training-model-list-modelinfo-button-${row.id}`
                const modelInfo = { ...row }

                !isObjectClassification && delete modelInfo.isMultiLabel
                return (
                    <div className="d-flex align-items-center justify-content-center">
                        <ActionButton
                            color="info"
                            onClick={handleModelInfo(modelInfo)}
                            id={tooltipId}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </ActionButton>
                    </div>
                )
            }
        },
        {
            dataField: 'datasetInfo',
            text: 'Dataset Info',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, row) => {
                const tooltipId = `pages-model-training-model-list-datasetinfo-button-${row.id}`;

                return row.datasetInfo ? (
                    <div className="d-flex align-items-center justify-content-center">
                        <ActionButton
                            color="info"
                            onClick={handleDatasetInfoShow(row.datasetInfo)}
                            id={tooltipId}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </ActionButton>
                    </div>
                ) : (
                    ''
                );
            }
        },
        {
            dataField: 'progress',
            text: 'Progress',
            align: 'center',
            headerAlign: 'center',
            formatter: (_cel, { canceledAt, completedAt, earlyStoppedAt, progressStatus, progress }) => {
                if (progressStatus === DetectionModelProgressStatus.Error) {
                    return (
                        <Badge color='danger' pill>
                            {DetectionModelProgressStatusUI.error}
                        </Badge>
                    );
                }
                else if (completedAt) {
                    if (canceledAt) {
                        return (
                            <Badge color='danger' pill>
                                Cancelled
                            </Badge>
                        );
                    } else if (earlyStoppedAt) {
                        return (
                            <Badge color='secondary' pill>
                                Early Stopped
                            </Badge>
                        );
                    } else {
                        return (
                            <Badge color='success' pill>
                                Completed
                            </Badge>
                        );
                    }
                } else {
                    return (
                        <Badge color='warning' pill>
                            <div className='d-flex justify-content-center align-items-center'>
                                <CircularProgress color='dark' />
                                <span className='ml-2'>
                                {progressStatus && DetectionModelProgressStatusUI[progressStatus]}
                                    {progressStatus === DetectionModelProgressStatus.Training && ` ${progress} %`}
                            </span>
                            </div>
                        </Badge>
                    );
                }
            }
        },
        {
            // TODO to time
            dataField: 'runtime',
            text: 'Runtime',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            formatter: (_cel, row) => row.runtime ? `${row.runtime} mins` : ''
        },
        {
            dataField: 'epochs',
            text: 'Epochs',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => <BestEpochTooltip detectionModel={row} isObjectDetection={isObjectDetection} />
        },
        {
            dataField: 'accuracy',
            text: 'Accuracy',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-sm-md text-nowrap',
            sort: true,
            headerFormatter: ({ dataField, text }, colIndex, { sortElement }) => {
                const isAccuracy = isObjectClassification
                return (
                    <HeaderWithTooltip
                        content={text}
                        tooltipContent={
                            !isProject
                                ? 'Accuracy or mAP of model'
                                : `The ${isAccuracy ? 'accuracy' : 'mAP'} of your model`
                        }
                        tooltipId={`pages-model-training-model-list-table_header-info-button-${dataField}`}
                        sortElement={sortElement}
                    />
                );
            },
            formatter: (_cel, row) => {
                const chartTooltipId = `pages-model-training-model-list-table_stat-button-${row.id}`;
                const matrixTooltipId = `pages-model-training-model-list-table_stat-button-${row.id}-confusion-matrix`;
                const isLegacy = dayjs(row.startTime).isBefore(dayjs('2023-03-09'))

                return (
                    (row.accuracy && row.accuracy > 0) ?
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='w-50'>
                            {`${row.accuracy}%`}
                        </div>

                        <ActionButton
                            color='primary'
                            onClick={handleModelStatisticShow(row)}
                            className='ml-2'
                            id={chartTooltipId}
                            tooltipText="Training Accuracy"
                        >
                            <FontAwesomeIcon icon={faChartLine} />
                        </ActionButton>
                        {isObjectClassification ? 
                            <ActionButton
                                color='primary'
                                onClick={handleConfusionMatrixDownload(row)}
                                className='ml-2'
                                id={matrixTooltipId}
                                tooltipText="Confusion Matrix"
                                disabled={isLegacy || !row.completedAt || !!row.canceledAt}
                            >
                                <FontAwesomeIcon icon={faChessBoard} />
                            </ActionButton>
                        : null}
                    </div> : ''
                );
            }
        },
        // Commented because FSAI-190
        /* {
            dataField: 'confidence',
            text: 'Confidence',
            headerFormatter: ({ dataField, text }) => {
                return (
                    <HeaderWithTooltip
                        content={text}
                        tooltipContent={text}
                        tooltipId={`pages-model-training-model-list-table_header-info-button-${dataField}`}
                    />
                );
            },
            formatter: (_cel, row) => row.confidence ? `${row.confidence}%` : ''
        }, */
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: 'right',
            align: 'right',
            hidden: isAllRunningTrainings,
            formatter: (_cel, row) => {
                const tooltipCancelButtonId = `pages-model-training-model-list-table_cancel-button-${row.id}`;
                const tooltipStopButtonId = `pages-model-training-model-list-table_stop-button-${row.id}`;
                const tooltipDeleteButtonId = `pages-model-training-model-list-table_delete-button-${row.id}`;
                const canChangeState = !row.completedAt && !row.canceledAt && !row.earlyStoppedAt &&
                    row.progressStatus !== DetectionModelProgressStatus.Error;
                const canEarlyStop = canChangeState && row.epochs && row.epochs > 0;

                return (
                    <div className='d-flex justify-content-end'>
                        {
                            canChangeState &&
                            <>
                                <ActionButton
                                    color='warning'
                                    onClick={handleCancel(row.id)}
                                    id={tooltipCancelButtonId}
                                    tooltipText="Cancel Training">
                                    <FontAwesomeIcon icon={faBan} />
                                </ActionButton>

                                <ActionButton
                                    color='secondary'
                                    hidden={!canEarlyStop}
                                    onClick={handleEarlyStop(row.id)}
                                    className='ml-2'
                                    id={tooltipStopButtonId}
                                    tooltipText="Early stop training"
                                    disabledTooltipText={"Please wait for the first epoch to be completed"}
                                >
                                    <FontAwesomeIcon icon={faStop} />
                                </ActionButton>
                            </>
                        }

                        <ActionButton
                            color='danger'
                            onClick={handleDeleteButtonClick(row)}
                            className='ml-2'
                            id={tooltipDeleteButtonId}
                            tooltipText="Delete item"
                            hidden={canChangeState}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </ActionButton>
                    </div>
                );
            }
        }
    ];

    const defaultSort: [{ dataField : any, order: SortOrder }] = [{
        dataField: 'startTime',
        order: 'desc'
    }];

    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={items}
                columns={columns}
                defaultSorted={defaultSort}
                striped
                bordered={false}
                wrapperClasses='table-responsive'
                classes='m-0'
                noDataIndication={noDataText}
            />

            <DeleteConfirmation
                body={`Are you sure you want to delete the "${deleteConfirmation?.modelWithDate}"?`}
                isOpen={!!deleteConfirmation}
                onConfirm={handleDelete}
                onClosed={handleDeleteConfirmationClosed}
                busyIndication={isLoading}
            />

            <Modal
                isOpen={!!showModelStatistic}
                toggle={handleModelStatisticClose}
                className='app-component-modal app-component-modal_full-height'
                size='xl'
            >
                <ModalHeader toggle={handleModelStatisticClose}>Training Accuracy</ModalHeader>
                <ModalBody>
                    <TrainingAccuracyStatistic detectionModel={showModelStatistic} />
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={handleModelStatisticClose}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

            <DatasetInfoModal
                isOpen={!!showDatasetInfo}
                toggle={handleDatasetInfoClose}
                datasetInfo={showDatasetInfo}
            />
            <ModelInfoModal
                isOpen={!!modelInfo}
                toggle={handleModelInfoClose}
                modelInfo={modelInfo}
            />
        </>
    );
});

export default ModelListTable;
