import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { DropdownList } from 'react-widgets';
import {
    DetectionModelBaseModelEnum,
    DetectionModelBaseModelEnumUI,
    DetectionModelFrameworkType,
    DetectionModelInputSizeEnum,
    DetectionModelInputSizeEnumUI,
    ModelTrainingAugmentationSettingsUI,
    ModelTrainingSettings,
    Project,
    ProjectType
} from '../../../models/api';
import ModelOptionWithIcon from '../../../components/DetectionModel/DetectionModelDropdownWithIcon/ModelOptionWithIcon';
import DetectionModelFrameworkTypeIcon from '../../../components/DetectionModel/DetectionModelFrameworkTypeIcon';
import { baseModelData, frameworkTypeData, modelInputSizeData } from './dropdownSettingsData';
import { Collapse, CustomInput } from 'reactstrap';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomSlider from '../../../components/CustomSlider';

export interface Props {
    settings: ModelTrainingSettings;
    project?: Project;
    onChange: (settings: ModelTrainingSettings) => void;
}
const marks = {
    10: '10%',
    50: '50%',
    100: '100%',
};

const AdvancedSettings: React.FC<Props> = ({ settings, project, onChange }) => {
    const [isAugmentationOpen, setIsAugmentationsOpen] = useState(false);

    const frameworkOutputData = frameworkTypeData[settings.frameworkType];

    const isClassificationProject = project?.type === ProjectType.ObjectClassification;
    const isSegmentationProject = project?.type === ProjectType.ObjectSegmentation;
    const isDetectionProject = project?.type === ProjectType.ObjectDetection;

    const [tf2ModelsInputSize, tf2ModelInputSize] = useMemo(() => {
        return [
            [{
                value: DetectionModelInputSizeEnum.VeryFast,
                text: DetectionModelInputSizeEnumUI.fast
            }],
            DetectionModelInputSizeEnum.VeryFast
        ]
    }, [])

    const augmentationAccordionToggle = () => setIsAugmentationsOpen(!isAugmentationOpen);

    const handleFrameworkChange = ({ value }: { value: DetectionModelFrameworkType }) => {
        const frameworkType = value;
        const baseModels = baseModelData.filter(baseModel => baseModel.frameworkType === frameworkType);
        const modelsInputSize = getModelInputSizeData(baseModels[0].value);

        onChange({
            ...settings,
            frameworkType,
            baseModels: baseModels,
            baseModel: baseModels[0].value,
            modelsInputSize: modelsInputSize,
            modelInputSize: modelsInputSize[0].value,
        });
    };

    const handleBaseModelChange = ({ value }: { value: DetectionModelBaseModelEnum }) => {
        const baseModel = value;
        const modelsInputSize = getModelInputSizeData(baseModel);

        onChange({
            ...settings,
            baseModel: baseModel,
            modelsInputSize: modelsInputSize,
            modelInputSize: modelsInputSize[0].value,
        });
    };

    const handleModelInputSizeChange = ({ value }: { value: DetectionModelInputSizeEnum }) => {
        onChange({ ...settings, modelInputSize: value });
    };

    const handleIgnoreEmptyAnnotations = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...settings, ignoreEmptyAnnotations: e.target.checked });
    };

    const handleFlip = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...settings, augmentations: {...settings.augmentations, [e.currentTarget.name]: e.target.checked} });
    };

    const handleShowSlider = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...settings, augmentations: {...settings.augmentations, [e.currentTarget.name]: e.target.checked ? 0.5 : 0} });
    };
    
    const handleSliderChange = (augmentationName: string) => (value: number) => {
        const normalizedValue = value / 100;
        onChange({ ...settings, augmentations: {...settings.augmentations, [augmentationName]: normalizedValue} });

    };

    const getModelInputSizeData = (baseModel: DetectionModelBaseModelEnum) => {
        return modelInputSizeData.filter(modelInputSize => modelInputSize.baseModels.includes( baseModel));
    };

    // remove TensorFlow V1 option for classification projects
    const dropdownListFrameworks = isClassificationProject ? 
        Object.values(frameworkTypeData).filter((frameworkType) => frameworkType.value !== DetectionModelFrameworkType.TensorFlowV1) :
        Object.values(frameworkTypeData)

    return (
        <>
            <table className='table table-striped'>
                <tbody>
                    <tr>
                        <td className='w-40'>Framework:</td>
                        <td>
                            <DropdownList
                                data={dropdownListFrameworks}
                                dataKey='value'
                                textField='text'
                                filter={false}
                                value={settings.frameworkType}
                                onChange={handleFrameworkChange}
                                optionComponent={ModelOptionWithIcon}
                                disabled={isSegmentationProject}
                                renderValue={({ item }) => {
                                    return item ? (
                                        <div>
                                            <DetectionModelFrameworkTypeIcon
                                                frameworkType={item.frameworkType}
                                                className='mr-2 img-w-sm'
                                            />

                                            <span>{item.text}</span>
                                        </div>
                                    ) : '';
                                }}
                            />
                        </td>
                    </tr>

                    <tr>
                            <td>Base Model:</td>
                            <td>
                                {project?.isMultiLabel ? (
                                    DetectionModelBaseModelEnumUI.resNext50
                                ) : isClassificationProject ? (
                                    DetectionModelBaseModelEnumUI.efficientNetB0
                                ) : isSegmentationProject ? (
                                    DetectionModelBaseModelEnumUI.rcnn
                                ) : (
                                    <DropdownList
                                        data={settings.baseModels}
                                        dataKey='value'
                                        textField='text'
                                        filter={false}
                                        value={settings.baseModel}
                                        onChange={handleBaseModelChange}
                                    />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Model Input Size:</td>
                            <td>
                                {project?.isMultiLabel ? (
                                    DetectionModelInputSizeEnumUI.ocFast
                                ) : isClassificationProject ? (
                                    DetectionModelInputSizeEnumUI.ocVeryFast
                                ) : isSegmentationProject ? (
                                    DetectionModelInputSizeEnumUI.osFast
                                ) : settings.frameworkType === DetectionModelFrameworkType.TensorFlow &&
                                    settings.baseModel === DetectionModelBaseModelEnum.SsdMobileNetV2Standard ? (
                                    <DropdownList
                                        data={tf2ModelsInputSize}
                                        dataKey='value'
                                        textField='text'
                                        filter={false}
                                        value={tf2ModelInputSize}
                                        onChange={handleModelInputSizeChange}
                                    />
                                ) : (
                                    <DropdownList
                                        data={settings.modelsInputSize}
                                        dataKey='value'
                                        textField='text'
                                        filter={false}
                                        value={settings.modelInputSize}
                                        onChange={handleModelInputSizeChange}
                                    />
                                )}
                            </td>
                        </tr>
                    
                    <tr>
                        <td>Training / Validation Ratio:</td>
                        <td>{frameworkOutputData.TrainingAndRation}</td>
                    </tr>
                    {isDetectionProject && (
                    <tr>
                        <td>Ignore images without annotations</td>
                        <td>
                            <CustomInput
                                className='app-advanced-settings-detection-model__custom-switch'
                                type='switch'
                                id='ignoreEmptyAnnotations'
                                name='ignoreEmptyAnnotations'
                                checked={settings.ignoreEmptyAnnotations}
                                onChange={handleIgnoreEmptyAnnotations}
                                />
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
            {!isClassificationProject && (
                <>
                    <div
                        className='d-flex mx-1 mt-4 mb-2 font-weight-bold'
                        onClick={augmentationAccordionToggle}
                    >
                        Augmentations advanced
                        <div className='ml-2'>
                            {isAugmentationOpen ? (
                                <FontAwesomeIcon icon={faChevronUp} />
                            ) : (
                                <FontAwesomeIcon icon={faChevronDown} />
                            )}
                        </div>
                    </div>
                    <Collapse isOpen={isAugmentationOpen} className='w-100'>
                        <table className='table table-striped'>
                            <tbody>
                                <tr>
                                    <td className='w-40'>Horizontal Flip</td>
                                    <td className='w-60' align='left'>
                                        <CustomInput
                                            className='app-advanced-settings-detection-model__custom-switch'
                                            type='switch'
                                            id='horizontalFlip'
                                            name='horizontalFlip'
                                            checked={settings.augmentations.horizontalFlip}
                                            onChange={handleFlip}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vertical Flip</td>
                                    <td align='left'>
                                        <CustomInput
                                            className='app-advanced-settings-detection-model__custom-switch'
                                            type='switch'
                                            id='verticalFlip'
                                            name='verticalFlip'
                                            checked={settings.augmentations.verticalFlip}
                                            onChange={handleFlip}
                                        />
                                    </td>
                                </tr>
                                {!isSegmentationProject && (
                                    <>
                                        <tr>
                                        <td>{ModelTrainingAugmentationSettingsUI.blur}</td>
                                        <td align='left'>
                                            <CustomInput
                                                className='app-advanced-settings-detection-model__custom-switch'
                                                type='switch'
                                                id='blur'
                                                name='blur'
                                                checked={settings.augmentations.blur > 0}
                                                onChange={handleShowSlider}
                                            />
                                        </td>
                                        </tr>
                                        {settings.augmentations.blur > 0 ? (
                                            <tr>
                                                <td colSpan={2} align='left'>
                                                    <CustomSlider
                                                        min={10}
                                                        max={100}
                                                        step={10}
                                                        defaultValue={settings.augmentations.blur * 100}
                                                        marks={marks}
                                                        onChange={handleSliderChange('blur')}
                                                        className="mb-4"
                                                    />
                                                </td>
                                            </tr>
                                        ) : null}
                                        <tr>
                                            <td>{ModelTrainingAugmentationSettingsUI.saltPepper}</td>
                                            <td align='left'>
                                                <CustomInput
                                                    className='app-advanced-settings-detection-model__custom-switch'
                                                    type='switch'
                                                    id='saltPepper'
                                                    name='saltPepper'
                                                    checked={settings.augmentations.saltPepper > 0}
                                                    onChange={handleShowSlider}
                                                />
                                            </td>
                                        </tr>
                                        {settings.augmentations.saltPepper > 0 ? (
                                            <tr>
                                                <td colSpan={2} align='left'>
                                                    <CustomSlider
                                                        min={10}
                                                        max={100}
                                                        step={10}
                                                        defaultValue={settings.augmentations.saltPepper * 100}
                                                        marks={marks}
                                                        onChange={handleSliderChange('saltPepper')}
                                                        className="mb-4"
                                                    />
                                                </td>
                                            </tr>
                                        ) : null}
                                        <tr>
                                            <td>{ModelTrainingAugmentationSettingsUI.randomLine}</td>
                                            <td align='left'>
                                                <CustomInput
                                                    className='app-advanced-settings-detection-model__custom-switch'
                                                    type='switch'
                                                    id='randomLine'
                                                    name='randomLine'
                                                    checked={settings.augmentations.randomLine > 0}
                                                    onChange={handleShowSlider}
                                                />
                                            </td>
                                        </tr>
                                        {settings.augmentations.randomLine > 0 ? (
                                            <tr>
                                                <td colSpan={2} align='left'>
                                                    <CustomSlider
                                                        min={10}
                                                        max={100}
                                                        step={10}
                                                        defaultValue={settings.augmentations.randomLine * 100}
                                                        marks={marks}
                                                        onChange={handleSliderChange('randomLine')}
                                                        className="mb-4"
                                                    />
                                                </td>
                                            </tr>
                                        ) : null}
                                        <tr>
                                            <td>Random Scale</td>
                                            <td align='left'>
                                                <CustomInput
                                                    className='app-advanced-settings-detection-model__custom-switch'
                                                    type='switch'
                                                    id='randomScale'
                                                    name='randomScale'
                                                    checked={settings.augmentations.randomScale}
                                                    onChange={handleFlip}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Adjust Brightness</td>
                                            <td align='left'>
                                                <CustomInput
                                                    className='app-advanced-settings-detection-model__custom-switch'
                                                    type='switch'
                                                    id='adjustBrightness'
                                                    name='adjustBrightness'
                                                    checked={settings.augmentations.adjustBrightness}
                                                    onChange={handleFlip}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Adjust Hue</td>
                                            <td align='left'>
                                                <CustomInput
                                                    className='app-advanced-settings-detection-model__custom-switch'
                                                    type='switch'
                                                    id='adjustHue'
                                                    name='adjustHue'
                                                    checked={settings.augmentations.adjustHue}
                                                    onChange={handleFlip}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Adjust Saturation</td>
                                            <td align='left'>
                                                <CustomInput
                                                    className='app-advanced-settings-detection-model__custom-switch'
                                                    type='switch'
                                                    id='adjustSaturation'
                                                    name='adjustSaturation'
                                                    checked={settings.augmentations.adjustSaturation}
                                                    onChange={handleFlip}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </Collapse>
                </>
            )}
        </>
    );
};

export default AdvancedSettings;
