import React, { useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import DnaLoader from '../../../components/DnaLoader';
import Paper from '../../../components/Paper';
import Layout from '../../../components/Layout';
import { useHistory, useParams } from 'react-router-dom';
import { ProfileInfoForm } from '../../user-profile/ProfileInfoForm';
import { LicenseType, User } from '../../../models/api';
import LicenseTypeForm from '../../user-profile/LicenseTypeForm';
import PageTitle from '../../../components/PageTitle';
import { EmailPreferencesForm } from '../../user-profile/EmailPreferencesForm';
import FileUploadListTable from '../../user-profile/FileUploadListTable';

const UserEdit: React.FC = observer(() => {
    const { usersStore } = useStores();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const handleSave = (user: User) => {
        if (user.licenseType === LicenseType.Free) {
            user.licenseExpiry = null;
        }
        usersStore.updateItem(user);

    };

    const isUserPro = usersStore.item.item?.licenseType === LicenseType.Pro;


        useEffect(() => {
        usersStore.fetchItem(id);
    }, [usersStore, id]);

    return (
        <Layout>
            {!usersStore.item.isFetched &&
                <DnaLoader />
            }

            {usersStore.item.isFetched && usersStore.item.item &&
            <>
                <PageTitle
                    heading={usersStore.item.item?.name}
                    subheading={usersStore.item.item?.email}
                    actionComponent={
                        <Button color='primary' onClick={history.goBack}>
                            Back
                        </Button>
                    }
                />
                <Row>
                    <Col sm={12}>

                        <Paper rounded className='p-4'>
                            <h6 className='font-weight-bold text-primary text-uppercase mb-4'>
                                Profile Info
                            </h6>

                            <ProfileInfoForm
                                user={usersStore.item.item}
                                onSave={handleSave}
                                isLoading={usersStore.item.isLoading}
                            />
                        </Paper>

                        <Paper rounded className='mt-4 p-4'>
                            <h6 className='font-weight-bold text-primary text-uppercase mb-4'>
                                License type
                            </h6>

                            <LicenseTypeForm
                                user={usersStore.item.item}
                                onSave={handleSave}
                                isLoading={usersStore.item.isLoading}
                            />
                        </Paper>

                        {isUserPro && (
                            <Paper  rounded className='mt-4 p-4'>
                                <h6 className='font-weight-bold text-primary text-uppercase mb-4'>
                                    Files
                                </h6>
    
                                <FileUploadListTable
                                    user={usersStore.item.item}
                                    isLoading={usersStore.item.isLoading}
                                />
                            </Paper>
                        )}

                        <Paper rounded className='mt-4 p-4'>
                            <h6 className='font-weight-bold text-primary text-uppercase mb-4'>
                                Email preferences
                            </h6>

                            <EmailPreferencesForm
                                user={usersStore.item.item}
                                onSave={handleSave}
                                isLoading={usersStore.item.isLoading}
                            />
                        </Paper>

                    </Col>
                </Row>
            </>
            }
        </Layout>
    );
}
);

export default UserEdit;
