import { useState } from 'react'
import { observer } from "mobx-react-lite";
import { DetectionModel, DetectionModelFrameworkType } from "../../../../models/api"
import { useStores } from "../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { UncontrolledTooltip } from "reactstrap";

interface Props {
    detectionModel: DetectionModel;
    isObjectDetection: boolean;
}

const BestEpochTooltip: React.FC<Props> = observer(({ detectionModel, isObjectDetection }) => {
    const { detectionModelsStore } = useStores();
    const [bestEpoch, setBestEpoch] = useState(-1)

    const handleTooltipMouseEnter = async () => {
        await detectionModelsStore.fetchItemTrainingAccuracyStatistic(detectionModel.id)
        const fscoreData = detectionModelsStore.itemTrainingAccuracyStatistic.data?.metricsByName.fscore.data

        if (fscoreData) {
            const maxFscore = Math.max(...fscoreData).toFixed(2).toString()
            const maxFscoreIndex = fscoreData.indexOf(maxFscore as unknown as number)
            setBestEpoch(maxFscoreIndex + 1)
        }
    }
    return (
        <div>
            {/* 
                total epochs hardcoded for now in scope of FSAI-325
                changed value to 1000 in the scope of FSAI-473
                changed value to 2000 for PyTorch OD in the scope of FSAI-1350 
             */}
            <span>
                {detectionModel.epochs
                    ? `${detectionModel.epochs}/${
                            isObjectDetection && detectionModel.frameworkType === DetectionModelFrameworkType.PyTorch
                                ? '2000'
                                : '1000'
                        }`
                    : ''}
            </span>
            {detectionModel.epochs && isObjectDetection ? (
                <>
                    <span className='text-info ml-1' onMouseEnter={handleTooltipMouseEnter} id={'test'+detectionModel.id}>
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                    <UncontrolledTooltip target={'test'+detectionModel.id}>
                        {detectionModelsStore.itemTrainingAccuracyStatistic.isLoading ? (
                            'Loading...'
                        ) : `Best epoch: ${bestEpoch}`}
                    </UncontrolledTooltip>
                </>
            ) : null}
        </div>
    )
})

export default BestEpochTooltip