import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type Props = {
    activeModal: boolean
    toggleModal: any;
    imageUrl: string;
}

const PredictiveMaintenanceModelTrainingImageModal:React.FC<Props> = ({toggleModal, activeModal, imageUrl}) => {
    return (
        <> 
            <Modal isOpen={activeModal} toggle={toggleModal} size={'xl'}>
                <ModalHeader>View</ModalHeader>
                <ModalBody>
                    <img src={imageUrl} style={{maxWidth: '1080px'}}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => toggleModal((prev: boolean) => !prev)}>
                        Done
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default PredictiveMaintenanceModelTrainingImageModal;