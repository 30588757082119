import Resizer from 'react-image-file-resizer';
import Compressor from 'compressorjs';
import * as jszip from 'jszip';
import {
    COMPRESSED_IMAGE_MIME_TYPE,
    COMPRESSED_IMAGE_QUALITY,
    MAX_COMPRESSED_IMAGE_SIZE_B,
    MAX_IMAGE_HEIGHT,
    MAX_IMAGE_WIDTH
} from '../config/image';
import { ImageType } from '../models/mimeTypes';
import { fileReaderUtil } from './file-reader';
import md5 from 'md5';
import path from 'path';
import { Extensions } from '../models/extensions';

export const imageUtil = {
    getDimensions: async (blob: Blob): Promise<{ width: number; height: number }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const image = new Image();
                image.onload = () => {
                    resolve({ width: image.width, height: image.height });
                };
                image.src = reader.result as string;
            };

            reader.onerror = reject;

            reader.readAsDataURL(blob);
        });
    },

    resizeFile: (file: File, extension: string, width: number, height: number) =>
        new Promise<File>((resolve) => {
            Resizer.imageFileResizer(
                file,
                width,
                height,
                extension,
                100,
                0,
                (uri) => {
                    resolve(uri as File);
                },
                'file'
            );
        }),

    compressFile: async (file: File) => {
        try {
            return await new Promise((resolve: (file: File) => void) => {
                new Compressor(file, {
                    maxWidth: MAX_IMAGE_WIDTH,
                    maxHeight: MAX_IMAGE_HEIGHT,
                    convertSize: MAX_COMPRESSED_IMAGE_SIZE_B,
                    quality: COMPRESSED_IMAGE_QUALITY,
                    mimeType: COMPRESSED_IMAGE_MIME_TYPE,
                    success: resolve
                });
            });
        } catch (e) {
            console.log('Compression error on file:', file.name);
            return file;
        }
    },

    getMd5Hash: async (file: File) => {
        const contents = await fileReaderUtil.readFileAsync(file);
        return md5(new Uint8Array(contents));
    },

    getHashedName: async (file: File) => {
        const md5Hash = await imageUtil.getMd5Hash(file);
        const ext = path.extname(file.name);
        return `${md5Hash}${ext}`;
    },

    validateFileExt: (fileNames: string[]) => {
        const extensions:string[] = [Extensions.Jpeg, Extensions.Jpg, Extensions.Png];
        for(let i = 0; i < fileNames.length; i++) {
            if(!extensions.includes(path.extname(fileNames[i]).split('.')[1])) {
                return false;
            } 
        }
        return true;
    },

    getThumbnailFromZippedImages: async (file: File): Promise<File | null> => {
        try {
            const zippedData = fileReaderUtil.readFileAsync(file);
            let thumbnailBuffer:ArrayBufferLike = new ArrayBuffer(0);
            
            const zip = await jszip.loadAsync(zippedData);
            if(Object.keys(zip.files).length !== 0) {
                if(!imageUtil.validateFileExt(Object.keys(zip.files)) || Object.keys(zip.files).length < 10) {
                    return null;
                }
                const zipKeys = Object.keys(zip.files);
                thumbnailBuffer = (await zip.files[zipKeys[0]].async('uint8array')).buffer;
            }

            return thumbnailBuffer.byteLength > 0 ? new File([thumbnailBuffer], 'thumbnail.png', { type: ImageType.Png }) : null;
        } catch (e) {
            return null;
        }
    }
};
