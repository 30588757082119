import React from 'react';
import history from './history';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { adminRoutes, projectRoutes, Routes } from './routes';
import GuestRoute from './components/guest-route';
import { LoginPage } from '../pages/login/login-page';
import PrivateRoute from './components/private-route';
import { DashboardPage } from '../pages/dashboard/dashboard-page';
import { ProjectGroupPage } from '../pages/dashboard/project-group-page';
import { NotFoundPage } from '../pages/not-found/not-found-page';
import { LabelsPage } from '../pages/labels/labels-page';
import { DatasetPage } from '../pages/dataset/dataset-page';
import { ModelTrainingPage } from '../pages/model-training/model-training-page';
import { ModelSimulationPage } from '../pages/model-simulation/model-simulation-page';
import { ModelDownloadPage } from '../pages/model-download/model-download-page';
import { UserProfilePage } from '../pages/user-profile/user-profile-page';
import { MyAccountPage } from '../pages/my-account/my-account-page';
import { PredictiveMaintenanceModelTrainingPage } from '../pages/predictive-maintenance-model-training/predictive-maintenance-model-training';

import AdminUserList from '../pages/admin/UserList';
import AdminUserEdit from '../pages/admin/UserEdit';
import AdminHelpVideos from '../pages/admin/HelpVideos';
import AdminRunningTrainings from '../pages/admin/RunningTrainings';
import AdminDeviceLicense from '../pages/admin/DeviceLicense';
import AdminUserActivity from '../pages/admin/UserActivity';
import AdminRunningSimulations from '../pages/admin/RunningSimulations';
import AdminTrainingReport from '../pages/admin/TrainingReport'

import { observer } from 'mobx-react-lite';
import { useStores } from '../store';
import { LicenseType, ProjectType } from '../models/api';
import { ModelGeneratorPage } from '../pages/model-generator/model-generator-page';
import { SystemLockPage } from '../pages/system-lock/system-lock.page';
import NotAvailableRoute from './components/not-available-route';

const RouteList = observer(() => {
    const { projectsStore, userStore } = useStores();

    const isPoseDetectionProject = projectsStore.current?.type === ProjectType.PoseDetection;
    const isModeling3dProject = projectsStore.current?.type === ProjectType.Modeling3d;
    const isPredictiveMaintenanceProject = projectsStore.current?.type === ProjectType.PredictiveMaintenance;

    return (
        <Router history={history}>
            <div className={'app-container'}>
                <Switch>
                    <GuestRoute path={Routes.SignIn} component={LoginPage} />
                    <GuestRoute path={Routes.SignUp} component={LoginPage} />
                    <GuestRoute path={Routes.ForgotPwd} component={LoginPage} />
                    <GuestRoute path={Routes.ChangeTemporaryPwd} component={LoginPage} />
                    <GuestRoute path={Routes.EmailConfirmation} component={LoginPage} />
                    <PrivateRoute path={Routes.SignOut} component={LoginPage} />

                    {userStore.user?.isAdmin && (
                        <Route exact path={adminRoutes}>
                            <PrivateRoute
                                exact
                                path={Routes.AdminUserList}
                                component={AdminUserList}
                            />

                            <PrivateRoute path={Routes.AdminUserEdit} component={AdminUserEdit} />

                            <PrivateRoute
                                exact
                                path={Routes.AdminRunningTrainings}
                                component={AdminRunningTrainings}
                            />

                            <PrivateRoute
                                exact
                                path={Routes.AdminTrainingReport}
                                component={AdminTrainingReport}
                            />


                            <PrivateRoute
                                exact
                                path={Routes.AdminHelpVideos}
                                component={AdminHelpVideos}
                            />

                            <PrivateRoute 
                                exact
                                path={Routes.AdminDeviceLicense}
                                component={AdminDeviceLicense}
                            />

                            <PrivateRoute
                                exact
                                path={Routes.AdminUserActivity}
                                component={AdminUserActivity}
                            />

                            <PrivateRoute 
                                exact
                                path={Routes.AdminRunningSimulations}
                                component={AdminRunningSimulations}
                            />
                            
                        </Route>
                    )}

                    <PrivateRoute path={Routes.UserProfile} component={UserProfilePage} />

                    <PrivateRoute path={Routes.Dashboard} component={DashboardPage} />

                    <PrivateRoute path={Routes.ProjectGroup} component={ProjectGroupPage} />

                    {userStore.user?.licenseType === LicenseType.Pro && (
                        <PrivateRoute path={Routes.MyAccount} component={MyAccountPage} />
                    )}

                    <Route exact path={projectRoutes}>
                        {projectsStore.current ? (
                            <>
                                <PrivateRoute path={Routes.Labels} component={LabelsPage}>
                                    {isPoseDetectionProject ? (
                                        <Redirect to={Routes.ModelSimulation} />
                                    ) : isModeling3dProject ? (
                                        <Redirect to={Routes.ModelGenerator} />
                                    ) : isPredictiveMaintenanceProject ? (
                                        <Redirect to={Routes.PredictiveMaintenanceModelTraining} />
                                    ) : null}
                                </PrivateRoute>

                                <PrivateRoute path={Routes.Dataset} component={DatasetPage}>
                                    {isPoseDetectionProject ? (
                                        <Redirect to={Routes.ModelSimulation} />
                                    ) : isModeling3dProject ? (
                                        <Redirect to={Routes.ModelGenerator} />
                                    ) : isPredictiveMaintenanceProject ? (
                                        <Redirect to={Routes.PredictiveMaintenanceModelTraining} />
                                    ) : null}
                                </PrivateRoute>

                                <PrivateRoute
                                    path={Routes.ModelTraining}
                                    component={ModelTrainingPage}
                                >
                                    {isPoseDetectionProject ? (
                                        <Redirect to={Routes.ModelSimulation} />
                                    ) : isModeling3dProject ? (
                                        <Redirect to={Routes.ModelGenerator} />
                                    ) : isPredictiveMaintenanceProject ? (
                                        <Redirect to={Routes.PredictiveMaintenanceModelTraining} />
                                    ) : null}
                                </PrivateRoute>

                                <PrivateRoute
                                    path={Routes.ModelSimulation}
                                    component={ModelSimulationPage}
                                >
                                    {isModeling3dProject ? (
                                        <Redirect to={Routes.ModelGenerator} />
                                    ) : null}
                                </PrivateRoute>

                                <PrivateRoute path={Routes.Download} component={ModelDownloadPage}>
                                    {isModeling3dProject ? (
                                        <Redirect to={Routes.ModelGenerator} />
                                    ) : null}
                                </PrivateRoute>

                                <PrivateRoute
                                    path={Routes.ModelGenerator}
                                    component={ModelGeneratorPage}
                                >
                                    {!isModeling3dProject ? (
                                        <Redirect to={Routes.ModelSimulation} />
                                    ) : null}
                                </PrivateRoute>

                                <PrivateRoute
                                    path={Routes.PredictiveMaintenanceModelTraining}
                                    component={PredictiveMaintenanceModelTrainingPage}
                                />
                            </>
                        ) : (
                            <Redirect to={Routes.Dashboard} />
                        )}
                    </Route>
                    {/*Redirects*/}
                    <PrivateRoute exact path={Routes.Default}>
                        <Redirect
                            to={userStore.user?.isAdmin ? Routes.AdminUserList : Routes.Dashboard}
                        />
                    </PrivateRoute>
                    
                    {/*System Lock*/}
                    <NotAvailableRoute path={Routes.SystemLock} component={SystemLockPage} />

                    {/*System*/}
                    <PrivateRoute path='*' component={NotFoundPage} />
                </Switch>
            </div>
        </Router>
    );
});

export default RouteList;
