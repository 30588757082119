import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LabelUpdate } from '../../../models/api';

interface Props {
    isOpen: boolean;
    onAccept: () => void;
    onReject: () => void;
    onCancel: () => void;
    data: LabelUpdate[] | null;
}

export const LabelBulkUploadConfirmation: React.FC<Props> = (props) => {
    const { isOpen, onAccept, onReject, onCancel, data } = props;
    return (
        <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
            <ModalHeader toggle={onCancel}>Add Labels</ModalHeader>
            <ModalBody>
                <div className="py-4">
                    <span>
                        There are {data?.length} labels detected in the file.
                        Do you want to add them to the project?
                    </span>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" style={{ width: '5rem' }} onClick={onAccept}>
                    Yes
                </Button>
                <Button color="secondary" style={{ width: '5rem' }} onClick={onReject}>
                    No
                </Button>
            </ModalFooter>
        </Modal>
    );
}
