import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import cx from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faFolderMinus } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import magicWand from '../../../../assets/app/images/magic-wand.svg';
import { ModelTrainingSettingsTypes, SettingsPresets } from '../../AdvancedSettings/dropdownSettingsData';
import { Props } from '../index';

const ProjectSettingsCarousel: React.FC<Props> = observer(({ settings, onChange, activeTemplate, setActiveTemplate}) => {
    const presetsNameList = Object.keys(ModelTrainingSettingsTypes);
    const [activeIndex, setActiveIndex] = useState(presetsNameList.indexOf(activeTemplate));

    const settingsImagesUrls = [
        'https://firststep.ai/content/templates/settings_mobile.jpg',
        'https://firststep.ai/content/templates/settings_edge.jpg',
        'https://firststep.ai/content/templates/settings_cloud.jpg'
    ]

    const handleNext = () => {
        const nextIndex = activeIndex === presetsNameList.length-1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
        setActiveTemplate(presetsNameList[activeIndex+1] as keyof typeof ModelTrainingSettingsTypes);
        onChange({...settings, ...SettingsPresets.get(presetsNameList[activeIndex+1])});
    }

    const handlePrevious = () => {
        const nextIndex = activeIndex === 0 ? presetsNameList.length-1 : activeIndex - 1;
        setActiveIndex(nextIndex);
        setActiveTemplate(presetsNameList[activeIndex-1] as keyof typeof ModelTrainingSettingsTypes);
        onChange({...settings, ...SettingsPresets.get(presetsNameList[activeIndex-1])});
    }

    useEffect(() => {
        setActiveIndex(presetsNameList.indexOf(activeTemplate));
    }, [activeTemplate]);

    return (
        <>
            {presetsNameList.length > 0 ? (
                <>
                    <div className="app-pages-projects-page__image-template-placeholder">
                        <img src={settingsImagesUrls[activeIndex]} alt="" />
                    </div>

                    <div className="d-flex justify-content-center align-items-center text-center mt-4">
                        <Button
                            color="primary"
                            size="sm"
                            name={'handlePrevious'}
                            onClick={handlePrevious}
                            disabled={activeIndex === 0}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>

                        <span className="font-weight-bold ml-3 mr-3 w-50">
                            {Object.values(ModelTrainingSettingsTypes).map((item, index) => (
                                <div
                                    className={cx({
                                        'd-none': index !== activeIndex,
                                    })}
                                >
                                    {item}
                                </div>
                            ))}
                        </span>

                        <Button
                            color="primary"
                            size="sm"
                            name={'handleNext'}
                            onClick={handleNext}
                            disabled={ activeIndex === Object.values(ModelTrainingSettingsTypes).length - 1 }
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-center align-items-center py-5">
                        <FontAwesomeIcon icon={faFolderMinus} size="5x"/>
                    </div>
                    <div className="text-center mt-2">
                        There is no available templates yet
                    </div>
                </>
            )}
        </>
    );
});

export default ProjectSettingsCarousel;