import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Row } from "reactstrap";
import DnaLoader from "../../../components/DnaLoader";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Paper from "../../../components/Paper";
import { longOperationsConfig } from "../../../config/long-operations";
import { useStores } from "../../../store";
import { ModelGeneratorsTable } from "../../model-generator/ModelGeneratorsTable";
import VideoListTable from "../../model-simulation/VideoListTable";

const RunningSimulations: React.FC = observer(() => {
    const { detectionModelSimulationsStore, detectionModelGeneratorsStore } = useStores();
    
    React.useEffect(() => {
        detectionModelSimulationsStore.fetchTerminatedSimulations();
        detectionModelGeneratorsStore.fetchTerminatedGenerators();
        detectionModelGeneratorsStore.fetchRunningGenerators();
        detectionModelSimulationsStore.fetchRunningSimulations();
        const handle = setInterval(() => {
            detectionModelSimulationsStore.fetchTerminatedSimulations();
            detectionModelSimulationsStore.fetchRunningSimulations();
            detectionModelGeneratorsStore.fetchTerminatedGenerators();
            detectionModelGeneratorsStore.fetchRunningGenerators();
        }, longOperationsConfig.refreshInterval);
        return () => {
            clearInterval(handle);
        };
    }, [detectionModelGeneratorsStore, detectionModelSimulationsStore]);

    return (
        <Layout>
            <>
                <PageTitle 
                    heading="Running Simulations"
                    icon="fas fa-atom icon-gradient bg-warm-flame"
                    subheading="See all current running OC / OD / Pose simulations and 3d simulations"
                    showCloseButton={true}
                />
                
                {!detectionModelSimulationsStore.runningSimulationsList.isFetched && (
                    <DnaLoader />
                )}

                <Row>
                    <Col sm={12} hidden={!detectionModelSimulationsStore.runningSimulationsList.isFetched}>
                        <div className="card-title">Running Simulations</div>
                        <Paper rounded>
                            <VideoListTable 
                                items={detectionModelSimulationsStore.runningSimulationsList.items}
                                isLoading={detectionModelSimulationsStore.isLoading}
                                isAllRunningSimulations={true}
                            />
                        </Paper>
                    </Col>
                </Row>

                {!detectionModelGeneratorsStore.runningList.isFetched && (
                    <DnaLoader />
                )}

                <Row>
                    <Col sm={12} className="mt-5" hidden={!detectionModelGeneratorsStore.runningList.isFetched}>
                        <div className="card-title">Running 3D Simulations</div>
                        <Paper rounded>
                            <ModelGeneratorsTable
                                items={detectionModelGeneratorsStore.runningList.items}
                                isAllRunningGenerators={true}
                            />
                        </Paper>
                    </Col>
                </Row>

                {!detectionModelSimulationsStore.terminatedSimulationsList.isFetched && (
                    <DnaLoader />
                )}

                <Row>
                    <Col sm={12} className="mt-5" hidden={!detectionModelSimulationsStore.terminatedSimulationsList.isFetched}>
                        <div className="card-title">Latest 10 finished simulations</div>
                        <Paper rounded>
                            <VideoListTable 
                                items={detectionModelSimulationsStore.terminatedSimulationsList.items}
                                isLoading={detectionModelSimulationsStore.isLoading}
                                isAllRunningSimulations={true}
                            />
                        </Paper>
                    </Col>
                </Row>

                {!detectionModelGeneratorsStore.terminatedList.isFetched && (
                    <DnaLoader />
                )}

                <Row>
                    <Col sm={12} className="mt-5" hidden={!detectionModelGeneratorsStore.terminatedList.isFetched}>
                        <div className="card-title">Latest 10 finished 3d simulations</div>
                        <Paper rounded>
                            <ModelGeneratorsTable
                                items={detectionModelGeneratorsStore.terminatedList.items}
                                isAllRunningGenerators={true}
                            />
                        </Paper>
                    </Col>
                </Row>
            </>
        </Layout>
    );
});

export default RunningSimulations;
