import React from 'react';
import dnaImage from '../../assets/app/images/dna-animation.svg';

const DnaLoader = () => {
    return (
        <div className='d-flex justify-content-center align-items-center he-100'>
            <img src={dnaImage} alt='dna loader' />
        </div>
    );
}

export default DnaLoader;
