import React from 'react';

interface Props {
    header:  JSX.Element,
    content:  JSX.Element[],
    footer:  JSX.Element,
    classNames?:  string,
}

const LicenseCard: React.FC<Props> = ({
    header,
    content,
    footer,
    classNames,
}) => {
    return (
        <div className={`license-card ${classNames}`}>
            <div className="header">
                <span>{header}</span>
            </div>

            <div className="content">{content}</div>
            <div className="footer">{footer}</div>
        </div>
    );
};

export default LicenseCard;
