import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Badge } from 'reactstrap';
import { faPen, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteConfirmation from '../../../components/Modals/DeleteConfirmation';
import ActionButton from '../../../components/BootstrapTable/ActionButton';
import { Label, LabelUpdate, ProjectType } from '../../../models/api';
import { noDataText } from '../../../components/BootstrapTable';
import { EditLabelModal } from '../EditLabelModal';
import { useStores } from '../../../store';
import { AnnotationLabelEditor } from '../AnnotationLabelEditor/AnnotationLabelEditor';

const DELETE_CONFIRMATION_MESSAGE = (
    labelName: string | undefined,
    annotationsCount: number | undefined
) => {
    if (annotationsCount && annotationsCount > 1)
        return `Do you want to delete the "${labelName}" label including all ${annotationsCount} annotations?`;
    if (annotationsCount && annotationsCount === 1)
        return `Do you want to delete the "${labelName}" label including ${annotationsCount} annotation?`;

    return `Are you sure you want to delete the "${labelName}" label?`;
};

interface Props {
    items: Label[];
    onDeleteLabel?: (id: string) => void;
    isLoading: boolean;
}

const LabelsTable: React.FC<Props> = ({ items, onDeleteLabel, isLoading }) => {
    const { projectsStore, labelsStore } = useStores();
    const [deleteConfirmation, setDeleteConfirmation] = useState<Label>();
    const [editConfirmation, setEditConfirmation] = useState<Label>();
    const [annotationEditorConfirmation, setAnnotationEditorConfirmation] = useState<Label>();

    const isObjectDetection = projectsStore.current?.type === ProjectType.ObjectDetection;

    const handleDelete = () => {
        if (!deleteConfirmation) {
            return;
        }

        onDeleteLabel && onDeleteLabel(deleteConfirmation.id);
        setDeleteConfirmation(undefined);
    };

    const handleEdit = (labelUpdate: LabelUpdate) => {
        if (!editConfirmation) {
            return;
        }
        const { id, ...rest } = labelUpdate;

        if (id) {
            labelsStore.updateItem(id, rest);
        }
        setEditConfirmation(undefined);
    };

    const handleEditButtonClick = (row: Label) => () => {
        setEditConfirmation(row);
    };
    const handleAnnotationEditorButtonClick = (row: Label) => () => {
        setAnnotationEditorConfirmation(row);
    };
    const handleDeleteButtonClick = (row: Label) => () => {
        setDeleteConfirmation(row);
    };

    const handleEditConfirmationClosed = () => {
        setEditConfirmation(undefined);
    };
    const handleAnnotationEditorConfirmationClosed = () => {
        setAnnotationEditorConfirmation(undefined);
    };
    const handleDeleteConfirmationClosed = () => {
        setDeleteConfirmation(undefined);
    };
    // Any type because the sortValue() doesn't exist in @types/react-bootstrap-table-next": ^4.0.13
    const columns: any[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel: any, row: Label, rowIndex: number) => rowIndex + 1
        },
        {
            dataField: 'name',
            text: 'Label Name',
            sort: true,
            formatter: (_cel: any, row: Label) => {
                return <div style={{ color: row.color }}>{row.name}</div>;
            }
        },
        {
            dataField: 'annotationsCount',
            text: 'Annotations',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel: any, row: Label) => {
                return (
                    <Badge
                        color={
                            (row.annotationAndClassificationCount || 0) > 0 ? 'success' : 'danger'
                        }
                        pill
                    >
                        {row.annotationAndClassificationCount}
                    </Badge>
                );
            },
            sortValue: (_cel: any, row: Label) => row.annotationAndClassificationCount
        },
        {
            dataField: 'actions',
            text: 'Actions',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-sm',
            formatter: (_cel: any, row: Label) => {
                const tooltipSearchButtonId = `pages-labels-labels-table_search-button-${row.id}`;
                const tooltipEditButtonId = `pages-labels-labels-table_edit-button-${row.id}`;
                const tooltipDeleteButtonId = `pages-labels-labels-table_delete-button-${row.id}`;
                return (
                    <div className='d-flex justify-content-center'>
                        {isObjectDetection && (row.annotationAndClassificationCount ?? 0) > 0 && (
                            <ActionButton
                                className='mr-2'
                                color='primary'
                                onClick={handleAnnotationEditorButtonClick(row)}
                                id={tooltipSearchButtonId}
                                tooltipText='Annotation Label Editor'
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </ActionButton>
                        )}
                        <ActionButton
                            className='mr-2'
                            color='warning'
                            onClick={handleEditButtonClick(row)}
                            id={tooltipEditButtonId}
                            tooltipText='Edit item'
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </ActionButton>
                        <ActionButton
                            color='danger'
                            onClick={handleDeleteButtonClick(row)}
                            id={tooltipDeleteButtonId}
                            tooltipText='Delete item'
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </ActionButton>
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={items}
                columns={columns}
                striped
                bordered={false}
                wrapperClasses='table-responsive'
                classes='m-0'
                noDataIndication={noDataText}
            />

            <DeleteConfirmation
                body={DELETE_CONFIRMATION_MESSAGE(
                    deleteConfirmation?.name,
                    deleteConfirmation?.annotationAndClassificationCount
                )}
                isOpen={!!deleteConfirmation}
                onConfirm={handleDelete}
                onClosed={handleDeleteConfirmationClosed}
                busyIndication={isLoading}
            />
            <EditLabelModal
                label={editConfirmation}
                isOpen={!!editConfirmation}
                onConfirm={handleEdit}
                onClosed={handleEditConfirmationClosed}
            />
            {isObjectDetection && (
                <AnnotationLabelEditor
                    inputLabelId={annotationEditorConfirmation?.id}
                    isOpen={!!annotationEditorConfirmation}
                    onClosed={handleAnnotationEditorConfirmationClosed}
                />
            )}
            
        </>
    );
};

export default LabelsTable;
