import { ComponentType } from 'react';
import { DropdownProps } from 'react-widgets/DropdownList';
import { DetectionModel, DetectionModelFrameworkType } from "../../../models/api";

interface SimulationDropdownProps extends DropdownProps<any> {
    detectionModelList: DetectionModel[]
}

const withSimulationDropdown = <T extends SimulationDropdownProps>(Component: ComponentType<T>) => (props: T) => {
    const modelOptions = props.detectionModelList.map(item => {
        return {
            value: item.id,
            model: item,
            text: `${item.modelWithModelIndex} (accuracy: ${item.accuracy ?? ''}%)`,
            frameworkType: item.frameworkType
        };
    });

    const disabledModelOptions = modelOptions.filter((model) => model.frameworkType === DetectionModelFrameworkType.TensorFlowV1)

    return <Component {...props} detectionModelList={modelOptions} disabled={disabledModelOptions} />
}

export default withSimulationDropdown