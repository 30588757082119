import React, { FunctionComponent } from 'react';
import { Form, withFormik, FormikProps, FormikBag } from 'formik';
import { ForgotPwdFormData } from '../../../models/login/forgot-pwd';
import * as Yup from 'yup';
import { FormField } from '../../../components/Form';
import { Button } from 'reactstrap';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

interface Props {
    onSubmit: (email:string)=>void;
}
type FormProps = Props & FormikProps<ForgotPwdFormData>;

const _ForgotPwdForm: FunctionComponent<FormProps> = (props) => {
    const formName = 'forgot-pwd-form';
    const isLoading = !props.isValidating && props.isSubmitting;
    const isSubmissionFailed =
        !props.isSubmitting && props.status && props.status.error;

    return (
        <Form noValidate>
            <FormField
                formName={formName}
                field={'email'}
                type={'email'}
                placeholder={'Email'}
                isFirst
            />

            <div className={'pt-4'}>
                <Button
                    color={'primary'}
                    type={'submit'}
                    block
                    disabled={isLoading}
                    size='lg'
                >
                    Reset password
                </Button>
            </div>
        </Form>
    );
};

const mapPropsToValues: () => ForgotPwdFormData = () => {
    return {
        email: '',
    };
};

const handleSubmit = async (
    values: ForgotPwdFormData,
    formikBag: FormikBag<Props, ForgotPwdFormData>
) => {
    try {
        await Auth.forgotPassword(values.email);

        formikBag.props.onSubmit(values.email);
    } catch (error) {
        toast(error.message, {type:toast.TYPE.ERROR});
    }

    formikBag.setSubmitting(false);
};

const validationSchema = Yup.object().shape({
    email: Yup.string().required('required').email('invalid email format'),
});

export const ForgotPwdForm = withFormik<Props, ForgotPwdFormData>({
    mapPropsToValues,
    handleSubmit,
    validationSchema,
})(_ForgotPwdForm);
