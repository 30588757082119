import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import container from '../../container/container';
import { Label, LabelUpdate } from '../../models/api';
import { RootStore } from './root';
import { CustomEventType } from '../../models/events';
import { NETWORK_ERROR_MESSAGE } from '../../utils/errors';
import { createHardRefreshToast } from '../../components/Toasts/HardRefreshToast';
import { removeSpecialCharactersFromString } from '../../utils/helper-functions';

const api = container.apiClient;

export class LabelsStore {
    list: Label[] = [];

    isLoading: boolean = false;
    listIsFetched: boolean = false;

    rootStore: RootStore;

    private readonly disposers: IReactionDisposer[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.disposers.push(
            reaction(() => ({
                labelCount: this.list.length,
                isLoading: this.isLoading
            }), ({ labelCount, isLoading }) => {
                if (isLoading || !this.rootStore.statisticsStore.project){
                    return;
                }

                this.rootStore.statisticsStore.setDashboardStatistic({
                    ...this.rootStore.statisticsStore.project,
                    labelCount
                });
            })
        );
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    resetList() {
        runInAction(() => {
            this.list = [];
            this.isLoading = false;
            this.listIsFetched = false;
        });
    }

    async fetchList() {
        // TODO: find solution for check and waiting
        if (!this.rootStore.projectsStore.current || this.isLoading) {
            return;
        }

        this.isLoading = true;
        this.listIsFetched = false;

        try {
            const { data } = await api.labelList(this.rootStore.projectsStore.current.id);

            runInAction(() => {
                this.list = data;
                this.listIsFetched = true;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async createItem(labels: LabelUpdate[]) {

        if (!this.rootStore.projectsStore.current) {
            return;
        }

        const projectId = this.rootStore.projectsStore.current.id;
        const projectName = this.rootStore.projectsStore.current.name;

        const toastId = createProgressToast();
        this.isLoading = true;

        try {
            const labelsForNames: Record<string, LabelUpdate> = {};

            labels.forEach((label) => {
                if (!label.name) return;
                const parsedName = removeSpecialCharactersFromString(label.name);
                if (!parsedName) return;
                labelsForNames[parsedName] = { ...label, name: parsedName };
            });

            const uniqueLabels = Object.values(labelsForNames);

            const { data } = await api.labelCreate(this.rootStore.projectsStore.current.id, uniqueLabels);

            if (data.length < uniqueLabels.length) {
                toast('Some labels are already exist in the project. Only the missing ones will be added', {
                    type: toast.TYPE.WARNING
                });
            }

            runInAction(() => {

                this.list.push(...data);

                data.forEach((label) => {
                    this.rootStore.eventsStore.addItem(CustomEventType.AddLabel, {
                        id: label.id,
                        name: label.name,
                        projectId,
                        projectName
                    });
                });
            });
        } catch (e) {
            const errorMessage = e?.response?.data?.errors?.message ?? e.message;
            if (errorMessage !== NETWORK_ERROR_MESSAGE) {
                toast(errorMessage, { type: toast.TYPE.ERROR });
            } else {
                createHardRefreshToast(() => this.fetchList());
            }
        } finally {
            runInAction(() => {
                toast.dismiss(toastId);
                this.isLoading = false;
            });
        }
    }

    async updateItem(id: string, label: LabelUpdate) {
        this.isLoading = true;
        const toastId = createProgressToast();
        try {
            if (!this.rootStore.projectsStore.current) {
                return;
            }

            if (label.name) {
                const parsedName = removeSpecialCharactersFromString(label.name);
                parsedName && (label.name = parsedName)
            }

            const { data: updatedLabel } = await api.labelUpdate(id, label);
            runInAction(() => {
                const updatedLabelIndex = this.list.findIndex(item => item.id === id);
                this.list[updatedLabelIndex] = {...this.list[updatedLabelIndex], ...updatedLabel};

                if (updatedLabel) {
                    this.rootStore.eventsStore.addItem(CustomEventType.UpdateLabel, {
                        id: updatedLabel.id,
                        name: updatedLabel.name,
                        projectId: this.rootStore.projectsStore.current!.id,
                        projectName: this.rootStore.projectsStore.current!.name
                    });
                }
            });
        } catch(e) {
            const errorMessage = e?.response?.data?.errors?.message ?? e.message;
            if (errorMessage !== NETWORK_ERROR_MESSAGE) {
                toast(errorMessage, { type: toast.TYPE.ERROR });
            } else {
                createHardRefreshToast(() => (this.isLoading = false));
            }
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async deleteItem(id: string) {
        this.isLoading = true;
        const toastId = createProgressToast();
        try {
            // TODO: find solution for check and waiting
            if (!this.rootStore.projectsStore.current) {
                return;
            }

            await api.labelDelete(id);
            runInAction(() => {
                const label = this.list.find(item => item.id === id);
                this.list = this.list.filter(item => item.id !== id);
                if (label) {
                    this.rootStore.eventsStore.addItem(CustomEventType.DeleteLabel, {
                        id: label.id,
                        name: label.name,
                        projectId: this.rootStore.projectsStore.current!.id,
                        projectName: this.rootStore.projectsStore.current!.name
                    });
                }
            });
        } catch(e) {
            const errorMessage = e?.response?.data?.errors?.message ?? e.message;
            if (errorMessage !== NETWORK_ERROR_MESSAGE) {
                toast(errorMessage, { type: toast.TYPE.ERROR });
            } else {
                createHardRefreshToast(() => (this.isLoading = false));
            }
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    updateLabelAnnotationCount(labelId: string, diff: number) {
        this.list = this.list.map((label) => label.id === labelId ? (
            {
                ...label,
                annotationAndClassificationCount: Math.max((label.annotationAndClassificationCount ?? 0) + diff, 0)
            }
        ) : (
            label
        ));
    }
}
