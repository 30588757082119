import React, { useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody } from 'reactstrap';

const MobileDeviceWarning: React.FC<{}> = () => {
    const [isMobileDeviceWarningOpen, setIsMobileDeviceWarningOpen] = useState(true);
    const mobileDeviceWarningHandler = () => {
        setIsMobileDeviceWarningOpen(false);
    };
    return (
        <Modal
            isOpen={isMobileDeviceWarningOpen}
            toggle={mobileDeviceWarningHandler}
            className={'app-components-mobile-device-warning'}
            centered
        >
            <span
                className="mr-2 text-right"
                style={{ cursor: 'pointer' }}
                onClick={mobileDeviceWarningHandler}
            >
                <FontAwesomeIcon icon={faTimes} />
            </span>
            <ModalBody>
                The screen size is too small for the app to work properly.
                Please use a desktop browser with a larger screen size
            </ModalBody>
        </Modal>
    );
};

export default MobileDeviceWarning;
