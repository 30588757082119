import container from "../../container/container";
import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { RootStore } from "./root";
import { createProgressToast } from "../../components/Toasts/ProgressToast";
import { toast } from "react-toastify";
import { DeviceLicense, PredictiveMaintenance, PredictiveMaintenanceModelTrainingProgressStatus, SetDeviceLicense } from "../../models/api";
import { s3StorageConfig, s3Util } from "../../utils/s3";
import { Storage } from "aws-amplify";
import { PREDICTIVE_MAINTENANCE_IDENTITY_ERROR } from "../../utils/errors";
import { v4 as uuidv4 } from 'uuid';
import { imageUtil } from "../../utils/image";
import { AccessLevel } from "@aws-amplify/ui-components";
import { CustomEventType } from "../../models/events";


const api = container.apiClient;
const TEMPLATE_CSV_FILENAME = 'firststep_predictive_maintenance_template.csv';
const MODEL_FSTEP_FILENAME = 'model.fstep';

interface PredictiveMaintenanceTrainingsList {
    list: PredictiveMaintenance[];
    isFetched: boolean;
    isLoading: boolean;
}

export class PredictiveMaintenanceModelTrainingStore {

    predictiveMaintenanceTrainingsList: PredictiveMaintenanceTrainingsList = {
        list: [],
        isFetched: false,
        isLoading: false
    };

    isFetched: boolean = false;
    isLoading: boolean = false;
    isError: boolean = false;

    rootStore: RootStore
    private readonly disposers: IReactionDisposer[] = [];
    constructor (rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;

        this.disposers.push(
            reaction(() => ({
                runningPredictiveMaintenanceCount: this.listOfRunning.length,
                predictiveMaintenanceModelCount: this.predictiveMaintenanceTrainingsList.list.length,
            }), ({ runningPredictiveMaintenanceCount, predictiveMaintenanceModelCount }) => {
                this.rootStore.statisticsStore.setDashboardStatistic({
                    ...this.rootStore.statisticsStore.project,
                    runningPredictiveMaintenanceCount,
                    predictiveMaintenanceModelCount
                })
            })
        )
    }

    dispose() {
        for (const disposer of this.disposers) {
            disposer();
        }
    }

    get listOfRunning() {
        return this.predictiveMaintenanceTrainingsList.list.filter(item => !item.completedAt);
    }

    async fetchPredictiveMaintenanceTrainingsList() {
        const project = this.rootStore.projectsStore.current;

        if (!project) {
            const toastId = createProgressToast();
            toast(PREDICTIVE_MAINTENANCE_IDENTITY_ERROR, { type: toast.TYPE.ERROR });
            this.isLoading = false;
            toast.dismiss(toastId);
            return;
        }

        try {
            const { data }  = await api.predictiveMaintenanceTrainingsList({
                projectId: project.id
            });
            runInAction(() => {
                this.predictiveMaintenanceTrainingsList.list = data;
                this.predictiveMaintenanceTrainingsList.isFetched = true;
            })
        } catch(e) {
            console.log(e);
        } 
    }

    async createItem(file: File) {
        this.isLoading = true;
        const toastId = createProgressToast();

        // Getting identityId for current user and also current project
        const identityId = this.rootStore.userStore.getIdentityIdSync();
        const project = this.rootStore.projectsStore.current;

        if (!identityId || !project) {
            toast(PREDICTIVE_MAINTENANCE_IDENTITY_ERROR, { type: toast.TYPE.ERROR });
            this.isLoading = false;
            toast.dismiss(toastId);
            return;
        }

        // Getting id for new generator and hash for video
        const id = uuidv4();
        const md5Hash = await imageUtil.getMd5Hash(file);

        // Paths to files on S3
        const s3Bucket = s3Util.getS3BucketName();
        const s3PrivateFolder = s3Util.getS3PrivateFolder(identityId);
        const outputFolder = s3Util.getPredictiveMaintenanceOutputFolder(project.id, id);
        const inputFilePath = s3Util.getPredictiveMaintenanceFileKey(project.id, id);

        // Creating initial model for generator
        const model = {
            id,
            filename: file.name,
            hash: md5Hash,
            progressStatus: PredictiveMaintenanceModelTrainingProgressStatus.Initializing,
            startedAt: new Date().toISOString(),
            projectId: project.id
        }

        // Uploading video and thumbnail to S3 for current generator
        const storageConfig: s3StorageConfig = { level: AccessLevel.Private, identityId };
        await Storage.put(inputFilePath, file, storageConfig);

        try {
            const { data } = await api.predictiveMaintenanceTrainingCreate({
                model,
                s3Bucket,
                inputFilePath: `${s3PrivateFolder}/${inputFilePath}`,
                outputFolder: `${s3PrivateFolder}/${outputFolder}`,
            });
            runInAction(() => {
                this.predictiveMaintenanceTrainingsList.list = [...this.predictiveMaintenanceTrainingsList.list, data]; 
                this.rootStore.eventsStore.addItem(CustomEventType.StartPredictiveMaintenanceTraining, {
                    id: data.id,
                    filename: data.filename,
                    projectId: project.id,
                    projectName: project.name
                });
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async deleteItem(id: string) {
        if (!this.rootStore.projectsStore.current) {
            return;
        }
        const project = this.rootStore.projectsStore.current;
        this.isLoading = true;
        const toastId = createProgressToast();
        try {
            await api.predictiveMaintenanceTrainingDelete(id);
            runInAction(() => {
                const data = this.predictiveMaintenanceTrainingsList.list.find((item) => item.id === id);
                if (data) {
                    this.predictiveMaintenanceTrainingsList.list = this.predictiveMaintenanceTrainingsList.list.filter((item) => item.id !== id);
                    this.rootStore.eventsStore.addItem(CustomEventType.DeletePredictiveMaintenanceTraining, {
                        id: data.id,
                        filename: data.filename,
                        projectId: project.id,
                        projectName: project.name
                    });
                }
            });
        } catch (e) {
            console.log(e);
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }

    async downloadPredictiveModel(projectId: PredictiveMaintenance['projectId'], predictiveMaintenanceTrainingId: string) {
        const toastId = createProgressToast();

        const project = this.rootStore.projectsStore.current;

        if (!project) {
            toast(PREDICTIVE_MAINTENANCE_IDENTITY_ERROR, { type: toast.TYPE.ERROR });
            this.isLoading = false;
            toast.dismiss(toastId);
            return;
        }
        try {
            const outputPath = s3Util.getPredictiveMaintenanceResultOutputFileKey(
                projectId,
                predictiveMaintenanceTrainingId
            );
    
            const {Body} = await Storage.get(outputPath, {
                download: true,
                level: 'private'
            }) as any;
    
            this.saveAs(Body, MODEL_FSTEP_FILENAME);
        } catch(e) {
            console.log(e);
        } finally {
            toast.dismiss(toastId);
        }
    }

    async getPredictiveModelImage(projectId: PredictiveMaintenance['projectId'], predictiveMaintenanceTrainingId: string) {
        const project = this.rootStore.projectsStore.current;

        const outputPath = s3Util.getPredictiveMaintenanceResultPictureKey(
            projectId,
            predictiveMaintenanceTrainingId
        );

        return await Storage.get(outputPath, {
            level: 'private'
        }) as string;
    }

    public getTemplateCSVFile = async ()  => {
        const path = s3Util.getPredictiveMaintenanceTemplateKey();
        const {Body} = await Storage.get(path, {
            download: true,
            level: 'public'
        }) as any;

        this.saveAs(Body, TEMPLATE_CSV_FILENAME);
    }

    private saveAs = (blob: any, fileName: string) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
}