import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { createProgressToast } from '../../components/Toasts/ProgressToast';
import container from '../../container/container';
import { Project, User } from '../../models/api';
import { RootStore } from './root';

const api = container.apiClient;

export class ProjectsTrashStore {
    list: Project[] = [];

    isLoading: boolean = false;
    isFetched: boolean = false;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    resetList() {
        runInAction(() => {
            this.list = [];
            this.isLoading = false;
            this.isFetched = false;
        });
    }

    async fetchList(userId?:  User['id']) {
        this.list = [];
        this.isLoading = true;

        try {
            const { data } = await api.projectTrashList({
                filter: {
                    userId: userId ? userId : this.rootStore.userStore.user?.id
                }
            });

            runInAction(() => {
                this.list = data;
                this.isFetched = true;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async restoreItem(id: string) {
        this.isLoading = true;
        const toastId = createProgressToast();

        try {
            await api.projectTrashRestore(id);

            runInAction(() => {
                this.list = this.list.filter(item => item.id !== id);
                this.rootStore.projectsStore.isFetched = false
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
                toast.dismiss(toastId);
            });
        }
    }
}