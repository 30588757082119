import { makeAutoObservable } from 'mobx';

export class SortOrder{
    public sortField: string = '';
    public sortOrder: string = '';
    public searchQuery: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public get queryObject(){
        return {
            sortField: this.sortField,
            sortOrder: this.sortOrder.toUpperCase(),
            searchQuery: this.searchQuery.toLowerCase()
        }
    }
}
