export enum VideoType {
    Mp4 = 'video/mp4',
    Mov = 'video/quicktime',
    M4v = 'video/m4v',
    Mpeg = 'video/mpeg',
    Avi = 'video/avi',
}

export enum ImageType {
    Jpeg = 'image/jpeg',
    Png = 'image/png',
}

export enum CodecType {
    Mp42 = 'mp42',
    Avc1 = 'avc1',
    Qt = 'qt',
    M4v = 'M4V'
}

export enum TextType {
    Plain = 'text/plain'
}

export enum ZipType {
    Zip = 'application/zip'
}

export enum CsvType {
    Csv = `text/csv`
}

const MimeTypes = { VideoType, ImageType };
export default MimeTypes;
