import React from 'react';

interface Props {
    color?: string
}

const CircularProgress: React.FC<Props> = ({ color }) => {
    return (
        <div className={`app-components-circular-progress border-${color || 'primary'}`} />
    );
};

export default CircularProgress;