import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import Layout from '../../components/Layout';
import ProjectsTable from './ProjectsTable';
import NewProjectPanel from './NewProjectPanel';
import Paper from '../../components/Paper';
import { useStores } from '../../store';
import DnaLoader from '../../components/DnaLoader';
import { ProjectCreate, ProjectType } from '../../models/api';
import { Routes } from '../../router/routes';
import WelcomeVideo from './welcomeVideo';

export const ProjectGroupPage: FunctionComponent = observer(() => {
    const { projectsStore } = useStores();
    const history = useHistory();

    const handleCreate = async (props: ProjectCreate) => {
      const data = await projectsStore.createItem(props);
      projectsStore.setCurrentProject(data.id);

      if (data.type === ProjectType.PoseDetection) {
        history.push(Routes.ModelSimulation);
      } else if (data.type === ProjectType.Modeling3d) {
        history.push(Routes.ModelGenerator);
      } else {
        history.push(Routes.Labels);
      }
    };

    return (
        <Layout>
            <>
                <div className="app-inner-layout">
                <Row>
                        <Col sm={12}>
                            <NewProjectPanel
                                onSubmit={handleCreate}
                                isLoading={projectsStore.isLoading}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Paper
                                rounded
                                className="mt-4"
                                hidden={!projectsStore.isFetched}
                            >
                                <ProjectsTable tableType={'projects'} urlSearchParam={history.location.search} />
                            </Paper>
                        </Col>
                    </Row>
                </div>

                {!projectsStore.isFetched && (
                    <DnaLoader />
                )}

                {projectsStore.isFetched && <WelcomeVideo />}
            </>
        </Layout>
    );
});
