import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalProps, Spinner } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import DnaLoader from '../../../components/DnaLoader';
import cn from 'classnames';
import _ from 'lodash';
import AnnotateModelById from '../AnnotateModalById';

const COLUMNS_N = 5;
const ROWS_PRELOAD_N = 2;
const ANNOTATION_PER_LABEL_LIMIT_ON_REQUEST = 100;

const MODAL_WIDTH = 1050;
const CELL_SIDE = 180;
const CELL_PADDING = 20;
const LABEL_COLOR_CIRCLE_SIDE = 10;
const VIEW_PERCENT_Y = 0.8;

const XS_SPINNER_WIDTH = 1;
const XS_SPINNER_SIDE = 12;

const getIsItemOnLastLine = (index: number = 6, total: number = 8) => {
    const itemsOnLastLine = total % COLUMNS_N;
    return total - index <= itemsOnLastLine && index >= COLUMNS_N;
};

interface Props extends ModalProps {
    inputLabelId: string | undefined;
}

export const AnnotationLabelEditor: React.FC<Props> = observer(
    ({ isOpen, onClosed, inputLabelId }) => {
        const { annotationsEditorStore, labelsStore, datasetImageStore } = useStores();

        const bodyScrollerRef = useRef<HTMLDivElement | null>(null);
        const [changeLabelPopupIndex, setChangeLabelPopupIndex] = useState<number | null>(null);
        const [datasetImageId, setDatasetImageId] = useState<string | null>(null);
        const [isAdditionalLoading, setIsAdditionalLoading] = useState(false);
        const [loadingLabelId, setLoadingLabelId] = useState<string | null>(null);
        const [offsetIndex, setOffsetIndex] = useState(0);

        const label = useMemo(() => {
            if (!inputLabelId) {
                return;
            }
            return labelsStore.list.find(({ id }) => id === inputLabelId);
        }, [inputLabelId, loadingLabelId, labelsStore.list]);

        const isLoading = useMemo(() => {
            if (!inputLabelId) {
                return true;
            }
            return (
                (annotationsEditorStore.annotationsForLabel[inputLabelId]?.length ?? 0) === 0 &&
                annotationsEditorStore.annotationsForLabelLoading[inputLabelId]
            );
        }, [
            annotationsEditorStore.annotationsForLabel?.[inputLabelId ?? ''],
            annotationsEditorStore.annotationsForLabelLoading?.[inputLabelId ?? '']
        ]);

        const changePopupOffset = useMemo(() => {
            if (!inputLabelId || changeLabelPopupIndex === null) {
                return null;
            }
            const cellStep = CELL_SIDE + CELL_PADDING;
            const colIndex = changeLabelPopupIndex % COLUMNS_N;
            const rowIndex = Math.floor(changeLabelPopupIndex / COLUMNS_N);
            const totalCount =
                annotationsEditorStore.annotationsForLabel[inputLabelId]?.length ?? 0;
            const isItemOnLastLine = totalCount
                ? getIsItemOnLastLine(changeLabelPopupIndex, totalCount)
                : false;
            const shiftY =
                labelsStore.list.length > 3
                    ? 40 + (Math.min(labelsStore.list.length, 8) - 4) * 44
                    : 0;
            return {
                x: CELL_PADDING * 0.5 + cellStep * colIndex,
                y: CELL_PADDING * 0.3 + cellStep * rowIndex - (isItemOnLastLine ? shiftY : 0)
            };
        }, [changeLabelPopupIndex]);

        useEffect(() => {
            setOffsetIndex(0);
        }, [inputLabelId]);

        useEffect(() => {
            if (!inputLabelId || annotationsEditorStore.annotationsForLabelLoading[inputLabelId]) {
                return;
            }
            let offsetIndexFormatted = offsetIndex;
            if (
                !annotationsEditorStore.annotationsForLabel[inputLabelId]?.length &&
                offsetIndex > 0
            ) {
                offsetIndexFormatted = 0;
            }
            (async () => {
                setIsAdditionalLoading(true);
                await annotationsEditorStore.fetchAnnotationsForLabel(
                    inputLabelId,
                    offsetIndexFormatted
                );
                setIsAdditionalLoading(false);
            })();
        }, [inputLabelId, offsetIndex]);

        const handleClose = (closed = true) => {
            if (inputLabelId) {
                annotationsEditorStore.overwriteLabel(inputLabelId);
            }
            setDatasetImageId(null);
            setChangeLabelPopupIndex(null);
            setOffsetIndex(0);
            if (closed) {
                onClosed && onClosed();
            }
        };

        const handleModalClick = () => {
            setDatasetImageId(null);
            setChangeLabelPopupIndex(null);
        };

        const handleModalScroll = () => {
            setDatasetImageId(null);
            setChangeLabelPopupIndex(null);

            const scrollTop = bodyScrollerRef.current?.scrollTop;
            if (!inputLabelId || !scrollTop || !label) {
                return;
            }

            const viewHeight = Math.floor(window.innerHeight * VIEW_PERCENT_Y);
            const pointCurrent = viewHeight + scrollTop;
            const cellStep = CELL_SIDE + CELL_PADDING;
            const dynamicLimit = annotationsEditorStore.annotationsForLabel[label.id]?.length % ANNOTATION_PER_LABEL_LIMIT_ON_REQUEST;
            const currentlyLoadedPerPage = dynamicLimit > 0 ? dynamicLimit : ANNOTATION_PER_LABEL_LIMIT_ON_REQUEST;
            const rowsLimit = currentlyLoadedPerPage / COLUMNS_N - ROWS_PRELOAD_N;
            const pointLimit = rowsLimit * cellStep;

            const regionIndex = Math.ceil(pointCurrent / pointLimit) - 1;
            
            if (regionIndex <= offsetIndex) {
                return;
            }

            setOffsetIndex(regionIndex);
        };

        const handleLabelChangeModalScroll = (e: React.WheelEvent) => {
            if (labelsStore.list.length > 7) {
                e.stopPropagation();
            }
        };

        const handleImageClick = (event: React.MouseEvent, i: number, datasetImgId: string) => {
            event.stopPropagation();
            if (i === changeLabelPopupIndex) {
                setChangeLabelPopupIndex(null);
                return;
            }
            setDatasetImageId(datasetImgId);
            setChangeLabelPopupIndex(i);
        };

        const rollbackLabelChange = () => {
            setLoadingLabelId(null);
        };

        const handleLabelChange = async (event: React.MouseEvent, labelId: string) => {
            event.stopPropagation();

            if (!inputLabelId || changeLabelPopupIndex === null) {
                rollbackLabelChange();
                return;
            }
            const annotation =
                annotationsEditorStore.annotationsForLabel[inputLabelId]?.[changeLabelPopupIndex];
            if (!annotation) {
                rollbackLabelChange();
                return;
            }

            setLoadingLabelId(labelId);
            const isSuccess = await annotationsEditorStore.changeLabelForAnnotation(
                annotation.id,
                labelId,
                inputLabelId
            );
            if (!isSuccess) {
                rollbackLabelChange();
                return;
            }
            setLoadingLabelId(null);
            setChangeLabelPopupIndex(null);
            setDatasetImageId(null);

            if (annotationsEditorStore.annotationsForLabel[inputLabelId]?.length === 0) {
                onClosed && onClosed();
            }
        };

        const handleAnnotateButtonClick = async (id: string | null) => {
            if (!id) return;
            const activeAnnotatingImage = await datasetImageStore.fetchDatasetImageById(id);

            if (!activeAnnotatingImage) return;
            datasetImageStore.annotate(activeAnnotatingImage);
        };

        return (
            <>
                {label ? (
                    <Modal
                        isOpen={isOpen}
                        style={{ maxWidth: MODAL_WIDTH }}
                        modalClassName='overflow-hidden'
                        toggle={() => handleClose()}
                        onClick={handleModalClick}
                    >
                        <ModalHeader toggle={() => handleClose()} className='align-items-center pl-4 pr-4'>
                            <div className='mb-1'>Annotation Label Editor</div>
                            <div className='d-flex'>
                                <span style={{ fontSize: 14 }}>
                                    <span className='mr-1'>Label:</span>
                                    {label.name}
                                </span>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span className='mr-2' style={{ fontSize: 14 }}>
                                    <span className='mr-1'>Annotations Shown:</span>
                                    {annotationsEditorStore.annotationsForLabel[label.id]?.length ??
                                        0}{' '}
                                    / {label.annotationAndClassificationCount ?? 0}
                                </span>
                                {isAdditionalLoading && (
                                    <Spinner
                                        size='sm'
                                        style={{
                                            borderWidth: XS_SPINNER_WIDTH,
                                            width: XS_SPINNER_SIDE,
                                            height: XS_SPINNER_SIDE
                                        }}
                                    />
                                )}
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div
                                ref={bodyScrollerRef}
                                className={cn(
                                    'position-relative d-flex flex-wrap overflow-auto pl-1',
                                    {
                                        'justify-content-start': !isLoading,
                                        'justify-content-center': isLoading
                                    }
                                )}
                                style={{
                                    minHeight: CELL_SIDE * 2.2,
                                    maxHeight: `${VIEW_PERCENT_Y * 100}vh`,
                                    gap: CELL_PADDING
                                }}
                                onWheel={handleModalScroll}
                            >
                                {isLoading && (
                                    <div className='mt-2'>
                                        <DnaLoader />
                                    </div>
                                )}
                                {annotationsEditorStore.annotationsForLabel[label.id]?.map(
                                    (annotation, i) => {
                                        const src =
                                            annotationsEditorStore.cachedCrops[annotation.id];
                                        return (
                                            <div
                                                key={`annotationCrop-${annotation.id}-${i}`}
                                                style={{
                                                    width: CELL_SIDE,
                                                    height: CELL_SIDE,
                                                    backgroundColor: '#424242'
                                                }}
                                                className='d-flex justify-content-center'
                                            >
                                                {src === 'error' ? (
                                                    <div
                                                        className='w-100 d-flex align-items-center justify-content-center border rounded'
                                                        style={{
                                                            height: CELL_SIDE,
                                                            backgroundColor: '#424242'
                                                        }}
                                                    >
                                                        <span className='text-white-50'>
                                                            No data
                                                        </span>
                                                    </div>
                                                ) : src ? (
                                                    <img
                                                        alt=''
                                                        src={src}
                                                        width='100%'
                                                        height={CELL_SIDE}
                                                        className='rounded'
                                                        style={{
                                                            cursor: 'pointer',
                                                            objectFit: 'contain'
                                                        }}
                                                        onClick={(event) =>
                                                            handleImageClick(
                                                                event,
                                                                i,
                                                                annotation.datasetImageId
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <div
                                                        className='w-100 d-flex align-items-center justify-content-center border rounded'
                                                        style={{ height: CELL_SIDE }}
                                                    >
                                                        <Spinner size='sm' />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                                {inputLabelId &&
                                    changeLabelPopupIndex !== null &&
                                    changePopupOffset !== null && (
                                        <div
                                            className='position-absolute overflow-auto card card-border rounded p-3'
                                            style={{
                                                left: changePopupOffset.x,
                                                top: changePopupOffset.y,
                                                maxHeight: CELL_SIDE * 2
                                            }}
                                            onWheel={handleLabelChangeModalScroll}
                                        >
                                            <div className='mb-2'>Re-label:</div>
                                            {labelsStore.list.map((currentLabel, i) => (
                                                <button
                                                    key={`changeLabelButton-${currentLabel.id}-${i}`}
                                                    className={cn(
                                                        'btn btn-light d-flex align-items-center justify-content-start px-3',
                                                        {
                                                            'mb-2':
                                                                i !== labelsStore.list.length - 1
                                                        }
                                                    )}
                                                    disabled={
                                                        currentLabel.id === inputLabelId ||
                                                        loadingLabelId !== null
                                                    }
                                                    onClick={(event) =>
                                                        label &&
                                                        handleLabelChange(event, currentLabel.id)
                                                    }
                                                >
                                                    {currentLabel.id === loadingLabelId ? (
                                                        <Spinner size='sm' />
                                                    ) : (
                                                        <div
                                                            className='rounded-circle'
                                                            style={{
                                                                width: LABEL_COLOR_CIRCLE_SIDE,
                                                                height: LABEL_COLOR_CIRCLE_SIDE,
                                                                backgroundColor: currentLabel.color
                                                            }}
                                                        />
                                                    )}
                                                    <div className='ml-2'>
                                                        {_.truncate(currentLabel.name, {
                                                            length: 12
                                                        })}
                                                    </div>
                                                </button>
                                            ))}
                                            <div className='my-2'>Image:</div>
                                            <Button
                                                color='primary'
                                                onClick={() =>
                                                    handleAnnotateButtonClick(datasetImageId)
                                                }
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    )}
                            </div>
                        </ModalBody>
                    </Modal>
                ) : null}
                <AnnotateModelById handleClose={() => handleClose(false)} inputLabelId={inputLabelId} />
            </>
        );
    }
);
