import { useState } from 'react';
import { useStores } from '../../../store';
import Dropzone from 'react-dropzone';
import cx from 'classnames';
import PageTitle from '../../../components/PageTitle';
import { observer } from 'mobx-react-lite';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from 'reactstrap';
import { Extensions } from '../../../models/extensions';
import { concatFilesNameWithMaxLength } from '../../../utils/helper-functions';

export const ImportButton = observer(() => {    
    const { datasetImageStore, projectsStore, imageToZipStore, userStore } = useStores();
    const [isOpen, setIsOpen] = useState(false);

    const [textDropZone, setTextDropZone] = useState('Drop files here or click to upload');
    const [fileObject, setFileObject] = useState<File | null>(null);
    const [isDisabledButtonImport, setIsDisabledButtonImport] = useState(false);

    const isDropzoneDisabled = imageToZipStore.inProgress || datasetImageStore.hasUnprocessedItems;
    const project = projectsStore.current!;

    const handleDrop = (files: File[]) => {
        if (!files.length) {
            return;
        }

        const file = files[0]
        setFileObject(file)
        setTextDropZone(concatFilesNameWithMaxLength(files))
        setIsDisabledButtonImport(false)
    }

    const handleImportDataset = async () => {
        if (!fileObject) {
            return;
        }

        setIsDisabledButtonImport(true)
        await datasetImageStore.importDataset(project.id, fileObject, userStore.user?.email ?? '')
        setIsDisabledButtonImport(false)
        setIsOpen(false);
    };

    const toggleModal = () => {
        if (isDisabledButtonImport) {
            return;
        }

        setIsOpen((prev) => !prev);
        setFileObject(null)
        setTextDropZone('Drop files here or click to upload')        
    };

    return (
        <>
            <div>
                <Button
                    className="btn mr-1 mr-lg-2 text-nowrap"
                    color="primary"
                    onClick={toggleModal}
                    style={{ height: '35px' }}
                    disabled={datasetImageStore.hasUnprocessedItems}
                >
                    Import
                </Button>
            </div>
            <Modal isOpen={isOpen} toggle={toggleModal} centered>
                <ModalHeader toggle={toggleModal}>
                    <PageTitle
                        heading="Dataset Import"
                        icon="fas fa-chart-pie icon-gradient bg-warm-flame"
                        subheading="Upload your dataset from a zip archive"
                        className="m-0 p-0"
                    />
                </ModalHeader>
                <ModalBody className="modal-body__athens-gray pt-3">
                    <div className="d-flex">
                        <div className="d-flex flex-grow-1 flex-wrap">
                            <div className="d-flex w-100 align-items-center mb-2">
                                <div className="w-40 flex-shrink-0 font-weight-bold text-primary text-nowrap">
                                    Choose VOC .zip archive:
                                </div>
                                <div
                                    className={cx('dropzone-wrapper h-auto w-100 ml-3', {
                                        'dropzone-disabled': isDropzoneDisabled
                                    })}
                                >
                                    <Dropzone
                                        onDrop={(files) => handleDrop(files)}
                                        disabled={isDropzoneDisabled}
                                        accept={`.${Extensions.Zip}`}
                                        maxFiles={1}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <div className="dropzone-content p-2">
                                                    {textDropZone}
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                            <div className="d-flex w-100 align-items-center mb-2 mt-2 justify-content-between">
                                <span className="font-weight-bold text-primary">
                                    Import status will be sent to:
                                </span>
                                {userStore.user?.email ?? "N/A"}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={handleImportDataset}
                        disabled={!!!fileObject || isDisabledButtonImport}
                    >
                        Import {isDisabledButtonImport ? <Spinner className="ml-1" size="sm" /> : null}
                    </Button>
                    <Button color="primary" onClick={toggleModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
});
