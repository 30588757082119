import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Row } from "reactstrap";
import DnaLoader from "../../../components/DnaLoader";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import { useStores } from "../../../store";
import UserActivityListTable from "./UserActivityListTable";

const UserActivity:React.FC = observer(() => {
    const {
        eventsStore
    } = useStores();

    React.useEffect(() => {
        eventsStore.fetchUsersEvents();
    }, [eventsStore.pagination.sortOrder.sortOrder, eventsStore.pagination.page])
  
    return (
        <Layout>
            <>
                <PageTitle 
                    heading="User Activity"
                    icon="fas fa-users icon-gradient bg-warm-flame"
                    showCloseButton={true}
                />
                {!eventsStore.isFetched && <DnaLoader />}
                <Row>
                    <Col sm={12}>
                        <UserActivityListTable 
                            items={Object.values(eventsStore.usersEventList)}
                            isLoading={true}
                            pagination={eventsStore.pagination}
                        />
                    </Col>
                </Row>
            </>
        </Layout>
    );
});

export default UserActivity;