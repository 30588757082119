import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store';
import { DetectionModelGenerator } from '../../models/api';
import { Col, Container, Row } from 'reactstrap';
import { ModelGeneratorsTable } from './ModelGeneratorsTable';
import { ModelGeneratorVideoUploadPanel } from './ModelGeneratorVideoUploadPanel';
import Paper from '../../components/Paper';
import DnaLoader from '../../components/DnaLoader';
import { longOperationsConfig } from '../../config/long-operations';
import { s3Util } from '../../utils/s3';
import { changeFilenameExtension } from '../../utils/helper-functions';
import { Extensions } from '../../models/extensions';

export const ModelGeneratorPage: React.FC = observer(() => {
    const { userStore, projectsStore, detectionModelGeneratorsStore, detectionModelDownloadsStore } = useStores();

    const [isStartingProcess, setIsStartingProcess] = useState(false);

    const identityId = userStore.getIdentityIdSync();
    const project = projectsStore.current;

    useEffect(() => {
        if (!detectionModelGeneratorsStore.isFetched) {
            detectionModelGeneratorsStore.fetchList();
        }
    }, [detectionModelGeneratorsStore.isFetched]);

    useEffect(() => {
        detectionModelGeneratorsStore.isFetched = false;
    }, [projectsStore.current?.id]);

    useEffect(() => {
        const intervalId = setInterval(
            () => detectionModelGeneratorsStore.fetchList(),
            longOperationsConfig.refreshInterval
        );
        return () => clearInterval(intervalId);
    }, [detectionModelGeneratorsStore]);

    const handleProcess = async (file: File, frames: number) => {
        setIsStartingProcess(true);
        await detectionModelGeneratorsStore.createItem(file, frames);
        setIsStartingProcess(false);
    };

    const handleCancel = async (item: DetectionModelGenerator) => {
        await detectionModelGeneratorsStore.cancelItem(item.id);
    };

    const handleDelete = async (item: DetectionModelGenerator) => {
        await detectionModelGeneratorsStore.deleteItem(item.id);
    };

    const handleDownload = async (item: DetectionModelGenerator) => {
        if (!project || !identityId) {
            return;
        }
        const privateFolder = s3Util.getS3PrivateFolder(identityId);
        const outputFilePath = s3Util.getModelGeneratorResultKey(project.id, item.id);
        await detectionModelDownloadsStore.downloadItem({
            url: `${privateFolder}/${outputFilePath}`,
            fileName: changeFilenameExtension(item.filename, Extensions.Glb)
        });
    };

    return (
        <Layout>
            <>
                <PageTitle
                    heading='3D Model Generator'
                    icon='fas fa-play icon-gradient bg-warm-flame'
                    subheading='Generate a 3D model from a video'
                    showCloseButton={true}
                />
                <Container style={{padding: 0, margin: 0}}>
                    <Row style={{gap: '12px'}}>
                        <Col sm={12}>
                            <ModelGeneratorVideoUploadPanel
                                isStartingProcess={isStartingProcess}
                                onProcess={handleProcess}
                            />
                        </Col>
                    </Row>
                </Container>

                {!detectionModelGeneratorsStore.isFetched && <DnaLoader />}

                <Row className='mt-4'>
                    <Col sm={12}>
                        <Paper rounded hidden={!detectionModelGeneratorsStore.isFetched}>
                            <ModelGeneratorsTable
                                identityId={identityId}
                                project={project}
                                items={detectionModelGeneratorsStore.list}
                                onCancel={handleCancel}
                                onDelete={handleDelete}
                                onDownload={handleDownload}
                            />
                        </Paper>
                    </Col>
                </Row>
            </>
        </Layout>
    );
});
