import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes, faPen } from '@fortawesome/free-solid-svg-icons';
import { LicenseTypeUI, User, UserUsageTypeUI } from '../../../../models/api';
import ActionButton from '../../../../components/BootstrapTable/ActionButton';
import { noDataText } from '../../../../components/BootstrapTable';
import DeleteConfirmation from '../../../../components/Modals/DeleteConfirmation';
import { Routes } from '../../../../router/routes';
import { dateConfig } from '../../../../config/date';
import { toast } from 'react-toastify';

interface Props {
    items: User[];
    isLoading: boolean;
    onDelete?: (id: string) => void;
    onImpersonation?: (id: string) => void;
}

const UserListTable: React.FC<Props> = ({ items, isLoading, onDelete, onImpersonation }) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState<User>();
    const history = useHistory();

    const handleDelete = () => {
        if (!deleteConfirmation) {
            return;
        }

        onDelete && onDelete(deleteConfirmation.id);
        setDeleteConfirmation(undefined);
    };

    const handleEditButtonClick = (row: User) => () => {
        history.push(Routes.AdminUserEdit.replace(':id', row.id));
    };

    const handleDeleteButtonClick = (row: User) => () => {
        setDeleteConfirmation(row);
    };

    const handleDeleteConfirmationClosed = () => {
        setDeleteConfirmation(undefined);
    };

    const handleImpersonationButtonClick = (row: User) => () => {
        if (!row.storagePath) {
            toast('The user should login at least once to allow impersonation under this user', {type:toast.TYPE.ERROR});
            return;
        }
        onImpersonation && onImpersonation(row.id);
    };

    const columns: ColumnDescription<User>[] = [
        {
            dataField: 'rowIndex',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            headerClasses: 'app-components-boostrap-table__th-xs',
            formatter: (_cel, row, rowIndex) => rowIndex + 1
        },
        {
            dataField: 'name',
            text: 'User Name',
            sort: true,
            formatter: (_cel, row) => {
                return `${row.name} ${row.surname}`;
            }
        },
        {
            //TODO add Verified
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Create Date',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => dayjs(row.createdAt).format(dateConfig.formats.date)
        },
        {
            dataField: 'lastActivity',
            text: 'Last Activity',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            sort: true,
            formatter: (_cel, row) => dayjs(row.lastActivity).format(dateConfig.formats.date)
                
        },
        {
            dataField: 'licenseType',
            text: 'License Type',
            classes: 'text-uppercase',
            sort: true,
            formatter: (_cel, row) => {
                return LicenseTypeUI[row.licenseType];
            }
        },
        {
            dataField: 'usageType',
            text: 'Usage Type',
            sort: true,
            formatter: (_cel, row) => {
                return UserUsageTypeUI[row.usageType];
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerClasses: 'text-nowrap',
            classes: 'text-nowrap',
            headerAlign: 'center',
            align: 'center',
            formatter: (_cel, row) => {
                const tooltipEditId = `pages-admin-user-list-table_edit-button-${row.id}`;
                const tooltipDeleteId = `pages-admin-user-list-table_delete-button-${row.id}`;
                const tooltipImpersonationId = `pages-admin-user-list-table_impersonation-button-${row.id}`;

                return (
                    <>
                        <ActionButton
                            color='primary'
                            onClick={handleImpersonationButtonClick(row)}
                            id={tooltipImpersonationId}
                            className='ml-2'
                            tooltipText='Impersonation'
                        >
                            <FontAwesomeIcon icon={faUser} />
                        </ActionButton>

                        <ActionButton
                            color='warning'
                            id={tooltipEditId}
                            tooltipText='Edit item'
                            className='ml-2'
                            onClick={handleEditButtonClick(row)}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </ActionButton>

                        <ActionButton
                            color='danger'
                            onClick={handleDeleteButtonClick(row)}
                            id={tooltipDeleteId}
                            className='ml-2'
                            tooltipText='Delete item'
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </ActionButton>
                    </>
                );
            }
        }
    ];

    return (
        <>
            <BootstrapTable
                bootstrap4
                keyField='id'
                data={items}
                columns={columns}
                striped
                bordered={false}
                wrapperClasses='table-responsive'
                defaultSorted={[{ dataField: 'name', order: 'asc' }]}
                classes='m-0'
                noDataIndication={noDataText}
            />

            <DeleteConfirmation
                body={`Are you sure you want to delete the "${deleteConfirmation?.name}" user?`}
                isOpen={!!deleteConfirmation}
                onConfirm={handleDelete}
                onClosed={handleDeleteConfirmationClosed}
                busyIndication={isLoading}
            />
        </>
    );
};

export default UserListTable;
