import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import path from "path";
import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { Extensions } from "../../../models/extensions";
import { CsvType } from "../../../models/mimeTypes";
import { PREDICTIVE_MAINTENANCE_FILE_TYPE_ERROR, PREDICTIVE_MAINTENANCE_MAXIMUM_DATA_ERROR, PREDICTIVE_MAINTENANCE_MINIMUM_DATA_ERROR, PREDICTIVE_MAINTENANCE_SAME_HEADINGS_ERROR } from "../../../utils/errors";
import { truncate } from "../../../utils/helper-functions";

interface Props {
    isStartingProcess: boolean;
    onProcess: (file: File) => void;
}

const POSSIBLE_PREDICTIVE_MAINTENANCE_MODEL_TRAINING_EXTENSIONS = [Extensions.Csv];
const POSSIBLE_PREDICTIVE_MAINTENANCE_MODEL_TRAINING_TYPES = [CsvType.Csv];

const MINIMUM_QUANTITY_DATA = 100;
const MAXIMUM_QUANTITY_DATA = 500000;
const VALID_HEADINGS = ['timestamp', 'y1'];

export const PredictiveMaintenanceModelTrainingUploadPanel:React.FC<Props> = ({isStartingProcess, onProcess}) => {
    const [file, setFile] = React.useState<File | null>(null);
    const [disableTrainButton,setDisableTrainButton] = React.useState<boolean>(false);

    const handleDropFiles = async (droppedFile: File) => {
        if (!isCorrectFileType(droppedFile)) {
            toast(PREDICTIVE_MAINTENANCE_FILE_TYPE_ERROR('csv'), { type: toast.TYPE.ERROR });
            return;
        }
        setFile(droppedFile);
        readFile(droppedFile);
    };

    const isCorrectFileType = (droppedFile: File) => {
        const mimeType = droppedFile.type;
        const extension = path.extname(droppedFile.name).slice(1);

        return (
            !!POSSIBLE_PREDICTIVE_MAINTENANCE_MODEL_TRAINING_TYPES.find((item) => item === mimeType) &&
            !!POSSIBLE_PREDICTIVE_MAINTENANCE_MODEL_TRAINING_EXTENSIONS.find((item) => item === extension)
        );
    };

    const validateHeadings = (headings:string[]) => {
        for(let i = 0; i < headings.length; i++) {
            if(!VALID_HEADINGS.every((e) => headings.includes(e))) {
                return false;
            } else if(headings.length < 2) {
                return false;
            } else if(headings[0] !== VALID_HEADINGS[0] || headings[1] !== VALID_HEADINGS[1]) {
                return false;
            } else {
                continue
            }
        }
        return true;
    }

    const validateData = (data:string[]) => {
        let isSameHeadings = false;
        let isMinimumData = false;
        let isMaximumData = false;

        if(data.length - 1 < MINIMUM_QUANTITY_DATA) {
            isMinimumData = true;
        } if(data.length - 1 > MAXIMUM_QUANTITY_DATA) {
            isMaximumData = true;
        } if(validateHeadings(data[0].split(','))) {
            isSameHeadings = true;
        }

        return [isSameHeadings, isMinimumData, isMaximumData];
    };
    
    const readFile = (file: File | null) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (evt) => {
                if (!evt?.target?.result) {
                    return;
                }
                const { result } = evt.target;
                const data = result.toString().split(/\r\n|\n/);
                const validationAnchors = validateData(data);
                if(!validationAnchors[0]) {
                    toast(PREDICTIVE_MAINTENANCE_SAME_HEADINGS_ERROR, {type: toast.TYPE.ERROR});
                    setDisableTrainButton(true);
                } if(validationAnchors[1]) {
                    toast(PREDICTIVE_MAINTENANCE_MINIMUM_DATA_ERROR, {type: toast.TYPE.ERROR});
                    setDisableTrainButton(true);
                } if(validationAnchors[2]) {
                    toast(PREDICTIVE_MAINTENANCE_MAXIMUM_DATA_ERROR, {type: toast.TYPE.ERROR});
                    setDisableTrainButton(true);

                } if(validationAnchors[0] && !validationAnchors[1] && !validationAnchors[2]) {
                    setDisableTrainButton(false);
                    return;
                }
            };
        reader.readAsBinaryString(file);
        }
    }

    const handleProcess = async () => {
        if (file) {
            onProcess(file);
        }
    };

    return (
        <>
            <div className='d-flex align-items-center w-100'>
                <div className='font-weight-bold text-primary flex-shrink-0 mr-3'>
                    Upload: 
                </div>
                <div className='position-relative dropzone-wrapper h-auto w-auto ml-1'>
                    <Dropzone onDrop={(files) => files?.[0] && handleDropFiles(files[0])}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className='dropzone-content content-width-md p-2'>
                                    <span>
                                        {file
                                            ? truncate(file.name, 22)
                                            : 'Drop files here or click to upload'}
                                    </span>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
                <Button
                    type='button'
                    color='primary'
                    className='ml-3 flex-shrink-0'
                    disabled={!file || disableTrainButton}
                    onClick={handleProcess}
                >
                    Train New Model
                </Button>
            </div>
        </>
    );
}