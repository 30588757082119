import { AxiosInstance } from 'axios';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import container from '../container/container';

const NEW_APP_VERSION_MSG =
    'A new application version is available. The application will now be refreshed automatically.';

class AppChecker {
    private lastAppVersion;
    private appVersion;
    private isSystemLock;
    private isUploadingRequest;
    private isReloading;

    constructor() {
        this.lastAppVersion = '';
        this.appVersion = '';
        this.isSystemLock = true;
        this.isUploadingRequest = false;
        this.isReloading = false;
    }

    setAppVersion(ver: string) {
        this.appVersion = ver;
    }
    setIsUploadingRequest(isUploading: boolean) {
        this.isUploadingRequest = isUploading;
    }

    public checkResponses() {
        if (!this.appVersion || this.isUploadingRequest) {
            return;
        }

        if (!this.lastAppVersion) {
            this.lastAppVersion = this.appVersion;
            console.log('App Version:', this.appVersion);
            return;
        }

        if (this.lastAppVersion !== this.appVersion && !this.isReloading) {
            this.isReloading = true;
            toast(NEW_APP_VERSION_MSG, {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
            });
            setTimeout(() => {
                window.location.reload();
                this.isReloading = false;
            }, 5000);
        }
    }

    public checkSystemLocked(anchor: string) {
        if(this.isUploadingRequest) {
            return;
        }

        if(anchor === 'true' && this.isSystemLock && window.location.pathname !== '/system-lock') {
            this.isSystemLock = false;
            window.location.href = `${window.location.origin}/system-lock`;
        }
    }
}

export const setupAxios = (axios: AxiosInstance) => {
    const appChecker = new AppChecker();

    axios.interceptors.response.use(
        (response) => {
            const { data, appVersion, sysLock } = response.data;

            appChecker.setAppVersion(appVersion);
            appChecker.checkResponses();
            appChecker.checkSystemLocked(sysLock);
            return data ? { ...response, data } : response;
        },
        async (e) => {
            const { response } = e;
            const originalRequest = e.config;

            if (
                !originalRequest._retry &&
                response &&
                response.status === 401 &&
                response.data.errors.message === 'jwt expired'
            ) {
                const accessToken = (await Auth.currentSession()).getAccessToken();
                container.apiClient.setAuthorizationHeader(accessToken.getJwtToken());
                originalRequest._retry = true;
                originalRequest.headers.Authorization = `Bearer ${accessToken.getJwtToken()}`;
                return axios(originalRequest);
            }
            return Promise.reject(e);
        }
    );

    axios.interceptors.request.use(
        (request) => {
            // find uploading requests by regexp
            const uploadingRegexp = /(\/projects\/).+(\/dataset-images)(?!\/)/;
            const isUploadingRequest =
                request.method === 'post' && uploadingRegexp.test(request.url!);

            // make checker aware of these requests
            appChecker.setIsUploadingRequest(isUploadingRequest);

            return request;
        },
        async (e) => {
            const { response } = e;
            const originalRequest = e.config;

            if (
                !originalRequest._retry &&
                response &&
                response.status === 401 &&
                response.data.errors.message === 'jwt expired'
            ) {
                const accessToken = (await Auth.currentSession()).getAccessToken();
                container.apiClient.setAuthorizationHeader(accessToken.getJwtToken());
                originalRequest._retry = true;
                originalRequest.headers.Authorization = `Bearer ${accessToken.getJwtToken()}`;
                return axios(originalRequest);
            }
            return Promise.reject(e);
        }
    );
};
